export class ExamSchedulePojo {

	 examScheduleId:number;
	 examId:number;
	 classId:number;
	 subjectId:number;
	 subjectName:string;
	 examDate:Date;
	 totalMark:number;
	 passMark:number;
	 academicYearId:number;
	 accId:number;
}  