export class ExamEntryPojo {

	 examEntryId:number;
	 classId:number;
	 subjectId:number;
	 sectionId:number;
	 examDate:Date;
	 examName:string;
	 studentId:number;
	 admissionNo:string;
	 examStatus:string;
	 name:string;
	 rollNo:string;
	 academicYearId:number;
	 reason:string;
	 securedMark:number;
	 minMark:number;
	 maxMark:number;
	 totalMark:number;
     examMasterId:number;
}
export class ExamEntryResponsePojo {

	code:number;
	response:string;
	academicYearId:number;
	classId:number;
	sectionId:number;
	examId:number;
	subjectId:number;
   list:ExamEntryPojo[]=[];
}