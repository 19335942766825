import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { PaymentEntryService } from "src/app/management/Accounts/payment/payment.service";
import { LedgerPojo } from "src/app/pojo/ledger.pojo";
import { PaymentPojo } from "src/app/pojo/payment-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LedgercreationService } from "src/app/management/Masters/ledgercreation/ledgercreation.service";
import { AccountMasterPojo } from "src/app/pojo/account-master.pojo";
import { AccountMasterService } from "src/app/management/Admin/account-master/account-master.service";
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { ReceiptEntryService } from '../receipt-entry/receipt-entry.service';
import { ReceiptHeadService } from '../receipt-head/receipt-head.service';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';

declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.scss']
  })
export class PaymentComponent implements OnInit {
    model: NgbDateStruct;
   date: { year: number, month: number };
   form: boolean = false;
   
   addEntryDate: NgbDateStruct;
   editEntryDate: NgbDateStruct;
   addInstrumentDate: NgbDateStruct;
   editInstrumentDate: NgbDateStruct;
   
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    ledgers: LedgerPojo[];
    
    editPayment = new PaymentPojo();
    payments: PaymentPojo[] = [];
    paymentPojo = new PaymentPojo();
    
    body = [];
    content = [];
    documentTime:string;
   // picksledgersByAccountGroup: LedgerPojo[] = [];
    pickspaidledgersByAccountGroup: LedgerPojo[] = [];
    receiptSpecHeadArr:ReceiptHeadPojo[]=[];
    ledgerId: LedgerPojo[];
    public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    editEntryAmount:number;
    addEntryAmount:number;
    accountMasterArr: AccountMasterPojo[] = [];
    specLedgers:LedgerPojo[]=[];
    ledgerGrpArr:LedgerGroupPojo[]=[];
    constructor( private paymentService: PaymentEntryService, private receiptheadService:ReceiptHeadService,
            private ledgercreationService: LedgercreationService,private accountmasterserivce: AccountMasterService,
           ) { }

  ngOnInit() {
     
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
      this.getLedgerByAccountGroup();
      this.getAllLedger();
      this.getAllPayment();
      this.getAccountMasterAll();
      this.getLedgerGrpAll();
  }
  getAccountMasterAll() {
      this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
          this.accountMasterArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Account All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  } getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  getAllReceiptHeadByLedgerId() {
    this.receiptSpecHeadArr=[];
     this.receiptheadService.getReceiptHeadByLedgerId(this.editPayment.ledgerId,this.loggedUser.accId).subscribe( rs => {
        this.receiptSpecHeadArr = rs;
       
    }, error => {
        bootbox.alert( 'Error' + error );
       } );
  }
  getLedgerGrpAll() {
    this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
        this.ledgerGrpArr = response;
      }, error => {
        bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
  }
  getLedgerByLedgerGroupId(event){
    this.specLedgers=[];
    if(this.editPayment.ledgerGroupId>0){
    this.ledgercreationService.getLedgerByLedgerGroupId(this.editPayment.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
      this.specLedgers = response;
    }, error => {
      bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    } );
  }
  }
  getLedgerGroupById( id ) {

    if ( this.ledgerGrpArr.find( r => r.ledgerGroupId == id ) ) {
        return this.ledgerGrpArr.find( r => r.ledgerGroupId == id ).ledgerGroupName;
    }
    else {
        return id;
    }
}
  getLedgerByAccountGroup() {
    
      this.ledgercreationService.getLedgerByType('PAID',this.loggedUser.accId).subscribe( log => {
          this.pickspaidledgersByAccountGroup = log;
      } );
  }

  getLedgerById( id ) {

    if ( this.ledgers.find( r => r.ledgerId == id ) ) {
        return this.ledgers.find( r => r.ledgerId == id ).ledgerName;
    }
    else {
        return id;
    }
}
getPaidLedgerById( id ) {

  if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
      return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
  }
  else {
      return id;
  }
}
  getAllLedger() {
      this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
          this.ledgers = rs;
      }, error => {
          alert( error );
      } );
  }  
  
  getAllPayment() {
      this.isLoading = true;
      this.paymentService.getPaymentAll(this.loggedUser.accId).subscribe( rs => {
          this.payments = rs;
          this.isLoading = false;

      }, error => {
          bootbox.alert( 'Error' + error );
          this.isLoading = false;
      } );
  }
  

  clickEdit( arg ) {
      this.editPayment = Object.assign( {}, arg );
      if(this.editPayment.paymentDate != null && this.editPayment.paymentDate != undefined){
        ( <HTMLFormElement>document.getElementById( 'editPaymentDate' ) ).value = this.editPayment.paymentDate;
        }
        if(this.editPayment.instrumentDate != null && this.editPayment.instrumentDate != undefined){
            ( <HTMLFormElement>document.getElementById( 'editInstrumenEntrytDate' ) ).value = this.editPayment.instrumentDate;
            }
            this.getAllReceiptHeadByLedgerId();
            this.getLedgerByLedgerGroupId(event);
         }  

  editClass() {
      let editEntry = new PaymentPojo();
      editEntry = this.editPayment;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.editPayment.paymentDate = moment(( <HTMLInputElement>document.getElementById( 'editPaymentDate' ) ).value ).toDate();
      this.editPayment.instrumentDate = moment(( <HTMLInputElement>document.getElementById( 'editInstrumenEntrytDate' ) ).value ).toDate();

         this.paymentService.updatePayment(editEntry).subscribe( response => {
          bootbox.alert( { message: 'Payment has been successfully updated.', className: 'text-success h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          $( '#editModal' ).modal( 'hide' );
          this.getAllPayment();
      }, error => {
          bootbox.alert( { message: 'Update Payment Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.payments.indexOf( this.payments.find( r => r.paymentId == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.paymentService.deletePayment( this.id,this.loggedUser.accId, this.loggedUser.loggedUserId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
              this.payments.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.payments.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          $( '#deleteModal' ).modal( 'hide' );
          this.getAllPayment();
      }, error => {
          bootbox.alert( { message: 'Delete  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
  downloadPDF() {
       
    this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
    this.content.push( {
        text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
       } );
        this.content.push( {text: 'PAYMENT ENTRY REPORT  ', fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
    } );
       this.content.push( {
         text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
         fontSize: 10, bold: true, alignment: 'center'
     } );
     
   
     this.content.push( {
         text: '\n', fontSize: 8, alignment: 'center'
     } );

     this.body.push( [
         { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Payment Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Payment No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Ledger Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Paid Ledger', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            ] );

     for ( let i = 0; i < this.payments.length; i++ ) {
        
         this.body.push( [
             { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: moment(this.payments[i].paymentDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.payments[i].paymentNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.getLedgerById(this.payments[i].ledgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.getPaidLedgerById(this.payments[i].dLedgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
            { text: this.payments[i].amount.toFixed(2), fontSize: 9, font: 'TimesNewRoman', alignment: 'right' },
                  ] );
     }

     this.content.push( {
         'table': {
             headerRows: 1,
             'body': this.body,
             widths: ['5%', '12%', '12%','29%', '30%', '12%']
         }
     } );
    

     const dd = {
         'content': this.content,
         pageOrientation: 'landscape',
         pageSize: 'A4',
         footer: ( currentPage, pageCount ) => {
             return {
                 margin: 10,
                 columns: [
                     {
                         fontSize: 9,
                         text: [
                             {
                                 text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                 '\n',
                                 margin: [0, 20]
                             },
                             {
                                 text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                             }
                         ],
                         alignment: 'center'
                     }
                 ]
             };
         }
     };

     pdfMake.createPdf( dd ).open();
     this.body = [];
     this.content = [];

 }
}
