import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { LedgerStatementPojo } from 'src/app/pojo/ledger-statement.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IncomeExpenditureReportPojo, ConsolidationReportPojo } from 'src/app/pojo/income-expenditure-report.pojo';
import { LedgerStatementService } from '../../Reports/ledger-statement/ledger-statement.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { TrailBalanceConsolidationReportPojo, TrailBalanceDetailsReportPojo, TrailBalanceReportPojo } from 'src/app/pojo/trial-balance-report.pojo';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
import { ReceiptHeadService } from '../receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';

@Component({
  selector: 'app-trial-balance',
  templateUrl: './trial-balance.component.html',
  styleUrls: ['./trial-balance.component.scss']
})
export class TrialBalanceComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  st = new IncomeExpenditureReportPojo();
  //isLoading: boolean;
  public filterQuery = '';
  public rowsOnPage = 10;
  public sortBy = 'i';
  public sortOrder = 'asc';
  isLoading: Boolean = false;
  body = [];
  content = [];
  documentTime: string;

  loggedUser = new MUserMasterPojo();
  ledgers: LedgerPojo[] = [];
  statement: TrailBalanceReportPojo[] = [];
  consolidatePojo = new TrailBalanceConsolidationReportPojo();
  ledger = new LedgerPojo();
  itemledgers: LedgerPojo[] = [];

  documentDate: string;
  startDate;
  endDate;
  resultFromDate;
  resultToDate;
  resultLedger;
  openingBal: number = 0;
  closingBal: number = 0;
  accountMasterArr:AccountMasterPojo[]=[];
  ledgerGroupArr:LedgerGroupPojo[]=[];
  receiptheadArr:ReceiptHeadPojo[]=[];
  academic:AcademicYearMasterPojo[] =[];
  academicYr;
  resultFromYr;
  resultToYr;
  constructor(private settingService:AcademicSettingService,private receiptHeadService:ReceiptHeadService,private accountMasterService:AccountMasterService,private ledgerstatementService: LedgerStatementService, private ledgercreationService: LedgercreationService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem("schoolManagementUserDetail"));
    this.documentDate = moment().format('DD-MM-YYYY');
    
    this.settingService.getAcademicYrAll().subscribe( response => {
      this.academic = response;
      for(let i=0;i<this.academic.length;i++){
          if(this.academic[i].academicFlag == 1){
                this.academicYr = this.academic[i].academicYear;
                }
      }
      
        }, error => {
      bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
       } );
    this.getAllLedger();
    this.accountMasterAll();
    this.getAllLedgerGroup();
    this.receiptHeadMasterAll();
  }
 
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  receiptHeadMasterAll() {
    this.receiptHeadService.getReceiptHeadAll(this.loggedUser.accId).subscribe(response => {
      this.receiptheadArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getReceiptHeadNameById(id) {
    if (this.receiptheadArr.find(r => r.receiptHeadId == id)) {
      return this.receiptheadArr.find(r => r.receiptHeadId == id).receiptHeadName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }

  getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
      this.ledgers = rs;
       let d = new LedgerPojo();
      for (let i = 0; i < this.ledgers.length; i++) {
        this.itemledgers.push(this.ledgers[i]);
      }
    }, error => {
      bootbox.alert('Error' + error);
    });
  }
  getAllLedgerGroup() {
    this.ledgercreationService.getLedgerGroupAll().subscribe(rs => {
      this.ledgerGroupArr = rs;
     }, error => {
      bootbox.alert('Error' + error);
    });
  }

  getLedgerById(id) {
    if (this.ledgers.find(r => r.ledgerId == id)) {
      return this.ledgers.find(r => r.ledgerId == id).ledgerName;
    }
    else {
      return id;
    }
  }
  getLedgerGroupById(id) {
    if (this.ledgerGroupArr.find(r => r.ledgerGroupId == id)) {
      return this.ledgerGroupArr.find(r => r.ledgerGroupId == id).ledgerGroupName;
    }
    else {
      return id;
    }
  }

  fetchstatement() {
    this.isLoading = true;
    this.consolidatePojo = new TrailBalanceConsolidationReportPojo();
    this.statement = [];
    var str = this.academicYr; 
    var fromYr = str.slice(0, 4);
    var toYr = Number(fromYr)+1;
    this.ledgerstatementService.getTrialBalanceReportByDate(fromYr, toYr, this.loggedUser.accId).subscribe(login1 => {
      this.isLoading = false;
      this.consolidatePojo = login1;
      this.statement=this.consolidatePojo.report;
      this.resultFromDate = fromYr;
      this.resultToDate = toYr;
    }, error => {
      bootbox.alert('Error');
      this.isLoading = false;
    });
    this.documentDate = moment().format('DD-MM-YYYY');
  }

  printledgerstatement() {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push( {
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
     } );
    this.content.push({
      text: 'TRIAL BALANCE REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
    });

    this.content.push({
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
    });
    this.content.push({
      text: 'SELECTED ACADEMIC YEAR : ' +this.resultFromDate+' - '+this.resultToDate , fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\n', fontSize: 8, alignment: 'center'
    });

    this.body.push([
      { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'PARTICULARS', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'INCOME', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: ' EXPENSE', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
    ]);

    for (let i = 0; i < this.statement.length; i++) {
      this.body.push([
        { text: this.statement[i].ledgerGroupName, fontSize: 12, font: 'TimesNewRoman', bold: true, alignment: 'center', colSpan: 4 }, {}, {}, {}
      ]);
      for (let j = 0; j < this.statement[i].detlist.length; j++) {
        this.body.push([
          { text: (j + 1), fontSize: 11, font: 'TimesNewRoman', alignment: 'center' },
          { text: this.statement[i].detlist[j].narration, fontSize: 11, font: 'TimesNewRoman', alignment: 'left' },
          { text: this.statement[i].detlist[j].income.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
          { text: this.statement[i].detlist[j].expenditure.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
        ]);
      }
      this.body.push([
        { text: 'TOTAL AMOUNT', fontSize: 10, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
        { text: this.statement[i].incomeTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].expenditureTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
      ]);

     }
    this.body.push([
      { text: 'CONSOLIDATION REPORT', fontSize: 12, font: 'TimesNewRoman', bold: true, alignment: 'center', colSpan: 4 },{},{}, {},
       ]);
    for (let i = 0; i < this.statement.length; i++) {
      this.body.push([
        { text: (i + 1), fontSize: 11, font: 'TimesNewRoman', alignment: 'center' },
        { text: this.statement[i].ledgerGroupName, fontSize: 12, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].incomeTotal.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].expenditureTotal.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
      ]);
    }
    this.body.push([
      { text: 'OPENING BALANCE', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: this.consolidatePojo.openingBal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: '', fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
    this.body.push([
      { text: 'CLOSING BALANCE', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: '', fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: this.consolidatePojo.closingBal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
    this.body.push([
      { text: 'TOTAL AMOUNT', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: this.consolidatePojo.creditTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: this.consolidatePojo.debitTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
   
    this.content.push({
      'table': {
        headerRows: 1,
        'body': this.body,
        widths: ['5%', '75%', '10%', '10%',]
      }
    });


    const dd = {
      'content': this.content,
      pageOrientation: 'landscape',
      pageSize: 'A4',
      footer: (currentPage, pageCount) => {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 20]
                },
                {
                  text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      }
    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];

  }
} 
