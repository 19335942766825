import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { CommunityPojo } from 'src/app/pojo/community-caste.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class CommunityMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllCommunity(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetCommunityAll/'+accid;
        return this.httpclient.get<CommunityPojo[]>( url );
    }
    
    addCommunity( arg: CommunityPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddCommunity';
        return this.httpclient.post( url, arg );
    }
    
    updateCommunity( arg: CommunityPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateCommunity';
        return this.httpclient.put( url, arg );
    }
    
    deleteCommunity( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteCommunity/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}