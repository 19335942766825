import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class ClassMasterService {

    constructor( private httpclient: HttpClient ) { }

    getClassMasterAll(accid:number): Observable<ClassMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetClassAll/'+accid;
        return this.httpclient.get<ClassMasterPojo[]>( url );
    }

    addClass( arg: ClassMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddClass';
        return this.httpclient.post( url, arg );
    }

    updateClass( arg: ClassMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateClass';
        return this.httpclient.put( url, arg );
    }

    deleteClass( id: number, loggedId: number,accid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteClass/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
    getClassMasterById(id,accid): Observable<ClassMasterPojo> {
        const url = StaticVariable.porturl + 'GetClassById/'+id+'/'+accid;
        return this.httpclient.get<ClassMasterPojo>( url );
    }
}
