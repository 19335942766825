export class ExamDetailsByClassWisePojo {

	 classId:number;
	 subjectCount:number;
	 sectionId:number;
	 examDate:Date;
	 examName:string;
	 studentId:number;
	 admissionNo:string;
	 name:string;
	 rollNo:string;
	 academicYearId:number;
	 totalMark:number;
    
}
export class ExamDetailsByStudentWisePojo {

	classId:number;
	subjectId:number;
	subjectName:string;
	sectionId:number;
	 examDate:Date;
	 examName:string;
	studentId:number;
	 admissionNo:string;
	 name:string;
	 rollNo:string;
	academicYearId:number;
	securedMark:number;
	minMark:number;
	maxMark:number;
    
}