import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AccountGroupPojo } from "src/app/pojo/account-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class AccountGroupMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllAccountGrpMaster(): Observable<any> {
        const url = StaticVariable.porturl + 'AccountGroupAll';
        return this.httpclient.get<AccountGroupPojo[]>( url );
    }
    
    addAccountGrpMaster( arg: AccountGroupPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAccountGroup';
        return this.httpclient.post( url, arg );
    }
    
    updateAccountGrpMaster( arg: AccountGroupPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateAccountGroup';
        return this.httpclient.put( url, arg );
    }
    
    deleteAccountGrpMaster( id: number): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteAccountGroup/' + id ;
        return this.httpclient.delete( url );
    }
    getAccountGrpMasterById(id): Observable<any> {
        const url = StaticVariable.porturl + 'AccountGroupById/'+id;
        return this.httpclient.get<AccountGroupPojo[]>( url );
    }

}