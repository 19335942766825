import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class StaffMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllStaff(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetStaffAll'+'/'+accid;
        return this.httpclient.get<StaffMasterPojo[]>( url );
    }

    getStaffByDeptId( did,accid ): Observable<any> {
        const url = StaticVariable.porturl + 'GetStaffByDeptId/' + did+'/'+accid;
        return this.httpclient.get<StaffMasterPojo[]>( url );
    }
    getStaffById( id,accid ): Observable<any> {
        const url = StaticVariable.porturl + 'GetStaffById/' + id+'/'+accid;
        return this.httpclient.get<StaffMasterPojo[]>( url );
    }

    addStaff( arg: StaffMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddStaff';
        return this.httpclient.post( url, arg );
    }

    updateStaff( arg: StaffMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateStaff';
        return this.httpclient.put( url, arg );
    }

    deleteStaff( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteStaff/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
}