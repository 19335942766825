import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'StudentMarkListPipe'
} )

export class StudentMarkListPipe implements PipeTransform {

    transform( array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.rollNo == query ) != '' ) {
                return _.filter( array, row => row.rollNo == query );
            }else if ( _.filter( array, row => row.admissionNo == query ) != '' ) {
                return _.filter( array, row => row.admissionNo == query );
            } else if ( _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}
