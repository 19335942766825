import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'termStructurePipe'
} )

export class StandardStructureMasterPipe implements PipeTransform {

    transform( array: any[], query: String): any {
        if ( query ) {
            if ( _.filter( array, row => row.amount == query ) != '' ) {
                return _.filter( array, row => row.amount == query );
            } else {
                return false;
            }
        }
        return array;
    }

}

@Pipe( {
    name: 'StandardMastterIdPipe'
} )

export class StandardMasterIdPipe implements PipeTransform {

    transform( array: any[], query: any): any {
        if ( query ) {
            if ( _.filter( array, row => row.classId == query ) != '' ) {
                return _.filter( array, row => row.classId == query );
            }    
            }
        return array;
    }

}