import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'ledgercreation'
})
export class LedgercreationPipe implements PipeTransform {

  transform(array: any[], query: string): any {
        if (query) {
             if (_.filter(array, row => row.ledgerCode == query) != '') {
               return _.filter(array, row => row.ledgerCode == query);
               } else if (_.filter(array, row => row.phoneNo == query) != '') {
               return _.filter(array, row => row.phoneNo == query);
               }else if (_.filter(array, row => row.mobileNo == query) != '') {
               return _.filter(array, row => row.mobileNo == query);
               }else if (_.filter(array, row => { if (row.ledgerName !== null ) {
               if (row.ledgerName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                   return true;
               }else {
                   return false;
               }}else {
                   return false;
               }
               }) != '') {
               return _.filter(array, row => { if (row.ledgerName !== null ) {
                   if (row.ledgerName.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                       return true;
                   }else {
                       return false;
                   }}else {
                       return false;
                   }
                   });
           }else {
               return;
           }
        }

        return array;
    }
}
