
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct, NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { HoursPojo } from 'src/app/pojo/hours.pojo';
import { HoursMasterService } from './hoursmaster.service';
declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-hours-master',
    templateUrl: './hours-master.component.html',
    styleUrls: ['./hours-master.component.scss']
})
export class HoursMasterComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    addFromDate: NgbDateStruct;
    addToDate: NgbDateStruct;
    editFromDate: NgbDateStruct;
    editToDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();


    hoursArr: HoursPojo[] = [];
    addHours = new HoursPojo();
    editHours = new HoursPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    startTime;
    endTime;
    editStartTime;
    editEndTime;
    constructor(config: NgbTimepickerConfig,
        private hoursmasterserivce: HoursMasterService) {
        config.size = 'small';
        config.meridian = true;
        config.spinners = false;
        config.minuteStep = 30;
    }
    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();
        this.getFeeTypeAll();
    }

    getFeeTypeAll() {
        this.isLoading = true;
        this.hoursmasterserivce.getAllHoursMaster(this.loggedUser.accId).subscribe(response => {
            this.hoursArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert({ message: 'Get Hours All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    getShortTimeFormat(arg) {
        return moment(arg, 'HH:mm:ss').format('hh:mm A');
    }

    add() {
        (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
        $('#addBeforeClick').hide(); $('#addAfterClick').show();
        this.addHours.hoursFrom = moment(this.startTime.hour + ':' + this.startTime.minute, 'HH:mm').format('HH:mm:ss');
        this.addHours.hoursTo = moment(this.endTime.hour + ':' + this.endTime.minute, 'HH:mm').format('HH:mm:ss');
        this.addHours.accId=this.loggedUser.accId;
        this.hoursmasterserivce.addHoursMaster(this.addHours).subscribe(response => {
            bootbox.alert({ message: 'Successfully Added', className: 'text-success h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            (<HTMLFormElement>document.getElementById('addSubjectForm')).reset();
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
            $('#addModal').modal('hide');
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert({ message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
            $('#addBeforeClick').show(); $('#addAfterClick').hide();
        });
    }

    clickEdit(arg) {
        this.editHours = Object.assign({}, arg);
        this.editStartTime = { hour: +moment( this.editHours.hoursFrom, 'HH:mm:ss' ).format( 'HH' ), minute: +moment( this.editHours.hoursFrom, 'HH:mm:ss' ).format( 'mm' ) };
        this.editEndTime = { hour: +moment( this.editHours.hoursTo, 'HH:mm:ss' ).format( 'HH' ), minute: +moment( this.editHours.hoursTo, 'HH:mm:ss' ).format( 'mm' ) };
          }

    edit() {
        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.editHours.hoursFrom = moment(this.editStartTime.hour + ':' + this.editStartTime.minute, 'HH:mm').format('HH:mm:ss');
        this.editHours.hoursTo = moment(this.editEndTime.hour + ':' + this.editEndTime.minute, 'HH:mm').format('HH:mm:ss');
           this.editHours.accId=this.loggedUser.accId;
        this.hoursmasterserivce.updateHoursMaster(this.editHours).subscribe(response => {
            bootbox.alert({ message: 'Successfully updated.', className: 'text-success h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
            $('#editModal').modal('hide');
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert({ message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
        });
    }

    delete(arg) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.hoursArr.indexOf(this.hoursArr.find(r => r.hoursId == this.id));
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.hoursmasterserivce.deleteHoursMaster(this.id, this.loggedUser.loggedUserId,this.loggedUser.accId).subscribe(response => {
            no = response;
            if (no == 0) {
                bootbox.alert({ message: 'This row already deleted!', className: 'text-danger h5', closeButton: false });
                this.hoursArr.splice(i, 1);
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else {
                this.hoursArr.splice(i, 1);
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert({ message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }
}
