import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-section-master',
    templateUrl: './section-master.component.html',
    styleUrls: ['./section-master.component.scss']
} )

export class SectionMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    sectionMasterArr: SectionMasterPojo[] = [];
    staffArr: StaffMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];

    addSectionMaster = new SectionMasterPojo();
    editSectionMaster = new SectionMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private sectionMasterService: SectionMasterService, private staffMasterService: StaffMasterService, private classMasterService: ClassMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();

        this.getSectionMasterAll();
        this.getStaffMasterAll();
        this.getClassMasterAll();
    }

    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Section All Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStaffMasterAll() {
        this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( response => {
            this.staffArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getSectionStaffNameByStaffId( id ) {
        if ( this.staffArr.find( response => response.staffId == id ) ) {
            return this.staffArr.find( response => response.staffId == id ).staffName;
        } else {
            return id;
        }
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }

    addClass() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addSectionMaster.accId=this.loggedUser.accId;
        this.sectionMasterService.addSection( this.addSectionMaster ).subscribe( response => {
            bootbox.alert( { message: 'Section has been successfully created.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSectionForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getSectionMasterAll();
        }, error => {
            bootbox.alert( { message: 'Add Section Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editSectionMaster = Object.assign( {}, arg );
    }

    editClass() {
        let editSection = new SectionMasterPojo();
        editSection = this.editSectionMaster;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        editSection.accId=this.loggedUser.accId;
        this.sectionMasterService.updateSection( editSection ).subscribe( response => {
            bootbox.alert( { message: 'Section has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getSectionMasterAll();
        }, error => {
            bootbox.alert( { message: 'Update Section Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.sectionMasterArr.indexOf( this.sectionMasterArr.find( response => response.sectionId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.sectionMasterService.deleteSection( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row is already deleted', className: 'text-danger h5', closeButton: false } );
                this.sectionMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.sectionMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getSectionMasterAll();
        }, error => {
            bootbox.alert( { message: 'Delete Section Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
