import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { TermPojo } from 'src/app/pojo/term-pojo';


@Injectable( {
    providedIn: 'root'
} )

export class TermMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllTerm(cid): Observable<any> {
        const url = StaticVariable.porturl + 'GetTermAll/'+cid;
        return this.httpclient.get<TermPojo[]>( url );
    }
    
    addTerm( arg: TermPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddTerm';
        return this.httpclient.post( url, arg );
    }
    
    updateTerm( arg: TermPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTerm';
        return this.httpclient.put( url, arg );
    }
    
    deleteTerm( id: number, loggedId: number,accid:number): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteTerm/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}