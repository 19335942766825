
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ExamMasterService } from '../../Masters/exam-master/exam-master.service';
import { ExamMasterPojo } from 'src/app/pojo/exam-master.pojo';
import { ExamScheduleMasterService } from '../../Masters/exam-schedule/exam-schedule.service';
import { ExamSchedulePojo } from 'src/app/pojo/exam-schedule.pojo';
import { SubjectMasterService } from '../../Masters/subject-master/subject-master.service';
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import * as converter from 'number-to-words';
import { ExamDetailsByClassWisePojo, ExamDetailsByStudentWisePojo } from 'src/app/pojo/exam-details-by-classWise.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-exam-mark-sheet',
  templateUrl: './exam-mark-sheet.component.html',
  styleUrls: ['./exam-mark-sheet.component.scss']
})
export class ExamMarkSheetComponent implements OnInit {
   loggedUser = new MUserMasterPojo();

    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
      classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
      typeId;
    academic:AcademicYearMasterPojo[] =[];
    academicYear;
     standTermArr: StandardTermPojo[] = [];
    classPojo = new ClassMasterPojo();
   examArr: ExamMasterPojo[] = [];
    examScheduleArr:ExamSchedulePojo[]=[];
    subjectMasterArr: SubjectMasterPojo[] = [];
    resultExam:number;
    examId:number;
    studentId:number;
    scheduleArr:ExamSchedulePojo[]=[];
    examRepArr:ExamDetailsByClassWisePojo[]=[];
    markDetArr:ExamDetailsByStudentWisePojo[]=[];
    totalMark:number=0;
    print = new ExamDetailsByStudentWisePojo();
    accountMasterArr:AccountMasterPojo[]=[];
     constructor( private standardTermService:StandardStructureMasterService,
        private settingService:AcademicSettingService,
        private reportService:ReportService, 
        private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, 
        private classMasterService: ClassMasterService, private router: Router,
        private exammasterserivce: ExamMasterService,private accountMasterService:AccountMasterService,
        private examScheduleService:ExamScheduleMasterService,private subjectmasterserivce: SubjectMasterService ) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.academicSettingAll();
        this.getStandrdTermAll();   
        this.getExamMasterAll();
        this.getSubjectAll();
        this.accountMasterAll();
    }
    
    academicSettingAll(){
      this.academic = [];
      this.settingService.getAcademicYrAll().subscribe( response => {
          this.academic = response;
          for(let i=0;i<this.academic.length;i++){
              if(this.academic[i].academicFlag == 1){
                  this.academicYear = this.academic[i].academicYearId;
              }
          }
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
     }
     accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getExamMasterAll() {
        this.isLoading = true;
        this.exammasterserivce.getAllExamMaster(this.loggedUser.accId).subscribe( response => {
            this.examArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    
    getSubjectAll() {
        this.isLoading = true;
        this.subjectmasterserivce.getAllSubject(this.loggedUser.accId).subscribe( response => {
            this.subjectMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Subject All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getClassMasterHoursListById() {
        this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe( response => {
            this.classPojo = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getStandrdTermAll() {
         this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
            this.standTermArr = response;
               }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
      }
    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getSectionNameById( id ) {
        if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
            return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
      }
      getStandardTermById( id ) {
        if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
            return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
        } else {
            return id;
        }
      }
      getExamById( id ) {
        if ( this.examArr.find( r => r.examMasterId == id ) ) {
            return this.examArr.find( r => r.examMasterId == id ).examName;
        } else {
            return id;
        }
      }
      getSubById( id ) {
        if ( this.subjectMasterArr.find( r => r.subjectId == id ) ) {
            return this.subjectMasterArr.find( r => r.subjectId == id ).subjectName;
        } else {
            return id;
        }
      }
      getStudentById( id ) {
        if ( this.examRepArr.find( r => r.studentId == id ) ) {
            return this.examRepArr.find( r => r.studentId == id ).name;
        } else {
            return id;
        }
      }
      
      getExamScheduleById() {
        this.scheduleArr=[];
          if(this.classid != null && this.examId != null){
         this.reportService.getExamScheduleById(this.classid,this.examId,this.loggedUser.academicYearId,this.loggedUser.accId).subscribe( response => {
            this.scheduleArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
        }
    } 
    getExamMarkDetailsById() {
      if(this.studentId!=null && this.studentId!= undefined){
        this.print = new ExamDetailsByStudentWisePojo();
           this.markDetArr=[];
          this.isLoading=true;
         this.reportService.getExamMarkById( this.academicYear,this.classid,this.sectionid,this.examId,this.studentId).subscribe( response => {
       this.markDetArr = response;
       for(let i=0;i<this.examRepArr.length;i++){
         if(this.examRepArr[i].studentId==this.studentId){
        this.print.name = this.examRepArr[i].name;
        this.print.admissionNo = this.examRepArr[i].admissionNo;
        this.print.rollNo = this.examRepArr[i].rollNo;
        this.print.classId = this.examRepArr[i].classId;
        this.print.sectionId = this.examRepArr[i].sectionId;
        this.print.examName = this.examRepArr[i].examName;
        this.print.academicYearId = this.examRepArr[i].academicYearId;
        this.totalMark=this.examRepArr[i].totalMark;
       }
      }
         this.isLoading=false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
      }
    }
     getReportById() {
        if(this.classid !=null&& this.examId != null&& this.sectionid != null){
       this.examRepArr=[];
        this.isLoading = true;
        this.print=new ExamDetailsByStudentWisePojo();
         this.reportService.getExamReportById( this.loggedUser.academicYearId, this.classid, this.sectionid,this.examId).subscribe( response => {
              this.examRepArr = response;
                   this.resultExam=this.examId;
                 this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }else{
        bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );
   
    }
    }
   
        Reset(){
            this.classid=0;
            this.departmentId=0;
            this.sectionid=0;
            this.examId=0;
            this.print=new ExamDetailsByStudentWisePojo();
            this.examRepArr=[];
            this.markDetArr=[];
             }
        printReport(){
             this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
            this.content.push(
                {
                    columns: [
                         {
                            image: StaticVariable.logo, width:50 },
                            {width:'4%',
                             text:''},
                            {
                                width: '80%',
                                
                                text: [{text:this.getAccountNameById(this.loggedUser.accId), style: 'e' },
                                {text:'\n'+this.getAccountPlaceById(this.loggedUser.accId)+'\n', style: 'e3' },{text:'Examination Result'+'\t'+this.print.examName+' [ '+this.getAcademicYrById(this.print.academicYearId)+' ]'+'\n', style: 'e' }
                               
                            ]
                                
                            },
                     ] } );
                     this.content.push( {
                        text: '__________________________________________________________________________________________________________________\n',
                        fontSize: 10, bold: true, alignment: 'center'
                    } );
                    this.content.push(
                        {
                          lineHeight:1.6,
                            columns: [
                                {
                                    width: '40%',
                                    text: 'Roll Number ' ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                             
                                },
                                {
                                    width: '60%',
                                    text: ':'+this.print.rollNo ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                }
                            ]  
                        } );
                        this.content.push(
                            {
                              lineHeight:1.6,
                                columns: [
                                    {
                                        width: '40%',
                                        text: 'Student Name ' ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                                 
                                    },
                                    {
                                        width: '60%',
                                        text: ':'+this.print.name ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                    }
                                ]  
                            } );
                            this.content.push(
                                {
                                  lineHeight:1.6,
                                    columns: [
                                        {
                                            width: '40%',
                                            text: "Father's Name " ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                                     
                                        },
                                        {
                                            width: '60%',
                                            text: ':' ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                        }
                                    ]  
                                } );
                        this.content.push(
                            {
                              lineHeight:1.6,
                                columns: [
                                    {
                                        width: '40%',
                                        text: "Mother's Name" ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                                 
                                    },
                                    {
                                        width: '60%',
                                        text: ':' ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                    }
                                ]  
                            } );
                            this.content.push(
                                {
                                  lineHeight:1.6,
                                    columns: [
                                        {
                                            width: '40%',
                                            text: "Date of Birth" ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                                     
                                        },
                                        {
                                            width: '60%',
                                            text: ':' ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                        }
                                    ]  
                                } );
                                this.content.push(
                                    {
                                      lineHeight:1.6,
                                        columns: [
                                            {
                                                width: '40%',
                                                text: "Class " ,alignment:'left', fontSize: 12, font: 'TimesNewRoman',bold:'true'
                                         
                                            },
                                            {
                                                width: '60%',
                                                text: ':'+this.getClassNameById(this.print.classId)+' - '+this.getSectionNameById(this.print.sectionId) ,alignment:'left', fontSize: 12, font: 'TimesNewRoman'
                                            }
                                        ]  
                                    } );
                           
                     this.body.push( [
                        { text: 'SUBJECT NAME ', fontSize: 12, font: 'ArialNarrow', alignment: 'center', bold: 'true', border:[true, true, true, true],lineHeight:1.2 },
                        { text: 'MAX.MARKS', fontSize: 12, font: 'ArialNarrow', alignment: 'center', bold: 'true', border:[true, true, true, true],lineHeight:1.2  },
                        { text: 'MARKS OBTAINED', fontSize: 12, font: 'ArialNarrow', alignment: 'center', bold: 'true', border:[true, true, true, true],lineHeight:1.2  },
                     ]);
                     let overAllTotal=0;
                     let overAllObtained=0;
                        for(let i=0;i<this.markDetArr.length;i++){
                            let words =converter.toWords( this.markDetArr[i].securedMark);
                            words = words.toUpperCase();
                         
                            this.body.push( [
                                { text: this.markDetArr[i].subjectName, fontSize: 12, font: 'TimesNewRoman', alignment: 'left',  border:[true, false, true, false],lineHeight:1.6 },
                                { text: this.markDetArr[i].maxMark, fontSize: 12, font: 'ArialNarrow', alignment: 'center',border:[true, false, true, false],lineHeight:1.6  },
                                { text: this.markDetArr[i].securedMark+'\t'+words, fontSize: 12, font: 'TimesNewRoman', alignment: 'left',  border:[true, false, true, false],lineHeight:1.6  },
                             ]);
                             overAllTotal=overAllTotal+this.markDetArr[i].maxMark;
                             overAllObtained=overAllObtained+this.markDetArr[i].securedMark;
                       }
                       let overAllWords =converter.toWords(overAllObtained);
                       overAllWords = overAllWords.toUpperCase();
                         
                         this.body.push( [
                            { text: 'TOTAL', fontSize: 13, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, true, true, true] ,lineHeight:1.2 },
                            { text: overAllTotal, fontSize: 13, font: 'ArialNarrow', alignment: 'center', bold: 'true', border:[true, true, true, true] ,lineHeight:1.2 },
                            {text: overAllObtained+'\t'+overAllWords,border:[true,true,true,true],alignment:'left',bold:'true', fontSize: 13,font: 'ArialNarrow', }
                        ] );
                        this.content.push( {
                            'table': {
                                headerRows: 1,
                                alignment:'center',
                                'body': this.body,
                                widths: ['30%', '20%','50%']
                            }
                        } );
                  
                        this.content.push({text:'\n\n'});
                       
                      
                   const dd = {
                         'content':this.content,  
                         pageOrientation: 'portrait',
                         pageSize: 'A4',  
                       
                        margins: [0, 0, 0, 0],
                         styles: {
                            e: {
                                font: 'TimesNewRoman',
                                 bold: true, 
                                 fontSize: 14,
                                 alignment: 'center'
                            },
                            e2: {
                                font: 'TimesNewRoman',
                                 bold: true, 
                                 fontSize: 11,
                                 alignment: 'center'
                            },
                            e3: {
                                font: 'TimesNewRoman',
                                 fontSize: 10,
                                 alignment: 'center'
                            },
                            e4: {
                                font: 'TimesNewRoman',
                                 fontSize: 11,
                                 bold: true, 
                                 alignment: 'center'
                            },
                        },
                        footer:{
                                  lineHeight:1.6,
                                    columns: [
                                        {
                                            width: '30%',
                                            text: "Candidate's Signature\n\n", fontSize: 12, font: 'TimesNewRoman', bold: 'true',alignment:'center'
                                        },
                                        {
                                          width: '40%',
                                          text: 'Class Teacher\n\n',alignment:'center', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                      },
                                        {
                                            width: '30%',
                                            text:'Principal\n\n', fontSize: 12, font: 'TimesNewRoman',alignment:'center',bold:'true'
                                        }
                                    ]
                                 }
                     };
               
                     pdfMake.createPdf( dd ).open();
                    this.body = [];
                    this.content =[];
                    this.Reset();
                    }
    
}
