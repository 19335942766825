export class JournalEntryPojo {
    journalEntryId: number;
    journalEntryDate: Date;
    ledgerId: number;
    dLedgerId: number;
    narration: string;
    debit: number;
    credit: number;
    companyId: number;
    accountType: string;
    amount: number;
    userId: number;
    accId:number;
    ledgerGroupId:number;

}