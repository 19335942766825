
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExamScheduleMasterService } from './exam-schedule.service';
import { ExamSchedulePojo } from 'src/app/pojo/exam-schedule.pojo';
import { ClassMasterService } from '../class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { ExamMasterService } from '../exam-master/exam-master.service';
import { ExamMasterPojo } from 'src/app/pojo/exam-master.pojo';
import { SubjectMasterService } from '../subject-master/subject-master.service';
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-exam-schedule',
  templateUrl: './exam-schedule.component.html',
  styleUrls: ['./exam-schedule.component.scss']
})
export class ExamScheduleComponent implements OnInit {
   model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
    
    addFromDate: NgbDateStruct;
    addToDate: NgbDateStruct;
    editFromDate: NgbDateStruct;
    editToDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

    
    examArr: ExamSchedulePojo[] = [];
    addExam = new ExamSchedulePojo();
    editExam = new ExamSchedulePojo();
    classMasterArr:ClassMasterPojo[]=[];
    examMasterArr:ExamMasterPojo[]=[];
    subjectMasterArr: SubjectMasterPojo[] = [];
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    academic:AcademicYearMasterPojo[] =[];
    constructor( private settingService:AcademicSettingService,private exammasterserivce: ExamScheduleMasterService,private classMasterService: ClassMasterService,
      private examMaster:ExamMasterService,private subjectmasterserivce: SubjectMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getExamScheduleAll();
          this.getExamMasterAll();
          this.getClassMasterAll();
          this.getSubjectAll();
          this.academicSettingAll();
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
            for(let i=0;i<this.academic.length;i++){
                if(this.academic[i].academicFlag == 1){
                    this.addExam.academicYearId = this.academic[i].academicYearId;
                      }
            }
            
              }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
    getExamMasterAll() {
      this.isLoading = true;
      this.examMaster.getAllExamMaster(this.loggedUser.accId).subscribe( response => {
          this.examMasterArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  }
    getExamScheduleAll() {
        this.isLoading = true;
        this.exammasterserivce.getAllExamScheduleMaster(this.loggedUser.accId).subscribe( response => {
            this.examArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  }
  getSubjectAll() {
    this.isLoading = true;
    this.subjectmasterserivce.getAllSubject(this.loggedUser.accId).subscribe( response => {
        this.subjectMasterArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Subject All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        this.isLoading = false;
    } );
}
getExamNameById( id ) {
    if ( this.examMasterArr.find( response => response.examMasterId == id ) ) {
        return this.examMasterArr.find( response => response.examMasterId == id ).examName;
    } else {
        return id;
    }
}
getClassNameById( id ) {
    if ( this. classMasterArr.find( response => response. classId == id ) ) {
        return this.classMasterArr.find( response => response.classId == id ).className;
    } else {
        return id;
    }
}
getSubjectNameId( id ) {
    if ( this.subjectMasterArr.find( response => response. subjectId == id ) ) {
        return this.subjectMasterArr.find( response => response.subjectId == id ).subjectName;
    } else {
        return id;
    }
}
    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addExam.examDate = moment( this.addFromDate.year + '-' + this.addFromDate.month + '-' + this.addFromDate.day, 'YYYY-MM-DD' ).toDate();
      this.addExam.accId=this.loggedUser.accId;
        this.exammasterserivce.addExamScheduleMaster( this.addExam ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getExamScheduleAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editExam = Object.assign( {}, arg );
        if(this.editExam.examDate != null && this.editExam.examDate != undefined){
            ( <HTMLFormElement>document.getElementById( 'editfromDate' ) ).value = this.editExam.examDate;
            }
           }  

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editExam.examDate = moment(( <HTMLInputElement>document.getElementById( 'editfromDate' ) ).value).toDate();
        this.editExam.accId=this.loggedUser.accId;   
        this.exammasterserivce.updateExamScheduleMaster( this.editExam ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getExamScheduleAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.examArr.indexOf( this.examArr.find( r => r.examScheduleId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.exammasterserivce.deleteExamScheduleMaster( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.examArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.examArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getExamScheduleAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
