import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo, ManagementRolePojo } from "src/app/pojo/management-user-pojo";
import { RoleMasterService } from "src/app/management/Admin/role-master/role-master.service";
import { UserCreationService } from "src/app/management/Admin/user-creation/user-creation.service";
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { AccountMasterService } from "src/app/management/Admin/account-master/account-master.service";
import { AccountMasterPojo } from "src/app/pojo/account-master.pojo";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-user-creation',
    templateUrl: './user-creation.component.html',
    styleUrls: ['./user-creation.component.scss'],
    providers: [RoleMasterService, StaffMasterService, UserCreationService]
} )

export class UserCreationComponent implements OnInit {

    userCreationArr: MUserMasterPojo[] = [];
    tempuserCreationArr: MUserMasterPojo[] = [];
    userCreationSingle = new MUserMasterPojo();
    roles: MRolePojo[];
    temproles: MRolePojo[];
    staff: StaffMasterPojo[];
    editUserRoleKey = new ManagementRolePojo();
    loggedUser = new MUserMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    accountMasterArr: AccountMasterPojo[] = [];
    constructor( private roleMasterService: RoleMasterService, private accountmasterserivce: AccountMasterService,private userCreationService: UserCreationService, private staffMasterService: StaffMasterService,
        ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        this.getRoleAll();
        this.getStaffAll();
        this.getManagementUserAll();
        this.getAccountMasterAll();
        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        $( '#checkSpinner' ).hide();
    }

    getRoleAll() {
        this.roles =[];
        this.roleMasterService.getMRoleAll(this.loggedUser.accId).subscribe( response => {
            this.temproles = response;
            if(this.loggedUser.roleId == 1){
                this.roles = this.temproles;        
            }else{
            for(let i=0;i<this.temproles.length;i++){
               
                if(this.temproles[i].roleId != 1){
                    this.roles.push(this.temproles[i]);  
                }
            }
        }
        }, error => {
            bootbox.alert( { message: 'Get M Role All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
    }
    getAccountMasterAll() {
        this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
            this.accountMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getStaffAll() {
        this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( response => {
            this.staff = response;
        }, error => {
            bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
    }

    getManagementUserAll() {
        this.userCreationArr=[];
        this.userCreationService.getManagementUser(this.loggedUser.accId).subscribe( response => {
            this.tempuserCreationArr = response;
            if(this.loggedUser.roleId == 1){
                this.userCreationArr = this.tempuserCreationArr;      
            }else{
            for(let i=0;i<this.tempuserCreationArr.length;i++){
               
                if(this.tempuserCreationArr[i].userId != 1){
                    this.userCreationArr.push(this.tempuserCreationArr[i]);  
                }
            }
            }
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get M User Master All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getRoleKeyByRoleId( id ) {
        if ( this.roles.find( r => r.roleId == id ) ) {
            return this.roles.find( r => r.roleId == id ).roleKey;
        } else {
            return id;
        }
    }

    checkUserName( userName) {
     let accId =   ( <HTMLFormElement>document.getElementById( 'accountId' ) ).value
         if ( userName != "" && accId != "" && accId!=null && accId != undefined ) {
             let d = new MUserMasterPojo();
             d.userName = userName;
             d.accId = accId;
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
            $( '#checkIcon' ).hide(); $( '#checkSpinner' ).show();
            this.userCreationService.checkUserName( d ).subscribe( response => {
                if ( response.text() =='NOTEXISTS' ) {
                    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
                    $( '#checkIcon' ).show(); $( '#checkSpinner' ).hide();
                } else {
                    alert( "Username Already exist.." );
                    ( <HTMLInputElement>document.getElementById( 'addUserName' ) ).value = '';
                    $( '#checkIcon' ).show(); $( '#checkSpinner' ).hide();
                    $( "#addUserName" ).focus();
                }
            }, error => {
                alert( 'Check User Name Service Error! - HTTP Status ' + error.status );
                $( '#checkIcon' ).show(); $( '#checkSpinner' ).hide();
                $( "#addUserName" ).focus();
            } );
        }
    }

    checkPassword( passWord ) {
        if ( passWord != "" ) {
            if ( ( <HTMLInputElement>document.getElementById( 'addPassword' ) ).value != passWord ) {
                ( <HTMLInputElement>document.getElementById( 'addConfirmPassword' ) ).value = '';
                alert( "Confirm Password Doesn't Match !" );
                $( "#addConfirmPassword" ).focus();
            }
        }
    }

    phoneNumValidationForAdd( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'addMobileNumber' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#addMobileNumber' ).focus();
        }
    }

    phoneNumValidationForEdit( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'editMobileNumber' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#editMobileNumber' ).focus();
        }
    }

    insertUser() {
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.userCreationSingle.userName = ( <HTMLFormElement>document.getElementById( 'addUserName' ) ).value;
        this.userCreationSingle.password = ( <HTMLFormElement>document.getElementById( 'addPassword' ) ).value;
        this.userCreationSingle.name = ( <HTMLFormElement>document.getElementById( 'addName' ) ).value;
        this.userCreationSingle.address = ( <HTMLFormElement>document.getElementById( 'addAddress' ) ).value;
        this.userCreationSingle.mobileNo = ( <HTMLFormElement>document.getElementById( 'addMobileNumber' ) ).value;
        this.userCreationSingle.designation = ( <HTMLFormElement>document.getElementById( 'addDesignation' ) ).value;
        this.userCreationSingle.roleId = ( <HTMLFormElement>document.getElementById( 'addRole' ) ).value;
        this.userCreationSingle.staffId = ( <HTMLFormElement>document.getElementById( 'addStaff' ) ).value;
        this.userCreationSingle.userStatus = ( <HTMLFormElement>document.getElementById( 'addUserStatus' ) ).value;
        this.userCreationSingle.accId = ( <HTMLFormElement>document.getElementById( 'accountId' ) ).value;
        this.userCreationSingle.accId=this.loggedUser.accId;
          this.userCreationService.addManagementUser( this.userCreationSingle ).subscribe( response => {
            bootbox.alert( { message: 'User has been created successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( { message: 'Add M User Master Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.id = arg.userId;
        this.editUserRoleKey = JSON.parse( arg.roleKeyJson );
        ( <HTMLFormElement>document.getElementById( 'editUserName' ) ).value = arg.userName;
        ( <HTMLFormElement>document.getElementById( 'editName' ) ).value = arg.name;
        ( <HTMLFormElement>document.getElementById( 'editAddress' ) ).value = arg.address;
        ( <HTMLFormElement>document.getElementById( 'editMobileNumber' ) ).value = arg.mobileNo;
        ( <HTMLFormElement>document.getElementById( 'editDesignation' ) ).value = arg.designation;
        ( <HTMLFormElement>document.getElementById( 'editRole' ) ).value = arg.roleId;
        ( <HTMLFormElement>document.getElementById( 'editStaff' ) ).value = arg.staffId;
        ( <HTMLFormElement>document.getElementById( 'editUserStatus' ) ).value = arg.userStatus;
        ( <HTMLFormElement>document.getElementById( 'editaccountId' ) ).value = arg.accId;
        }

    updateUser() {
        let no;
        const i = this.userCreationArr.indexOf( this.userCreationArr.find( r => r.userId == this.id ) );
        this.userCreationSingle.userId = this.userCreationArr[i].userId;
        this.userCreationSingle.name = ( <HTMLFormElement>document.getElementById( 'editName' ) ).value;
        this.userCreationSingle.address = ( <HTMLFormElement>document.getElementById( 'editAddress' ) ).value;
        this.userCreationSingle.mobileNo = ( <HTMLFormElement>document.getElementById( 'editMobileNumber' ) ).value;
        this.userCreationSingle.designation = ( <HTMLFormElement>document.getElementById( 'editDesignation' ) ).value;
        this.userCreationSingle.roleId = ( <HTMLFormElement>document.getElementById( 'editRole' ) ).value;
        this.userCreationSingle.staffId = ( <HTMLFormElement>document.getElementById( 'editStaff' ) ).value;
        this.userCreationSingle.userStatus = ( <HTMLFormElement>document.getElementById( 'editUserStatus' ) ).value;
        this.userCreationSingle.accId = ( <HTMLFormElement>document.getElementById( 'editaccountId' ) ).value;
       //   this.userCreationSingle.roleKey = this.getRoleKeyByRoleId( this.userCreationSingle.roleId );
        this.userCreationSingle.loggedUserDetails.loggedUserId = this.loggedUser.userId;
        this.userCreationSingle.loggedUserDetails.name = this.loggedUser.name;
        this.userCreationSingle.loggedUserDetails.userName = this.loggedUser.userName;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.userCreationSingle.accId=this.loggedUser.accId;
        this.userCreationService.updateManagementUser( this.userCreationSingle ).subscribe( response => {
            no = response;
            bootbox.alert( { message: 'User has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( { message: 'Update M User Master Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.userCreationArr.indexOf( this.userCreationArr.find( r => r.userId == this.id ) );
        this.userCreationSingle = this.userCreationArr[i];
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.userCreationService.deleteManagementUser( this.id, this.loggedUser.loggedUserId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.userCreationArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.userCreationArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getManagementUserAll();
        }, error => {
            bootbox.alert( { message: 'Delete M User Master Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }

}