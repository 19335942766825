import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { EventPojo } from 'src/app/pojo/event.pojo'; 

@Injectable( {
    providedIn: 'root'
} )

export class EventService {

    constructor( private httpclient: HttpClient ) { }

    getEventAll(accid): Observable<EventPojo[]> {
        const url = StaticVariable.porturl + 'GetEventAll/'+accid;
        return this.httpclient.get<EventPojo[]>( url );
    }

    // getStudentMasterByDidCidAndSid( did, cid, sid ): Observable<EventPojo[]> {
    //     const url = StaticVariable.porturl + 'GetStudentByDeptIdClassIdAndSectionId/' + did + '/' + cid + '/' + sid;
    //     return this.httpclient.get<EventPojo[]>( url );
    // }

    addEvent( arg: EventPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddEvent';
        return this.httpclient.post( url, arg );
    }

    updateEvent( arg: EventPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateEvent';
        return this.httpclient.put( url, arg );
    }

    deleteEvent( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteEvent/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }

    getEventById( notesId: number,accid:number ): Observable<EventPojo> {
        const url = StaticVariable.porturl + 'GetNotesMasterById/' + notesId+'/'+accid;
        return this.httpclient.get<EventPojo>( url );
    }
    
  
}