
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ArrearReportPojo, ArrearReportStudentDetailsPojo } from 'src/app/pojo/arrears-report.pojo';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-set-fee-allocation-sms',
  templateUrl: './set-fee-allocation-sms.component.html',
  styleUrls: ['./set-fee-allocation-sms.component.scss']
})
export class SetFeeAllocationSmsComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

      sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    studentMasterArr: StudentMasterPojo[] = [];
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltType:number;
    searchOption = 'ALL';
    typeId;
    academic:AcademicYearMasterPojo[] =[];
    academicYear;
    arrearPojo = new ArrearReportPojo();
    arrearDetailsList:ArrearReportStudentDetailsPojo[]=[];
    feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
    standTermArr: StandardTermPojo[] = [];
    constructor( private standardTermService:StandardStructureMasterService,private feeAllocationService:FeeAllocationMasterService,private settingService:AcademicSettingService,private reportService:ReportService,private studentMasterService: StudentMasterService, private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService, private router: Router ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.academicSettingAll();
        this.getStandrdTermAll();
    }
    academicSettingAll(){
      this.academic = [];
      this.settingService.getAcademicYrAll().subscribe( response => {
          this.academic = response;
          for(let i=0;i<this.academic.length;i++){
              if(this.academic[i].academicFlag == 1){
                  this.academicYear = this.academic[i].academicYearId;
              }
          }
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
     }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getStandrdTermAll() {
         this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
            this.standTermArr = response;
               }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
      }
    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getSectionNameById( id ) {
        if ( this.sectionArr.find( r => r.sectionId == id ) ) {
            return this.sectionArr.find( r => r.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
      }
      getStandardTermById( id ) {
        if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
            return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
        } else {
            return id;
        }
      }
     getReportById() {
        this.arrearDetailsList=[];
        this.isLoading = true;
        this.arrearPojo = new ArrearReportPojo();
        this.reportService.getArrearReportById( this.academicYear,this.departmentId, this.classid, this.sectionid,0,this.loggedUser.accId ).subscribe( response => {
            this.arrearPojo = response;
            this.arrearDetailsList = this.arrearPojo.detList;
                this.resultDept= this.departmentId;
            this.resultClass= this.classid;
            this.resultSection=this.sectionid;
            this.resulltType = this.typeId;
             this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    submitReport() {
        bootbox.confirm('Are you sure !\n Do you want to send SMS to these students?', ( result ) => {
            if ( result ) {
                this.isLoading = true;
                this.reportService.sendSmsTofeeAllocation( this.arrearPojo,this.loggedUser.accId,this.loggedUser.userId ).subscribe( response => {
                  if(response ==101){
                    bootbox.alert( { message: 'SMS Status for this type is Inactive!Please change the Status to Active..', className: "text-danger h5", closeButton: false } );
              
                  }else{
                    bootbox.alert( { message: 'Successfully sent SMS to these Students..', className: "text-success h5", closeButton: false } );
                    this.arrearDetailsList=[];
                    this.arrearPojo = new ArrearReportPojo();
              
                  }
                       this.isLoading = false;
              }, error => {
                  bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
                  this.isLoading = false;
              } );
            }
        });
      
  }
   clickView(admissionNo){
      this.feeAllocationService.getFeeAllocationDetIdandAdmissioNo(this.academicYear,admissionNo,this.loggedUser.accId).subscribe( response => {
          this.feeCollectionArr= response;
             }, error => {
          bootbox.alert( { message: 'Get Fee Collection Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;   
        } );
     
   }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: StaticVariable.titleName + '\n' + StaticVariable.footerName, font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
                this.content.push( {
                    text: 'ARREAR STUDENTS REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
                } );
            
           this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
       if(this.resultSection !=0){
       this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+ this.getSectionNameById(this.resultSection), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );
      } if(this.resultSection ==0){
     this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+'ALL', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
   }
         this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Roll Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Section', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
              { text: 'Mobile No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Total Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Paid Amount ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Balance Amnt', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                ] );
   
         for ( let i = 0; i < this.arrearDetailsList.length; i++ ) {
            
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.arrearDetailsList[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.arrearDetailsList[i].rollNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.arrearDetailsList[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left', },
                { text: this.arrearDetailsList[i].sectionName, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.arrearDetailsList[i].mobileNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                   { text: this.arrearDetailsList[i].overAllTotal, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    { text: this.arrearDetailsList[i].overAllPaid, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                    { text: this.arrearDetailsList[i].overAllBalance, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '12%','12%', '24%', '6%', '10%', '11%', '10%', '10%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: StaticVariable.footerName + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
     
}
