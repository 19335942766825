import { QueriePojo } from "./queriepojo";

export class QuerieResPojo {
	code:number;
	response:string;
	academicYearId:number;
	classId:number;
	sectionId:number;
	accId:number;
	list:QueriePojo[]=[];
	querieStartDate;
	querieEndDate;
	studentId:number;
}