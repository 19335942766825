import { Component, OnInit } from '@angular/core';
import { LedgerPojo } from "src/app/pojo/ledger.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LedgercreationService } from "src/app/management/Masters/ledgercreation/ledgercreation.service";
import { AccountGroupMasterService } from "src/app/management/Admin/account-master/account-group-master.service";
import { AccountGroupPojo } from "src/app/pojo/account-master.pojo";
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
declare var $: any;
declare var bootbox: any;

const STATES = [
    { id: 1, 'name': 'Andaman and Nicobar Islands' },
    { id: 2, 'name': 'Andhra' },
    { id: 3, 'name': 'Arunachal Pradesh' },
    { id: 4, 'name': 'Assam' },
    { id: 5, 'name': 'Bihar' },
    { id: 6, 'name': 'Chandigarh' },
    { id: 7, 'name': 'Chhattisgarh' },
    { id: 8, 'name': 'Dadra and Nagar Haveli' },
    { id: 9, 'name': 'Daman and Diu' },
    { id: 10, 'name': 'Delhi' },
    { id: 11, 'name': 'Goa' },
    { id: 12, 'name': 'Gujarat' },
    { id: 13, 'name': 'Haryana' },
    { id: 14, 'name': 'Himachal Pradesh' },
    { id: 15, 'name': 'Jammu and Kashmir' },
    { id: 16, 'name': 'Jharkhand' },
    { id: 17, 'name': 'Karnataka' },
    { id: 18, 'name': 'Kerala' },
    { id: 19, 'name': 'Lakshadweep' },
    { id: 20, 'name': 'Madhya Pradesh' },
    { id: 21, 'name': 'Maharashtra' },
    { id: 22, 'name': 'Manipur' },
    { id: 23, 'name': 'Meghalaya' },
    { id: 24, 'name': 'Mizoram' },
    { id: 25, 'name': 'Nagaland' },
    { id: 26, 'name': 'Orissa' },
    { id: 27, 'name': 'Pondicherry' },
    { id: 28, 'name': 'Punjab' },
    { id: 29, 'name': 'Rajasthan' },
    { id: 30, 'name': 'Sikkim' },
    { id: 31, 'name': 'Tamil Nadu' },
    { id: 32, 'name': 'Telungana' },
    { id: 33, 'name': 'Tripura' },
    { id: 34, 'name': 'Uttaranchal' },
    { id: 35, 'name': 'Uttar Pradesh' },
    { id: 36, 'name': 'West Bengal' },
];

@Component({
  selector: 'app-ledgercreation',
  templateUrl: './ledgercreation.component.html',
  styleUrls: ['./ledgercreation.component.scss']
})
export class LedgercreationComponent implements OnInit {
 ledgers: LedgerPojo[] = [];
ledger = new LedgerPojo();
id: number;
public Acc: number[] = [1, 2, 5, 3];
cid: number;
public acc: number[];
public data;
public filterQuery = '';
public rowsOnPage = 10;
public sortBy = 'i';
public sortOrder = 'asc';
isLoading: boolean = true;
loggedUser = new MUserMasterPojo();
public states: any[] = STATES;
public itemsAcc: any[];
flag: string;
addLedg = new LedgerPojo();
editLedg = new LedgerPojo();
accounts: AccountGroupPojo[] = [];
ledgerGrpArr:LedgerGroupPojo[]=[];
allLedgerGrpArr:LedgerGroupPojo[]=[];
constructor(private ledgercreationService: LedgercreationService,private accountgroupmasterService: AccountGroupMasterService) { }

  ngOnInit() {
      $( '#addAfterClick' ).hide();
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
    //  this.getLedgerByAccountGroup( this.acc);
      this.getUserLedgerAll();
      this.getAccountGrpAll();
      this.getLedgerGrpAll();
      this.getLedgerGrpAllNotPaid();
  }
  getAccountGrpAll() {
      this.accountgroupmasterService.getAllAccountGrpMaster().subscribe( response => {
          this.accounts = response;
        }, error => {
          bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
  }
  getLedgerGrpAll() {
    this.allLedgerGrpArr=[];
    this.ledgercreationService.getAllLedgerGroup().subscribe( response => {
        this.allLedgerGrpArr = response;
      }, error => {
        bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
  getLedgerGrpAllNotPaid() {
    this.ledgerGrpArr=[];
    this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
        this.ledgerGrpArr = response;
      }, error => {
        bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
 
  getAccountById(id) {
      if (this.accounts.find(r => r.accGroupId == id)) {
          return this.accounts.find(r => r.accGroupId == id).accGroupName;
      }
      else {
          return id;
      }
  }  
  getLedgerGroupById(id) {
    if (this.allLedgerGrpArr.find(r => r.ledgerGroupId == id)) {
        return this.allLedgerGrpArr.find(r => r.ledgerGroupId == id).ledgerGroupName;
    }
    else {
        return id;
    }
}  
 clickAdd(){
    this.ledgercreationService.getLedgerCode(this.loggedUser.accId).subscribe(log => {
        this.addLedg.ledgerCode = log;
    });
 }
  getUserLedgerAll() {
      this.isLoading = true;
      this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
          this.ledgers = rs;
          this.isLoading = false;
      }, error => {
          bootbox.alert('Error' + error);
          this.isLoading = false;
      });
  }
  
  addClass() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
     if(this.addLedg.accountType =="CREDIT"){
         this.addLedg.credit = this.addLedg.amount;
         this.addLedg.debit=0; 
     } if(this.addLedg.accountType =="DEBIT"){
         this.addLedg.debit = this.addLedg.amount;
         this.addLedg.credit =0;
     } if(this.addLedg.creditLimit ==null||this.addLedg.creditLimit ==undefined){
        this.addLedg.creditLimit = 0;
    }
     this.addLedg.accountId = this.loggedUser.accId;
     this.addLedg.accGroupId=3;
      this.ledgercreationService.addLedger(this.addLedg).subscribe( response => {
          this.flag = response._body;
          if (this.flag == 'notexists') {
              bootbox.alert( { message: 'Ledger has been added successfully.', className: 'text-success h5', closeButton: false } );
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              ( <HTMLFormElement>document.getElementById( 'addLedgerForm' ) ).reset();
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              $( '#addModal' ).modal( 'hide' );
              this.getUserLedgerAll();
          }
          else if (this.flag == 'exists') {
              bootbox.alert('Name already exist!');
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
                }
          
          else if (this.flag == 'codeexists') {
              bootbox.alert('LedgerCode is already exist!');
              ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
                }
       
      }, error => {
          bootbox.alert( { message: 'Add Ledger Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
   } );
  }

  clickEdit( arg ) {
      this.editLedg = Object.assign( {}, arg );
     
      if(this.editLedg.credit == 0){
          this.editLedg.amount =  this.editLedg.debit;
          this.editLedg.accountType = "DEBIT";
      } if(this.editLedg.debit == 0){
          this.editLedg.amount =  this.editLedg.credit;
          this.editLedg.accountType = "CREDIT";
      }
      if(this.editLedg.ledgerGroupId==4){
        this.ledgerGrpArr=[];
        this.ledgercreationService.getAllLedgerGroup().subscribe( response => {
            this.ledgerGrpArr = response;
          }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
      }else{
        this.ledgerGrpArr=[];
        this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
            this.ledgerGrpArr = response;
          }, error => {
            bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
      }
  }  

  editClass() {
      let editLedgerPojo = new LedgerPojo();
      this.editLedg.accountId = this.loggedUser.accId;
      editLedgerPojo = this.editLedg;
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      
      if(this.editLedg.accountType =="CREDIT"){
          this.editLedg.credit = this.editLedg.amount;
          this.editLedg.debit=0;
      } if(this.editLedg.accountType =="DEBIT"){
          this.editLedg.debit = this.editLedg.amount;
          this.editLedg.credit=0;
      }
      editLedgerPojo.accGroupId=3;
         this.ledgercreationService.updateLedger(editLedgerPojo).subscribe( response => {
             this.flag = response._body;
             if (this.flag == 'notexists') {
                 bootbox.alert( { message: 'Ledger has been successfully updated.', className: 'text-success h5', closeButton: false } );
                 ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
                 $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
                 $( '#editModal' ).modal( 'hide' );
                 this.getUserLedgerAll();
             }
             else if (this.flag == 'codeexists') {
                 bootbox.alert('LedgerCode is already exist!');
                 ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
                 $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
             }
             else if (this.flag == 'exists') {
                 bootbox.alert('LedgerName is already exist!');
                 ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
                 $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            }
         
      }, error => {
          bootbox.alert( { message: 'Update Ledger Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.ledgers.indexOf( this.ledgers.find( r => r.ledgerId == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.ledgercreationService.deleteLedger( this.id,this.loggedUser.accId, this.loggedUser.loggedUserId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
              this.ledgers.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.ledgers.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          $( '#deleteModal' ).modal( 'hide' );
          this.getUserLedgerAll();
      }, error => {
          bootbox.alert( { message: 'Delete Ledger Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
}
