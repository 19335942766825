export class LedgerStatementPojo {

    DET: string;
    DEBIT: number;
    CREDIT: number;
    BALANCE: number;
    LEDGERID: number;
    TRANSACTIONDATE: string;

    companyId: number;
    ledgerName: string;
    openingBalance: number;
    closingBalance: number;

}
