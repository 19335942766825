import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { NotesMasterPojo } from 'src/app/pojo/student-master.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class NotesMasterService {

    constructor( private httpclient: HttpClient ) { }

    getNotesAll(accid): Observable<NotesMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetNotesMasterAll/'+accid;
        return this.httpclient.get<NotesMasterPojo[]>( url );
    }

    getStudentMasterByDidCidAndSid( did, cid, sid ): Observable<NotesMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetStudentByDeptIdClassIdAndSectionId/' + did + '/' + cid + '/' + sid;
        return this.httpclient.get<NotesMasterPojo[]>( url );
    }

    addNotes( arg: NotesMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddNotesMaster';
        return this.httpclient.post( url, arg );
    }

    updateNotes( arg: NotesMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateNotesMaster';
        return this.httpclient.put( url, arg );
    }

    deletegetNotes( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteNotesMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }

    getNotesById( notesId: number,accid:number ): Observable<NotesMasterPojo> {
        const url = StaticVariable.porturl + 'GetNotesMasterById/' + notesId+'/'+accid;
        return this.httpclient.get<NotesMasterPojo>( url );
    }
    
  
}
