export class AccountMasterPojo {

    accId:number;
    accName:string;
    address:string;
    place:string;
    district:string;
    phoneNo:string;
    userId:number;
    title:string;
    schoolName:string;
	educationalDistrict:string;
	revenueDistrict:string;
}
export class AccountGroupPojo {
     accGroupId:number;
     accGroupName:string;
     accGroup:string;
     accType:string;
     tradingStatus:string;
     tradingSNo:string;
     tradeType:string;
     balSNo:string;
     balType:string;
     fLedger:string;
}