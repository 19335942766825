import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'receiptEntry'
})
export class ReceiptEntryPipe implements PipeTransform {
    

  transform(array: any[], query: string): any {
     
          if (query) {
               if (_.filter(array, row => row.receiptNo == query) != ''){
                 return _.filter(array, row => row.receiptNo == query);
                 } else if (_.filter(array, row => row.amount == query) != '') {
                 return _.filter(array, row => row.amount == query);
             } 
                 else {
                 return;
             }
          }

          return array;
      }
  }
