import { Component, OnInit } from '@angular/core';
import { StudentQuickAdmissionPojo } from 'src/app/pojo/quick-admission.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { StudentMasterService } from '../Masters/student-master/student-master.service';
import { ClassMasterService } from '../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { AcademicSettingService } from '../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-quick-admission',
  templateUrl: './quick-admission.component.html',
  styleUrls: ['./quick-admission.component.scss'],
  providers:[AcademicSettingService]
})
export class QuickAdmissionComponent implements OnInit {
    studentMaster = new StudentQuickAdmissionPojo();
    studentMasterRes = new StudentQuickAdmissionPojo();
    addDOA;
    addDOB: NgbDateStruct;
    classArr: ClassMasterPojo[] = [];
    loggedUser = new MUserMasterPojo();
    academic:AcademicYearMasterPojo[] =[];
    isLoading: boolean = false;
  constructor(private studentMasterService: StudentMasterService,
    private classMasterService: ClassMasterService,private settingService:AcademicSettingService,) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

    this.getClassMasterAll();
    this.autoGenAdmissionNo();
    this.academicSettingAll();
    this.getServerDate();
  }
  getServerDate(){
    this.studentMasterService.getCurrenntDate().subscribe( response => {
        this.addDOA = response;
        this.addDOA = { day: moment( this.addDOA ).date(), month: moment( this.addDOA ).month() + 1, year: moment( this.addDOA ).year() };
           
          }, error => {
        bootbox.alert( { message: 'Get Server Date Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
  academicSettingAll(){
    this.academic = [];
    this.settingService.getCurrentandPreviousYrList().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                this.studentMaster.joiningAcademicYear = this.academic[i].academicYearId;
            }
        }
        
          }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
        this.classArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert({ message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
        this.isLoading = false;
    });
}
autoGenAdmissionNo(){
  this.studentMasterService.getAutoGenerateAdmissionNo(this.loggedUser.accId).subscribe( rs => {
        this.studentMaster.admissionNo = rs;
         }, error => {
        bootbox.alert( 'Error' + error );
        } );
  }
  addClass() {
   
   this.isLoading = true;
   this.studentMaster.doa = moment(this.addDOA.year + '-' + this.addDOA.month + '-' + this.addDOA.day, 'YYYY-MM-DD').toDate();
  // this.studentMaster.doa = this.addDOA;
   this.studentMaster.dob = moment(this.addDOB.year + '-' + this.addDOB.month + '-' + this.addDOB.day, 'YYYY-MM-DD').toDate();
   //this.studentMaster.joiningAcademicYear = this.loggedUser.academicYearId;
   this.studentMaster.accId=this.loggedUser.accId;
   this.studentMasterService.addQuickAdmission(this.studentMaster).subscribe(response => {
     if(response == 101){
      bootbox.alert('Admission Number already Exist');
       this.isLoading = false;
      //  this.studentMasterService.getAutoGenerateAdmissionNo(this.loggedUser.accId).subscribe( rs => {
      //   this.studentMaster.admissionNo = rs;
      //   this.addClass()
      //  }, error => {
      //   bootbox.alert( 'Error' + error );
      //   } );
        }if(response == 102){
       this.studentMasterRes = response;
       bootbox.alert({ message: 'Student has been Successfully Registered ! '+'\n Your Admission Number is- '+this.studentMaster.admissionNo, className: 'text-success h5', closeButton: false });
             this.isLoading = false;
              this.studentMaster = new StudentQuickAdmissionPojo();
              this.addDOB = null;
             // this.addDOA = null;  
             (<HTMLFormElement>document.getElementById('formFirst')).reset();
             this.autoGenAdmissionNo();
             this.academicSettingAll();
             this.getServerDate();
     }
        }, error => {
       bootbox.alert({ message: 'Add Quick Admission Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
       this.isLoading = false;
   });
}
reset(){
  this.studentMaster = new StudentQuickAdmissionPojo();
  this.addDOB = null;
  //this.addDOA = null;
 (<HTMLFormElement>document.getElementById('formFirst')).reset();
 this.autoGenAdmissionNo();
 this.academicSettingAll();
 this.studentMaster.doa = moment(this.addDOA.year + '-' + this.addDOA.month + '-' + this.addDOA.day, 'YYYY-MM-DD').toDate();
     
}
}
