export class AttendancePojo {

	 attendanceId:number;
	 studentId:number;
	 name:string;
	 rollNo:string;
	 admissionNo:string;
	 attendanceDate:Date;
	 hoursId:number;
	 attendanceStatus:string;
	 reason:string;
	 code:number;
	 classId:number;
	 sectionId:number;
	 academicYearId:number;

}    
export class AttendanceResponsePojo {
	response:string;
	code:number;
	classId:number;
	 sectionId:number;
	 academicYearId:number;
	 hoursId:number;
	 attendanceDate;
	 accId:number;
	list:AttendancePojo[]=[];
}
