
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NotesMasterPojo } from 'src/app/pojo/student-master.pojo';
import { NotesMasterService } from './notes-master.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-notes-master',
  templateUrl: './notes-master.component.html',
  styleUrls: ['./notes-master.component.scss']
})

export class NotesMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    notesArr: NotesMasterPojo[] = [];
    addNotes = new NotesMasterPojo();
    editNotes = new NotesMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private notesmasterserivce: NotesMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getNotesAll();
    }
    
    getNotesAll() {
        this.isLoading = true;
        this.notesmasterserivce.getNotesAll(this.loggedUser.accId).subscribe( response => {
            this.notesArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Notes All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addNotes.userId = this.loggedUser.userId;
        this.addNotes.accId = this.loggedUser.accId;
          this.notesmasterserivce.addNotes( this.addNotes ).subscribe( response => {
            bootbox.alert( { message: ' Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getNotesAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editNotes = Object.assign( {}, arg );
    }

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editNotes.userId = this.loggedUser.userId;
        this.editNotes.accId = this.loggedUser.accId;
        this.notesmasterserivce.updateNotes( this.editNotes ).subscribe( response => {
            bootbox.alert( { message: 'successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getNotesAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.notesArr.indexOf( this.notesArr.find( r => r.notesId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.notesmasterserivce.deletegetNotes( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.notesArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.notesArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getNotesAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
