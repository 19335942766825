import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { TermPojo } from 'src/app/pojo/term-pojo';
import { HoursPojo } from 'src/app/pojo/hours.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class HoursMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllHoursMaster(cid): Observable<any> {
        const url = StaticVariable.porturl + 'GetHoursAll/'+cid;
        return this.httpclient.get<HoursPojo[]>( url );
    }
    
    addHoursMaster( arg: HoursPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddHours';
        return this.httpclient.post( url, arg );
    }
    
    updateHoursMaster( arg: HoursPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateHours';
        return this.httpclient.put( url, arg );
    }
    
    deleteHoursMaster( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteHours/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}