import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

  
@Pipe( {  
    name: 'ExamScheduleIdPipe'
} )

export class ExamScheduleIdPipe implements PipeTransform {

    transform( array: any[], query: any): any {
        if ( query ) {
            if ( _.filter( array, row => row.examId == query ) != '' ) {
                return _.filter( array, row => row.examId == query );
            }    
            }
        return [];
    }

}
