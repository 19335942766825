import { Component, OnInit } from '@angular/core';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from './academic-setting.service';
import { ResponsePojo } from 'src/app/pojo/response.pojo';
declare var bootbox: any;
declare var $: any;
@Component({
  selector: 'app-academic-setting',
  templateUrl: './academic-setting.component.html',
  styleUrls: ['./academic-setting.component.scss'],
  providers:[AcademicSettingService]
})
export class AcademicSettingComponent implements OnInit {
  academic = new AcademicYearMasterPojo();
  response = new ResponsePojo();
  constructor(private settingService:AcademicSettingService,) { }

  ngOnInit() {
    $( '#editAfterClick' ).show();
      this.settingService.getSettingById(1).subscribe( response => {
          this.academic = response;
          $( '#editAfterClick' ).hide();
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
  }
  backUpData() {
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
    $( '#backupLoading' ).addClass( 'fa fa-spinner fa fa-spin' );
    this.settingService.backUpData().subscribe( response => {
        this.response = response;
        alert(this.response.responseText);
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
        }, error => {
        bootbox.alert( 'BackUp Service Error! - HTTP Status ' + error.status );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#backupLoading' ).removeClass( 'fa fa-spinner fa fa-spin' ); 
        } );
}

}
