import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { DiscountTypeMasterPojo } from 'src/app/pojo/discount-type-master.pojo';
@Injectable( {
    providedIn: 'root'
} )

export class DiscountTypeMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllDiscountType(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetDiscountTypeMasterAll/'+accid;
        return this.httpclient.get<DiscountTypeMasterPojo[]>( url );
    }
    
    addDiscountType( arg: DiscountTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddDiscountTypeMaster';
        return this.httpclient.post( url, arg );
    }
    
    updateDiscountType( arg: DiscountTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateDiscountTypeMaster';
        return this.httpclient.put( url, arg );
    }
    
    deleteDiscountType( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteDiscountTypeMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
    getDiscountTypeById(id,accid:number): Observable<any> {
        const url = StaticVariable.porturl + 'GetDiscountTypeMasterById/'+id+'/'+accid;
        return this.httpclient.get<DiscountTypeMasterPojo>( url );
    }
}