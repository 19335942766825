import { Pipe, PipeTransform } from '@angular/core';
import { UserSettingsPojo } from 'src/app/pojo/userSettingsPojo';


declare var _: any;

@Pipe({
  name: 'userSettings'
})
export class UserSettingsPipe implements PipeTransform {
  transform(array: UserSettingsPojo[], query: string): any {
    if (query) {
        if (_.filter(array, (row: { settingsName: string; }) => row.settingsName.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
            return _.filter(array, (row: { settingsName: string; }) => row.settingsName.toLowerCase().indexOf(query.toLowerCase()) > -1);
        } else {
            return false;
        }
    }
    return array;
}
}
