
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo, AcademicStandardDetailsResultPojo } from 'src/app/pojo/academic-standard-details.pojo';

import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';

import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { StaffMasterService } from '../../Masters/staff-master/staff-master.service';
import { StaffMasterPojo } from 'src/app/pojo/staff-master.pojo';
import { PromotionService } from '../promotion.service';
import { PromotionReportPojo } from 'src/app/pojo/promotion-report.pojo';
import { stringify } from 'querystring';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss'],
  providers:[AcademicSettingService]
})
export class PromotionComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    standarResultPojo = new AcademicStandardDetailsResultPojo();
    standardArr: AcademicStandardDetailsPojo[] = [];
    responseArr:AcademicStandardDetailsPojo[] = [];
    standard = new AcademicStandardDetailsPojo();
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    studentMasterArr: StudentMasterPojo[] = [];
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltGender:string;
    searchOption = 'ALL';
    promoteFrom;
    promoteTo;
    academic:AcademicYearMasterPojo[] =[];
    staffMasterArr: StaffMasterPojo[] = [];
    resultStatus;
    selectedStaffId;
    resultStaffId;
    updateArr:AcademicStandardDetailsPojo[] =[];
    accountMasterArr:AccountMasterPojo[]=[];
    constructor( private accountMasterService:AccountMasterService,private staffMasterService: StaffMasterService,private settingService:AcademicSettingService,private promotionService:PromotionService,private studentMasterService: StudentMasterService, private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService, private router: Router ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.academicSettingAll();
        this.getStaffAll();
        this.promoteFrom = this.loggedUser.academicYearId-1;
        this.promoteTo = this.loggedUser.academicYearId;

       // this.getStudentByDepartmentId( 1 );
       this.accountMasterAll();
    }
    accountMasterAll() {
      this.accountMasterService.getAllAccountMaster().subscribe(response => {
        this.accountMasterArr = response;
       
      }, error => {
        bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
      });
    }
    getAccountNameById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).accName;
      } else {
        return id;
      }
    }
    getAccountPlaceById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).place;
      } else {
        return id;
      }
    }
    getStaffAll() {
      this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( run => {
          this.isLoading = false;
          this.staffMasterArr = run;
      }, error => {
          bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  }

    academicSettingAll(){
      this.academic = [];
      this.settingService.getAcademicYrAll().subscribe( response => {
          this.academic = response;
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
     }
     getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
    }
     getStaffNameById( id ) {
        if ( this.staffMasterArr.find( r => r.staffId == id ) ) {
            return this.staffMasterArr.find( r => r.staffId == id ).staffName;
        } else {
            return id;
        }
    }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getSectionNameBySectionId( id ) {
        if ( this.sectionArr.find( response => response.sectionId == id ) ) {
            return this.sectionArr.find( response => response.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }

    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }
    getClassMasterByDeptId(id) {
      this.studentMasterService.getClassMasterBydeptId(id,this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getSectionMasterByDeptId(id) {
      this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
          this.sectionMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getSectionNameById( id ) {
      if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
          return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
      } else {
          return id;
      }
  }
  clickEdit( id ) {
    let studentId = id;
    this.router.navigate( [`/console/editStudentRegister`, studentId,{path:2}] );
}

    getReportById() {
      this.standarResultPojo = new AcademicStandardDetailsResultPojo();
      this.standardArr =[];
      this.studentMasterArr= [];
      this.searchOption = 'ALL';
      this.filterQuery = '';
      this.selectedStaffId = '';
        this.isLoading = true;
        this.promotionService.getPromotionByFromandToAndId( this.promoteFrom,this.promoteTo ,this.classid,this.loggedUser.accId).subscribe( response => {
           this.standarResultPojo = response;
           if(this.standarResultPojo.code == 101){
            bootbox.confirm('Students are Already Promoted !\n Do you want to Edit ?', ( result ) => {
                if(result){
                    for(let i=0;i<this.standarResultPojo.alist.length;i++){
                          this.standardArr.push(this.standarResultPojo.alist[i]);
                    }
                    
                    this.resultDept= this.departmentId;
                    this.resultClass= this.classid;
                    this.resultSection=this.sectionid;
                     this.isLoading = false;
                }else{
                    this.isLoading = false;
                }
            });
           }if(this.standarResultPojo.code == 102){
            for(let i=0;i<this.standarResultPojo.alist.length;i++){
                this.standarResultPojo.alist[i].selectStatus = "PASS";
                this.standardArr.push(this.standarResultPojo.alist[i]);
            }
            
            this.resultDept= this.departmentId;
            this.resultClass= this.classid;
            this.resultSection=this.sectionid;
             this.isLoading = false;
           }if(this.standarResultPojo.code == 103){
               bootbox.alert('Promoted Class Not Created!');
               this.isLoading = false;
           }
            
        }, error => {
            bootbox.alert( { message: 'Get Report Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    onChangeResultStatus(item){
         let e = new AcademicStandardDetailsPojo();
        e = item;
           if(this.updateArr.length > 0){
              for ( let i = 0; i < this.updateArr.length; i++ ) {
                if(this.updateArr[i].studentId == e.studentId){
                        this.updateArr.splice( i );
                        i++;
                    this.updateArr.push(e);
                     }else{
                    this.updateArr.push(e); 
                }
            } 
        }else{
               this.updateArr.push(e);  
        }
          }
          updatePromotion(){
              if(this.updateArr.length>0){
               if(this.selectedStaffId !=0 && this.selectedStaffId != null && this.selectedStaffId != undefined){
                this.isLoading = true;
                ( <HTMLInputElement>document.getElementById( 'updateBtn' ) ).disabled = true;
                let promotion = new PromotionReportPojo();
                promotion.fromAcademicYearId = this.promoteFrom;
                promotion.toAcademicYearId = this.promoteTo;
                promotion.userId = this.loggedUser.userId;
                promotion.fromClassId = this.classid;
                promotion.staffId = this.selectedStaffId;
                for(let i=0;i<this.updateArr.length;i++){
                    let d= new AcademicStandardDetailsPojo();
                   d.studentId = this.updateArr[i].student.studentId;
                   d.classId = this.updateArr[i].student.classId;
                   d.selectStatus = this.updateArr[i].selectStatus;
                   d.admissionNo = this.updateArr[i].admissionNo;
                     promotion.selectList.push(d); 
                   //  console.log(this.standardArr.length); 
                   promotion.accId=this.loggedUser.accId;
                      if(i == this.updateArr.length-1){
                             this.promotionService.updatePromotion( promotion,this.loggedUser.accId ).subscribe( response => {
                                 this.responseArr = response;
                                 this.resultStaffId = 
                            bootbox.confirm( ' Promotion Updated!\n Do you want PDF ?', ( result ) => {
                                if ( result ) {
                                    this.downloadPDF();
                                }
                            });    ( <HTMLInputElement>document.getElementById( 'updateBtn' ) ).disabled = false;
                            this.isLoading = false;
                            this.resultStaffId = this.selectedStaffId;
                            this.selectedStaffId = '';
                            this.departmentId = 0;
                            this.classid = 0;
                            this.standardArr = [];
                            promotion.selectList = [];
                            this.filterQuery= "";
                            this.updateArr = [];
                             }, error => {
                            bootbox.alert( { message: 'Promotion Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                            ( <HTMLInputElement>document.getElementById( 'updateBtn' ) ).disabled = false;
                            this.isLoading = false;    
                        } );
                     }
                }
            }else{
                bootbox.alert('Please select the Staff Name...');
                } 
                 }else{
                bootbox.alert('No Records to be edited...');

                 }
          }
    submit() {
        if(this.selectedStaffId !=0 && this.selectedStaffId != null && this.selectedStaffId != undefined){
        this.isLoading = true;
        ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = true;
        let promotion = new PromotionReportPojo();
        promotion.fromAcademicYearId = this.promoteFrom;
        promotion.toAcademicYearId = this.promoteTo;
        promotion.userId = this.loggedUser.userId;
        promotion.fromClassId = this.classid;
        promotion.staffId = this.selectedStaffId;
        for(let i=0;i<this.standardArr.length;i++){
            let d= new AcademicStandardDetailsPojo();
           d.studentId = this.standardArr[i].student.studentId;
           d.classId = this.standardArr[i].student.classId;
           d.selectStatus = this.standardArr[i].selectStatus;
           d.admissionNo = this.standardArr[i].admissionNo;
              promotion.selectList.push(d); 
              promotion.accId=this.loggedUser.accId;
           //  console.log(this.standardArr.length); 
              if(i == this.standardArr.length-1){
                     this.promotionService.submitPromotion( promotion,this.loggedUser.accId ).subscribe( response => {
                         this.responseArr = response;
                         this.resultStaffId = 
                    bootbox.confirm( 'Successfully Promoted!\n Do you want PDF ?', ( result ) => {
                        if ( result ) {
                            this.downloadPDF();
                        }
                    });
                    ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = false;
                    this.isLoading = false;
                    this.resultStaffId = this.selectedStaffId;
                    this.selectedStaffId = '';
                    this.departmentId = 0;
                    this.classid = 0;
                    this.standardArr = [];
                    promotion.selectList = [];
                    this.filterQuery= "";
                     }, error => {
                    bootbox.alert( { message: 'Promotion Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                    ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = false;
                    this.isLoading = false;    
                } );
             }
        }
    }else{
        bootbox.alert('Please select the Staff Name...');
        }
    }
   
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
         this.content.push( {
             text: 'PROMOTION REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );

        
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         this.content.push( {
            text: 'PROMOTE FROM'+' [ '+this.getAcademicYrById(this.promoteFrom)+' TO '+this.getAcademicYrById(this.promoteTo)+' ]', fontSize: 14, alignment: 'center', font: 'TimesNewRoman', bold: true
        } );
         this.content.push( {text: '\nSELECTED TYPE : '+ this.getDepartmentNameBydepartmentId(this.resultDept)+' - '+this.getClassNameById(this.resultClass), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
      
      /* this.content.push( {text: '\nSELECTED CLASS :'+this.getClassNameById(this.resultClass), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );*/
      
     this.content.push( {text: '\nSELECTED STAFF :'+this.getStaffNameById(this.selectedStaffId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
    this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                { text: 'Mobile No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Gender', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'DOB ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Result Status', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
               ] );
   
         for ( let i = 0; i < this.responseArr.length; i++ ) {
            let temp;
            if ( this.responseArr[i].student.dob != null ) {
                temp = moment( this.responseArr[i].student.dob ).format( 'DD/MM/YYYY' ) ;
            } else {
                temp = "-";
            }
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.responseArr[i].student.admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.responseArr[i].student.name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                    { text: this.responseArr[i].student.mobileNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.responseArr[i].student.gender, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text:temp, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.responseArr[i].selectStatus, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '12%', '40%', '10%', '8%', '12%', '13%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
     
}
export class XlPojo {
    admissionNo: string;
    name: string;
    sectionId: string;
    mobileNo: string;
    gender: string;
    dob: Date;
    fatherName: string;
    formEntryStatus: string;
    
}