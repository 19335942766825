
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { AreaMasterService } from '../../Masters/area-master/area-master.service';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { FeeCollectionReportDetailsPojo } from 'src/app/pojo/fee-collection-report-detail.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { Router } from '@angular/router';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.scss'],
  providers:[AcademicSettingService]
})
export class RefundComponent implements OnInit {
   model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;
    loggedUser = new MUserMasterPojo();
    addEntryDate: NgbDateStruct;
    editEntryDate: NgbDateStruct;
    feeCollectionArr: FeeCollectionReportDetailsPojo[] = [];
    tempPushArr:FeeAllocationPojo[]=[];
    classMasterArr: ClassMasterPojo[] = [];
    addMaster = new FeeAllocationPojo();
    editMaster = new FeeAllocationPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = false;
    feeStructArr: StandardTermPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
     standardArr: AcademicStandardDetailsPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    studentMasterAllArr: StudentMasterPojo[] = [];
    studentEditMasterArr: StudentMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    classid;
    sectionid;
    departmentId;
    academicYr;
    termArr: TermPojo[] = [];
    termid;
    standardTermId;
    filteredFeeStructArr:StandardTermPojo[]=[];
    reportPojo = new FeeCollectionReportDetailsPojo();
    filterId:string;
    tempArr:FeeAllocationPojo[]=[];
    areaArr:AreaMasterPojo[]=[];
    pickspaidledgersByAccountGroup:LedgerPojo[]=[];
    public paidacc: number[] = [1, 2];
    constructor(private departmentMasterService:DepartmentMasterService,private classMasterService: ClassMasterService,private router:Router,
      private feeTypemasterserivce: TermMasterService,private feeAloocationService:FeeAllocationMasterService,private ledgercreationService:LedgercreationService,
      private settingService:AcademicSettingService,private sectionMasterService:SectionMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        this.academicSettingAll();
       this.getDepartmentAll();
        this.getClassMasterAll();
        this.getSectionMasterAll();
         this.getTermAll();
          this.ledgercreationService.getLedgerByAccountGroup( this.paidacc,this.loggedUser.accId).subscribe( log => {
          this.pickspaidledgersByAccountGroup = log;
      } ); 
        }
     
      getPaidLedgerById( id ) {
        if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
            return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
        } else {
            return id;
        }
      }
        getTermAll() {
          this.isLoading = true;
          this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
              this.termArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              this.isLoading = false;
          } );
      }
       getStandardTermById(){  
        this.filteredFeeStructArr=[];
        this.feeAloocationService.getBusStandardTermByClassidAndTermId(this.classid,this.termid,this.loggedUser.accId).subscribe( response => {
          this.filteredFeeStructArr= response;
            }, error => {
          bootbox.alert( { message: 'Get STandard Term By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
      }
        getAllocationDetailsById(){
          this.feeCollectionArr =[];
          this.tempArr=[];
          this.isLoading = true;
             let deptId=this.getDeptNameById(this.departmentId);
         
          this.feeAloocationService.getBusFeeCollectionDetailsById(this.academicYr,deptId,this.classid,this.standardTermId,this.loggedUser.accId).subscribe( response => {
               this.feeCollectionArr= response;
              this.isLoading = false;
             }, error => {
            bootbox.alert( { message: 'Get Bus Fee Collection Report Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;   
          } );
        }
  
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
        this.departmentArr = run;
    }, error => {
        bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
    }
  getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
             }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                this.addMaster.academicYearId = this.academic[i].academicYearId;
                this.academicYr = this.academic[i].academicYearId;
                  }
        }
        
          }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
   getAcademicYrById( id ) {
    if ( this.academic.find( r => r.academicYearId == id ) ) {
        return this.academic.find( r => r.academicYearId == id ).academicYear;
    } else {
        return id;
    }
  }
  getStandardTermNameById( id ) {
    if ( this.filteredFeeStructArr.find( r => r.standardTermId == id ) ) {
        return this.filteredFeeStructArr.find( r => r.standardTermId == id ).standardTerm;
    } else {
        return id;
    }
  }
  getDeptNameById( id ) {
    if ( this.departmentArr.find( r => r.deptId == id ) ) {
        return this.departmentArr.find( r => r.deptId == id ).deptName;
    } else {
        return id;
    }
  }
  getClassNameById( id ) {
    if ( this.classMasterArr.find( r => r.classId == id ) ) {
        return this.classMasterArr.find( r => r.classId == id ).className;
    } else {
        return id;
    }
  }
  getSectionNameById( id ) {
    if ( this.sectionArr.find( r => r.sectionId == id ) ) {
        return this.sectionArr.find( r => r.sectionId == id ). sectionName;
    } else {
        return id;
    }
  }
  getTermNameById( id ) {
    if ( this.termArr.find( r => r.termId == id ) ) {
        return this.termArr.find( r => r.termId == id ).termName;
    } else {
        return id;
    }
  }
  routeToRefundForm(arg:FeeCollectionReportDetailsPojo){
    let d= new TempFeeCollectionReportDetailsPojo();
    d.collectionDate=arg.collectionDate;
    d.collectionTime=arg.collectionTime;
    d.collectionId=arg.collectionId;
    d.name=arg.name;
    d.admissionNo=arg.admissionNo;
    d.academicYearId=arg.academicYearId;
    d.classId=arg.classId;
    d.sectionId=arg.sectionId;
    d.receiptId=arg.receiptId;
    d.receiptNo=arg.receiptNo;
    d.overAllPaidTotal=arg.overAllPaidTotal;
    d.paidLedgerId=arg.paidLedgerId;
    d.termId=arg.termId;
    d.standardTermId=arg.standardTermId;
    d.studentId=arg.studentId;
    d.feeAllocationId=arg.feeAllocationId;
    d.academicYear=this.getAcademicYrById(arg.academicYearId);
    d.className=this.getClassNameById(arg.classId);
    d.sectionName=this.getSectionNameById(arg.sectionId);
    d.paidLedgerName=this.getPaidLedgerById(arg.paidLedgerId);
    d.standardTermName=this.getStandardTermNameById(arg.standardTermId);
    d.termName=this.getTermNameById(arg.termId);
     this.router.navigate( [`/console/refundForm`, d] );
  }

}

export class TempFeeCollectionReportDetailsPojo {

  collectionId:number;
  collectionDate:Date;
  collectionTime:string;
  classId:number;
  sectionId:number;
  studentId:number;
  name:string;
  receiptId:number;
  receiptNo:string;
  overAllPaidTotal:number;
  academicYearId:number;
  admissionNo:string;
  paidLedgerId:number;
  userId:number;
  accId:number;
  termId:number;
  standardTermId:number;
  feeAllocationId:number;
  standardTerm:string;
  paidLedgerName:string;
  termName:string;
  standardTermName:string;
  sectionName:string;
  className:string;
  academicYear:string;
  areaId:number;
}