
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo, NotesMasterPojo } from "src/app/pojo/student-master.pojo";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { Subject, Observable } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

declare var $: any;
declare var bootbox: any;

import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { AcademicSettingService } from 'src/app/management/Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import * as printJs from 'print-js'
import { NotesMasterService } from '../../notes-master/notes-master.service';
import { AccountMasterService } from 'src/app/management/Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

@Component({
  selector: 'app-print-student-master',
  templateUrl: './print-student-master.component.html',
  styleUrls: ['./print-student-master.component.scss'],
  providers:[AcademicSettingService]
})

export class PrintStudentMasterComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    loggedUser = new MUserMasterPojo();

    editStudentMaster = new StudentMasterPojo();

    mediumArr: MediumMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];

    imagePojo: FtpImagePojo = new FtpImagePojo();

    isLoading: boolean = true;
    editDOA: NgbDateStruct;
    editDOB: NgbDateStruct;

    studentId;
    fileObj: any;
    showSaveBtn: boolean = false;
    imageUploadLoading: boolean = false;
    imageUploadStatus: boolean = false;
    tempFtpArr:FtpImagePojo[]=[];
    selectedFiles: File[] = [];
    newFile : boolean = false;
    fileincreament:number = 0;
    // toggle webcam on/off
    public showWebcam = false;
    public mirrorImage = "always";
    documentListPojo = new FtpImagePojo();
    // latest snapshot
    public webcamImage: WebcamImage = null;

    deleteFileIncreament = 0;
    addFiles:boolean=false;
    deleteFiles:boolean=false;
    bothFunction:boolean=false;
    onlyUpdate:boolean=false;
    serviceCount:number=0;
    notesArr : NotesMasterPojo[] =[];
    notesArrAll:NotesMasterPojo [] =[];
    editNotes = new NotesMasterPojo();
    academic:AcademicYearMasterPojo[] =[];
    path;
    body = [];
    content = [];
    documentTime:string;
    admissionNum:string;
    accountMasterArr:AccountMasterPojo[]=[];
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();

    public triggerSnapshot(): void {
        this.trigger.next();
        this.showWebcam = false;
        this.showSaveBtn = true;
    }

    public handleImage( webcamImage: WebcamImage ): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    constructor( private activatedRoute: ActivatedRoute, private router: Router, private calendar: NgbCalendar, 
        private mediumMasterService: MediumMasterService, private sectionMasterService: SectionMasterService, 
        private studentMasterService: StudentMasterService, private classMasterService: ClassMasterService,
        private notesMasterService:NotesMasterService,private settingService:AcademicSettingService,
        private accountMasterService:AccountMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
//console.log(this.loggedUser);
        // this.activatedRoute.params.subscribe( response => {
        //       this.studentId = response['studentId'];
        //     this.path = response['path'];
            
        // } );
          // this.getStudentMasterById( this.studentId );
          this.academicSettingAll();
        this.getMediumMasterAll();
      //  this.getSectionMasterAll();
        this.getClassMasterAll();
        this.getNotesMasterAll();
        this.accountMasterAll();
    }
    accountMasterAll() {
      this.accountMasterService.getAllAccountMaster().subscribe(response => {
        this.accountMasterArr = response;
       
      }, error => {
        bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
      });
    } getAccountNameById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).accName;
      } else {
        return id;
      }
    }
    getAccountPlaceById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).place;
      } else {
        return id;
      }
    }
     print() {
      printJs({
      printable: 'formFirst',
      type: 'html',
     // style: '.modal-header { color: red; width: 100px; } .test { width:100%;height:20%; }'
      targetStyles: ['*']
   })
  }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
             }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
       getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        }
        else {
            return id;
        }
    }
    getNotesMasterAll() {
        this.notesMasterService.getNotesAll(this.loggedUser.accId).subscribe( response => {
            this.notesArrAll = response;
             }, error => {
            bootbox.alert( { message: 'Get Notes All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getNotesByNotesId(id){
        this.editNotes.description = this.getNotesById(id);
        this.editNotes.name = this.getNotesNameById(id);
        
    }
    getNotesById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).description;
        }
        else {
            return id;
        }
    }
    getNotesNameById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).name;
        }
        else {
            return id;
        }
    }
    getClassNameById( id ) {
      if ( this.classArr.find( r => r.classId == id ) ) {
          return this.classArr.find( r => r.classId == id ).className;
      }
      else {
          return id;
      }
  }
  
  getMediumNameById( id ) {
    if ( this.mediumArr.find( r => r.mediumId == id ) ) {
        return this.mediumArr.find( r => r.mediumId == id ).mediumName;
    }
    else {
        return id;
    }
}


    getMediumMasterAll() {
        this.mediumMasterService.getMediumAll().subscribe( response => {
            this.mediumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( {
                message: 'Get Medium All Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
            this.isLoading = false;
        } );
    }

    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
getStudentDet(){
  this.editStudentMaster=new StudentMasterPojo();
  this.isLoading=true;
  this.studentMasterService.getStudentMasterByAdmissionNo( this.admissionNum,this.loggedUser.accId ).subscribe( response => {
    this.editStudentMaster = response;
    this.getSectionMasterByDeptId(this.editStudentMaster.classId);
    this.notesArr = this.editStudentMaster.notes;
    this.editDOA = { day: moment( this.editStudentMaster.doa ).date(), month: moment( this.editStudentMaster.doa ).month() + 1, year: moment( this.editStudentMaster.doa ).year() };
    this.editDOB = { day: moment( this.editStudentMaster.dob ).date(), month: moment( this.editStudentMaster.dob ).month() + 1, year: moment( this.editStudentMaster.dob ).year() };
    this.isLoading=false;
  }, error => {
    bootbox.alert( {
        message: 'Get Student By Admission No Service Error! HTTP Status ' + error.status,
        className: 'text-danger h5',
        closeButton: false
    } );
    this.isLoading=false;
} );
}
    getStudentMasterById( studentId ) {
        this.studentMasterService.getStudentById( studentId,this.loggedUser.accId ).subscribe( response => {
            this.editStudentMaster = response;
            this.getSectionMasterByDeptId(this.editStudentMaster.classId);
            this.notesArr = this.editStudentMaster.notes;
            this.editDOA = { day: moment( this.editStudentMaster.doa ).date(), month: moment( this.editStudentMaster.doa ).month() + 1, year: moment( this.editStudentMaster.doa ).year() };
            this.editDOB = { day: moment( this.editStudentMaster.dob ).date(), month: moment( this.editStudentMaster.dob ).month() + 1, year: moment( this.editStudentMaster.dob ).year() };
        }, error => {
            bootbox.alert( {
                message: 'Get Student By Id Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
        } );
    }
    modalShow(){
      $( '#myModal1' ).modal( 'show' );
  }
    
    getSectionMasterByDeptId(id) {
        this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    downloadPdf(){
      this.downloadPDF();
         this.router.navigate( ['/console/studentMaster'] );
         this.editStudentMaster = new StudentMasterPojo();
         }
    downloadPDF() {
       
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      
          this.content.push(
            {
                columns: [
                     {
                        image: StaticVariable.logo, width:50 },
                        {width:'4%',
                         text:''},
                        {
                            width: '80%',
                            
                            text: [{text:this.getAccountNameById(this.loggedUser.accId) , style: 'e' }, {text:'\n' + this.getAccountPlaceById(this.loggedUser.accId), style: 'e2' },
                              ]  
                            
                        },
                 ] } );
       this.content.push( {
           text: 'STUDENT ADMISSION DETAILS', fontSize: 15, alignment: 'center', font: 'TimesNewRoman', bold: true
       } );
 
       this.content.push( {
           text: '------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
           fontSize: 10, bold: true, alignment: 'center'
       } );
      
       this.content.push( {
           text: '\n', fontSize: 8, alignment: 'center'
       } );
       this.content.push(
        {
          lineHeight:1.6,
            columns: [
                {
                    width: '30%',
                    text: 'Student Name in English', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                },
                {
                  width: '3%',
                  text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
              },
                {
                    width: '67%',
                    text: this.editStudentMaster.name, fontSize: 12, font: 'TimesNewRoman'
                }
            ]
        } );
        this.content.push(
          {
            lineHeight:1.6,
              columns: [
                  {
                      width: '30%',
                      text: 'Student Name in Tamil', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                  },
                  {
                    width: '3%',
                    text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                },
                  {
                      width: '67%',
                      text: this.editStudentMaster.nameTamil, fontSize: 12, font: 'TimesNewRoman'
                  }
              ]
          } );
          this.content.push(
            {
              lineHeight:1.6,
                columns: [
                    {
                        width: '30%',
                        text: 'Gender', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                    },
                    {
                      width: '3%',
                      text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                  },
                    {
                        width: '67%',
                        text: this.editStudentMaster.gender, fontSize: 12, font: 'TimesNewRoman'
                    }
                ]
            } );
            let dob;
            if(this.editStudentMaster.dob == null){
              dob = moment(this.editStudentMaster.dob,'YYYY-MM-DD').format('DD/MM/YYYY');
            }else{
              dob = '-';
            }
            this.content.push(
              {
                lineHeight:1.6,
                  columns: [
                      {
                          width: '30%',
                          text: 'Date of Birth', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                      },
                      {
                        width: '3%',
                        text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                    },
                      {
                          width: '67%',
                          text: dob, fontSize: 12, font: 'TimesNewRoman'
                      }
                  ]
              } );
              this.content.push(
                {
                  lineHeight:1.6,
                    columns: [

                        {
                            width: '30%',
                            text: 'Academic Year', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                        },
                        {
                          width: '3%',
                          text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                      },
                        {
                            width: '67%',
                            text: this.getAcademicYrById(this.editStudentMaster.joiningAcademicYear), fontSize: 12, font: 'TimesNewRoman'
                        }
                    ]
                } );
                this.content.push(
                  {
                    lineHeight:1.6,
                      columns: [
                          {
                              width: '30%',
                              text: 'Last year Status', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                          },
                          {
                            width: '3%',
                            text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                        },
                          {
                              width: '67%',
                              text: this.editStudentMaster.lastYearStatus, fontSize: 12, font: 'TimesNewRoman'
                          }
                      ]
                  } );
                  this.content.push(
                    {
                      lineHeight:1.6,
                        columns: [
                            {
                                width: '30%',
                                text: 'Fathers Name', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                            },
                            {
                              width: '3%',
                              text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                          },
                            {
                                width: '67%',
                                text: this.editStudentMaster.fatherName, fontSize: 12, font: 'TimesNewRoman'
                            }
                        ]
                    } );
                    this.content.push(
                      {
                        lineHeight:1.6,
                          columns: [
                              {
                                  width: '30%',
                                  text: 'Mother Name', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                              },
                              {
                                width: '3%',
                                text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                            },
                              {
                                  width: '67%',
                                  text: this.editStudentMaster.motherName, fontSize: 12, font: 'TimesNewRoman'
                              }
                          ]
                      } );
                      this.content.push(
                        {
                          lineHeight:1.6,
                            columns: [
                                {
                                    width: '30%',
                                    text: 'Guardian Name', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                },
                                {
                                  width: '3%',
                                  text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                              },
                                {
                                    width: '67%',
                                    text: this.editStudentMaster.guardianName, fontSize: 12, font: 'TimesNewRoman'
                                }
                            ]
                        } );

                        this.content.push(
                          {
                            lineHeight:1.6,
                              columns: [
                                  {
                                      width: '30%',
                                      text: 'Permanent Address', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                  },
                                  {
                                    width: '3%',
                                    text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                },
                                  {
                                      width: '67%',
                                      text: this.editStudentMaster.address, fontSize: 12, font: 'TimesNewRoman'
                                  }
                              ]
                          } );
                          this.content.push(
                            {
                              lineHeight:1.6,
                                columns: [
                                    {
                                        width: '30%',
                                        text: 'Pincode', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                    },
                                    {
                                      width: '3%',
                                      text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                  },
                                    {
                                        width: '67%',
                                        text: this.editStudentMaster.pincode, fontSize: 12, font: 'TimesNewRoman'
                                    }
                                ]
                            } );
                            this.content.push(
                              {
                                lineHeight:1.6,
                                  columns: [
                                      {
                                          width: '30%',
                                          text: 'Nationality', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                      },
                                      {
                                        width: '3%',
                                        text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                    },
                                      {
                                          width: '67%',
                                          text: this.editStudentMaster.nationality, fontSize: 12, font: 'TimesNewRoman'
                                      }
                                  ]
                              } );
                              this.content.push(
                                {
                                  lineHeight:1.6,
                                    columns: [
                                        {
                                            width: '30%',
                                            text: 'Religion', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                        },
                                        {
                                          width: '3%',
                                          text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                      },
                                        {
                                            width: '67%',
                                            text: this.editStudentMaster.religion, fontSize: 12, font: 'TimesNewRoman'
                                        }
                                    ]
                                } );
                                this.content.push(
                                  {
                                    lineHeight:1.6,
                                      columns: [
                                          {
                                              width: '30%',
                                              text: 'Community ( Caste Name )', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                          },
                                          {
                                            width: '3%',
                                            text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                        },
                                          {
                                              width: '67%',
                                              text:  this.editStudentMaster.community+'('+this.editStudentMaster.cast+')', fontSize: 12, font: 'TimesNewRoman'
                                          }
                                      ]
                                  } );
                                  this.content.push(
                                    {
                                      lineHeight:1.6,
                                        columns: [
                                            {
                                                width: '30%',
                                                text: 'Mobile Number (1 And 2)', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                            },
                                            {
                                              width: '3%',
                                              text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                          },
                                            {
                                                width: '67%',
                                                text: this.editStudentMaster.mobileNo +'/'+this.editStudentMaster.mobileNo2, fontSize: 12, font: 'TimesNewRoman'
                                            }
                                        ]
                                    } );
                                    this.content.push(
                                      {
                                        lineHeight:1.6,
                                          columns: [
                                              {
                                                  width: '30%',
                                                  text: 'Telephone Number', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                              },
                                              {
                                                width: '3%',
                                                text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                            },
                                              {
                                                  width: '67%',
                                                  text: this.editStudentMaster.telephoneNo, fontSize: 12, font: 'TimesNewRoman'
                                              }
                                          ]
                                      } );
                                      this.content.push(
                                        {
                                          lineHeight:1.6,
                                            columns: [
                                                {
                                                    width: '30%',
                                                    text: 'Blood Group', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                },
                                                {
                                                  width: '3%',
                                                  text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                              },
                                                {
                                                    width: '67%',
                                                    text: this.editStudentMaster.bloodGroup, fontSize: 12, font: 'TimesNewRoman'
                                                }
                                            ]
                                        } );
                                        this.content.push(
                                          {
                                            lineHeight:1.6,
                                              columns: [
                                                  {
                                                      width: '30%',
                                                      text: 'Adhaar Number', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                  },
                                                  {
                                                    width: '3%',
                                                    text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                },
                                                  {
                                                      width: '67%',
                                                      text: this.editStudentMaster.aadharNo, fontSize: 12, font: 'TimesNewRoman'
                                                  }
                                              ]
                                          } );
                                          this.content.push(
                                            {
                                              lineHeight:1.6,
                                                columns: [
                                                    {
                                                        width: '30%',
                                                        text: 'EMIS Number', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                    },
                                                    {
                                                      width: '3%',
                                                      text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                  },
                                                    {
                                                        width: '67%',
                                                        text: this.editStudentMaster.emisNo, fontSize: 12, font: 'TimesNewRoman'
                                                    }
                                                ]
                                            } );
                                            this.content.push(
                                              {
                                                lineHeight:1.6,
                                                  columns: [
                                                      {
                                                          width: '30%',
                                                          text: 'Medium of Studied', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                      },
                                                      {
                                                        width: '3%',
                                                        text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                    },
                                                      {
                                                          width: '67%',
                                                          text: this.getMediumNameById(this.editStudentMaster.mediumId), fontSize: 12, font: 'TimesNewRoman'
                                                      }
                                                  ]
                                              } );
                                              this.content.push(
                                                {
                                                  lineHeight:1.6,
                                                    columns: [
                                                        {
                                                            width: '30%',
                                                            text: 'Class Name', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                        },
                                                        {
                                                          width: '3%',
                                                          text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                      },
                                                        {
                                                            width: '67%',
                                                            text: this.getClassNameById(this.editStudentMaster.classId), fontSize: 12, font: 'TimesNewRoman'
                                                        }
                                                    ]
                                                } );
                                                this.content.push(
                                                  {
                                                    lineHeight:1.6,
                                                      columns: [
                                                          {
                                                              width: '30%',
                                                              text: 'SMS Flag / WhatsApp Flag', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                          },
                                                          {
                                                            width: '3%',
                                                            text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                        },
                                                          {
                                                              width: '67%',
                                                              text: this.editStudentMaster.smsFlag +' / '+this.editStudentMaster.whatsappStatus, fontSize: 12, font: 'TimesNewRoman'
                                                          }
                                                      ]
                                                  } );
                                                  this.content.push(
                                                    {
                                                      lineHeight:1.6,
                                                        columns: [
                                                            {
                                                                width: '30%',
                                                                text: 'Qualification of Father', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                            },
                                                            {
                                                              width: '3%',
                                                              text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                          },
                                                            {
                                                                width: '67%',
                                                                text: this.editStudentMaster.fatherQualification, fontSize: 12, font: 'TimesNewRoman'
                                                            }
                                                        ]
                                                    } );
                                                    this.content.push(
                                                      {
                                                        lineHeight:1.6,
                                                          columns: [
                                                              {
                                                                  width: '30%',
                                                                  text: 'Father Occupation', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                              },
                                                              {
                                                                width: '3%',
                                                                text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                            },
                                                              {
                                                                  width: '67%',
                                                                  text: this.editStudentMaster.fatherOccupation, fontSize: 12, font: 'TimesNewRoman'
                                                              }
                                                          ]
                                                      } );
                                                    this.content.push(
                                                      {
                                                        lineHeight:1.6,
                                                          columns: [
                                                              {
                                                                  width: '30%',
                                                                  text: 'Father Income', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                              },
                                                              {
                                                                width: '3%',
                                                                text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                            },
                                                              {
                                                                  width: '67%',
                                                                  text: this.editStudentMaster.fatherIncome, fontSize: 12, font: 'TimesNewRoman'
                                                              }
                                                          ]
                                                      } );
                                                      
                                                        this.content.push(
                                                          {
                                                            lineHeight:1.6,
                                                              columns: [
                                                                  {
                                                                      width: '30%',
                                                                      text: 'Qualification of Mother', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                  },
                                                                  {
                                                                    width: '3%',
                                                                    text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                },
                                                                  {
                                                                      width: '67%',
                                                                      text: this.editStudentMaster.motherQualification, fontSize: 12, font: 'TimesNewRoman'
                                                                  }
                                                              ]
                                                          } );
                                                          this.content.push(
                                                            {
                                                              lineHeight:1.6,
                                                                columns: [
                                                                    {
                                                                        width: '30%',
                                                                        text: 'Mother Occupation', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                    },
                                                                    {
                                                                      width: '3%',
                                                                      text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                  },
                                                                    {
                                                                        width: '67%',
                                                                        text: this.editStudentMaster.motherOccupation, fontSize: 12, font: 'TimesNewRoman'
                                                                    }
                                                                ]
                                                            } );
                                                            this.content.push(
                                                              {
                                                                lineHeight:1.6,
                                                                  columns: [
                                                                      {
                                                                          width: '30%',
                                                                          text: 'Mother Income', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                      },
                                                                      {
                                                                        width: '3%',
                                                                        text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                    },
                                                                      {
                                                                          width: '67%',
                                                                          text: this.editStudentMaster.motherIncome, fontSize: 12, font: 'TimesNewRoman'
                                                                      }
                                                                  ]
                                                              } );
                                                              this.content.push(
                                                                {
                                                                  lineHeight:1.6,
                                                                    columns: [
                                                                        {
                                                                            width: '30%',
                                                                            text: 'Qualification of Guardian', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                        },
                                                                        {
                                                                          width: '3%',
                                                                          text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                      },
                                                                        {
                                                                            width: '67%',
                                                                            text: this.editStudentMaster.guardianQualification, fontSize: 12, font: 'TimesNewRoman'
                                                                        }
                                                                    ]
                                                                } );
                                                                this.content.push(
                                                                  {
                                                                    lineHeight:1.6,
                                                                      columns: [
                                                                          {
                                                                              width: '30%',
                                                                              text: 'Guardian Occupation', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                          },
                                                                          {
                                                                            width: '3%',
                                                                            text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                        },
                                                                          {
                                                                              width: '67%',
                                                                              text: this.editStudentMaster.guardianOccupation, fontSize: 12, font: 'TimesNewRoman'
                                                                          }
                                                                      ]
                                                                  } ); this.content.push(
                                                                    {
                                                                      lineHeight:1.6,
                                                                        columns: [
                                                                            {
                                                                                width: '30%',
                                                                                text: 'Guardian Income', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                            },
                                                                            {
                                                                              width: '3%',
                                                                              text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                          },
                                                                            {
                                                                                width: '67%',
                                                                                text: this.editStudentMaster.guardianIncome, fontSize: 12, font: 'TimesNewRoman'
                                                                            }
                                                                        ]
                                                                    } );
                                                                    this.content.push(
                                                                      {
                                                                        lineHeight:1.6,
                                                                          columns: [
                                                                              {
                                                                                  width: '30%',
                                                                                  text: 'Student Height / Weight', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                              },
                                                                              {
                                                                                width: '3%',
                                                                                text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                            },
                                                                              {
                                                                                  width: '67%',
                                                                                  text: this.editStudentMaster.height+' / '+this.editStudentMaster.weight, fontSize: 12, font: 'TimesNewRoman'
                                                                              }
                                                                          ]
                                                                      } );
                                                                      this.content.push(
                                                                        {
                                                                          lineHeight:1.6,
                                                                            columns: [
                                                                                {
                                                                                    width: '30%',
                                                                                    text: 'Identification Mark (1 And 2)', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                },
                                                                                {
                                                                                  width: '3%',
                                                                                  text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                              },
                                                                                {
                                                                                    width: '67%',
                                                                                    text: this.editStudentMaster.identificationMark1+'\n'+this.editStudentMaster.identificationMark2, fontSize: 12, font: 'TimesNewRoman'
                                                                                }
                                                                            ]
                                                                        } ); this.content.push(
                                                                          {
                                                                            lineHeight:1.6,
                                                                              columns: [
                                                                                  {
                                                                                      width: '30%',
                                                                                      text: 'Email ID', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                  },
                                                                                  {
                                                                                    width: '3%',
                                                                                    text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                },
                                                                                  {
                                                                                      width: '67%',
                                                                                      text: this.editStudentMaster.emailId, fontSize: 12, font: 'TimesNewRoman'
                                                                                  }
                                                                              ]
                                                                          } ); this.content.push(
                                                                            {
                                                                              lineHeight:1.6,
                                                                                columns: [
                                                                                    {
                                                                                        width: '30%',
                                                                                        text: 'Medical Allergies', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                    },
                                                                                    {
                                                                                      width: '3%',
                                                                                      text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                  },
                                                                                    {
                                                                                        width: '67%',
                                                                                        text: this.editStudentMaster.allergy, fontSize: 12, font: 'TimesNewRoman'
                                                                                    }
                                                                                ]
                                                                            } );
                                                                            this.content.push(
                                                                              {
                                                                                lineHeight:1.6,
                                                                                  columns: [
                                                                                      {
                                                                                          width: '30%',
                                                                                          text: 'Physically Disabled', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                      },
                                                                                      {
                                                                                        width: '3%',
                                                                                        text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                    },
                                                                                      {
                                                                                          width: '67%',
                                                                                          text: this.editStudentMaster.physicallyDisabled, fontSize: 12, font: 'TimesNewRoman'
                                                                                      }
                                                                                  ]
                                                                              } );
                                                                              this.content.push(
                                                                                {
                                                                                  lineHeight:1.6,
                                                                                    columns: [
                                                                                        {
                                                                                            width: '30%',
                                                                                            text: 'Disabled Reason', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                        },
                                                                                        {
                                                                                          width: '3%',
                                                                                          text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                      },
                                                                                        {
                                                                                            width: '67%',
                                                                                            text: this.editStudentMaster.disabledDesc, fontSize: 12, font: 'TimesNewRoman'
                                                                                        }
                                                                                    ]
                                                                                } ); this.content.push(
                                                                                  {
                                                                                    lineHeight:1.6,
                                                                                      columns: [
                                                                                          {
                                                                                              width: '30%',
                                                                                              text: 'Related Student Number 1', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                          },
                                                                                          {
                                                                                            width: '3%',
                                                                                            text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                        },
                                                                                          {
                                                                                              width: '67%',
                                                                                              text: this.editStudentMaster.relatedStudentNo1, fontSize: 12, font: 'TimesNewRoman'
                                                                                          }
                                                                                      ]
                                                                                  } );
                                                                                  this.content.push(
                                                                                    {
                                                                                      lineHeight:1.6,
                                                                                        columns: [
                                                                                            {
                                                                                                width: '30%',
                                                                                                text: 'Related Student Number 2', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                            },
                                                                                            {
                                                                                              width: '3%',
                                                                                              text: ':', fontSize: 12, font: 'TimesNewRoman', bold: 'true'
                                                                                          },
                                                                                            {
                                                                                                width: '67%',
                                                                                                text: this.editStudentMaster.relatedStudentNo2, fontSize: 12, font: 'TimesNewRoman'
                                                                                            }
                                                                                        ]
                                                                                    } );
 if(this.editStudentMaster.notes.length != null && this.editStudentMaster.notes.length != undefined && this.editStudentMaster.notes.length != 0){
       this.body.push( [
           { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
           { text: 'Notes Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
           { text: 'Notes Description', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
           ] );
 
       for ( let i = 0; i < this.editStudentMaster.notes.length; i++ ) {
         
           this.body.push( [
               { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
               { text: this.editStudentMaster.notes[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
               { text: this.editStudentMaster.notes[i].description, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                ] );
       }
 
       this.content.push( {
           'table': {
               headerRows: 1,
               'body': this.body,
               widths: ['5%', '25%', '70%']
           }
       } );
 }
 
       const dd = {
           'content': this.content,
           pageOrientation: 'portrait',
           pageSize: 'A4',
           styles: {
            e: {
                font: 'TimesNewRoman',
                 bold: true, 
                 fontSize: 18,
                 alignment: 'center',
                 
            },
            e2: {
              font: 'TimesNewRoman',
               bold: true, 
               fontSize: 16,
               alignment: 'center',
               
          },
          },
           footer: ( currentPage, pageCount ) => {
               return {
                   margin: 10,
                   columns: [
                       {
                           fontSize: 9,
                           text: [
                               {
                                   text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                   '\n',
                                   margin: [0, 20]
                               },
                               {
                                   text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                               }
                           ],
                           alignment: 'center'
                       }
                   ]
               };
           }
       };
 
       pdfMake.createPdf( dd ).open();
       this.body = [];
       this.content = [];
 
   }
   
}
