import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { LedgerStatementPojo } from 'src/app/pojo/ledger-statement.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { StaticVariable } from 'src/app/globle.class';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { IncomeExpenditureReportPojo, ConsolidationReportPojo } from 'src/app/pojo/income-expenditure-report.pojo';
import { LedgerStatementService } from '../../Reports/ledger-statement/ledger-statement.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';

@Component({
  selector: 'app-income-expenditure-report',
  templateUrl: './income-expenditure-report.component.html',
  styleUrls: ['./income-expenditure-report.component.scss']
})
export class IncomeExpenditureReportComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  st = new IncomeExpenditureReportPojo();
  //isLoading: boolean;
  public filterQuery = '';
  public rowsOnPage = 10;
  public sortBy = 'i';
  public sortOrder = 'asc';
  isLoading: Boolean = false;
  body = [];
  content = [];
  documentTime: string;

  loggedUser = new MUserMasterPojo();
  ledgers: LedgerPojo[] = [];
  statement: IncomeExpenditureReportPojo[] = [];
  consolidatePojo = new ConsolidationReportPojo();
  ledger = new LedgerPojo();
  itemledgers: LedgerPojo[] = [];

  documentDate: string;
  startDate;
  endDate;
  resultFromDate;
  resultToDate;
  resultLedger;
  openingBal: number = 0;
  closingBal: number = 0;
  accountMasterArr:AccountMasterPojo[]=[];
  constructor(private accountMasterService:AccountMasterService,private ledgerstatementService: LedgerStatementService, private ledgercreationService: LedgercreationService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem("schoolManagementUserDetail"));
    this.documentDate = moment().format('DD-MM-YYYY');
    $('.daterangeAdd').daterangepicker({ opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf('day'), endDate: moment().endOf('day') },
      (start, end, label) => {
        this.startDate = moment(start).format('YYYY-MM-DD');
        this.endDate = moment(end).format('YYYY-MM-DD');
      });
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
      this.isLoading = true;
      this.ledgerstatementService.getConsolidatedReportByDate(this.startDate, this.endDate, this.loggedUser.accId).subscribe(login1 => {
        this.isLoading = false;
        this.consolidatePojo = login1;
        this.statement=this.consolidatePojo.ielist;
        this.resultFromDate = this.startDate;
        this.resultToDate = this.endDate;
      }, error => {
        bootbox.alert('Error');
        this.isLoading = false;
      });
    this.getAllLedger();
    this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }

  getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
      this.ledgers = rs;
       let d = new LedgerPojo();
      for (let i = 0; i < this.ledgers.length; i++) {
        this.itemledgers.push(this.ledgers[i]);
      }
    }, error => {
      bootbox.alert('Error' + error);
    });
  }

  getLedgerById(id) {
    if (this.ledgers.find(r => r.ledgerId == id)) {
      return this.ledgers.find(r => r.ledgerId == id).ledgerName;
    }
    else {
      return id;
    }
  }

  fetchstatement() {
    this.isLoading = true;
    this.consolidatePojo = new ConsolidationReportPojo();
    this.statement = [];
    if (this.startDate == undefined && this.endDate == undefined) {
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');

    }
    this.ledgerstatementService.getConsolidatedReportByDate(this.startDate, this.endDate, this.loggedUser.accId).subscribe(login1 => {
      this.isLoading = false;
      this.consolidatePojo = login1;
      this.statement=this.consolidatePojo.ielist;
      this.resultFromDate = this.startDate;
      this.resultToDate = this.endDate;
    }, error => {
      bootbox.alert('Error');
      this.isLoading = false;
    });
    this.documentDate = moment().format('DD-MM-YYYY');
  }

  printledgerstatement() {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push( {
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
     } );
    this.content.push({
      text: 'INCOME EXPENDITURE REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
    });

    this.content.push({
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
    });
    this.content.push({
      text: 'REPORT FROM : ' + moment(this.resultFromDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' TO ' + moment(this.resultToDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\n', fontSize: 8, alignment: 'center'
    });

    this.body.push([
      { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'PARTICULARS', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'INCOME', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: ' EXPENSE', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
    ]);

    for (let i = 0; i < this.statement.length; i++) {
      this.body.push([
        { text: this.statement[i].ledgerGroupName, fontSize: 12, font: 'TimesNewRoman', bold: true, alignment: 'center', colSpan: 4 }, {}, {}, {}
      ]);
      for (let j = 0; j < this.statement[i].det.length; j++) {
        this.body.push([
          { text: (j + 1), fontSize: 11, font: 'TimesNewRoman', alignment: 'center' },
          { text: this.statement[i].det[j].narration, fontSize: 11, font: 'TimesNewRoman', alignment: 'left' },
          { text: this.statement[i].det[j].income.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
          { text: this.statement[i].det[j].expenditure.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
        ]);
      }
      this.body.push([
        { text: 'TOTAL AMOUNT', fontSize: 10, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
        { text: this.statement[i].incomeTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].expenditureTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
      ]);

    }
    this.body.push([
      { text: 'CONSOLIDATION REPORT', fontSize: 12, font: 'TimesNewRoman', bold: true, alignment: 'center', colSpan: 4 },{},{}, {},
       ]);
    for (let i = 0; i < this.statement.length; i++) {
      this.body.push([
        { text: (i + 1), fontSize: 11, font: 'TimesNewRoman', alignment: 'center' },
        { text: this.statement[i].ledgerGroupName, fontSize: 12, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].incomeTotal.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
        { text: this.statement[i].expenditureTotal.toFixed(2), fontSize: 11, font: 'TimesNewRoman', alignment: 'right' },
      ]);
    }
    this.body.push([
      { text: 'OPENING BALANCE', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: this.consolidatePojo.openingBal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: '', fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
    this.body.push([
      { text: 'CLOSING BALANCE', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: '', fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: this.consolidatePojo.closingBal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
    this.body.push([
      { text: 'TOTAL AMOUNT', fontSize: 11, font: 'TimesNewRoman', bold: true, alignment: 'right', colSpan: 2 }, {},
      { text: this.consolidatePojo.creditTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' },
      { text: this.consolidatePojo.debitTotal.toFixed(2), fontSize: 11, bold: true, font: 'TimesNewRoman', alignment: 'right' }
    ]);
   
    this.content.push({
      'table': {
        headerRows: 1,
        'body': this.body,
        widths: ['5%', '75%', '10%', '10%',]
      }
    });


    const dd = {
      'content': this.content,
      pageOrientation: 'landscape',
      pageSize: 'A4',
      footer: (currentPage, pageCount) => {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 20]
                },
                {
                  text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      }
    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];

  }
} 
