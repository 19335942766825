import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";

import { Http } from '@angular/http';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class ReceiptHeadService {

    constructor( private httpclient: HttpClient,private http: Http ) { }

    getReceiptHeadAll(cid): Observable<ReceiptHeadPojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptHeadAll/'+cid;
        return this.httpclient.get<ReceiptHeadPojo[]>( url );
    }
    
    addReceiptHead( arg: ReceiptHeadPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddReceiptHead';
        return this.httpclient.post( url, arg );
    }
    
    updateReceiptHead( arg: ReceiptHeadPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateReceiptHead';
        return this.httpclient.put( url, arg );
    }
    
    deleteReceiptHead( arg: number, uid:number  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteReceiptHead/' + arg +'/' + uid;
        return this.httpclient.delete( url );
    }
    getReceiptHeadById(cid): Observable<ReceiptHeadPojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptHeadById/'+cid;
        return this.httpclient.get<ReceiptHeadPojo[]>( url );
    }
    getReceiptHeadByLedgerId(ledgerId,accid): Observable<ReceiptHeadPojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptHeadByLedgerId/'+ledgerId+'/'+accid;
        return this.httpclient.get<ReceiptHeadPojo[]>( url );
    }
    getReceiptHeadForReceipt(accid): Observable<ReceiptHeadPojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptHeadForReceipt/'+accid;
        return this.httpclient.get<ReceiptHeadPojo[]>( url );
    }
    
}