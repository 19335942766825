import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { StudentQuickAdmissionPojo } from 'src/app/pojo/quick-admission.pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class  StudentMasterService{

    constructor( private httpclient: HttpClient ) { }

    getStudentAll(accid:number): Observable<StudentMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetStudentAll/'+accid;
        return this.httpclient.get<StudentMasterPojo[]>( url );
    }

    getStudentMasterByDateAndDidCidAndAid( from,to,did, cid, sid,accid ): Observable<StudentMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetStudentByDeptIdClassIdAndSectionId/' +from+'/'+to+'/'+ did + '/' + cid + '/' + sid+'/'+accid;
        return this.httpclient.get<StudentMasterPojo[]>( url );
    }
    getStudentMasterByAdmissionNo( admissionNo,accid ): Observable<StudentMasterPojo> {
        const url = StaticVariable.porturl + 'GetStudentByAdmissionNumber/'+admissionNo+'/'+accid;
        return this.httpclient.get<StudentMasterPojo>( url );
    }

    addStudent( arg: StudentMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddStudent';
        return this.httpclient.post( url, arg );
    }

    updateStudent( arg: StudentMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateStudent';
        return this.httpclient.put( url, arg );
    }

    deleteStudent( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteStudent/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }

    getStudentById( studentId: number,accid ): Observable<StudentMasterPojo> {
        const url = StaticVariable.porturl + 'GetStudentById/' + studentId+'/'+accid;
        return this.httpclient.get<StudentMasterPojo>( url );
    }
    
    uploadProfileImage( file: File ): Observable<FtpImagePojo> {
        let formData: any = new FormData();
        formData.append( 'file', file, file.name );
        const url = StaticVariable.porturl + 'AddFtpImage';
        return this.httpclient.post<FtpImagePojo>( url, formData );
    }
    uploadImage(file: File,id:number,uniqueId): Observable<FtpImagePojo> {
        // tslint:disable-next-line:prefer-const
               let formData: any = new FormData();
               // tslint:disable-next-line:prefer-const
                    formData.append('file', file, file.name);
               const url = StaticVariable.porturl + 'AddFtpImage/'+id+'/'+uniqueId;
               return this.httpclient.post<FtpImagePojo>(url , formData);
    }  
    deleteFtpImage( id: number,typeId): Observable<any> {
        const url = StaticVariable.porturl + 'FtpImageDelete/' + id+'/'+typeId;
        return this.httpclient.delete<any>( url );
    }
    addQuickAdmission( arg: StudentQuickAdmissionPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddQuickAdmission';
        return this.httpclient.post( url, arg );
    }
    getAutoGenerateAdmissionNo(accid):  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateAdmissionCode/'+accid;
        return this.httpclient.get<any>(url);
    }
    getClassMasterBydeptId( deptId: number,accid ): Observable<ClassMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetClassByDeptId/' + deptId+'/'+accid;
        return this.httpclient.get<ClassMasterPojo[]>( url );
    }
    getSectionMasterByClassId( classId: number,accid ): Observable<SectionMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetSectionByClassId/' + classId+'/'+accid;
        return this.httpclient.get<SectionMasterPojo[]>( url );
    }
    getAcademicStuDetailsById(deptId,classId,sectionId,aid,accid): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetClassWiseReport/'+deptId+'/'+classId+'/'+sectionId+'/'+aid+'/'+accid;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getDiscontinueStudentsById(deptId,classId,sectionId,aid,accid): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetDiscontinueStudentReport/'+deptId+'/'+classId+'/'+sectionId+'/'+aid+'/'+accid;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    
    getCurrenntDate(): Observable<any> {
        const url = StaticVariable.porturl + 'GetCurrentDate';
        return this.httpclient.get<any>( url );
    }
    
}
