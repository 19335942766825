export class FeeCollectionReportDetailsPojo {

	 collectionId:number;
	 collectionDate:Date;
	 collectionTime:string;
	 classId:number;
	 sectionId:number;
	 studentId:number;
	 name:string;
	 receiptId:number;
	 receiptNo:string;  
	 overAllPaidTotal:number;
	 academicYearId:number;
	 admissionNo:string;
	 paidLedgerId:number;
	 userId:number;
	 accId:number;
	 termId:number;
	 standardTermId:number;
     feeAllocationId:number;
}