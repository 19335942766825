import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { ReportService } from '../../Reports/report-service';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-student-det-child',
  templateUrl: './student-det-child.component.html',
  styleUrls: ['./student-det-child.component.scss']
})
export class StudentDetChildComponent implements OnInit {
  @Input() PData: number;
  @Output() childEvent = new EventEmitter<string>();
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  studentMasterArr: StudentMasterPojo[] = [];
  sectionArr: SectionMasterPojo[] = [];
  academic:AcademicYearMasterPojo[] =[];
  standardArr: AcademicStandardDetailsPojo[] = [];
  academicYr;
  departmentId;
  classid;
  sectionid;
  studentid;
  feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
  loggedUser = new MUserMasterPojo();
  isLoading:boolean = false;
  public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    searchOption = 'ALL';
  constructor( private feeStructuremasterserivce: StandardStructureMasterService,private ledgercreationService: LedgercreationService,private feeAloocationService:FeeAllocationMasterService,private reportService:ReportService ,private studentMasterService: StudentMasterService,private settingService:AcademicSettingService, private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService ) { }
  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
     this.getSectionMasterAll();
    this.getClassMasterAll();
    this.getDepartmentAll();
     this.academicSettingAll();
     //this.getLedgerByAccountGroup( this.acc);
     //this.getStandrdTermAll();
  }
  onChange(value) {
    this.childEvent.emit(value);
  }
  clickEdit(id){
    this.childEvent.emit(id);
//this.PData = this.getAdmissionNoByStudId(id);
  }
  getFeeAllocationById(){
    this.feeCollectionArr =[];
    this.isLoading = true;
    this.feeAloocationService.getFeeAllocationByTermWise(this.academicYr,this.studentid,this.loggedUser.accId).subscribe( response => {
      this.feeCollectionArr= response;
       this.isLoading = false;
       }, error => {
      bootbox.alert( { message: 'Get Fee Collection Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;   
    } );
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
getClassMasterAll() {
  this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
      this.classArr = response;
       }, error => {
      bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
getDepartmentAll() {
  this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
      this.departmentArr = run;
  }, error => {
      bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
academicSettingAll(){
  this.academic = [];
  this.settingService.getAcademicYrAll().subscribe( response => {
      this.academic = response;
      for(let i=0;i<this.academic.length;i++){
          if(this.academic[i].academicFlag == 1){
                this.academicYr = this.academic[i].academicYearId;
                }
      }
      
        }, error => {
      bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
       } );
 }
getStudentById() {
  this.studentMasterArr =[];
  this.reportService.getClassWiseReportById( this.departmentId, this.classid, this.sectionid,this.academicYr,this.loggedUser.accId ).subscribe( response => {
    this.standardArr = response;
    for(let i=0;i<this.standardArr.length;i++){
        this.studentMasterArr.push(this.standardArr[i].student);
    }
    }, error => {
    bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
   } );
}
getSectionNameBySectionId( id ) {
  if ( this.sectionArr.find( response => response.sectionId == id ) ) {
      return this.sectionArr.find( response => response.sectionId == id ).sectionName;
  } else {
      return id;
  }
}
getAdmissionNoByStudId( id ) {
  if ( this.studentMasterArr.find( response => response.studentId == id ) ) {
      return this.studentMasterArr.find( response => response.studentId == id ).admissionNo;
  } else {
      return id;
  }
}
getClassNameByClassId( id ) {
  if ( this.classArr.find( response => response.classId == id ) ) {
      return this.classArr.find( response => response.classId == id ).className;
  } else {
      return id;
  }
}
getReportById() {
  this.standardArr = [];
  this.studentMasterArr= [];
  this.searchOption = 'ALL';
  this.filterQuery = '';
    this.isLoading = true;
    this.reportService.getClassWiseReportById( this.departmentId, this.classid, this.sectionid,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
        this.standardArr = response;
        for(let i=0;i<this.standardArr.length;i++){
            this.studentMasterArr.push(this.standardArr[i].student);
        }
            this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
        this.isLoading = false;
    } );
}
}
