import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AttendancePojo, AttendanceResponsePojo } from 'src/app/pojo/attendance.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class AttendanceEntryService {

    constructor( private httpclient: HttpClient ) { }

    getAttendaceEntryList(academicYrId,classId,sectionId,date,hours,accid): Observable<AttendanceResponsePojo> {
        const url = StaticVariable.porturl + 'GetTempAttendanceListByYearIdAndClassIdAndSectionId/'+academicYrId+'/'+classId+'/'+sectionId+'/'+date+'/'+hours+'/'+accid;
        return this.httpclient.get<AttendanceResponsePojo>( url );
    }
    
    addAttendanceLis( arg: AttendanceResponsePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAttendance';
        return this.httpclient.post( url, arg );
    }
    
    updateAttendanceList( arg: AttendancePojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateAttendance';
        return this.httpclient.put( url, arg );
    }
    
   
}