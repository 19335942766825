export class AttendanceAbsentDetailsPojo {

	 academicYearId:number;
	 classId:number;
	 sectionId:number;
	 accId:number;
	 name:string;
	 mobileNo:string;
	 studentId:number;
	 admissionNo:string;
	 attendanceDate:Date;
	 totalHoursCount:number;
	 absentHoursCount:number;
     reason:string;
}
export class AttendanceAbsentSummaryPojo {

	 academicYearId:number;
	 classId:number;
	 accId:number;
	 attendanceDate:Date;
	 code:number;
	 response:string;
    list:AttendanceAbsentDetailsPojo[]=[];
}