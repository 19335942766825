import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'SelectedUserPipe'
} )
export class SelectedUserPipe implements PipeTransform {

    transform( array: any[], query: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.userName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.userName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            }else
                return [];
        }
       return array;
    }

}
