export class TrailBalanceConsolidationReportPojo {

	report:TrailBalanceReportPojo[]=[];
	 openingBal:number;
	 closingBal:number;
	 creditTotal:number;
     debitTotal:number;
}
export class TrailBalanceDetailsReportPojo {

	narration:string;
	 income:number;
	 expenditure:number;
	 receiptHeadId:number;
     ledgerGroupId:number;
}
export class TrailBalanceReportPojo {

	 expenditureTotal:number;
     incomeTotal:number;
     ledgerGroupName:string;
    detlist:TrailBalanceDetailsReportPojo[]=[];
}