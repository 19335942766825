import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class SubjectMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllSubject(cid): Observable<any> {
        const url = StaticVariable.porturl + 'GetSubjectAll/'+cid;
        return this.httpclient.get<SubjectMasterPojo[]>( url );
    }
    
    addSubject( arg: SubjectMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSubject';
        return this.httpclient.post( url, arg );
    }
    
    updateSubject( arg: SubjectMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSubject';
        return this.httpclient.put( url, arg );
    }
    
    deleteSubject( id: number, loggedId: number,accid ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSubject/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
    getSubjectByCurriculumId(cid,accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetSubjectByCurriculumId/'+cid+'/'+accid;
        return this.httpclient.get<SubjectMasterPojo[]>( url );
    }
    getSubjectByMediumId(mid,accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetSubjectByMediumId/'+mid+'/'+accid;
        return this.httpclient.get<SubjectMasterPojo[]>( url );
    }
    getSubjectByClassId(cid,accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetSubjectByClassId/'+cid+'/'+accid;
        return this.httpclient.get<SubjectMasterPojo[]>( url );
    }
    getSubjectById(id,accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetSubjectById/'+id+'/'+accid;
        return this.httpclient.get<SubjectMasterPojo[]>( url );
    }
}