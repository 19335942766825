import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';



@Injectable( {
    providedIn: 'root'
} )

export class StandardStructureMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllStandardTerm(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetStandardTermAll/'+accid;
        return this.httpclient.get<StandardTermPojo[]>( url );
    }
    
    addStandardTerm( arg: StandardTermPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddStandardTerm';
        return this.httpclient.post( url, arg );
    }
    
    updateStandardTerm( arg: StandardTermPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateStandardTerm';
        return this.httpclient.put( url, arg );
    }
    
    deleteStandardTerm( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteStandardTerm/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}