import { SmsChildPojo } from "src/app/pojo/sms-child.pojo";

export class SmsMasterPojo {
    smsId: number;
    smsName: string;
    createdDate: Date;
    smsContent: string = "";
    repeateMode: string = "0";
    repeateCategory: string = "0";
    sendDate: Date;
    sendTime: string;
    studentAllSelection: number = 0;
    staffAllSelection: number = 0;
    manualSelection: number = 0;
    status: string = "";
    accId:number;
    userId:number;

    smschildpojo: SmsChildPojo[] = [];
}
export class SmsTypeMasterPojo{
     smsTypeId:number;
	 smsType:string;
	 accId:number;
     userId:number;
     smsTypeNo:number;
     status:string;
     content:string;
}