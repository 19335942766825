
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ArrearReportPojo, ArrearReportStudentDetailsPojo } from 'src/app/pojo/arrears-report.pojo';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceResponsePojo, AttendancePojo } from 'src/app/pojo/attendance.pojo';
import { AttendanceEntryService } from '../../Attendance/attendance-entry/attendance-entry.service';
import { ExamMasterService } from '../../Masters/exam-master/exam-master.service';
import { ExamMasterPojo } from 'src/app/pojo/exam-master.pojo';
import { ExamScheduleMasterService } from '../../Masters/exam-schedule/exam-schedule.service';
import { ExamSchedulePojo } from 'src/app/pojo/exam-schedule.pojo';
import { SubjectMasterService } from '../../Masters/subject-master/subject-master.service';
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { ExamEntryResponsePojo, ExamEntryPojo } from 'src/app/pojo/Exam-Entry.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-student-mark-list',
  templateUrl: './student-mark-list.component.html',
  styleUrls: ['./student-mark-list.component.scss']
})
export class StudentMarkListComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
  
    fromDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

      sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltDate:number;
    searchOption = 'ALL';
    typeId;
    academic:AcademicYearMasterPojo[] =[];
    academicYear;
     feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
    standTermArr: StandardTermPojo[] = [];
    classPojo = new ClassMasterPojo();
    resultSubjectId:number;
    examRes = new ExamEntryResponsePojo();
    examEntryArr:ExamEntryPojo[]=[];
    subjectId:number;
    updateArr:ExamEntryPojo[]=[];
    finalEntryResArr:ExamEntryPojo[] =[];
    examArr: ExamMasterPojo[] = [];
    examScheduleArr:ExamSchedulePojo[]=[];
    subjectMasterArr: SubjectMasterPojo[] = [];
    resultExam:number;
    examId:number;
    scheduleArr:ExamSchedulePojo[]=[];
    accountMasterArr:AccountMasterPojo[]=[];
     constructor( private standardTermService:StandardStructureMasterService,
        private feeAllocationService:FeeAllocationMasterService,private settingService:AcademicSettingService,
        private reportService:ReportService,private studentMasterService: StudentMasterService, 
        private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, 
        private classMasterService: ClassMasterService, private router: Router,
        private attendanceService:AttendanceEntryService,private exammasterserivce: ExamMasterService,
        private examScheduleService:ExamScheduleMasterService,private subjectmasterserivce: SubjectMasterService,
        private accountMasterService:AccountMasterService ) { }

    ngOnInit() {
        $( document ).ready( function() {
            $( 'input' ).keyup( function( e ) {
                if ( e.which == 39 )
                    $( this ).closest( 'td' ).next().find( 'input' ).focus();
                else if ( e.which == 37 )
                    $( this ).closest( 'td' ).prev().find( 'input' ).focus();
                else if ( e.which == 40 )
                    $( this ).closest( 'tr' ).next().find( 'td:eq(' + $( this ).closest( 'td' ).index() + ')' ).find( 'input' ).focus();
                else if ( e.which == 38 )
                    $( this ).closest( 'tr' ).prev().find( 'td:eq(' + $( this ).closest( 'td' ).index() + ')' ).find( 'input' ).focus();
            } );
        } );

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.academicSettingAll();
        this.getStandrdTermAll();   
        this.getExamMasterAll();
        this.getExamScheduleAll(); 
        this.getSubjectAll();
        this.accountMasterAll();
    }
    onselect( e ) {
        $( document ).ready( function() {
            $( 'input' ).keyup( function( e ) {
                if ( e.which == 39 )
                    $( this ).closest( 'td' ).next().find( 'input' ).focus();
                else if ( e.which == 37 )
                    $( this ).closest( 'td' ).prev().find( 'input' ).focus();
                else if ( e.which == 40 )
                    $( this ).closest( 'tr' ).next().find( 'td:eq(' + $( this ).closest( 'td' ).index() + ')' ).find( 'input' ).focus();
                else if ( e.which == 38 )
                alert();
                    $( this ).closest( 'tr' ).prev().find( 'td:eq(' + $( this ).closest( 'td' ).index() + ')' ).find( 'input' ).focus();
            } );
        } );
    }
    academicSettingAll(){
      this.academic = [];
      this.settingService.getAcademicYrAll().subscribe( response => {
          this.academic = response;
          for(let i=0;i<this.academic.length;i++){
              if(this.academic[i].academicFlag == 1){
                  this.academicYear = this.academic[i].academicYearId;
              }
          }
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
     }
     accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      } getAccountDistrictById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).district;
        } else {
          return id;
        }
      }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getExamMasterAll() {
        this.isLoading = true;
        this.exammasterserivce.getAllExamMaster(this.loggedUser.accId).subscribe( response => {
            this.examArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getExamScheduleAll() {
        this.isLoading = true;
        this.examScheduleService.getAllExamScheduleMaster(this.loggedUser.accId).subscribe( response => {
            this.examScheduleArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getSubjectAll() {
        this.isLoading = true;
        this.subjectmasterserivce.getAllSubject(this.loggedUser.accId).subscribe( response => {
            this.subjectMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Subject All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getClassMasterHoursListById() {
        this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe( response => {
            this.classPojo = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getStandrdTermAll() {
         this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
            this.standTermArr = response;
               }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
      }
    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getSectionNameById( id ) {
        if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
            return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
      }
      getStandardTermById( id ) {
        if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
            return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
        } else {
            return id;
        }
      }
      getExamById( id ) {
        if ( this.examArr.find( r => r.examMasterId == id ) ) {
            return this.examArr.find( r => r.examMasterId == id ).examName;
        } else {
            return id;
        }
      }
      getSubById( id ) {
        if ( this.subjectMasterArr.find( r => r.subjectId == id ) ) {
            return this.subjectMasterArr.find( r => r.subjectId == id ).subjectName;
        } else {
            return id;
        }
      }
      getExamScheduleById() {
        this.scheduleArr=[];
          if(this.classid != null && this.examId != null){
         this.reportService.getExamScheduleById(this.classid,this.examId,this.loggedUser.academicYearId,this.loggedUser.accId).subscribe( response => {
            this.scheduleArr = response;
              }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
        }
    } 
     getReportById() {
        if(this.subjectId !=null &&this.classid !=null&&this.sectionid != null && this.examId != null){
       this.examEntryArr=[];
        this.isLoading = true;
        this.examRes = new ExamEntryResponsePojo();
        this.reportService.getStudentExamEntryById( this.loggedUser.academicYearId, this.classid, this.sectionid,this.examId,this.subjectId,this.loggedUser.accId ).subscribe( response => {
         if(response.code==101){

            bootbox.confirm('Already Completed !\n Do you want to Edit ?', ( result ) => {
                    if(result){
                        this.examRes = response;
                       this.examEntryArr = this.examRes.list;
                        this.resultDept= this.departmentId;
                        this.resultClass= this.classid;
                        this.resultSection=this.sectionid;
                        this.resultSubjectId = this.subjectId;
                        this.resultExam=this.examId;
                         this.isLoading = false;
                    }else{
                        this.isLoading = false;
                    }
                });
            }else if(response.code==104){
                bootbox.alert('Exam Date not scheduled for this subject');
            }else{
                this.examRes = response;
                this.examEntryArr = this.examRes.list;
                 this.resultDept= this.departmentId;
                 this.resultClass= this.classid;
                 this.resultSection=this.sectionid;
                 this.resultSubjectId = this.subjectId;
                 this.resultExam=this.examId;
                  this.isLoading = false;
             
            }
                this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }else{
        bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );
   
    }
    }
    submitAttendance(){
        this.isLoading = true;
        this.finalEntryResArr=[];
        this.examRes.classId=this.resultClass;
        this.examRes.sectionId=this.resultSection;
        this.examRes.subjectId=this.resultSubjectId;
        this.examRes.examId=this.resultExam;
        this.examRes.academicYearId=this.loggedUser.academicYearId;
         this.examRes.list =[];
        this.examRes.list = this.examEntryArr;
       this.reportService.addStudentMarkList( this.examRes ).subscribe( response => {
            this.examRes = new ExamEntryResponsePojo();
                this.examRes = response;
                this.finalEntryResArr = this.examRes.list;
                 this.examEntryArr=[];
            this.Reset();
            bootbox.confirm('Submitted Successfulyy !\n Do you want to Download PDF ?', ( result ) => {
                if(result){
                    this.downloadPDF();
                }
            });this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Submit Student Mark List ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    onChangeAttendanceStatus(item){
        let e = new ExamEntryPojo();
       e = item;
       if(e.examStatus == 'PASS'){
        e.reason ="";
    }
          if(this.updateArr.length > 0){
             for ( let i = 0; i < this.updateArr.length; i++ ) {
               if(this.updateArr[i].studentId == e.studentId){
                       this.updateArr.splice( i );
                       i++;
                    this.updateArr.push(e);
                    }else{
                   this.updateArr.push(e); 
               }
           } 
       }else{
              this.updateArr.push(e);  
       }
         }
      updateAttendance(){
             if(this.updateArr.length>0){
                this.isLoading = true;
                this.finalEntryResArr =[];
                  this.reportService.updateSTudentMarkEntryList(  this.updateArr).subscribe( response => {
                this.examRes = new ExamEntryResponsePojo();
                this.finalEntryResArr = response;
                  this.updateArr =[];
               this.examEntryArr =[];
               this.Reset();
                this.examRes = response;
                bootbox.confirm('Submitted Successfulyy !\n Do you want to Download PDF ?', ( result ) => {
                    if(result){
                        this.downloadPDF();
                    }
                  });  this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Submit STudent Mark List ' + error.status, className: "text-danger h5", closeButton: false } );
                this.isLoading = false;
            } );  
        }else{
            bootbox.alert('No Records to be Editted');
        }
        }
        Reset(){
            this.classid=0;
            this.departmentId=0;
            this.sectionid=0;
            this.examId=0;
            this.subjectId=0;
            this.fromDate=null;
        }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
                this.content.push( {
                    text: 'STUDENT EXAM MARK ENTRY REPORT'+'[ '+this.getAcademicYrById(this.loggedUser.academicYearId)+' ]', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
                } );
           
           this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
       this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+ this.getSectionNameById(this.resultSection), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );
       this.content.push( {text: '\nSELECTED EXAM:'+this.getExamById(this.resultExam)+'-'+this.getSubById(this.resultSubjectId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
  
         this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Roll Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Mark', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Status', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Reason', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                 ] );
   
         for ( let i = 0; i < this.finalEntryResArr.length; i++ ) {
            
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].rollNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left', },
                 { text: this.finalEntryResArr[i].securedMark, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                 { text: this.finalEntryResArr[i].examStatus, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    { text: this.finalEntryResArr[i].reason, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                     ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '10%','15%', '33%','5%', '7%', '20%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
     
}
