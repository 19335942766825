import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-staff-type-master',
  templateUrl: './staff-type-master.component.html',
  styleUrls: ['./staff-type-master.component.scss']
})
export class StaffTypeMasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
