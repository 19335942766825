import { Component } from '@angular/core';
import * as moment from 'moment';
declare var $: any;
declare var bootbox: any;
import * as pdfMake from 'node_modules/pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { LedgerStatementPojo } from 'src/app/pojo/ledger-statement.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgerStatementService } from './ledger-statement.service';
import { StaticVariable } from 'src/app/globle.class';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
import { LedgerWiseReportPojo } from 'src/app/pojo/ledger-wise-report.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

@Component({
    selector: 'app-ledger-statement',
    templateUrl: './ledger-statement.component.html',
    styleUrls: ['./ledger-statement.component.scss']
})
export class LedgerStatementComponent {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    st = new LedgerStatementPojo();
    //isLoading: boolean;
    public filterQuery = '';
    public rowsOnPage = 10;
    public sortBy = 'i';
    public sortOrder = 'asc';
    isLoading: Boolean = false;
    body = [];
    content = [];
    documentTime: string;

    loggedUser = new MUserMasterPojo();
    ledgers: LedgerPojo[] = [];
    statement: LedgerWiseReportPojo[] = [];
    ledger = new LedgerPojo();
    itemledgers: LedgerPojo[] = [];

    documentDate: string;
    startDate;
    endDate;
    resultFromDate;
    resultToDate;
    resultLedger;
    openingBal: number = 0;
    closingBal: number = 0;
    ledgerGrpArr:LedgerGroupPojo[]=[];
    specLedgers:LedgerPojo[]=[];
    ledgerGroupId:number;
    resultLedgerGroupId:number;
    accountMasterArr:AccountMasterPojo[]=[];
    constructor(private accountMasterService:AccountMasterService,private ledgerstatementService: LedgerStatementService, private ledgercreationService: LedgercreationService) { }

    ngOnInit() {
        this.loggedUser = JSON.parse(sessionStorage.getItem("schoolManagementUserDetail"));
        this.documentDate = moment().format('DD-MM-YYYY');
        this.getAllLedger();
        $('.daterangeAdd').daterangepicker({ opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf('day'), endDate: moment().endOf('day') },
            (start, end, label) => {
                this.startDate = moment(start).format('YYYY-MM-DD');
                this.endDate = moment(end).format('YYYY-MM-DD');
            });
            this.getLedgerGrpAll();
            this.accountMasterAll();
    }

    getAllLedger() {
        this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
            this.ledgers = rs;
            this.isLoading = false;
            this.ledgers = rs;
            let d = new LedgerPojo();
            for (let i = 0; i < this.ledgers.length; i++) {
                this.itemledgers.push(this.ledgers[i]);
            }
        }, error => {
            bootbox.alert('Error' + error);
            this.isLoading = false;
        });
    }
    accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      } 
    getLedgerGrpAll() {
        this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
            this.ledgerGrpArr = response;
          }, error => {
            bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
      }
      getLedgerByLedgerGroupId(event){
        this.specLedgers=[];
        if(this.ledgerGroupId>0){
        this.ledgercreationService.getLedgerByLedgerGroupId(this.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
          this.specLedgers = response;
        }, error => {
          bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
      }
      }
    getLedgerById(id) {
        if (this.ledgers.find(r => r.ledgerId == id)) {
            return this.ledgers.find(r => r.ledgerId == id).ledgerName;

        }
        else {
            return id;
        }
    }getLedgerGrpById(id) {
        if (this.ledgerGrpArr.find(r => r.ledgerGroupId == id)) {
            return this.ledgerGrpArr.find(r => r.ledgerGroupId == id).ledgerGroupName;

        }
        else {
            return id;
        }
    }

    fetchstatement() {
        this.isLoading = true;
        this.statement = [];
        this.openingBal = 0;
        this.closingBal = 0;
        if (this.startDate == undefined && this.endDate == undefined) {
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');

        }
            this.st.companyId = this.loggedUser.accId;
            this.ledgerstatementService.getLedgerwiseReport(this.startDate, this.endDate, this.st.LEDGERID, this.loggedUser.accId).subscribe(login1 => {
                this.isLoading = false;
                this.statement = login1;
                this.resultLedgerGroupId = this.ledgerGroupId;
                // for (let i = 0; i < this.statement.length; i++) {
                //     if (i == 0) {
                //         this.openingBal = this.statement[i].BALANCE;
                //         this.statement.splice(i, 1);
                //     }
                //     if (i == this.statement.length - 1) {
                //         this.closingBal = this.statement[i].BALANCE;
                //         this.statement.splice(i, 1);
                //     }

                // }
                this.resultFromDate = this.startDate;
                this.resultToDate = this.endDate;
                this.resultLedger = this.st.LEDGERID;
                this.isLoading = false;
            }, error => {
                bootbox.alert('Error');
                this.isLoading = false;
            });
            this.documentDate = moment().format('DD-MM-YYYY');
      
        
    }

    printledgerstatement() {
        this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
        this.content.push({
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
        });
        this.content.push({
            text: 'LEDGER STATEMENT REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
        });

        this.content.push({
            text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
            fontSize: 10, bold: true, alignment: 'center'
        });
        this.content.push({
            text: 'REPORT FROM : ' + moment(this.resultFromDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' TO ' + moment(this.resultToDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
        });if(this.resultLedgerGroupId>0){
        this.content.push({ text: '\nSELECTED LEDGER GROUP:' + this.getLedgerGrpById(this.resultLedgerGroupId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman' });
        }else{this.content.push({ text: '\nSELECTED LEDGER GROUP:' +'ALL LEDGER GROUP', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman' });}
        if(this.resultLedger>0){
            this.content.push({ text: '\nSELECTED LEDGER :' + this.getLedgerById(this.resultLedger), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman' });
            }else{this.content.push({ text: '\nSELECTED LEDGER :' +'ALL LEDGER', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman' });}
    
        this.content.push({
            text: '\n', fontSize: 8, alignment: 'center'
        });

        this.body.push([
            { text: 'S.No', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Date', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Ledger Details', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Income', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: ' Expense', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
              ]);
 
        for (let i = 0; i < this.statement.length; i++) {
              this.body.push([
                { text: this.statement[i].ledgerName, fontSize: 9, font: 'TimesNewRoman',bold:true, alignment: 'center', colSpan: 5 }, {}, {},{},{}
            ]);
            for(let j=0;j<this.statement[i].detlist.length;j++){
            let temp;
            if (this.statement[i].detlist[j].statementDate != null) {
                temp = moment(this.statement[i].detlist[j].statementDate).format('DD/MM/YYYY');
            } else {
                temp = "-";
            }
            this.body.push([
                { text: (i + 1), fontSize: 10, font: 'TimesNewRoman', alignment: 'center' },
                { text: temp, fontSize: 10, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.statement[i].detlist[j].details, fontSize: 10, font: 'TimesNewRoman', alignment: 'left' },
                { text: this.statement[i].detlist[j].credit.toFixed(2), fontSize: 10, font: 'TimesNewRoman', alignment: 'right', },
                { text: this.statement[i].detlist[j].debit.toFixed(2), fontSize: 10, font: 'TimesNewRoman', alignment: 'right', },
                 ]);
        }
        this.body.push([
            { text: 'Total Amount', fontSize: 12, font: 'TimesNewRoman', alignment: 'right', colSpan: 3 }, {}, {},
             { text: this.statement[i].creditTotal.toFixed(2), fontSize: 10, font: 'TimesNewRoman', alignment: 'right',bold: true },
            { text: this.statement[i].debitTotal.toFixed(2), fontSize: 10, font: 'TimesNewRoman', alignment: 'right',bold: true },
           
        ]);
        }
         this.content.push({
            'table': {
                headerRows: 1,
                'body': this.body,
                widths: ['5%', '10%', '65%', '10%', '10%', ]
            }
        });


        const dd = {
            'content': this.content,
            pageOrientation: 'landscape',
            pageSize: 'A4',
            footer: (currentPage, pageCount) => {
                return {
                    margin: 10,
                    columns: [
                        {
                            fontSize: 9,
                            text: [
                                {
                                    text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                        '\n',
                                    margin: [0, 20]
                                },
                                {
                                    text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                }
                            ],
                            alignment: 'center'
                        }
                    ]
                };
            }
        };

        pdfMake.createPdf(dd).open();
        this.body = [];
        this.content = [];

    }
} 