import {LeaveApplicationPojo}  from "./leave-application.pojo";

export class LeaveApplicationResPojo {
    code:number;
	response:string;
	academicYearId:number;
	classId:number;
	sectionId:number;
	accId:number;
	list:LeaveApplicationPojo[]=[];
	leaveStartDate;
	leaveEndDate;
	studentId:number;
}