import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import * as moment from 'moment';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-student-master',
    templateUrl: './student-master.component.html',
    styleUrls: ['./student-master.component.scss'],
    providers:[AcademicSettingService]
} )

export class StudentMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    studentMasterArr: StudentMasterPojo[] = [];
    studentMaster = new StudentMasterPojo();
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId;

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    startDate;
    endDate;
    id: number;
    isLoading: boolean = false;
    classMasterArr: ClassMasterPojo[] = [];
    sectionMasterArr: SectionMasterPojo[] = [];
    classid;
    sectionid;
    academic:AcademicYearMasterPojo[] =[];
    currentAcademicYr;
    minYr;
    maxyr;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    accountMasterArr:AccountMasterPojo[]=[];
    constructor( private accountMasterService:AccountMasterService,private settingService:AcademicSettingService,private studentMasterService: StudentMasterService, private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService, private router: Router ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        this.academicSettingAll();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
          $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true,
        
     locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
        ( start, end, label ) => {
            this.startDate = moment(start).format('YYYY-MM-DD');
            this.endDate = moment(end).format('YYYY-MM-DD');
             
             }
    );
        this.accountMasterAll();
       // this.getStudentByDepartmentId();
    }
    accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      }
    yrSetting(){
        this.currentAcademicYr = this.getAcademicYrById(this.loggedUser.academicYearId);
         //let str = this.currentAcademicYr;
             
    }
    focusSearch( event) {
        if ( event.keyCode == 13 ) {
           this.getStudentDetailsByAdmissionNo();
        }
    }
    getStudentDetailsByAdmissionNo(){
        this.studentMasterArr = [];
        this.isLoading = true;
        this.studentMasterService.getStudentMasterByAdmissionNo( this.filterQuery,this.loggedUser.accId ).subscribe( response => {
        let d= new StudentMasterPojo();
        d= response;
        if(d.studentId !=0){
          this.studentMasterArr.push(d);
        }
        this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Student By Admission Number Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
            //for(let i=0;i<this.academic.length;i++){
             //   if(this.academic[i].academicFlag == 1){
                   // this.currentAcademicYr = this.academic[i].academicYearId;
           //         this.currentAcademicYr = this.getAcademicYrById(this.loggedUser.academicYearId);
      
                   // console.log( this.currentAcademicYr);
                    //this.minYr = this.currentAcademicYr.substring(0,4);
                    //console.log(this.minYr);
                    //this.maxyr = this.currentAcademicYr.substring(this.currentAcademicYr.length-4,this.currentAcademicYr.length);
                    //console.log(this.minYr);
              //  }
            //}
            
             }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
       getAcademicYrById( id ) {
        if ( this.academic.find( response => response.academicYearId == id ) ) {
            return this.academic.find( response => response.academicYearId == id ).academicYear;
        } else {
            return id;
        }
    }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    

    getSectionNameBySectionId( id ) {
        if ( this.sectionArr.find( response => response.sectionId == id ) ) {
            return this.sectionArr.find( response => response.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getClassMasterByDeptId(id) {
        this.classMasterArr = [];
        this.classid = 0;
        this.studentMasterService.getClassMasterBydeptId(id,this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getSectionMasterByDeptId(id) {
       
        this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }

    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
   
    getStudentByDepartmentId() {
        if(this.startDate == undefined && this.endDate == undefined){
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
                      
           }
            if(this.departmentId != undefined && this.classid != undefined){
        this.studentMasterArr = [];
        this.isLoading = true;
        this.studentMasterService.getStudentMasterByDateAndDidCidAndAid( this.startDate,this.endDate, this.departmentId, this.classid, this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
            this.studentMasterArr = response;
            this.resultDept= this.departmentId;
            this.resultClass= this.classid;
          
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Student By DeptId ClassId And AccountId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
        else{
            bootbox.alert('Please Fill all Mandatory Fields..');
        }
    }
    clickPrint(id){
        let studentId = id;
        this.router.navigate( [`/console/ptrintstudentmaster`, studentId,{path:1}] );
   
    }
    clickEdit( id ) {
        let studentId = id;
        this.router.navigate( [`/console/editStudentRegister`, studentId,{path:1}] );
    }

    delete( id ) {
        this.id = id;
    }

    deleteConfirm() {
        let no;
        const i = this.studentMasterArr.indexOf( this.studentMasterArr.find( response => response.studentId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.studentMasterService.deleteStudent( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row is already deleted', className: 'text-danger h5', closeButton: false } );
                this.studentMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.studentMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getStudentByDepartmentId();
        }, error => {
            bootbox.alert( { message: 'Delete Student Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
         this.content.push( {
             text: 'ADMISSION REPORT FOR\t'+'[ '+this.getAcademicYrById(this.loggedUser.academicYearId)+' ]', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );
   
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
        
         if(this.resultDept !=0){
         this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
    }if(this.resultDept ==0){
        this.content.push( {text: 'SELECTED DEPARTMENT : '+ 'ALL', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
   }
       if(this.resultClass !=0){
       this.content.push( {text: '\nSELECTED CLASS :'+this.getClassNameByClassId(this.resultClass), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );
      } if(this.resultClass ==0){
     this.content.push( {text: '\nSELECTED CLASS :'+'ALL', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
   }
         this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Mobile No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Gender', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'DOB ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'FatherName', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Religion', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Community', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         ] );
   
         for ( let i = 0; i < this.studentMasterArr.length; i++ ) {
             let temp;
             if ( this.studentMasterArr[i].mobileNo != null ) {
                 temp = this.studentMasterArr[i].mobileNo ;
             } else {
                 temp = "-";
             }
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.studentMasterArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.studentMasterArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text: this.studentMasterArr[i].mobileNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.studentMasterArr[i].gender, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: moment( this.studentMasterArr[i].dob ).format( 'DD/MM/YYYY' ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.studentMasterArr[i].fatherName, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.studentMasterArr[i].religion, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                 { text: this.studentMasterArr[i].community, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
             ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '12%', '24%', '9%', '10%', '8%', '12%', '10%', '10%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId)+ ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
}
