import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { StaticVariable } from 'src/app/globle.class';
import { LedgerStatementPojo } from 'src/app/pojo/ledger-statement.pojo';
import { IncomeExpenditureReportPojo, ConsolidationReportPojo } from 'src/app/pojo/income-expenditure-report.pojo';
import { LedgerWiseReportPojo } from 'src/app/pojo/ledger-wise-report.pojo';
import { TrailBalanceConsolidationReportPojo } from 'src/app/pojo/trial-balance-report.pojo';
@Injectable({
  providedIn: 'root'
})
    
export class LedgerStatementService {
    
    constructor(private httpclient: HttpClient, private http: Http) { }
    
    getStatementByDate( fdate, tdate, ledgerId, cid ): Observable<LedgerStatementPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerStatement/' + fdate + '/' + tdate+'/'+ledgerId+'/'+cid;
        return this.httpclient.get<LedgerStatementPojo[]>(url);
    }
    getIncomeExpenditureReportByDate( fdate, tdate, cid ): Observable<IncomeExpenditureReportPojo[]> {
      const url = StaticVariable.porturl + 'GetIncomeExpenditureReport/' + fdate + '/' + tdate+'/'+cid;
      return this.httpclient.get<IncomeExpenditureReportPojo[]>(url);
  }
  getConsolidatedReportByDate( fdate, tdate, cid ): Observable<ConsolidationReportPojo> {
    const url = StaticVariable.porturl + 'GetConsolidationReport/' + fdate + '/' + tdate+'/'+cid;
    return this.httpclient.get<ConsolidationReportPojo>(url);
}
getTrialBalanceReportByDate( fdate, tdate, cid ): Observable<TrailBalanceConsolidationReportPojo> {
  const url = StaticVariable.porturl + 'GetTrailBalnceReport/' + fdate + '/' + tdate+'/'+cid;
  return this.httpclient.get<TrailBalanceConsolidationReportPojo>(url);
}
getLedgerwiseReport(fromDate,toDate,ledgerId,accId): Observable<LedgerWiseReportPojo[]> {
  const url = StaticVariable.porturl + 'GetLedgerWiseReport/'+fromDate+'/'+toDate+'/'+ledgerId+'/'+accId;
  return this.httpclient.get<LedgerWiseReportPojo[]>( url );
}
 }