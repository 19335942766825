export class ReceiptHeadPojo {

	 receiptHeadId:number;
	 receiptHeadName:string;
	 ledgerId:number;
	 printName:string;
	 defaultFlag:string;
	 accId:number;
	 userId:number;
	 orderNo:number;
	 code:number;
	 ledgerGroupId:number;
	 }