import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AttendanceAbsentSummaryPojo, AttendanceAbsentDetailsPojo } from 'src/app/pojo/attendance-absent-details.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class AbsentListService {

    constructor( private httpclient: HttpClient ) { }

    
    getAbsendtStudentDetailsById(aid,cid,date,accid): Observable<AttendanceAbsentSummaryPojo> {
        const url = StaticVariable.porturl + 'GetDayWiseAttendanceSummaryReport/'+aid+'/'+cid+'/'+date+'/'+accid;
        return this.httpclient.get<AttendanceAbsentSummaryPojo>( url );
    }
    sendSmsToAbsentList(arg:AttendanceAbsentDetailsPojo[],accid,uid): Observable<number> {
        const url = StaticVariable.porturl + 'SmsToAbsentStudentList';
        return this.httpclient.get<number>( url );
    }
   
  
          

    
}