import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StrengthReportPojo } from 'src/app/pojo/strength-pojo';
import { ArrearReportPojo } from 'src/app/pojo/arrears-report.pojo';
import { AttendanceResponsePojo } from 'src/app/pojo/attendance.pojo';
import { DayWiseAttendancePojo } from 'src/app/pojo/daywise-attendance-entry.pojo';
import { ArrearReportStudentDetailsByStandardTermPojo } from 'src/app/pojo/standard-term-wise.pojo';
import { ExamEntryResponsePojo, ExamEntryPojo } from 'src/app/pojo/Exam-Entry.pojo';
import { ExamSchedulePojo } from 'src/app/pojo/exam-schedule.pojo';
import { ExamDetailsByClassWisePojo, ExamDetailsByStudentWisePojo } from 'src/app/pojo/exam-details-by-classWise.pojo';
import { FeeCollectionPojo } from 'src/app/pojo/fee-collection.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class ReportService {

    constructor( private httpclient: HttpClient ) { }

    
    getClassWiseReportById(deptId,classId,sectionId,academicYrId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetClassWiseReport/'+deptId+'/'+classId+'/'+sectionId+'/'+academicYrId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getBusStandardAcademicDetailsById(aid,cid,accid): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetBusStandardAcademicDetailsByYearIdAndClassId/'+aid+'/'+cid+'/'+accid;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getGenderWiseReportById(deptId,classId,sectionId,gender,academicYrId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetGenderWiseReport/'+deptId+'/'+classId+'/'+sectionId+'/'+gender+'/'+academicYrId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getCommunityWiseReportById(deptId,classId,sectionId,comm,academicYrId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetCommunityWiseReport/'+deptId+'/'+classId+'/'+sectionId+'/'+comm+'/'+academicYrId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
      
    getDistinctCommunity(): Observable<any[]> {
        const url = StaticVariable.porturl + 'GetDistinctCommunity';
        return this.httpclient.get<any[]>( url );
    }
    getPhysicallyDisabledReportById(deptId,classId,sectionId,academicYrId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetPhysicallyDisabledReport/'+deptId+'/'+classId+'/'+sectionId+'/'+academicYrId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getStrengthReportById(deptId,classId,sectionId,academicYrId,accId): Observable<StrengthReportPojo> {
        const url = StaticVariable.porturl + 'GetStrengthReport/'+deptId+'/'+classId+'/'+sectionId+'/'+academicYrId+'/'+accId;
        return this.httpclient.get<StrengthReportPojo>( url );
    }
    getArrearReportById(academicYrId,dept,classId,sectionId,typeId,accId): Observable<ArrearReportPojo> {
        const url = StaticVariable.porturl + 'GetArrearAndNonArrearReport/'+academicYrId+'/'+dept+'/'+classId+'/'+sectionId+'/'+typeId+'/'+accId;
        return this.httpclient.get<ArrearReportPojo>( url );
    }
    sendSmsTofeeAllocation(arg:ArrearReportPojo ,accId,uid): Observable<any> {
        const url = StaticVariable.porturl + 'SmsToStudentFeeAllocation';
        return this.httpclient.post( url,arg );
    }
   
    getDayWiseAttendanceEntryReportById(academicYrId,classId,sectionId,date,accId): Observable<DayWiseAttendancePojo[]> {
        const url = StaticVariable.porturl + 'GetDayWiseAttendance/'+academicYrId+'/'+classId+'/'+sectionId+'/'+date+'/'+accId;
        return this.httpclient.get<DayWiseAttendancePojo[]>( url );
    }
    getStandardTermwiseReportById(academicYrId,classId,sectionId,typeId,stermid,accId): Observable<ArrearReportStudentDetailsByStandardTermPojo[]> {
        const url = StaticVariable.porturl + 'GetArrearAndNonArrearReportByTermWise/'+academicYrId+'/'+classId+'/'+sectionId+'/'+typeId+'/'+stermid+'/'+accId;
        return this.httpclient.get<ArrearReportStudentDetailsByStandardTermPojo[]>( url );
    }
    getStudentListById(academicYrId,classId,sectionId,date,hours,accId): Observable<AttendanceResponsePojo> {
        const url = StaticVariable.porturl + 'GetTempAttendanceListByYearIdAndClassIdAndSectionId/'+academicYrId+'/'+classId+'/'+sectionId+'/'+date+'/'+hours+'/'+accId;
        return this.httpclient.get<AttendanceResponsePojo>( url );
    }
     
    getStudentExamEntryById(academicYrId,classId,sectionId,examId,subId,accId): Observable<ExamEntryResponsePojo> {
        const url = StaticVariable.porturl + 'GetTempExamEntryListByYearIdAndClassIdAndSectionId/'+academicYrId+'/'+classId+'/'+sectionId+'/'+examId+'/'+subId+'/'+accId;
        return this.httpclient.get<ExamEntryResponsePojo>( url );
    }
    addStudentMarkList( arg: ExamEntryResponsePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddExamEntry';
        return this.httpclient.post( url, arg );
    }
    updateSTudentMarkEntryList( arg: ExamEntryPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateExamEntry';
        return this.httpclient.put( url, arg );
    }
    getExamScheduleById(classId,examId,aId,accId): Observable<ExamSchedulePojo[]> {
        const url = StaticVariable.porturl + 'GetExamScheduleByClassIdAndExamId'+'/'+classId+'/'+examId+'/'+aId+'/'+accId;
        return this.httpclient.get<ExamSchedulePojo[]>( url );  
    }
    getExamReportById(aid,classId,sectionId,examId): Observable<ExamDetailsByClassWisePojo[]> {
        const url = StaticVariable.porturl + 'GetExamDetailsByClassWise'+'/'+aid+'/'+classId+'/'+sectionId+'/'+examId;
        return this.httpclient.get<ExamDetailsByClassWisePojo[]>( url );
    }
    getExamMarkById(aid,classId,sectionId,examId,studId): Observable<ExamDetailsByStudentWisePojo[]> {
        const url = StaticVariable.porturl + 'GetExamDetailsByStudentWise'+'/'+aid+'/'+classId+'/'+sectionId+'/'+examId+'/'+studId;
        return this.httpclient.get<ExamDetailsByStudentWisePojo[]>( url );
    }
          

    
}