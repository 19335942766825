export class ClassMasterPojo {
    classId: number;
    deptId: number;
    className: string;
    classNo: string;
    classStaffId: number = 0;
    noOfStudents: number;
    orderNo:number;
    list:ClassHoursPojo[]=[];
    accId:number;
}
export class ClassHoursPojo {

	 classHoursId:number;
	 classId:number;
     hoursId:number;
     hoursName:string;
}  