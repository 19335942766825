import { FtpImagePojo } from "./ftp-image.pojo";

export class StudentMasterPojo {
    studentId: number;
    admissionNoSerial: number;
    admissionNo: string;
    name: string;
    nameTamil: string;
    gender: string;
    dob: Date;
    doa: Date;
    fatherName: string;
    motherName: string;
    guardianName: string;
    address: string;
    pincode: string;
    nationality: string = "INDIAN";
    religion: string;
    cast: string;
    bloodGroup: string = "N/R";
    mobileNo: string;
    mobileNo2: string;
    aadharNo: string;
    mediumId: number = 0;
    classId: number = 0;
    sectionId: number = 0;
    fatherOccupation: string;
    motherOccupation: string;
    guardianOccupation: string;
    fatherQualification: string;
    motherQualification: string;
    guardianQualification: string;
    fatherIncome: number;
    motherIncome: number;
    guardianIncome: number;
    height: number;
    weight: number;
    identificationMark1: string;
    identificationMark2: string;
    relatedStudentNo1: string;
    relatedStudentNo2: string;

    community: string;
    emisNo: string;

    fileName: string;
    fileAddressPath: string;
    imageId: number;
    emailId: string;
    allergy: string;
    telephoneNo: string;
    formEntryStatus:string;
    discontinueFlag:string;
    physicallyDisabled:string;
    disabledDesc:string;
    joiningAcademicYear:number;
    relivingAcademicYear:number;
    smsFlag:string;
    relivingStandard:number;
    imageList:FtpImagePojo[];
    profile = new FtpImagePojo();
    notes:NotesMasterPojo[];
    responseCode:number;
    lastYearStatus:string;
    whatsappStatus:string = "FALSE";
   discountFlag:string ;
   discountTypeId:number;
   busFlag:string;
   areaId:number;
   discountType:string;
   discountAmount:number;
   accId:number;

}
export class NotesMasterPojo {

	 notesId:number;
	 name:string;
	 description:string;
	 accId:number;
     userId:number;
}