import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AccountMasterPojo } from "src/app/pojo/account-master.pojo";

@Injectable( {
    providedIn: 'root'
} )  

export class AccountMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllAccountMaster(): Observable<any> {
        const url = StaticVariable.porturl + 'AccountMasterAll';
        return this.httpclient.get<AccountMasterPojo[]>( url );
    }
    
    addAccountMaster( arg: AccountMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAccountMaster';
        return this.httpclient.post( url, arg );
    }
    
    updateAccountMaster( arg: AccountMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateAccountMaster';
        return this.httpclient.put( url, arg );
    }
    
    deleteAccountMaster( id: number): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteAccountMaster/' + id ;
        return this.httpclient.delete( url );
    }

}