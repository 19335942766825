import { StudentMasterPojo } from "./student-master.pojo";

export class AcademicStandardDetailsPojo {


	 academicStandardDetId:number;
	 academicYearId:number;
	 academicYear:string;
	 studentId:number;
	 classId:number;
	 sectionId:number;
	 selectStatus:string ="PASS";
	  reason:string;
	 removeFlag:string;
	 rollNo:string;
	 admissionNo:string;
     student = new StudentMasterPojo();
}
export class AcademicStandardDetailsResultPojo {

	code:number;
	response:string;
	alist:AcademicStandardDetailsPojo[] =[];
}