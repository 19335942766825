import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";

import { AcademicStandardDetailsResultPojo, AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { PromotionReportPojo } from 'src/app/pojo/promotion-report.pojo';
import { SectionAllocationReportPojo } from 'src/app/pojo/section-allocation-report.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class PromotionService {

    constructor( private httpclient: HttpClient ) { }

    
    getPromotionByFromandToAndId(from,to,classId,accId): Observable<AcademicStandardDetailsResultPojo> {
        const url = StaticVariable.porturl + 'GetStandardAcademicDetailsByFromToYearIdAndClassId/'+from+'/'+to+'/'+classId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsResultPojo>( url );
    }
    getSectionAllocationByFromandToAndId(from,to,classId,sectionId,accId): Observable<AcademicStandardDetailsResultPojo> {
        const url = StaticVariable.porturl + 'GetStandardAcademicDetailsByYearIdAndClassIdAndSectionId/'+from+'/'+to+'/'+classId+'/'+sectionId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsResultPojo>( url );
    }
    submitPromotion( arg: PromotionReportPojo,accId ): Observable<any> {
        const url = StaticVariable.porturl + 'DoPromotion/'+accId;
        return this.httpclient.post( url, arg );
    }
    updatePromotion( arg: PromotionReportPojo,accId ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdatePromotion/'+accId;
        return this.httpclient.put( url, arg );
    }
    submitSectionAllocation( arg: SectionAllocationReportPojo,accId ): Observable<any> {
        const url = StaticVariable.porturl + 'DoSectionAllocation/'+accId;
        return this.httpclient.post( url, arg );
    }
  /*  updateSectionAllocation( arg: SectionAllocationReportPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSectionAllocation';
        return this.httpclient.post( url, arg );
    }*/
    checkPromotionAndAllocation(yearId,classId,sectionId,accId): Observable<AcademicStandardDetailsResultPojo> {
        const url = StaticVariable.porturl + 'CheckPromotionAndSectionAllocation/'+yearId+'/'+classId+'/'+sectionId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsResultPojo>( url );
    }
    checkPromotionStandardDetailsById(yearId,classId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetStandardAcademicDetailsByYearIdAndClassId/'+yearId+'/'+classId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getPreviousSectionListById(preYrId,curYrId,prevClassId,curClassId,sectionId,typeId,value,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetAcademicStandardDetailsBySectionIdOrGenderOrAdmissionNo/'+preYrId+'/'+curYrId+'/'+prevClassId+'/'+curClassId+'/'+sectionId+'/'+typeId+'/'+value+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    
    getNonAllocatedDetailsById(yearId,classId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetNonAllocatedSectionDetailsByYearIdAndClassId/'+yearId+'/'+classId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
    getAllocatedDetailsById(yearId,classId,accId): Observable<AcademicStandardDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetAllocatedSectionDetailsByYearIdAndClassId/'+yearId+'/'+classId+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsPojo[]>( url );
    }
   reallocation(yearId,classId,accId): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSectionAndRollNumberEmpty/'+yearId+'/'+classId+'/'+accId;
        return this.httpclient.put( url,yearId);
    }
    
    
}