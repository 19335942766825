import { TeacherNotesPojo } from "./teacher-notes.pojo";

export class Teachernoterespojo {
    response:string;
	code:number;
	classId:number;
	 sectionId:number;
	 academicYearId:number;
     noteDate;
	 accId:number;
	 userId:number;
	 staffId:number;
	 list:TeacherNotesPojo[]=[];
}