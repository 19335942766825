import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { FeeAllocationReportByTermWisePojo, FeeCollectionPojo } from 'src/app/pojo/fee-collection.pojo';
import { DiscountDetailsPojo } from 'src/app/pojo/discount-detail.pojo';
import { DiscountDetailsResponsePojo } from 'src/app/pojo/discount-detail-response.pojo';
import { BusFeeRefundPojo } from 'src/app/pojo/bus-fee-refund.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class FeeCollectionService {

    constructor( private httpclient: HttpClient ) { }  

    getFeeCollectionById(id): Observable<any> {
        const url = StaticVariable.porturl + 'GetFeeCollectionById/'+id;
        return this.httpclient.get<FeeCollectionPojo>( url );
    }
    sendSmstoFeeCollection(arg:FeeCollectionPojo): Observable<any> {
        const url = StaticVariable.porturl + 'SmsToStudentFeeCollection';
        return this.httpclient.post( url,arg );
    }
    
    addFeeCollection( arg: FeeCollectionPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddFeeCollection';
        return this.httpclient.post( url, arg );
    }
    addBusFeeRefund( arg: BusFeeRefundPojo): Observable<any> {
        const url = StaticVariable.porturl + 'AddBusFeeRefund';
        return this.httpclient.post( url, arg );
    }
    addDiscountDetails( arg: DiscountDetailsPojo[]): Observable<any> {
        const url = StaticVariable.porturl + 'AddDiscountDetails';
        return this.httpclient.post( url, arg );
    }
    updateDiscountDetails( arg: DiscountDetailsResponsePojo): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateDiscountDetails';
        return this.httpclient.put( url, arg );
    }
    
    getFeeCollectionAll(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetFeeCollectionAll/'+accid;
        return this.httpclient.get<FeeCollectionPojo[]>( url );
    }
    
    
}