import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'paymentEntry'
})
export class PaymentEntryPipe implements PipeTransform {
    

  transform(array: any[], query: string): any {
     
          if (query) {
               if (_.filter(array, row => row.paymentNo == query) != ''){
                 return _.filter(array, row => row.paymentNo == query);
                 } else if (_.filter(array, row => row.ledgerId == query) != '') {
                 return _.filter(array, row => row.ledgerId == query);
             } else if (_.filter(array, row => row.dLedgerId == query) != '') {
                 return _.filter(array, row => row.dLedgerId == query);
             }else if (_.filter(array, row => row.credit == query) != '') {
                 return _.filter(array, row => row.credit == query);
             } else if (_.filter(array, row => row.debit == query) != '') {
                 return _.filter(array, row => row.debit == query);
             } 
                 else {
                 return;
             }
          }

          return array;
      }
  }
