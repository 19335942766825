import { AcademicStandardDetailsPojo } from './academic-standard-details.pojo';

export class SectionAllocationReportPojo {

	 academicYearId:number;
	 classId:number;
	 sectionId:number;
	 staffId:number;
	 userId:number;
    selectList:AcademicStandardDetailsPojo[]= [];
}
