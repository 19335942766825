import { Component, OnInit } from '@angular/core';

import { UserSettingsPojo } from 'src/app/pojo/userSettingsPojo';
import { UserSettingsService } from './userSettings.service';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';


declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-userSettings',
  templateUrl: './userSettings.component.html',
  styleUrls: ['./userSettings.component.scss']
})
export class UserSettingsComponent implements OnInit {


  loggedUser = new MUserMasterPojo();


  userSettingsList: UserSettingsPojo[] = [];
  settingsTypeList: UserSettingsPojo[] = [];
  editUserSettings = new UserSettingsPojo();

  editStatus: boolean;

  public data: number;
  public sortBy = 'i';
  public rowsOnPage = 12;
  public filterQuery = '';
  public sortOrder = 'asc';

  selectedSettingsType: string = '';

  isLoading: boolean;
  showBtnFlag: boolean;
  updateBtnFlag: boolean;

  constructor(private userSettingsService: UserSettingsService) { }

  ngOnInit() {


    this.loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    this.enterKeyEvent();
    this.getSettingsTypeList();
  }


  enterKeyEvent() {
    var inputs = $('.changeFocus').keypress(function (e: any) {
        if (e.which == 13) {
            e.preventDefault();
            var nextInput = inputs.get(inputs.index(this) + 1);
            if (nextInput) {
                nextInput.focus();
            }
        }
    });
}

getSettingsTypeList() {
    this.isLoading = true;
    this.userSettingsService.getSettingsTypeList().subscribe(response => {
        this.isLoading = false;
        this.settingsTypeList = response;
         this.selectedSettingsType =  this.settingsTypeList[0].settingsType;
        this.showClick();
    }, error => {
        this.isLoading = false;
        bootbox.alert({ message: 'Get Settings Type List Service Error! HTTP Status ' + error.status, className: 'text-danger text-center h5', closeButton: false });
    });
}

showClick() {
    this.showBtnFlag = true;
    this.userSettingsService.getUserSettingsListByTypeName(this.selectedSettingsType).subscribe(response => {
        this.showBtnFlag = false;
        this.userSettingsList = response;
        this.loggedUser.userSettingsPojoList = response;
        sessionStorage.setItem("loggedUser", JSON.stringify(this.loggedUser));
    }, error => {
        this.showBtnFlag = false;
        bootbox.alert({ message: 'Get User Settings List By Type Name Service Error! HTTP Status ' + error.status, className: 'text-danger text-center h5', closeButton: false, size: 'large' });
    });
}

castString(arg: any): string {
    return String(arg).toUpperCase();
}

editClick(arg: UserSettingsPojo) {
    this.editUserSettings = Object.assign({}, arg);
}

updateUserSettings() {
    this.updateBtnFlag = true;
    // this.editUserSettings.valueString.trim();
    this.userSettingsService.updateUserSettings(this.editUserSettings).subscribe(() => {
        this.updateBtnFlag = false;
        bootbox.alert({ message: 'Successfully Updated', className: 'text-success h5 text-center', closeButton: false });
        this.selectedSettingsType = this.editUserSettings.settingsType;
        this.showClick();
        $('#updateModal').modal('hide');
    }, error => {
        this.updateBtnFlag = false;
        bootbox.alert({ message: 'Update User Settings Service Error! HTTP Status ' + error.status, className: 'text-danger text-center h5', closeButton: false });
    });
}


}
