import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'SectionPipe'
} )

export class SectionMasterPipe implements PipeTransform {

    transform( array: any[], query: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.noOfStudents == query ) != '' ) {
                return _.filter( array, row => row.noOfStudents == query );
            } else if ( _.filter( array, row => row.sectionName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.sectionName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.sectionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.sectionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}

@Pipe( {
    name: 'SectionMasterIdPipe'
} )

export class SectionMasterIdPipe implements PipeTransform {

    transform( array: any[], query: any): any {
        if ( query ) {
            if ( _.filter( array, row => row.classId == query ) != '' ) {
                return _.filter( array, row => row.classId == query );
            }
        }
        return [];
    }

}
