import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SmsMasterPojo } from "src/app/pojo/sms-master.pojo";
import { SmsMasterService } from "src/app/management/SMS/sms-master/sms-master.service";
import { Router } from "@angular/router";

import * as moment from 'moment';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-sms-master',
    templateUrl: './sms-master.component.html',
    styleUrls: ['./sms-master.component.scss']
} )

export class SmsMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    smsMasterArr: SmsMasterPojo[] = [];

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    tableLoading: boolean = true;

    constructor( private smsMasterService: SmsMasterService, private router: Router ) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.getSmsMasterAll();
    }

    getSmsMasterAll() {
        this.tableLoading = true;
        this.smsMasterService.getSmsMasterAll(this.loggedUser.accId).subscribe( response => {
            this.tableLoading = false;
            this.isLoading = false;
            this.smsMasterArr = response;
        }, error => {
            bootbox.alert( { message: 'Get Sms Master All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.tableLoading = false;
            this.isLoading = false;
        } );
    }

    getShortTimeFormat( arg ) {
        return moment( arg, 'HH:mm:ss' ).format( 'hh:mm A' );
    }

    clickEdit( id ) {
        let smsId = id;
        this.router.navigate( [`/console/editSmsMaster`, smsId] );
    }

    delete( id ) {
        this.id = id;
    }

    deleteConfirm() {
        let no;
        const i = this.smsMasterArr.indexOf( this.smsMasterArr.find( response => response.smsId == this.id ) );
        this.isLoading = true;
        this.smsMasterService.deleteSms( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row is already deleted', className: 'text-danger h5', closeButton: false } );
                this.smsMasterArr.splice( i, 1 );
            } else {
                this.smsMasterArr.splice( i, 1 );
            }
            $( '#deleteModal' ).modal( 'hide' );
            this.getSmsMasterAll();
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Delete Sms Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
}
