
export class StandardTermPojo {

	 standardTermId:number;
	 standardTerm:string;
	 classId:number;
	 termId:number;
	 amount:number;
	 gst:number;
	 academicYearId:number;
	 receiptHeadId:number;
	 response:number;
	 code:number;
	 discount:number;
	 accId:number;
	 receiptOrderNo:number;
}