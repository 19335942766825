import { Component, OnInit } from '@angular/core';
import { ClassMasterPojo, ClassHoursPojo } from "src/app/pojo/class-master.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { StaticVariable } from "src/app/globle.class";
import { HoursPojo } from 'src/app/pojo/hours.pojo';
import { HoursMasterService } from '../hours-master/hoursmaster.service';
import * as moment from 'moment';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-class-master',
    templateUrl: './class-master.component.html',
    styleUrls: ['./class-master.component.scss']
} )

export class ClassMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    classMasterArr: ClassMasterPojo[] = [];
    staffArr: StaffMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];

    addClassMaster = new ClassMasterPojo();
    editClassMaster = new ClassMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    public orderNoArr:number[] = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];
    id: number;
    isLoading: boolean = true;
    hoursArr: HoursPojo[] = [];
    specStaffArr:StaffMasterPojo[]=[];
    constructor( private classMasterService: ClassMasterService,private hoursmasterserivce: HoursMasterService, private staffMasterService: StaffMasterService, private departmentMasterService: DepartmentMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();

        this.getClassMasterAll();
        this.getStaffMasterAll();
        this.getDepartmentMasterAll();
        this.getHoursAll();
    }
    getHoursAll() {
          this.hoursmasterserivce.getAllHoursMaster(this.loggedUser.accId).subscribe(response => {
            this.hoursArr = response;
            for(let i=0;i<this.hoursArr.length;i++){
                this.hoursArr[i].hoursFrom = moment(this.hoursArr[i].hoursFrom,'HH:mm').format('hh:mm A');
                this.hoursArr[i].hoursTo = moment(this.hoursArr[i].hoursTo,'HH:mm').format('hh:mm A');
            }
            }, error => {
            bootbox.alert({ message: 'Get Hours All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
             });
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStaffMasterAll() {
        this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( response => {
            this.staffArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getStaffMasterByDeptId(event:any){
        this.specStaffArr=[];
        this.staffMasterService.getStaffByDeptId(this.addClassMaster.deptId,this.loggedUser.accId).subscribe( response => {
            this.specStaffArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Staff By Type Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }  getEditStaffMasterByDeptId(event:any){
        this.specStaffArr=[];
        this.staffMasterService.getStaffByDeptId(this.editClassMaster.deptId,this.loggedUser.accId).subscribe( response => {
            this.specStaffArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Staff By Type Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } ); 
    }
    getDepartmentMasterAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( response => {
            this.departmentArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getClassStaffNameByStaffId( id ) {
        if ( this.staffArr.find( response => response.staffId == id ) ) {
            return this.staffArr.find( response => response.staffId == id ).staffName;
        } else {
            return id;
        }
    }
    getDepartmentNameByDepId( id ) {
        if ( this.departmentArr.find( response => response.deptId == id ) ) {
            return this.departmentArr.find( response => response.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    checkHourSelection( checkPointObject, item: HoursPojo ) {
        if ( checkPointObject.checked ) {
            let j = this.hoursArr.findIndex( r => r.hoursId == item.hoursId );
            let d = new ClassHoursPojo();
            d.hoursId = this.hoursArr[j].hoursId;
              this.addClassMaster.list.push( d );
        } else {
            let j = this.addClassMaster.list.findIndex( r => r.hoursId == item.hoursId );
            this.addClassMaster.list.splice( j, 1 );
        }
    }

    addClass() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        if(this.addClassMaster.list.length <0){
            this.addClassMaster.list=[];
        }
        this.addClassMaster.accId=this.loggedUser.accId;
        this.classMasterService.addClass( this.addClassMaster ).subscribe( response => {
            bootbox.alert( { message: 'Class has been successfully created.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addClassForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.addClassMaster = new ClassMasterPojo();
            this.getClassMasterAll();
        }, error => {
            bootbox.alert( { message: 'Add Class Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editClassMaster = Object.assign( {}, arg );
        for ( let i = 0; i < this.hoursArr.length; i++ ) {
            ( <HTMLInputElement>document.getElementById( 'edithours' + i ) ).checked = false;
            for ( let j = 0; j < this.editClassMaster.list.length; j++ ) {
                if ( this.hoursArr[i].hoursId == this.editClassMaster.list[j].hoursId ) {
                  ( <HTMLInputElement>document.getElementById( 'edithours' + i ) ).checked = true;
                }
            }
        }
        this.getEditStaffMasterByDeptId(event);
    }
    checkEditHourSelection( checkPointObject, item: ClassHoursPojo ){
         if ( checkPointObject.checked ) {
            let j = this.hoursArr.findIndex( r => r.hoursId == item.hoursId );
            let d = new ClassHoursPojo();
           d.hoursId = this.hoursArr[j].hoursId;
           d.classId = this.editClassMaster.classId;
                  this.editClassMaster.list.push( d );
        } else {
           
            let j = this.editClassMaster.list.findIndex( r => r.hoursId == item.hoursId );
            this.editClassMaster.list.splice( j, 1 );
        }
    }
    editClass() {
        let editClass = new ClassMasterPojo();
        editClass = this.editClassMaster;
        editClass.accId=this.loggedUser.accId;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.classMasterService.updateClass( editClass ).subscribe( response => {
            bootbox.alert( { message: 'Class has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getClassMasterAll();
        }, error => {
            bootbox.alert( { message: 'Update Class Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.classMasterArr.indexOf( this.classMasterArr.find( response => response.classId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.classMasterService.deleteClass( this.id, this.loggedUser.userId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row is already deleted', className: 'text-danger h5', closeButton: false } );
                this.classMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.classMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getClassMasterAll();
        }, error => {
            bootbox.alert( { message: 'Delete Class Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
