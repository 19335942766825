
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { ReportService } from '../report-service';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
declare var $: any;
declare var bootbox: any;
import * as XLSX from 'xlsx';
import { CommunityMasterService } from '../../Masters/community-master/community.service';
import { CommunityPojo } from 'src/app/pojo/community-caste.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
@Component({
  selector: 'app-community-wise',
  templateUrl: './community-wise.component.html',
  styleUrls: ['./community-wise.component.scss']
})
export class CommunityWiseComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    standardArr: AcademicStandardDetailsPojo[] = [];
    standard = new AcademicStandardDetailsPojo();
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    studentMasterArr: StudentMasterPojo[] = [];
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    community:string;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
   content = [];
   documentTime:string;
   resultDept:number;
   resultClass:number;
   resultSection:number;
   resulltGender:string;
   communityArr:CommunityPojo[]=[];;
   accountMasterArr:AccountMasterPojo[]=[];
    constructor( private reportService:ReportService,private studentMasterService: StudentMasterService,
         private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService,
          private classMasterService: ClassMasterService, private router: Router,private accountMasterService:AccountMasterService,
          private communityMasterService:CommunityMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.getDistinctCommunity();
       // this.getStudentByDepartmentId( 1 );
       this.accountMasterAll();
    }
    accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getSectionNameBySectionId( id ) {
        if ( this.sectionArr.find( response => response.sectionId == id ) ) {
            return this.sectionArr.find( response => response.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getDistinctCommunity(){
      this.communityArr = [];
      this.communityMasterService.getAllCommunity(this.loggedUser.accId).subscribe( response => {
        this.communityArr = response;
          }, error => {
        bootbox.alert( { message: 'Get Community Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
        } );
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }
    getClassMasterByDeptId(id) {
      this.studentMasterService.getClassMasterBydeptId(id,this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getSectionMasterByDeptId(id) {
      this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
          this.sectionMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }

    getClassNameById( id ) {
      if ( this.classMasterArr.find( r => r.classId == id ) ) {
          return this.classMasterArr.find( r => r.classId == id ).className;
      } else {
          return id;
      }
  }
  getSectionNameById( id ) {
    if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
        return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
    } else {
        return id;
    }
}

    getReportById() {
        if(this.departmentId !=null &&this.classid !=null&&this.sectionid != null && this.loggedUser.academicYearId != null && this.community != null){
    
      this.standardArr = [];
        this.isLoading = true;
           this.reportService.getCommunityWiseReportById( this.departmentId, this.classid, this.sectionid,this.community,this.loggedUser.academicYearId,this.loggedUser.accId).subscribe( response => {
            this.standardArr = response;
            this.resultDept= this.departmentId;
            this.resultClass= this.classid;
            this.resultSection=this.sectionid;
            this.resulltGender= this.community;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }else{
        bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );
   
    }
    }
    printExcel() {
        let dummyList: XlPojo[] = [];

        for ( let i = 0; i <  this.standardArr.length; i++ ) {
            let t = new XlPojo();
            t.admissionNo = this.standardArr[i].student.admissionNo;
            t.name = this.standardArr[i].student.name;
            t.sectionId = this.getSectionNameBySectionId(this.standardArr[i].student.sectionId);
            t.mobileNo = this.standardArr[i].student.mobileNo;
            t.gender = this.standardArr[i].student.gender;
            t.dob = this.standardArr[i].student.dob;
            t.classId = this.getClassNameByClassId(this.standardArr[i].student.classId);
            t.religion = this.standardArr[i].student.religion;
            t.community = this.standardArr[i].student.community;
               dummyList.push( t );
        }

       const readyToExport = dummyList;
        const workBook = XLSX.utils.book_new(); // create a new blank book
        const workSheet = XLSX.utils.json_to_sheet( readyToExport, { header: ["admissionNo", "name","classId", "sectionId", "mobileNo", "gender", "dob","religion","community"] } );

        XLSX.utils.book_append_sheet( workBook, workSheet, 'data' ); // add the workSheet to the book
        XLSX.writeFile( workBook,  ' Community wise Report.xlsx' );
        $( '#exportLoading' ).removeClass( 'fa fa-spinner fa-spin' );
        bootbox.alert( 'Successfully exported Community wise report in Your Download path!' );
    }
    downloadPDF() {
     this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
     this.content.push({
        text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
      });
      this.content.push( {
          text: 'COMMUNITY WISE REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
      } );

      this.content.push( {
          text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
          fontSize: 10, bold: true, alignment: 'center'
      } );
      if(this.resultDept==0){
        this.content.push( {text: 'SELECTED DEPARTMENT : '+'ALL DEPARTMENT', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    } );
     }else{
     this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
   } );}
   if(this.resultClass !=0 && this.resultSection !=0){
   this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+ this.getSectionNameById(this.resultSection)+' [ COMMUNITY ] -'+this.resulltGender, fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
 } );
  }else if(this.resultClass ==0 && this.resultSection ==0){
 this.content.push( {text: '\nSELECTED TYPE :'+'ALL CLASS'+' - '+'ALL SECTION'+' [ COMMUNITY ] -'+this.resulltGender, fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
  } );
}else if(this.resultClass !=0 && this.resultSection ==0){
this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+'ALL SECTION'+' [ COMMUNITY ] -'+this.resulltGender, fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
 } );

}
      this.content.push( {
          text: '\n', fontSize: 8, alignment: 'center'
      } );

      this.body.push( [
          { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Class', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Section', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Mobile No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Gender', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'DOB ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Religion ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Community ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        ] );

      for ( let i = 0; i < this.standardArr.length; i++ ) {
          let temp;
          if ( this.standardArr[i].student.dob != null ) {
              temp = this.standardArr[i].student.dob ;
          } else {
              temp = "-";
          }
          this.body.push( [
              { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.standardArr[i].student.admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.standardArr[i].student.name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
              { text: this.getClassNameByClassId(this.standardArr[i].student.classId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
              { text: this.getSectionNameBySectionId(this.standardArr[i].student.sectionId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
              { text: this.standardArr[i].student.mobileNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.standardArr[i].student.gender, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: temp, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.standardArr[i].student.religion, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.standardArr[i].student.community, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            ] );
      }

      this.content.push( {
          'table': {
              headerRows: 1,
              'body': this.body,
              widths: ['5%', '12%', '21%', '8%', '10%', '9%', '10%', '8%','10%','7%']
          }
      } );
     

      const dd = {
          'content': this.content,
          pageOrientation: 'landscape',
          pageSize: 'A4',
          footer: ( currentPage, pageCount ) => {
              return {
                  margin: 10,
                  columns: [
                      {
                          fontSize: 9,
                          text: [
                              {
                                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                  '\n',
                                  margin: [0, 20]
                              },
                              {
                                  text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                              }
                          ],
                          alignment: 'center'
                      }
                  ]
              };
          }
      };

      pdfMake.createPdf( dd ).open();
      this.body = [];
      this.content = [];

  }
}
export class XlPojo {
    admissionNo: string;
    name: string;
    sectionId: string;
    classId:string;
    mobileNo: string;
    gender: string;
    dob: Date;
    religion:string;
    community:string;
    
}