import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo, AcademicStandardDetailsResultPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { StaffMasterPojo } from 'src/app/pojo/staff-master.pojo';
import { ResultPostingSmsService } from './result-posting.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-result-posting-sms',
  templateUrl: './result-posting-sms.component.html',
  styleUrls: ['./result-posting-sms.component.scss'],
  providers: [AcademicSettingService]
})
export class ResultPostingSmsComponent implements OnInit {

  loggedUser = new MUserMasterPojo();
  standarResultPojo = new AcademicStandardDetailsResultPojo();
  standardArr: AcademicStandardDetailsPojo[] = [];
  responseArr: AcademicStandardDetailsPojo[] = [];
  standard = new AcademicStandardDetailsPojo();
  sectionArr: SectionMasterPojo[] = [];
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  departmentId: number;
  studentMasterArr: StudentMasterPojo[] = [];
  sectionMasterArr: SectionMasterPojo[] = [];
  classid: number;
  sectionid: number;
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';
  classMasterArr: ClassMasterPojo[] = [];
  id: number;
  isLoading: boolean = false;
  promoteFrom;
  promoteTo;
  academic: AcademicYearMasterPojo[] = [];
  staffMasterArr: StaffMasterPojo[] = [];
  updateArr: AcademicStandardDetailsPojo[] = [];
  constructor(private settingService: AcademicSettingService,
    private resultPostingSmsService: ResultPostingSmsService, private studentMasterService: StudentMasterService,
    private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService, private router: Router) { }

  ngOnInit() {

    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

    $('#editAfterClick').hide();
    this.getDepartmentAll();
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.academicSettingAll();
    this.promoteFrom = this.loggedUser.academicYearId - 1;
    this.promoteTo = this.loggedUser.academicYearId;
  }

  academicSettingAll() {
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe(response => {
      this.academic = response;
    }, error => {
      bootbox.alert('Setting Service Error! - HTTP Status ' + error.status);
    });
  }
  getAcademicYrById(id) {
    if (this.academic.find(r => r.academicYearId == id)) {
      return this.academic.find(r => r.academicYearId == id).academicYear;
    } else {
      return id;
    }
  }

  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe(response => {
      this.sectionArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }

  getSectionNameBySectionId(id) {
    if (this.sectionArr.find(response => response.sectionId == id)) {
      return this.sectionArr.find(response => response.sectionId == id).sectionName;
    } else {
      return id;
    }
  }

  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
      this.classArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }

  getClassNameByClassId(id) {
    if (this.classArr.find(response => response.classId == id)) {
      return this.classArr.find(response => response.classId == id).className;
    } else {
      return id;
    }
  }
  getClassMasterByDeptId(id) {
    this.studentMasterService.getClassMasterBydeptId(id, this.loggedUser.accId).subscribe(response => {
      this.classMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getSectionMasterByDeptId(id) {
    this.studentMasterService.getSectionMasterByClassId(id, this.loggedUser.accId).subscribe(response => {
      this.sectionMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe(run => {
      this.departmentArr = run;
    }, error => {
      bootbox.alert({ message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }

  getDepartmentNameBydepartmentId(id) {
    if (this.departmentArr.find(r => r.deptId == id)) {
      return this.departmentArr.find(r => r.deptId == id).deptName;
    } else {
      return id;
    }
  }
  getClassNameById(id) {
    if (this.classMasterArr.find(r => r.classId == id)) {
      return this.classMasterArr.find(r => r.classId == id).className;
    } else {
      return id;
    }
  }
  getSectionNameById(id) {
    if (this.sectionMasterArr.find(r => r.sectionId == id)) {
      return this.sectionMasterArr.find(r => r.sectionId == id).sectionName;
    } else {
      return id;
    }
  }


  getReportById() {
    this.standarResultPojo = new AcademicStandardDetailsResultPojo();
    this.standardArr = [];
    this.studentMasterArr = [];
    this.isLoading = true;
    this.resultPostingSmsService.getResultPostedStudentsById(this.promoteFrom, this.promoteTo, this.classid, this.loggedUser.accId).subscribe(response => {
      this.standarResultPojo = response;
      if (this.standarResultPojo.code == 102) {
        bootbox.alert({ message: 'Result not Posted for this Class!', className: "text-danger h5", closeButton: false });

      } else if (this.standarResultPojo.code == 103) {
        bootbox.alert({ message: 'Promoted Class Not created!', className: "text-danger h5", closeButton: false });

      } else {
        for (let i = 0; i < this.standarResultPojo.alist.length; i++) {
          this.standardArr.push(this.standarResultPojo.alist[i]);
        }
      }
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Report Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
      this.isLoading = false;
    });
  }

  sendSms() {
    bootbox.confirm('Are you sure!\n Do you want to send SMS ?', (result) => {
      if (result) {
        this.isLoading = true;
        this.resultPostingSmsService.sendSmsToResultPostedStudents(this.standardArr, this.loggedUser.accId, this.loggedUser.userId).subscribe(response => {
          if(response == 101){
            bootbox.alert( { message: 'SMS Status for this type is Inactive!Please change the Status to Active..', className: "text-danger h5", closeButton: false } );
            }else{
          this.standardArr=[];
          bootbox.alert({ message: 'Succesfully sent...', className: 'text-success h5', closeButton: false });
             } this.isLoading = false;
        }, error => {
          bootbox.alert({ message: 'Send SMS Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
          this.isLoading = false;
        });
      }
    });

  }

}