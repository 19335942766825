export class DiscountDetailsPojo {

	 discountDetailsId:number;
	 discountTypeId:number;
	 discountType:string;
	 discountAmount:number;
	 receiptHeadId:number;
	 academicYearId:number;
	 studentId:number;
	 name:string;
	 classId:number;	
	 sectionId:number;
	 rollNo:string;
	 admissionNo:string;
	 status:string;
	 feeAllocationId:string;
	
	
}