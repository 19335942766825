import { Component, OnInit } from '@angular/core';
import { ManagementRolePojo, MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LogDetailsPojo } from "src/app/pojo/log-details-pojo";
import { Router } from "@angular/router";
import { AcademicSettingService } from '../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AccountMasterService } from '../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { StaticVariable } from 'src/app/globle.class';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-menubar',
    templateUrl: './menubar.component.html',
    styleUrls: ['./menubar.component.scss'],
    providers:[AcademicSettingService]
} )

export class MenubarComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    logpojo = new LogDetailsPojo();
    schoolName: string;
    schoolPlace: string;
    academic:AcademicYearMasterPojo[] =[];
    accountMasterArr:AccountMasterPojo[]=[];
role = new ManagementRolePojo();
    lastScrollTop: any = 0;
    delta = 15;




    constructor( private router: Router,private settingService:AcademicSettingService,
        private accountmasterserivce:AccountMasterService) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.academicSettingAll();
        this.getAccountMasterAll();
        this.schoolName = StaticVariable.loginTitle;
        this.schoolPlace = StaticVariable.loginPlace;
        this.role = JSON.parse( this.loggedUser.roleKey.roleKey );



    // $(window).scroll((event)=> {
    //     var st = $(window).scrollTop();


    //     if ((st > this.lastScrollTop) && (this.lastScrollTop > 0)) {
    //         // downscroll code
    //         $(".header").css("display", "none");

    //     } else {
    //      // $(".header").css("display", "block");
    //         // upscroll code
    //         // $(".header").css("top", "-100px");
    //     }
    //     this.lastScrollTop = st;
    // });

  //   $("nav .nav-link").on("click", function(){
  //     $("nav").find(".active").removeClass("active");
  //     $(this).addClass("active");
  //  });


    window.onscroll = function() {myFunction()};

    var navbar = document.getElementById("nav1");
    var sticky = navbar.offsetTop;

    function myFunction() {
      if (window.pageYOffset >= sticky-100) {
        navbar.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky");
      }
    }





    $('.navbar-nav>li').on('click', function(){

      if($(this).hasClass('dropdown')){
         $('.dropdown-item').on('click',function(){
          $('.navbar-collapse').collapse('hide');
         })
      }else{
          $('.navbar-collapse').collapse('hide');
      }
    });



    }

    customHide(){
      $('.navbar-collapse').collapse('hide');
    }

    getAccountMasterAll() {
         this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
            this.accountMasterArr = response;
              }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
              }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
      //  parentcorner(){
      // this.router.navigate([`/login`]);
      //  }
       getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
    }
    getAccountTitleById( id ) {
        if ( this.accountMasterArr.find( r => r.accId == id ) ) {
            return this.accountMasterArr.find( r => r.accId == id ).title;
        } else {
            return id;
        }
    }

    logout() {
      bootbox.confirm( 'Do you want to Logout?', ( result ) => {
            if ( result ) {
                this.logpojo.logDescription = this.loggedUser.name + " is log out from the management page ";
                this.logpojo.userId = this.loggedUser.userId;
                this.logpojo.name = this.loggedUser.name;
                this.logpojo.logName = "log out";
                sessionStorage.setItem( 'schoolManagementUserDetail', null );
                this.router.navigate( [``]);
            }
        } );
    }


}
