import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { SmsMasterPojo, SmsTypeMasterPojo } from "src/app/pojo/sms-master.pojo";
import { SmsHistoryPojo } from 'src/app/pojo/sms-history.pojo';
import { SmsAbstractPojo } from 'src/app/pojo/sms-abstract.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class SmsMasterService {

    constructor( private httpclient: HttpClient ) { }

    getSmsMasterAll(accid:number): Observable<SmsMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetSmsMasterAll/'+accid;
        return this.httpclient.get<SmsMasterPojo[]>( url );
    }
 
    addSmsMaster( arg: SmsMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSmsMaster';
        return this.httpclient.post( url, arg );
    }

    updateSms( arg: SmsMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSmsMaster';
        return this.httpclient.put( url, arg );
    }

    deleteSms( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSmsMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }

    getSmsById( smsId: number,accid:number ): Observable<SmsMasterPojo> {
        const url = StaticVariable.porturl + 'GetSmsMasterById/' + smsId+'/'+accid;
        return this.httpclient.get<SmsMasterPojo>( url );  
    }
    getSmsHistoryByDate( from,to,smsid,status,accid:number ): Observable<SmsHistoryPojo[]> {
        const url = StaticVariable.porturl + 'GetSmsHistoryByDateAndIdAndStatus/' + from+'/'+to+'/'+smsid+'/'+status+'/'+accid;
        return this.httpclient.get<SmsHistoryPojo[]>( url );
    }
    getSmsAbstractData( from,to,smsTypeId,accid): Observable<SmsAbstractPojo[]> {
        const url = StaticVariable.porturl + 'GetSmsAbstractByDate/' + from+'/'+to+'/'+smsTypeId+'/'+accid;
        return this.httpclient.get<SmsAbstractPojo[]>( url );
    }
    getSmsTypeAllByAccId(accid:number): Observable<SmsTypeMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetSmsTypeAll/'+accid;
        return this.httpclient.get<SmsTypeMasterPojo[]>( url );
    }
    updateSmsTypeArr( arg: SmsTypeMasterPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSmsType';
        return this.httpclient.put( url, arg );
    }
    addSmsTypeMaster( arg: SmsTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSmsType';
        return this.httpclient.post( url, arg );
    }
    updateSmsTypeMaster( arg: SmsTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSmsTypeContent';
        return this.httpclient.put( url, arg );
    }
    deleteSmsType( id: number, loggedId: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSmsType/' + id + '/' + loggedId;
        return this.httpclient.delete( url );
    }
   
}
