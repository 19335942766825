import { FtpImagePojo } from './ftp-image.pojo';

export class StaffMasterPojo {
    staffId: number = 0;
    staffTypeId: number;
    deptId: number;
    staffName: string;
    staffQualification: string;
    staffSpecialization: string;
    doj: Date;
    dob: Date;
    mobileNo: string;
    emailId: string;
    address: string;

    fileName: string;
    fileAddressPath: string;
    imageId: number;
    mobileNo2: string;
    telephoneNo: string;
    imagePojo = new FtpImagePojo();
    accId:number;
}