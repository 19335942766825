import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { StaffTypeMasterPojo } from "src/app/pojo/staff-type-mster.pojo";

@Injectable()

export class StaffTypeMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllStaffType(): Observable<any> {
        const url = StaticVariable.porturl + 'GetStaffTypeAll';
        return this.httpclient.get<StaffTypeMasterPojo[]>( url );
    }
    
    addStaff( arg: StaffTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddStaff';
        return this.httpclient.post( url, arg );
    }
    
    updateStaff( arg: StaffTypeMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateStaff';
        return this.httpclient.put( url, arg );
    }
    
    deleteStafft( arg: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteStaff/' + arg;
        return this.httpclient.delete( url );
    }
}