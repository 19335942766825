import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AttendanceResponsePojo } from 'src/app/pojo/attendance.pojo';
import { TeacherNotesPojo } from 'src/app/pojo/teacher-notes.pojo';
import { Teachernoterespojo } from 'src/app/pojo/teachernotesreponse.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class NoteEntryService {

    constructor( private httpclient: HttpClient ) { }

    getNotesEntryList(academicYrId,classId,sectionId,date,accid): Observable<Teachernoterespojo> {
        const url = StaticVariable.porturl + 'GetNotesAndStudentByClassId/'+academicYrId+'/'+classId+'/'+sectionId+'/'+date+'/'+accid;
        return this.httpclient.get<Teachernoterespojo>( url );
    }
    
    addNoteLis( arg: Teachernoterespojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddNote';
        return this.httpclient.post( url, arg );
    }
    
    updateNoteList( arg: TeacherNotesPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateNote';
        return this.httpclient.put( url, arg );
    }
    
}