import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'classWisePipe'
} )

export class ClassWiseReportPipe implements PipeTransform {

    transform( array: any[], query: string, query1: string ): any {
       
        if ( query ) {
            if(query1 == 'ALL'){
            if ( _.filter( array, row => row.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            }
            else if ( _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        if(query1 == 'COMPLETE'){
            if ( _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } 
            else {
                return false;
            }
        }
        if(query1 == 'INCOMPLETE'){
            if ( _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.formEntryStatus.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } 
            else {
                return false;
            }
        }
        if(query1 == 'ADMIISSIONNO'){
            if ( _.filter( array, row => row.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } 
            else {
                return false;
            }
        }
        if(query1 == 'MOBILENO'){
            if ( _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            }
            else {
                return false;
            }
        }
    }
        return array;
    }

}
