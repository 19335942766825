import { DiscountDetailsPojo } from './discount-detail.pojo';

export class DiscountDetailsResponsePojo {

    discountTypeId:number;
	 academicYearId:number;
    classId:number;	
	code:number;
	 response:string;
    detList:DiscountDetailsPojo[]=[];;
}