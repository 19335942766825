import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { QuerieResPojo } from 'src/app/pojo/querierespojo';
import { QueriePojo } from 'src/app/pojo/queriepojo';


@Injectable( {
    providedIn: 'root'
} )

export class QuerieService {

    constructor( private httpclient: HttpClient ) { }

    getQuerieList(academicYrId,classId,sectionId,sdate,edate,accid): Observable<QuerieResPojo> {
        const url = StaticVariable.porturl + 'GetQuerieList/'+academicYrId+'/'+classId+'/'+sectionId+'/'+sdate+'/'+edate+'/'+accid;
        return this.httpclient.get<QuerieResPojo>( url );
    }
    
    // addNoteLis( arg: Teachernoterespojo ): Observable<any> {
    //     const url = StaticVariable.porturl + 'AddNote';
    //     return this.httpclient.post( url, arg );
    // }
    
    // getimageDetails(id):Observable<ImageDataPojo>{
    //     const url=StaticVariable.porturl + 'GetFtpImageById/'+id;
    //     return this.httpclient.get<ImageDataPojo>( url );
    // }


    update( arg: QueriePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateQuerie';
        return this.httpclient.put( url, arg );
    }
    
}