
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { HolidayListMasterService } from './holiday-list.service';
import { HolidayListPojo } from 'src/app/pojo/holiday-list.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['./holiday-list.component.scss']
})
export class HolidayListComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
    
    addFromDate: NgbDateStruct;
    addToDate: NgbDateStruct;
    editFromDate: NgbDateStruct;
    editToDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

    
    holidayArr: HolidayListPojo[] = [];
    addHoliday = new HolidayListPojo();
    editHoliday = new HolidayListPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private holidaymasterserivce: HolidayListMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getHolidayAll();
    }

    getHolidayAll() {
        this.isLoading = true;
        this.holidaymasterserivce.getAllHolidayListMaster(this.loggedUser.accId).subscribe( response => {
            this.holidayArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Holiday All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addHoliday.holidayListFrom = moment( this.addFromDate.year + '-' + this.addFromDate.month + '-' + this.addFromDate.day, 'YYYY-MM-DD' ).toDate();
        this.addHoliday.holidayListTo = moment( this.addToDate.year + '-' + this.addToDate.month + '-' + this.addToDate.day, 'YYYY-MM-DD' ).toDate();
         this.addHoliday.accId=this.loggedUser.accId;
        this.holidaymasterserivce.addHolidayListMaster( this.addHoliday ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getHolidayAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editHoliday = Object.assign( {}, arg );
        if(this.editHoliday.holidayListFrom != null && this.editHoliday.holidayListFrom != undefined){
            ( <HTMLFormElement>document.getElementById( 'editfromDate' ) ).value = this.editHoliday.holidayListFrom;
            }
            if(this.editHoliday.holidayListTo != null && this.editHoliday.holidayListTo != undefined){
                ( <HTMLFormElement>document.getElementById( 'edittoDate' ) ).value = this.editHoliday.holidayListTo;
                }
    }  

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editHoliday.holidayListFrom = moment(( <HTMLInputElement>document.getElementById( 'editfromDate' ) ).value).toDate();
        this.editHoliday.holidayListTo = moment(( <HTMLInputElement>document.getElementById( 'edittoDate' ) ).value).toDate();
        //this.editHoliday.holidayListFrom = moment( this.editFromDate.year + '-' + this.editFromDate.month + '-' + this.editFromDate.day, 'YYYY-MM-DD' ).toDate();
        //this.editHoliday.holidayListTo = moment( this.editToDate.year + '-' + this.editToDate.month + '-' + this.editToDate.day, 'YYYY-MM-DD' ).toDate();
          this.editHoliday.accId=this.loggedUser.accId;
        this.holidaymasterserivce.updateHolidayListMaster( this.editHoliday ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getHolidayAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.holidayArr.indexOf( this.holidayArr.find( r => r.holidayListId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.holidaymasterserivce.deleteHolidayListMaster( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.holidayArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.holidayArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getHolidayAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
