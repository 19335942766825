export class BusFeeRefundPojo{
     busRefundId:number;
	 academicYearId:number;
	 accId:number;
	 classId:number;
	 sectionId:number;
	 studentId:number;
	 admissionNo:string;
	 termId:number;
	 standardTermId:number;
	 receiptId:number;
	 feeAllocationId:number;
	 areaId:number;
	 busAmount:number;
	 refundAmount:number;
	 refundDate:Date;
	 paidLedgerId:number;
}