import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'ClassMasterPipe'
} )

export class ClassMasterPipe implements PipeTransform {

    transform( array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.noOfStudents == query ) != '' ) {
                return _.filter( array, row => row.noOfStudents == query );
            } else if ( _.filter( array, row => row.className.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.className.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.classNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.classNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}

@Pipe( {
    name: 'ClassMasterIdPipe'
} )

export class ClassMasterIdPipe implements PipeTransform {

    transform( array: any[], query: any): any {
        if ( query ) {
            if ( _.filter( array, row => row.deptId == query ) != '' ) {
                return _.filter( array, row => row.deptId == query );
            }    
            }
        return [];
    }

}
