import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'LedgerStmntPipe'
} )

export class LedgerStatementrPipe implements PipeTransform {

    transform( array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.BALANCE == query ) != '' ) {
                return _.filter( array, row => row.BALANCE == query );
            } else if ( _.filter( array, row => row.DET.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.DET.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return [];
            }
        }
        return array;
    }

}
