import { UserSettingsPipe } from './management/Admin/userSettings/userSettings.pipe';
import { UserSettingsComponent } from './management/Admin/userSettings/userSettings.component';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { NgbModule, NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { DataTableModule } from "angular-6-datatable-cc";
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { WebcamModule } from "ngx-webcam";

import { AppComponent } from './app.component';
import { MenubarComponent } from "src/app/management/menubar/menubar.component";
import { StudentRegisterComponent } from "src/app/management/student-register/student-register.component";
import { ClassMasterComponent } from "src/app/management/Masters/class-master/class-master.component";
import { RoleMasterComponent } from "src/app/management/Admin/role-master/role-master.component";
import { SmsCreationComponent } from "src/app/management/SMS/sms-creation/sms-creation.component";
import { DepartmentMasterComponent } from "src/app/management/Masters/department-master/department-master.component";
import { SectionMasterComponent } from "src/app/management/Masters/section-master/section-master.component";
import { StaffMasterComponent } from "src/app/management/Masters/staff-master/staff-master.component";
import { StaffTypeMasterComponent } from "src/app/management/Masters/staff-type-master/staff-type-master.component";
import { StudentMasterComponent } from "src/app/management/Masters/student-master/student-master.component";
import { ChangePasswordComponent } from "src/app/management/Admin/change-password/change-password.component";
import { UserCreationComponent } from "src/app/management/Admin/user-creation/user-creation.component";
import { CurriculumMasterComponent } from "src/app/management/Masters/curriculum-master/curriculum-master.component";
import { RoleMasterPipe } from "src/app/management/Admin/role-master/role-master.pipe";
import { UserCreationPipe } from "src/app/management/Admin/user-creation/user-creation.pipe";
import { LoginComponent } from "src/app/management/login/login.component";
import { StaffMasterPipe } from "src/app/management/Masters/staff-master/staff-master.pipe";
import { DepartmentMasterPipe } from "src/app/management/Masters/department-master/department-master.pipe";
import { ClassMasterPipe, ClassMasterIdPipe } from "src/app/management/Masters/class-master/class-master.pipe";
import { SectionMasterPipe, SectionMasterIdPipe } from "src/app/management/Masters/section-master/section-master.pipe";
import { StudentMasterPipe } from "src/app/management/Masters/student-master/student-master.pipe";
import { EditStudentMasterComponent } from "src/app/management/Masters/student-master/edit-student-master/edit-student-master.component";
import { ManagementAuthenticationGuard } from "src/app/management/Authentication/management-authentication-guard";
import { SmsMasterComponent } from "src/app/management/SMS/sms-master/sms-master.component";
import { EditSmsMasterComponent } from "src/app/management/SMS/edit-sms-master/edit-sms-master.component";
import { SmsMasterPipe } from "src/app/management/SMS/sms-master/sms-master.pipe";
import { SmsTemplateComponent } from "src/app/management/SMS/sms-template/sms-template.component";
import { SmsTemplatePipe } from "src/app/management/SMS/sms-template/sms-template.pipe";
import { SelectedUserPipe } from "src/app/management/SMS/sms-creation/sms-creation.pipe";
import { SmsHistoryComponent } from "src/app/management/SMS/sms-history/sms-history.component";
import { SmsHistoryPipe, SmsAbstractPipe } from "src/app/management/SMS/sms-history/sms-history.pipe";
import { JournalEntryComponent } from "src/app/management/Accounts/journal-entry/journal-entry.component";
import { ReceiptEntryComponent } from "src/app/management/Accounts/receipt-entry/receipt-entry.component";
import { ReceiptHeadComponent } from "src/app/management/Accounts/receipt-head/receipt-head.component";
import { JournalEntryPipe } from "src/app/management/Accounts/journal-entry/journalEntry.pipe";
import { LedgercreationComponent } from "src/app/management/Masters/ledgercreation/ledgercreation.component";
import { AccountMasterComponent } from "src/app/management/Admin/account-master/account-master.component";
import { LedgercreationPipe } from "src/app/management/Masters/ledgercreation/ledgercreation.pipe";
import {HttpModule} from '@angular/http';
import { PaymentComponent } from "src/app/management/Accounts/payment/payment.component";
import { PaymentEntryPipe } from "src/app/management/Accounts/payment/payment-entry.pipe";
import { SubjectMasterComponent } from "src/app/management/Masters/subject-master/subject-master.component";
import { SubjectMasterPipe, SubjectMasterIdPipe } from './management/Masters/subject-master/subject-master.pipe';
import { ReceiptEntryPipe } from './management/Accounts/receipt-entry/receipt-entry.pipe';
import { ReceiptHeadPipe } from './management/Accounts/receipt-head/receipt-head.pipe';
import { ReceiptDetailsComponent } from './management/Accounts/receipt-details/receipt-details.component';
import { AcademicSettingComponent } from './management/Admin/academic-setting/academic-setting.component';
import { QuickAdmissionComponent } from './management/quick-admission/quick-admission.component';
import { GenderWiseComponent } from './management/Reports/gender-wise/gender-wise.component';
import { ClassWiseComponent } from './management/Reports/class-wise/class-wise.component';
import { CommunityWiseComponent } from './management/Reports/community-wise/community-wise.component';
import { ClassWiseReportPipe } from './management/Reports/class-wise/class-wise-report.pipe';
import { PhysicallyDisabledComponent } from './management/Reports/physically-disabled/physically-disabled.component';
import { StrengthComponent } from './management/Reports/strength/strength.component';
import { PrintStudentMasterComponent } from './management/Masters/student-master/print-student-master/print-student-master.component';
import { CommunityMasterComponent } from './management/Masters/community-master/community-master.component';
import { CasteMasterComponent } from './management/Masters/caste-master/caste-master.component';
import { PromotionComponent } from './management/Promotion/promotion/promotion.component';
import { SectionAllocationComponent } from './management/Promotion/section-allocation/section-allocation.component';
import { PromotionPipe } from './management/Promotion/promotion/promotion.pipe';
import { FeeCollectionComponent } from './management/Fees/fee-collection/fee-collection.component';
import { FeeAllocationComponent } from './management/Fees/fee-allocation/fee-allocation.component';
import { FeeAllocationPipe } from './management/Fees/fee-allocation/fee-allocation.pipe';
import { StandardStructureComponent } from './management/Masters/standard-structure/standard-structure.component';
import { StandardStructureMasterPipe, StandardMasterIdPipe } from './management/Masters/standard-structure/standard-structure.pipe';
import { TermComponent } from './management/Masters/term/term.component';
import { PaymentEntryComponent } from './management/Entry/payment-entry/payment-entry.component';
import { JournalComponent } from './management/Entry/journal-entry/journal-entry.component';
import { ReceiptComponent } from './management/Entry/receipt-entry/receipt-entry.component';
import { LedgerStatementComponent } from './management/Reports/ledger-statement/ledger-statement.component';
import { LedgerStatementrPipe } from './management/Reports/ledger-statement/ledger-statement.pipe';
import { StudentDetChildComponent } from './management/Fees/student-det-child/student-det-child.component';
import { ExamMasterComponent } from './management/Masters/exam-master/exam-master.component';
import { ExamMasterPipe } from './management/Masters/exam-master/exam-master.pipe';
import { HolidayListComponent } from './management/Masters/holiday-list/holiday-list.component';
import { HoursMasterComponent } from './management/Masters/hours-master/hours-master.component';
import { HoursMasterPipe } from './management/Masters/hours-master/hours.pipe';
import { HolidayMasterPipe } from './management/Masters/holiday-list/holiday-list.pipe';
import { ArrearsReportComponent } from './management/Reports/arrears-report/arrears-report.component';
import { AttendanceEntryComponent } from './management/Attendance/attendance-entry/attendance-entry.component';
import { AttendanceEntryPipe } from './management/Attendance/attendance-entry/attendance-entry.pipe';
import { DaywiseAttendanceEntryComponent } from './management/Reports/daywise-attendance-entry/daywise-attendance-entry.component';
import { NotesMasterComponent } from './management/Masters/notes-master/notes-master.component';
import { StandardTermWiseComponent } from './management/Reports/standard-term-wise/standard-term-wise.component';
import { DiscountMasterComponent } from './management/Masters/discount-master/discount-master.component';
import { DiscountFixingMasterPipe } from './management/Masters/discount-master/discount-master.pipe';
import { DiscountFixingComponent } from './management/Fees/discount-fixing/discount-fixing.component';
import { DicountTypeMasterComponent } from './management/Masters/dicount-type-master/dicount-type-master.component';
import { AreaMasterComponent } from './management/Masters/area-master/area-master.component';
import { ExamScheduleComponent } from './management/Masters/exam-schedule/exam-schedule.component';
import { StudentMarkListComponent } from './management/Reports/student-mark-list/student-mark-list.component';
import { ExamScheduleIdPipe } from './management/Masters/exam-schedule/exam-schedule.pipe';
import { StudentMarkListPipe } from './management/Reports/student-mark-list/student-mark-list.pipe';
import { StudExamReportComponent } from './management/Reports/stud-exam-report/stud-exam-report.component';
import { CreateSmsTemplateComponent } from './management/SMS/create-sms-template/create-sms-template.component';
import { BusFeeAllocationComponent } from './management/Fees/bus-fee-allocation/bus-fee-allocation.component';
import { TcGenerationComponent } from './management/Reports/tc-generation/tc-generation.component';
import { TcGenerationReportComponent } from './management/Reports/tc-generation-report/tc-generation-report.component';
import { TcGenerationReportPipe } from './management/Reports/tc-generation-report/tc-generation-report.pipe';
import { ExamMarkSheetComponent } from './management/Reports/exam-mark-sheet/exam-mark-sheet.component';
import { RefundComponent } from './management/Fees/refund/refund.component';
import { RefundFormComponent } from './management/Fees/refund/refund-form/refund-form.component';
import { OverallAccountsReportComponent } from './management/Accounts/overall-accounts-report/overall-accounts-report.component';
import { FeecollectionDetailsReportComponent } from './management/Fees/feecollection-details-report/feecollection-details-report.component';
import { ReundReportComponent } from './management/Fees/reund-report/reund-report.component';
import { SmsSettingsComponent } from './management/Admin/sms-settings/sms-settings.component';
import { SmsTypeContentSettingsComponent } from './management/SMS/sms-type-content-settings/sms-type-content-settings.component';
import { AbsentListComponent } from './management/SMS/absent-list/absent-list.component';
import { SetFeeAllocationSmsComponent } from './management/SMS/set-fee-allocation-sms/set-fee-allocation-sms.component';
import { BusStudentsReportComponent } from './management/Fees/bus-students-report/bus-students-report.component';
import { TReprintComponent } from './management/Reports/t-reprint/t-reprint.component';
import { AttendanceGenerationComponent } from './management/Certificates/attendance-generation/attendance-generation.component';
import { BonafideGenerationComponent } from './management/Certificates/bonafide-generation/bonafide-generation.component';
import { ConductGenerationComponent } from './management/Certificates/conduct-generation/conduct-generation.component';
import { CourseGenerationComponent } from './management/Certificates/course-generation/course-generation.component';
import { GenuineGenerationComponent } from './management/Certificates/genuine-generation/genuine-generation.component';
import { AttendanceReportComponent } from './management/Certificates/attendance-report/attendance-report.component';
import { BonafideReportComponent } from './management/Certificates/bonafide-report/bonafide-report.component';
import { ConductReportComponent } from './management/Certificates/conduct-report/conduct-report.component';
import { CourseReportComponent } from './management/Certificates/course-report/course-report.component';
import { GenuineReportComponent } from './management/Certificates/genuine-report/genuine-report.component';
import { CertificateReprintComponent } from './management/Certificates/certificate-reprint/certificate-reprint.component';
import { CashBookReportComponent } from './management/Accounts/cash-book-report/cash-book-report.component';
import { ResultPostingSmsComponent } from './management/SMS/result-posting-sms/result-posting-sms.component';
import { IncomeExpenditureReportComponent } from './management/Accounts/income-expenditure-report/income-expenditure-report.component';
import { TrialBalanceComponent } from './management/Accounts/trial-balance/trial-balance.component';
import { NotesEntryComponent } from './management/Entry/StudentNotesEntry/notes-entry/notes-entry.component';
import { NotesEntryPipe } from './management/Entry/StudentNotesEntry/notes-entry/notes-entry.pipe';
import { LandingpageComponent } from './management/login/landingpage/landingpage.component';
import { EventComponent } from './management/Entry/event/event.component';
import { LeaveApplicationComponent } from './management/Attendance/leave-application/leave-application.component';
import { StudentComponent } from './management/Attendance/Student/Student.component';
@NgModule( {
    declarations: [
        AppComponent,
        MenubarComponent,
        StudentRegisterComponent,
        ClassMasterComponent,
        RoleMasterComponent,
        SmsCreationComponent,
        DepartmentMasterComponent,
        SectionMasterComponent,
        StaffMasterComponent,
        StaffTypeMasterComponent,
        StudentMasterComponent,
        ChangePasswordComponent,
        UserCreationComponent,
        CurriculumMasterComponent,
        RoleMasterPipe,
        UserCreationPipe,
        LoginComponent,
        StaffMasterPipe,
        DepartmentMasterPipe,
        ClassMasterPipe,
        ClassMasterIdPipe,
        SectionMasterPipe,
        SectionMasterIdPipe,
        StudentMasterPipe,
        EditStudentMasterComponent,
        SmsMasterComponent,
        EditSmsMasterComponent,
        SmsMasterPipe,
        SmsTemplateComponent,
        SmsTemplatePipe,
        SelectedUserPipe,
        SmsHistoryComponent,
        SmsHistoryPipe,
        JournalEntryComponent,
        JournalEntryPipe,
        LedgercreationComponent,
        AccountMasterComponent,
        LedgercreationPipe,
        PaymentComponent,
        ReceiptHeadComponent,
        ReceiptEntryComponent,
        PaymentEntryPipe,
        SubjectMasterComponent,
        SubjectMasterPipe,
        ReceiptEntryPipe,
        ReceiptHeadPipe,
        ReceiptDetailsComponent,
        AcademicSettingComponent,
        QuickAdmissionComponent,
        GenderWiseComponent,
        ClassWiseComponent,
        CommunityWiseComponent,
        ClassWiseReportPipe,
        PhysicallyDisabledComponent,
        StrengthComponent,
        PrintStudentMasterComponent,
        CommunityMasterComponent,
        CasteMasterComponent,
        PromotionComponent,
        SectionAllocationComponent,
        PromotionPipe,
        FeeCollectionComponent,
        FeeAllocationComponent,
        TermComponent,
        StandardStructureComponent,
        StandardStructureMasterPipe,
        FeeAllocationPipe,
        StandardMasterIdPipe,
        JournalComponent,
        PaymentEntryComponent,
        ReceiptComponent,
        LedgerStatementComponent,
        LedgerStatementrPipe,
        StudentDetChildComponent,
        ExamMasterComponent,
        ExamMasterPipe,
        HolidayListComponent,
        HoursMasterComponent,
        HoursMasterPipe,
        HolidayMasterPipe,
        ArrearsReportComponent,
        AttendanceEntryComponent,
        NotesEntryComponent,
        AttendanceEntryPipe,
        DaywiseAttendanceEntryComponent,
        NotesMasterComponent,
        StandardTermWiseComponent,
        DiscountMasterComponent,
        DiscountFixingMasterPipe,
        DiscountFixingComponent,
        DicountTypeMasterComponent,
        AreaMasterComponent,
        ExamScheduleComponent,
        StudentMarkListComponent,
        SubjectMasterIdPipe,
        ExamScheduleIdPipe,
        StudentMarkListPipe,
        StudExamReportComponent,
        CreateSmsTemplateComponent,
        BusFeeAllocationComponent,
        SmsAbstractPipe,
        TcGenerationComponent,
        TcGenerationReportComponent,
        TcGenerationReportPipe,
        ExamMarkSheetComponent,
        RefundComponent,
        RefundFormComponent,
        OverallAccountsReportComponent,
        FeecollectionDetailsReportComponent,
        ReundReportComponent,
        SmsSettingsComponent,
        SmsTypeContentSettingsComponent,
        AbsentListComponent,
        SetFeeAllocationSmsComponent,
        BusStudentsReportComponent,
        TReprintComponent,
        AttendanceGenerationComponent,
        BonafideGenerationComponent,
        ConductGenerationComponent,
        CourseGenerationComponent,
        GenuineGenerationComponent,
        AttendanceReportComponent,
        BonafideReportComponent,
        ConductReportComponent,
        CourseReportComponent,
        GenuineReportComponent,
        CertificateReprintComponent,
        CashBookReportComponent,
        ResultPostingSmsComponent,
        IncomeExpenditureReportComponent,
        TrialBalanceComponent,
        LandingpageComponent,
        UserSettingsComponent,
        UserSettingsPipe,
        NotesEntryPipe,
        EventComponent,
        LeaveApplicationComponent,
        StudentComponent,

    ],

    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        NgbPaginationModule,
        NgbAlertModule,
        DataTableModule,
        AngularFontAwesomeModule,
        WebcamModule,

    ],
    providers: [
        ManagementAuthenticationGuard
    ],
    bootstrap: [
        AppComponent
    ]
} )

export class AppModule { }

