
import { Component, OnInit } from '@angular/core';
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { DiscountFixingService } from './discount-master.service';
import { DiscountFixingPojo } from 'src/app/pojo/discount-fixing.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-discount-master',
  templateUrl: './discount-master.component.html',
  styleUrls: ['./discount-master.component.scss']
})

export class DiscountMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    addDisc = new DiscountFixingPojo();
    editDisc = new DiscountFixingPojo();
    receiptHeadArr:ReceiptHeadPojo[] = [];
    discountArr :DiscountFixingPojo[]=[];
      public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
     constructor( private receiptheadService:ReceiptHeadService,private discountFixingService: DiscountFixingService,) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        this.getAllReceiptHead();
        this.getAllDiscountFixing();
          }
         
        getAllReceiptHead() {
           this.receiptheadService.getReceiptHeadAll(this.loggedUser.accId).subscribe( rs => {
              this.receiptHeadArr = rs;
               }, error => {
              bootbox.alert( 'Error' + error );
               } );
      }
      getReceiptHeadNameById( id ) {
        if ( this.receiptHeadArr.find( r => r.receiptHeadId == id ) ) {
              return this.receiptHeadArr.find( r => r.receiptHeadId == id ).receiptHeadName;
          }
          else {
              return id;
          }
      }
      getAllDiscountFixing() {
        this.discountArr=[];
        this.discountFixingService.getDiscountFixingAll(this.loggedUser.accId).subscribe( rs => {
           this.discountArr = rs;
           this.isLoading = false;
            }, error => {
           bootbox.alert( 'Error' + error );
           this.isLoading = false;
            } );
   }
     
    addClass() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addDisc.accId=this.loggedUser.accId;
        this.discountFixingService.addDiscountFixing( this.addDisc ).subscribe( response => {
              bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
                ( <HTMLFormElement>document.getElementById( 'addReceiptHeadForm' ) ).reset();
                $( '#addModal' ).modal( 'hide' );
                this.getAllDiscountFixing();
             ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
           
        }, error => {
            bootbox.alert( { message: 'Add Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editDisc = Object.assign( {}, arg );
    }

    editClass() {
         ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editDisc.accId=this.loggedUser.accId;
        this.discountFixingService.updateDiscountFixing( this.editDisc ).subscribe( response => {
                 bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
                $( '#editModal' ).modal( 'hide' );
                this.getAllDiscountFixing();
               ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
           
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.discountArr.indexOf( this.discountArr.find( r => r.discountFixingId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.discountFixingService.deleteDiscountFixing( this.id,this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.discountArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.discountArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getAllDiscountFixing();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
