export class LedgerPojo {

  ledgerId: number;
  ledgerCode: number;
  ledgerName: string;
  ledgerNameTamil: string;
  accountId: number;
  companyId: number;
  debit: number;
  credit: number;
  address: string;
  place: string;
  placeTamil: string;
  phoneNo: number;
  mobileNo: number;
  gstNo: number;
  state: string;
  userId: number;
  taxType: string;
  categoryId: number;
  creditLimit: number;
  creditDays: number;
  accountType: string;
  amount: number;
  name: string;
  accGroupId: number;
  accId: number;
  ledgerGroupId: number;
}
