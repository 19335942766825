export class IncomeExpenditureReportPojo {

	ledgerGroupId:number;
	ledgerGroupName:string;
	 expenditureTotal:number;
	 incomeTotal:number;
    det:IncomeExpenditureDetailsReportPojo[]=[];
}
export class IncomeExpenditureDetailsReportPojo {

	 narration:string;
	 income:number;
	 expenditure:number;
	 ledgerGroupId:number;
}
export class ConsolidationReportPojo {

	ielist:IncomeExpenditureReportPojo[]=[];
	 openingBal:number;
	 closingBal:number;
	 creditTotal:number;
	 debitTotal:number;
}