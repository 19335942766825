export class SubjectMasterPojo {
    subjectId: number;
    subjectCode:string;
    subjectName:string;
    curriculumId: number = 0;
    mediumId: number = 0;
    classId: number = 0;
    minimumMark:number;
    maximumMark:number;
    accId:number;
    userId:number;
}