import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { DiscountDetailsPojo } from 'src/app/pojo/discount-detail.pojo';
import { DiscountDetailsResponsePojo } from 'src/app/pojo/discount-detail-response.pojo';
import { FeeCollectionReportDetailsPojo } from 'src/app/pojo/fee-collection-report-detail.pojo';
import { BusFeeRefundPojo } from 'src/app/pojo/bus-fee-refund.pojo';
import { FeeCollectionReportPojo } from 'src/app/pojo/fee-collection-report.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class FeeAllocationMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllFeeAllocation(cid): Observable<any> {
        const url = StaticVariable.porturl + 'GetFeeAllocationAll/'+cid;
        return this.httpclient.get<FeeAllocationPojo[]>( url );
    }
    
    addFeeAllocation( arg: FeeAllocationPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'AddFeeAllocation';
        return this.httpclient.post( url, arg );
    }
    
    updateFeeAllocation( arg: FeeAllocationPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateFeeAllocation';
        return this.httpclient.put( url, arg );
    }
    
    deleteFeeAllocation( id: number, loggedId: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteFeeAllocation/' + id + '/' + loggedId;
        return this.httpclient.delete( url );
    }
    getAllFeeAllocationDetailsById(aid,deptId,classId,sectionId,accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetFeeAllocationByDeptIdAndClassIdAndSectionId/'+aid+'/'+deptId+'/'+classId+'/'+sectionId+'/'+accid;
        return this.httpclient.get<FeeAllocationPojo[]>( url );
    }
    getTempFeeAllocationDetailsById(aid,classId,standardTermId,accid): Observable<FeeAllocationReportPojo> {
        const url = StaticVariable.porturl + 'GetTempFeeAllocationByYearIdAndClassIdAndStandardTermId/'+aid+'/'+classId+'/'+standardTermId+'/'+accid;
        return this.httpclient.get<FeeAllocationReportPojo>( url );
    }
    getTempBusFeeAllocationDetailsById(aid,classId,standardTermId,accid): Observable<FeeAllocationReportPojo> {
        const url = StaticVariable.porturl + 'GetTempBusFeeAllocationByYearIdAndClassIdAndStandardTermId/'+aid+'/'+classId+'/'+standardTermId+'/'+accid;
        return this.httpclient.get<FeeAllocationReportPojo>( url );
    }
    getBusFeeCollectionDetailsById(aid,dept,classId,standardTermId,accid): Observable<FeeCollectionReportDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetBusFeeCollectionByYearIdClassIdAndStandardTermId/'+aid+'/'+dept+'/'+classId+'/'+standardTermId+'/'+accid;
        return this.httpclient.get<FeeCollectionReportDetailsPojo[]>( url );
    }
    getBusFeeRefundReportById(aid,classId,standardTermId,accid,from,to): Observable<BusFeeRefundPojo[]> {
        const url = StaticVariable.porturl + 'GetBusRefundByYearIdClassIdAndStandardTermId/'+aid+'/'+classId+'/'+standardTermId+'/'+accid+'/'+from+'/'+to;
        return this.httpclient.get<BusFeeRefundPojo[]>( url );
    }
    getBusFeeCollectionDetailsByIdAndAdmissionNo(aid,admissionNo,accid): Observable<FeeCollectionReportPojo[]> {
        const url = StaticVariable.porturl + 'GetFeeCollectionByTermWiseAndAdmissionNo/'+aid+'/'+admissionNo+'/'+accid;
        return this.httpclient.get<FeeCollectionReportPojo[]>( url );
    }
     
    getFeeAllocationByTermWise(aid,sid,accid): Observable<FeeAllocationReportByTermWisePojo[]> {
        const url = StaticVariable.porturl + 'GetFeeAllocationByTermWise/'+aid+'/'+sid+'/'+accid;
        return this.httpclient.get<FeeAllocationReportByTermWisePojo[]>( url );
    }
    getAcademicdetByYrIdandAdmissioNo(aid,sid,accid): Observable<FeeAllocationReportByTermWisePojo[]> {
        const url = StaticVariable.porturl + 'GetFeeAllocationByTermWiseAndAdmissionNo/'+aid+'/'+sid+'/'+accid;
        return this.httpclient.get<FeeAllocationReportByTermWisePojo[]>( url );
    }
    getFeeAllocationDetIdandAdmissioNo(aid,sid,accid): Observable<FeeAllocationReportByTermWisePojo[]> {
        const url = StaticVariable.porturl + 'GetFeeAllocationTermDetailsByAdmissionNo/'+aid+'/'+sid+'/'+accid;
        return this.httpclient.get<FeeAllocationReportByTermWisePojo[]>( url );
    }
    getStandardTermByClassidAndTermId(cid,tid,accid): Observable<StandardTermPojo[]> {
        const url = StaticVariable.porturl + 'GetStandardTermByClassIdAndTermId/'+cid+'/'+tid+'/'+accid;
        return this.httpclient.get<StandardTermPojo[]>( url );
    }
    getNonBusStandardTermByClassidAndTermId(cid,tid,accid): Observable<StandardTermPojo[]> {
        const url = StaticVariable.porturl + 'GetNonBusStandardTermByClassIdAndTermId/'+cid+'/'+tid+'/'+accid;
        return this.httpclient.get<StandardTermPojo[]>( url );
    }
    getBusStandardTermByClassidAndTermId(cid,tid,accid): Observable<StandardTermPojo[]> {
        const url = StaticVariable.porturl + 'GetBusStandardTermByClassIdAndTermId/'+cid+'/'+tid+'/'+accid;
        return this.httpclient.get<StandardTermPojo[]>( url );
    }
    getAcademicDetById(yrId,cid,tid,accid): Observable<DiscountDetailsResponsePojo> {
        const url = StaticVariable.porturl + 'GetStandardAcademicDetailsByYearIdAndClassIdAndDiscountTypeId/'+yrId+'/'+cid+'/'+tid+'/'+accid;
        return this.httpclient.get<DiscountDetailsResponsePojo>( url );
    }
    
    
}