
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import * as moment from 'moment';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { CertificateAllService } from '../certificate.service';
import { AttendanceCertificatePojo, BonafideCertificatePojo, ConductCertificatePojo, CourseCertificatePojo, GenuineCertificatePojo } from 'src/app/pojo/certificate.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component( {
  selector: 'app-genuine-report',
  templateUrl: './genuine-report.component.html',
  styleUrls: ['./genuine-report.component.scss'],
 providers:[AcademicSettingService]
} )

export class GenuineReportComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
   
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    startDate;
    endDate;
    resultStartDate;
    resultEndDate;
    id: number;
    isLoading: boolean = false;

    certificateArr:GenuineCertificatePojo[]=[];

    body = [];
    content = [];
    documentTime:string;
    accountMasterArr:AccountMasterPojo[]=[];
     
    constructor(private classMasterService: ClassMasterService, private router: Router,
        private accountMasterService:AccountMasterService,private certificateService:CertificateAllService,private sectionMasterService:SectionMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.getClassMasterAll();
        this.getSectionMasterAll();
          $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true,
        
     locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
        ( start, end, label ) => {
            this.startDate = moment(start).format('YYYY-MM-DD');
            this.endDate = moment(end).format('YYYY-MM-DD');
             
             }
    );this.accountMasterAll();
      }
      accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }

    getSectionMasterAll() {
      this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
          this.sectionArr = response;
          }, error => {
          bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  

  getSectionNameBySectionId( id ) {
      if ( this.sectionArr.find( response => response.sectionId == id ) ) {
          return this.sectionArr.find( response => response.sectionId == id ).sectionName;
      } else {
          return id;
      }
  }
    getReport() {
        if(this.startDate == undefined && this.endDate == undefined){
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
                      
           }
           this.certificateArr =[];
         this.isLoading = true;
        this.certificateService.getGenuineCertificateBydate( this.startDate,this.endDate ).subscribe( response => {
            this.certificateArr = response;
            this.resultStartDate=this.startDate;
            this.resultEndDate=this.endDate;
             this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Genuine By Date Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
     }
  

    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
         this.content.push( {
             text: 'GENUINE  CERTIFICATE REPORT\t', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );
   
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
        
         this.content.push( {text: 'REPORT DATE FROM : '+moment(this.resultStartDate,'YYYY-MM-DD+').format('DD-MM-YYYY')+' TO '+moment(this.resultEndDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
       this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Issue Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Certificate No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Father Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Studied Class', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Religion And Caste', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                ] );
   
         for ( let i = 0; i < this.certificateArr.length; i++ ) {
               this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: moment(this.certificateArr[i].certificateDate,'YYYY-MM-DD').format('DD/MM/YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.certificateArr[i].genuineCertificateNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.certificateArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.certificateArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text:  this.certificateArr[i].fatherName, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text: this.getClassNameByClassId(this.certificateArr[i].classId)+' - '+this.getSectionNameBySectionId(this.certificateArr[i].sectionId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.certificateArr[i].religion+' - '+this.certificateArr[i].caste, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                 ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '9%', '12%', '12%', '17%', '20%', '10%', '15%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
}
