import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { SmsTemplatePojo } from "src/app/pojo/sms-template.pojo";
import { SmsMasterPojo } from "src/app/pojo/sms-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class SmsTemplateService {

    constructor( private httpclient: HttpClient ) { }

    getSmsTemplateAll(accid:number): Observable<SmsTemplatePojo[]> {
        const url = StaticVariable.porturl + 'GetSmsTemplateMasterAll/'+accid;
        return this.httpclient.get<SmsTemplatePojo[]>( url );
    }

    addSmsTemplateMaster( arg: SmsTemplatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSmsTemplateMaster';
        return this.httpclient.post( url, arg );
    }

    updateSmsTemplate( arg: SmsTemplatePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSmsTemplateMaster';
        return this.httpclient.put( url, arg );
    }

    deleteSmsTemplate( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSmsTemplateMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }

    getSmsTemplateById( smsId: number,accid:number ): Observable<SmsMasterPojo> {
        const url = StaticVariable.porturl + 'GetSmsTemplateMasterById/' + smsId+'/'+accid;
        return this.httpclient.get<SmsMasterPojo>( url );
    }
}
