
import { Component, OnInit } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { SmsAbstractPojo } from 'src/app/pojo/sms-abstract.pojo';
import { AccountsReportsService } from '../account-reports.service';
import { CreditDebitReportPojo } from 'src/app/pojo/credit-debit-report.pojo';
import { LedgerStatementService } from '../../Reports/ledger-statement/ledger-statement.service';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-overall-accounts-report',
  templateUrl: './overall-accounts-report.component.html',
  styleUrls: ['./overall-accounts-report.component.scss']
})
export class OverallAccountsReportComponent implements OnInit {
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';

  startDate;
  endDate;
  resultFromDate;
  resultToDate;
  body = [];
  content = [];
  documentTime: string;
  isLoading: boolean = true;
  loggedUser = new MUserMasterPojo();
  creditDebitPojo = new CreditDebitReportPojo();
  ledgers: LedgerPojo[] = [];
  accountMasterArr:AccountMasterPojo[]=[];
  accountId:number;
  resultAccountId:number;
  constructor(private accountsReportService: AccountsReportsService, private ledgercreationService: LedgercreationService,
    private accountmasterserivce:AccountMasterService ) { }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

    $('.daterangeAdd').daterangepicker({ opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf('day'), endDate: moment().endOf('day') },
      (start, end, label) => {
        this.startDate = moment(start).format('YYYY-MM-DD');
        this.endDate = moment(end).format('YYYY-MM-DD');

      }
    );
    this.getAllLedger();
    this.getAccountMasterAll();
    this.startDate = moment().format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');
    this.isLoading = true;
    this.accountsReportService.getCashBookReportByDate(this.startDate, this.endDate, this.loggedUser.accId).subscribe(response => {
      this.creditDebitPojo = response;
      this.resultFromDate = this.startDate;
      this.resultToDate = this.endDate;
      this.resultAccountId=this.loggedUser.accId;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get CashBook Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
  }
  getAccountMasterAll() {
    this.isLoading = true;
    this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
        this.accountMasterArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        this.isLoading = false;
    } );
}
getAccountNameById(id) {
  if (this.accountMasterArr.find(r => r.accId == id)) {
    return this.accountMasterArr.find(r => r.accId == id).accName;
  }
  else {
    return id;
  }
} getAccountPlaceById(id) {
  if (this.accountMasterArr.find(r => r.accId == id)) {
    return this.accountMasterArr.find(r => r.accId == id).place;
  } else {
    return id;
  }
}
  getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
      this.ledgers = rs;
      this.isLoading = false;
      this.ledgers = rs;

    }, error => {
      bootbox.alert('Error' + error);
      this.isLoading = false;
    });
  }
  getLedgerById(id) {
    if (this.ledgers.find(r => r.ledgerId == id)) {
      return this.ledgers.find(r => r.ledgerId == id).ledgerName;
    }
    else {
      return id;
    }
  }

  fetch() {
    if(this.accountId!=0 && this.accountId!=undefined && this.accountId !=null){
    if (this.startDate == undefined && this.endDate == undefined) {
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');

    }
    this.isLoading = true;
    this.creditDebitPojo =new CreditDebitReportPojo();
    this.accountsReportService.getCashBookReportByDate(this.startDate, this.endDate, this.accountId).subscribe(response => {
      this.creditDebitPojo = response;
      this.resultFromDate = this.startDate;
      this.resultToDate = this.endDate;
      this.resultAccountId=this.accountId;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get CashBook Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
  }else{
    bootbox.alert('Please select the Account!');
  }
  }
  downloadPDF() {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push( {
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
     } );
    this.content.push({
      text: 'MULTI ACCOUNT REPORT\n', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
    });
    this.content.push({
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
    });
 
    this.content.push({
      text: 'REPORT FROM : ' + moment(this.resultFromDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' TO ' + moment(this.resultToDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: 'SELECTED ACCOUNT : ' +this.getAccountNameById(this.resultAccountId), fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\n\n', fontSize: 8, alignment: 'center'
    });

    this.body.push([
      { text: 'INCOME', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 5 }, {}, {}, {}, {},
      { text: 'EXPENDITURE', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 5 }, {}, {}, {}, {},
    ]);
    this.body.push([
      { text: 'T.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'Details', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'Bank Amt', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'T.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Details', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: 'Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Bank Amt', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
    ]);
    this.body.push([
      { text: 'Opening Balance', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.openingBalance, fontSize: 9, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankOpeningBalance, fontSize: 9, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: '', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: '', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: '', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);

if(this.creditDebitPojo.cpojo !=[] && this.creditDebitPojo.dpojo !=[]){
 // let n = this.creditDebitPojo.cpojo.length >= this.creditDebitPojo.dpojo.length ? this.creditDebitPojo.cpojo.length : this.creditDebitPojo.dpojo.length;
  
    if (this.creditDebitPojo.cpojo.length ==  this.creditDebitPojo.dpojo.length) {
      for (let i = 0; i <this.creditDebitPojo.cpojo.length; i++) {
      this.body.push(
        [
          { text: this.creditDebitPojo.cpojo[i].transactionNo, style: 'e', alignment: 'center' },
          { text: moment(this.creditDebitPojo.cpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
          { text: this.creditDebitPojo.cpojo[i].narration, style: 'e', alignment: 'left' },
          { text: this.creditDebitPojo.cpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
          { text: this.creditDebitPojo.cpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
          { text: this.creditDebitPojo.dpojo[i].transactionNo, style: 'e', alignment: 'center' },
          { text: moment(this.creditDebitPojo.dpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
          { text:  this.creditDebitPojo.dpojo[i].narration, style: 'e', alignment: 'left' },
          { text: this.creditDebitPojo.dpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
          { text: this.creditDebitPojo.dpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
        ]
      );
      }
    }else if(this.creditDebitPojo.cpojo.length>this.creditDebitPojo.dpojo.length) {
      let dpojoLength=this.creditDebitPojo.dpojo.length;
      for(let i=0;i<this.creditDebitPojo.cpojo.length;i++){
        
        if(dpojoLength>i){
          this.body.push(
            [
              { text: this.creditDebitPojo.cpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.cpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text: this.creditDebitPojo.cpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.cpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.cpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.dpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text:  this.creditDebitPojo.dpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.dpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
            ]
          );
        }else{
          this.body.push(
            [
              { text: this.creditDebitPojo.cpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.cpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text: this.creditDebitPojo.cpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.cpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.cpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
              { text: '', style: 'e', alignment: 'center' },
              { text: '', style: 'e', alignment: 'left' },
              { text: '', style: 'e', alignment: 'center' },
              { text: '', style: 'e', alignment: 'right' },
              { text: '', style: 'e', alignment: 'right' },
            ]
          );
        }

      }
    }  else if(this.creditDebitPojo.dpojo.length>this.creditDebitPojo.cpojo.length) {
      let cpojoLength=this.creditDebitPojo.cpojo.length;
      for(let i=0;i<this.creditDebitPojo.dpojo.length;i++){
         if(cpojoLength>i){
            this.body.push(
            [
              { text: this.creditDebitPojo.cpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.cpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text: this.creditDebitPojo.cpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.cpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.cpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.dpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text:  this.creditDebitPojo.dpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.dpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
            ]
          );
        }else{
          this.body.push(
            [
              { text: '', style: 'e', alignment: 'center' },
              { text: '', style: 'e', alignment: 'center' },
              { text: '', style: 'e', alignment: 'left' },
              { text: '', style: 'e', alignment: 'center' },
              { text: '', style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].transactionNo, style: 'e', alignment: 'center' },
              { text: moment(this.creditDebitPojo.dpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
              { text: this.creditDebitPojo.dpojo[i].narration, style: 'e', alignment: 'left' },
              { text: this.creditDebitPojo.dpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
              { text: this.creditDebitPojo.dpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
            ]
          );
        }

      }
    }      
}else if(this.creditDebitPojo.cpojo!=[]&&this.creditDebitPojo.dpojo==[]){
  for(let i=0;i<this.creditDebitPojo.cpojo.length;i++){
    this.body.push(
      [
        { text: this.creditDebitPojo.cpojo[i].transactionNo, style: 'e', alignment: 'center' },
        { text: moment(this.creditDebitPojo.cpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
        { text: this.creditDebitPojo.cpojo[i].narration, style: 'e', alignment: 'left' },
        { text: this.creditDebitPojo.cpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
        { text: this.creditDebitPojo.cpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
        { text: '', style: 'e', alignment: 'center' },
        { text: '', style: 'e', alignment: 'left' },
        { text: '', style: 'e', alignment: 'center' },
        { text: '', style: 'e', alignment: 'right' },
        { text: '', style: 'e', alignment: 'right' },
      ]
    );
  }
}
else if(this.creditDebitPojo.cpojo==[] && this.creditDebitPojo.dpojo !=[]){
  for(let i=0;i<this.creditDebitPojo.dpojo.length;i++){
  this.body.push(
    [
      { text: '', style: 'e', alignment: 'center' },
      { text: '', style: 'e', alignment: 'center' },
      { text: '', style: 'e', alignment: 'left' },
      { text: '', style: 'e', alignment: 'center' },
      { text: '', style: 'e', alignment: 'right' },
      { text: this.creditDebitPojo.dpojo[i].transactionNo, style: 'e', alignment: 'center' },
      { text: moment(this.creditDebitPojo.dpojo[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
      { text: this.creditDebitPojo.dpojo[i].narration, style: 'e', alignment: 'left' },
      { text: this.creditDebitPojo.dpojo[i].amount.toFixed(2), style: 'e', alignment: 'right' },
      { text: this.creditDebitPojo.dpojo[i].bankAmount.toFixed(2), style: 'e', alignment: 'right' },
    ]
  );
  }
}
   
  
    this.body.push([
      { text: 'Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.creditTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankCreditTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.debitTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankDebitTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);
    this.body.push([
      { text: 'Opening Balance', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.openingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankOpeningBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Closing Balance', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.closingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankClosingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);
    this.body.push([
      { text: 'Overall Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.creditSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankCreditSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: 'Overall Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 3 }, {}, {},
      { text: this.creditDebitPojo.debitSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankDebitSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);

    this.content.push({
      'table': {
         'body': this.body,
        widths: ['5%', '7%', '22%', '8%', '8%', '5%', '7%', '22%', '8%', '8%']
      }
    });


    const dd = {
      'content': this.content,
      pageOrientation: 'landscape',
      pageSize: 'A4',
      styles: {
        tableHeader: {
          fontSize: 8,
          alignment: 'center',
          bold: true
        },
        e: {
          fontSize: 8,
          font: 'TimesNewRoman'
        },
      },
      footer: (currentPage, pageCount) => {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 20]
                },
                {
                  text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      }
    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];

  }
}
