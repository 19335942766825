import { Component, OnInit } from '@angular/core';
import { SmsMasterService } from '../../SMS/sms-master/sms-master.service';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { SmsTypeMasterPojo } from 'src/app/pojo/sms-master.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-sms-settings',
  templateUrl: './sms-settings.component.html',
  styleUrls: ['./sms-settings.component.scss']
})
export class SmsSettingsComponent implements OnInit {
  isLoading: boolean = true;
  loggedUser = new MUserMasterPojo();
  smsTypeArr:SmsTypeMasterPojo[]=[];
  userCreatedId:string;
  attendanceId:string;
  feeAllocationId:string;
  feeCollectionId:string;
  updateLoading:boolean=false;
  resultPostingId:string;
  constructor(private smsMasterService:SmsMasterService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
    this.getSmsTypeAll();
  }
getSmsTypeAll(){
  this.smsMasterService.getSmsTypeAllByAccId(this.loggedUser.accId).subscribe( response => {
     this.isLoading = false;
    this.smsTypeArr = response;
    for(let i=0;i<this.smsTypeArr.length;i++){
      // if(this.smsTypeArr[i].smsTypeNo==1){
      //   this.userCreatedId = this.smsTypeArr[i].status;
      // }
      if(this.smsTypeArr[i].smsTypeNo==2){
        this.attendanceId = this.smsTypeArr[i].status;
      }
      if(this.smsTypeArr[i].smsTypeNo==3){
        this.feeAllocationId = this.smsTypeArr[i].status;
      }
      if(this.smsTypeArr[i].smsTypeNo==4){
        this.feeCollectionId = this.smsTypeArr[i].status;
      }
      if(this.smsTypeArr[i].smsTypeNo==5){
        this.resultPostingId=this.smsTypeArr[i].status;
      }
    }
}, error => {
    bootbox.alert( { message: 'Get Sms Type Master All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
     this.isLoading = false;
} );
}
update() {
  this.updateLoading = true;
  for(let i=0;i<this.smsTypeArr.length;i++){
    // if(this.smsTypeArr[i].smsTypeNo==1){
    //   this.smsTypeArr[i].status = this.userCreatedId;
    // }
    if(this.smsTypeArr[i].smsTypeNo==2){
      this.smsTypeArr[i].status=this.attendanceId ;
    }
    if(this.smsTypeArr[i].smsTypeNo==3){
      this.smsTypeArr[i].status=this.feeAllocationId;
    }
    if(this.smsTypeArr[i].smsTypeNo==4){
      this.smsTypeArr[i].status=this.feeCollectionId;
    }
    if(this.smsTypeArr[i].smsTypeNo==5){
      this.smsTypeArr[i].status=this.resultPostingId;
    }
  }
      this.smsMasterService.updateSmsTypeArr( this.smsTypeArr ).subscribe( response => {
          bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
          this.updateLoading = false;
          }, error => {
          bootbox.alert( { message: 'Update SMS Status Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.updateLoading = false;
         } );
  
}

}
