
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo, NotesMasterPojo } from "src/app/pojo/student-master.pojo";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';

declare var $: any;
declare var bootbox: any;

import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { AcademicSettingService } from 'src/app/management/Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import * as printJs from 'print-js'
import { NotesMasterService } from '../../Masters/notes-master/notes-master.service';
import { TransferDetailsPojo, TransferDetailsResponsePojo } from 'src/app/pojo/tc-generation.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import * as converter from 'number-to-words';
import { TcGenerationService } from '../../Reports/tc-generation/tc-generation.service';
import { CertificateAllService } from '../certificate.service';
import { BonafideCertificatePojo } from 'src/app/pojo/certificate.pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
@Component({
  selector: 'app-bonafide-generation',
  templateUrl: './bonafide-generation.component.html',
  styleUrls: ['./bonafide-generation.component.scss'],
  providers:[AcademicSettingService]
})
export class BonafideGenerationComponent implements OnInit {

    
    loggedUser = new MUserMasterPojo();
    studentMaster = new StudentMasterPojo();
    academic:AcademicYearMasterPojo[] =[];
    departmentArr:DepartmentMasterPojo[]=[];
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    isLoading: boolean = true;
   
    body = [];
    content = [];
    documentTime:string;
    departmentId;
    classid;
    sectionid;
    studentid;
    studentMasterArr:AcademicStandardDetailsPojo[]=[];
    accountMasterArr:AccountMasterPojo[]=[];
    
    certificatePojo= new BonafideCertificatePojo();

    constructor(private sectionMasterService: SectionMasterService, 
        private studentMasterService: StudentMasterService, private classMasterService: ClassMasterService,
        private settingService:AcademicSettingService,private departmentMasterService:DepartmentMasterService,
        private certificateService:CertificateAllService,private accountmasterserivce:AccountMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        $( '#addAfterClick' ).hide();
         this.academicSettingAll();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();  
        this.getAccountMasterAll();
        }
        getAccountMasterAll() {
            this.isLoading = true;
            this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
                this.accountMasterArr = response;
                    this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                this.isLoading = false;
            } );
        }
        getDepartmentAll() {
            this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
                this.departmentArr = run;
            }, error => {
                bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                } );
        }
        getClassMasterAll() {
            this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
                this.classArr = response;
                this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                this.isLoading = false;
            } );
        }
        getSectionMasterAll() {
            this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
                this.sectionArr = response;
                this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                this.isLoading = false;
            } );
        }
        academicSettingAll(){
            this.academic = [];
            this.settingService.getAcademicYrAll().subscribe( response => {
                this.academic = response;
                 }, error => {
                bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
                 } );
           }
           getClassNameById( id ) {
            if ( this.classArr.find( r => r.classId == id ) ) {
                return this.classArr.find( r => r.classId == id ).className;
            }
            else {
                return id;
            }
        }
        getAcademicYrById( id ) {
            if ( this.academic.find( r => r.academicYearId == id ) ) {
                return this.academic.find( r => r.academicYearId == id ).academicYear;
            }
            else {
                return id;
            }
        }
        noEnter( e ) {
            return !( window.event && e.keyCode == 13 );
          }
       getStudentMaster() {
      if ( this.departmentId != "" && this.classid != "" && this.sectionid != "" ) {
           this.studentMasterArr = [];
          this.isLoading = true;
           this.studentMasterService.getDiscontinueStudentsById( this.departmentId, this.classid, this.sectionid,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
              this.studentMasterArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( 'Get Student All Service Error! - HTTP Status ' + error.status );
              this.isLoading = false;
          } );
      }
  }
  getBonafideReportPdf(bonafideCertificateNo){
    this.certificateService.getBonafideReport(bonafideCertificateNo).subscribe(Response=>{
      const file=new Blob([Response],{type:'application/pdf'} );
      const fileUrl = URL.createObjectURL(file);
      window.open(fileUrl);

    },error=>{
      bootbox.alert({message:'get attendance report error!' + error.status,className:'h5 text-center text-danger', closeButton:false});
   });

   }
  
   getStudentMasterById( studentId ) {
    this.certificatePojo=new BonafideCertificatePojo();
    for(let i=0;i<this.accountMasterArr.length;i++){
        if(this.accountMasterArr[i].accId==this.loggedUser.accId){
            this.certificatePojo.schoolName=this.accountMasterArr[i].schoolName;
            this.certificatePojo.district=this.accountMasterArr[i].district;
            this.certificatePojo.station=this.accountMasterArr[i].place;
        }
    }

        this.studentMasterService.getStudentById( studentId,this.loggedUser.accId ).subscribe( response => {
            this.studentMaster = response;
           this.certificatePojo.name=this.studentMaster.name; 
           this.certificatePojo.fatherName=this.studentMaster.fatherName;          
           this.certificatePojo.classId=this.studentMaster.classId; 
           this.certificatePojo.dob=this.studentMaster.dob; 
           this.certificatePojo.nationality=this.studentMaster.nationality; 
           this.certificatePojo.religion=this.studentMaster.religion; 
           this.certificatePojo.caste=this.studentMaster.cast; 
           this.certificatePojo.admissionNo=this.studentMaster.admissionNo;
           this.certificatePojo.academicYearId=this.studentMaster.joiningAcademicYear; 
           this.certificatePojo.studentId=this.studentMaster.studentId;
           this.certificatePojo.certificateDate=moment().toDate();
          
     let dobyr=moment(this.certificatePojo.dob,'YYYY-MM-DD').format('YYYY');
    let dobfullYr=converter.toWords(dobyr);
    let dobfullMontn=moment(this.certificatePojo.dob,'YYYY-MM-DD').format('MMMM');
     let dobfullDays=converter.toWords(moment(this.certificatePojo.dob,'YYYY-MM-DD').format('DD'));
    this.certificatePojo.dobWords= (dobfullDays+' - '+dobfullMontn+' - '+dobfullYr).toUpperCase();
                }, error => {
            bootbox.alert( {
                message: 'Get Student By Id Service Error! HTTP Status ' + error.status,className: 'text-danger h5', closeButton: false
            } );
        } );
    }
  
    add() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
      this.certificatePojo.accId = this.loggedUser.accId;
      this.certificatePojo.userId = this.loggedUser.userId;
     
        this.certificateService.addBonafideCertificate(this.certificatePojo).subscribe( response => {
          this.certificatePojo=new BonafideCertificatePojo();
         let d= new BonafideCertificatePojo();
           d=response;
           if(d.code==101){
            bootbox.alert( { message: 'Already Generated!', className: 'text-danger h5', closeButton: false } );
             }else{
              this.getBonafideReportPdf(d.bonafideCertificateNo);
           bootbox.alert({message: "Successfully Generated", className: 'text-success h5' });
               }
             ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              }, error => {
          bootbox.alert( { message: 'Add BonafideCertificate Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
     
    }
    printBonafide(arg: BonafideCertificatePojo) {
        this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
          this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    
        this.body.push([
    
          { text: '', border: [false, false, false, false] },
          {
            image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
          }, {},
    
          { text: 'BONAFIDE CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
          { text: '', border: [false, false, false, false], colSpan: 2 }, {},
        ]);
        this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{text:'', border: [false, false, false, false] },{ text: 'THIS IS TO CERTIFY THAT', colSpan: 3, style: 'nontxtleft', border: [false, false, false, false] }, {}, {},
        { text: arg.name, colSpan: 6, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {}]);
        this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: 'D/o', style: 'nontxtleft', border: [false, false, false, false] },
        { text: arg.fatherName, colSpan: 5, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {},
        { text: 'IS A BONAFIDE STUDENT OF OUR', colSpan: 4, style: 'nontxtright', border: [false, false, false, false] },{}, {}, {}]);
        this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: arg.schoolName,style:'txt', colSpan: 10, border: [false, false, false, true] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: 'STUDYING IN STANDARD', style: 'nontxtleft', colSpan: 3, border: [false, false, false, false] }, {}, {},
        { text: this.getClassNameById(arg.classId), colSpan: 3, style: 'txt', border: [false, false, false, true], }, {}, {},  
        { text: 'DURING THE ACADEMIC YEAR OF', style: 'nontxtright', border: [false, false, false, false], alignment: 'right', colSpan: 4 },{},{}, {}]);
        this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: this.getAcademicYrById(arg.academicYearId), style: 'txt', colSpan: 3, border: [false, false, false, true] }, {}, {},
        { text: 'HER DATE OF BIRTH IS', colSpan: 3, style: 'nontxtcenter', border: [false, false, false, false], }, {},{},
        { text: moment(arg.dob,'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'txt', border: [false, false, false, true], colSpan: 4, }, {},{},{}]);
        this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: arg.dobWords,style:'txt', colSpan: 10, border: [false, false, false, true] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: '\nAS PER OUR SCHOOL RECORDS.',style:'nontxtleft', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: 'ADMISSION NO :',style:'nontxtleft', border: [false, false, false, false] ,colSpan:2},{}, 
        {text:arg.admissionNo,style:'txt',alignment:'left',border:[false,false,false,false],colSpan:8}, {}, {}, {}, {}, {}, {}, {},]);
        this.body.push([{ text: 'NATIONALITY,RELIGION & CASTE :',style:'nontxtleft',colSpan:4, border: [false, false, false, false] },{},{},{}, 
        {text:arg.nationality+' - '+arg.religion+' - '+arg.caste,style:'txt',alignment:'left',border:[false,false,false,false],colSpan:6},  {}, {}, {}, {}, {}, ]);
          this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
        this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
        { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
        this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
        { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    
        this.content.push({
          'table': {
            headerRows: 1,
            alignment: 'center',
              'body': this.body,
            widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
          }
        });
    
        const dd = {
          'content': this.content,
          pageOrientation: 'portrait',
          pageSize: 'A4',
          styles: {
            txt: {
              font: 'TimesNewRoman',
              bold: true,
              fontSize: 10,
              alignment: 'center'
            },
            nontxtleft: {
              font: 'TimesNewRoman',
              fontSize: 10,
              alignment: 'left',
    
            },
            nontxtright: {
              font: 'TimesNewRoman',
              fontSize: 10,
              alignment: 'right',
    
            },
            nontxtcenter: {
              font: 'TimesNewRoman',
              fontSize: 10,
              alignment: 'center',
    
            },
          },
    
        };
    
        pdfMake.createPdf(dd).open();
        this.body = [];
        this.content = [];
      }
}

