import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';

import { ArrearReportPojo, ArrearReportStudentDetailsPojo } from 'src/app/pojo/arrears-report.pojo';

import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';

import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceResponsePojo, AttendancePojo } from 'src/app/pojo/attendance.pojo';

import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { StandardStructureMasterService } from 'src/app/management/Masters/standard-structure/standard-structure.service';
import { FeeAllocationMasterService } from 'src/app/management/Fees/fee-allocation/fee-allocation.service';
import { AcademicSettingService } from 'src/app/management/Admin/academic-setting/academic-setting.service';
import { ReportService } from 'src/app/management/Reports/report-service';
import { AccountMasterService } from 'src/app/management/Admin/account-master/account-master.service';
import { AttendanceEntryService } from 'src/app/management/Attendance/attendance-entry/attendance-entry.service';
import { NoteEntryService } from './notes-entry.service';
import { Teachernoterespojo } from 'src/app/pojo/teachernotesreponse.pojo';
import { TeacherNotesPojo } from 'src/app/pojo/teacher-notes.pojo';
import { LogDetailsPojo } from 'src/app/pojo/log-details-pojo';

declare var $: any;
declare var bootbox: any;


@Component({
  selector: 'app-notes-entry',
  templateUrl: './notes-entry.component.html',
  styleUrls: ['./notes-entry.component.scss']
})
export class NotesEntryComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
  
    fromDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

      sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    noteDate:Date;
    typeToAll:string;
    descriptionToAll:string;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltDate:number;
    searchOption = 'ALL';
    typeId;
    academic:AcademicYearMasterPojo[] =[];
    academicYear;
     feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
    standTermArr: StandardTermPojo[] = [];
    classPojo = new ClassMasterPojo();
    hoursId:number;
    notesRes = new Teachernoterespojo();
    notesArr:TeacherNotesPojo[]=[];
    resultHoursId:number;
    updateArr:TeacherNotesPojo[]=[];
    finalEntryResArr:TeacherNotesPojo[] =[];
    logpojo = new LogDetailsPojo();
    
    accountMasterArr:AccountMasterPojo[]=[];
     constructor( private standardTermService:StandardStructureMasterService,
        private feeAllocationService:FeeAllocationMasterService,private settingService:AcademicSettingService,
        private reportService:ReportService,private studentMasterService: StudentMasterService, 
        private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, 
        private classMasterService: ClassMasterService, private router: Router,
        private accountMasterService:AccountMasterService,private attendanceService:AttendanceEntryService,private notesentryservice:NoteEntryService ) { }


  ngOnInit() {
    
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

    (<HTMLFormElement>document.getElementById('fromEntryDate')).value = moment().format('YYYY-MM-DD');
    $( '#editAfterClick' ).hide();
    this.getDepartmentAll();
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.academicSettingAll();
    this.getStandrdTermAll();
    this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                this.academicYear = this.academic[i].academicYearId;
            }
        }
           }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
  getSectionMasterAll() {
      this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
          this.sectionMasterArr = response;
          }, error => {
          bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getClassMasterHoursListById() {
      this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe( response => {
          this.classPojo = response;
           }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
getDepartmentAll() {
      this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
          this.departmentArr = run;
      }, error => {
          bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getStandrdTermAll() {
       this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
          this.standTermArr = response;
             }, error => {
          bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
    }
  getDepartmentNameBydepartmentId( id ) {
      if ( this.departmentArr.find( r => r.deptId == id ) ) {
          return this.departmentArr.find( r => r.deptId == id ).deptName;
      } else {
          return id;
      }
  }
  getClassNameById( id ) {
      if ( this.classMasterArr.find( r => r.classId == id ) ) {
          return this.classMasterArr.find( r => r.classId == id ).className;
      } else {
          return id;
      }
  }
  getSectionNameById( id ) {
      if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
          return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
      } else {
          return id;
      }
  }
  getAcademicYrById( id ) {
      if ( this.academic.find( r => r.academicYearId == id ) ) {
          return this.academic.find( r => r.academicYearId == id ).academicYear;
      } else {
          return id;
      }
    }
    getStandardTermById( id ) {
      if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
          return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
      } else {
          return id;
      }
    }
    getHoursNameById( id ) {
      if ( this.classPojo.list.find( r => r.hoursId == id ) ) {
          return this.classPojo.list.find( r => r.hoursId == id ).hoursName;
      } else {
          return id;
      }
    }
   getReportById() {
      this.notesArr=[];
      this.isLoading = true;
      this.notesRes = new Teachernoterespojo();
      let arg1 =(<HTMLFormElement>document.getElementById('fromEntryDate')).value;
      this.notesentryservice.getNotesEntryList( this.academicYear, this.classid, this.sectionid,arg1,this.loggedUser.accId ).subscribe( response => {
         if(response.code == 102 || response.code == 103){
              bootbox.confirm('Notes already Completed !\n Do you want to Edit ?', ( result ) => {
                  if(result){
                      this.notesRes = response;
                     this.notesArr = this.notesRes.list;
                      this.resultDept= this.departmentId;
                      this.resultClass= this.classid;
                      this.resultSection=this.sectionid;
                       this.isLoading = false;
                  }else{
                      this.isLoading = false;
                  }
              });
             }else{
              this.notesRes = response;
              this.notesArr = this.notesRes.list;
              this.resultDept= this.departmentId;
          this.resultClass= this.classid;
          this.resultSection=this.sectionid;
          this.resulltDate = arg1;
         }
              this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
          this.isLoading = false;
      } );
  }
  submitNotes(){
      this.isLoading = true;
      this.finalEntryResArr=[];
      this.notesRes.classId = this.resultClass;
      this.notesRes.sectionId = this.resultSection;
      this.notesRes.academicYearId = this.academicYear;
      this.notesRes.accId = this.loggedUser.accId;
      this.notesRes.noteDate =  (<HTMLFormElement>document.getElementById('fromEntryDate')).value;
      this.notesRes.list =[];
      this.notesRes.list = this.notesArr;
      this.notesRes.list.forEach(res => res.accId = this.notesRes.accId);
      this.notesRes.list.forEach(res => res.noteDate = this.notesRes.noteDate);
      this.notesRes.userId=this.loggedUser.userId;
      this.notesRes.list.forEach(res=>res.userId = this.notesRes.userId);
      this.notesRes.staffId=this.loggedUser.staffId;
      this.notesRes.list.forEach(res => res.staffId=this.notesRes.staffId);
       this.notesRes.list.forEach( res =>{
        if(res.types ==null ){
          res.types = 'NOTES';
        }
       })
     this.notesentryservice.addNoteLis( this.notesRes ).subscribe( response => {
          this.notesRes = new Teachernoterespojo();
              this.notesRes = response;
              this.finalEntryResArr = this.notesRes.list;
               this.notesArr=[];
          this.Reset();
          bootbox.alert('Submitted Successfuly')
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Submit Notes ' + error.status, className: "text-danger h5", closeButton: false } );
          this.isLoading = false;
      } );
  }

  addApplyAll(){
   this.isLoading= true;
     this.notesRes.list.map((a)=>{a.types=this.typeToAll; a.description = this.descriptionToAll});
     this.submitNotes();
    this.isLoading = false;

    }

  applyAll(){
 this.isLoading= true;
   this.notesArr.map((a)=>{a.types=this.typeToAll; a.description = this.descriptionToAll});
  this.updateArr=this.notesArr;
  this.updateNotes();
  this.isLoading = false;
  }

  onChangeNotesStatus(item){
      let e = new TeacherNotesPojo();
     e = item;
        if(this.updateArr.length > 0){
           for ( let i = 0; i < this.updateArr.length; i++ ) {
             if(this.updateArr[i].studentId == e.studentId){
                     this.updateArr.splice( i );
                     i++;
                  this.updateArr.push(e);
                  }else{
                 this.updateArr.push(e); 
             }
         } 
     }else{
            this.updateArr.push(e);  
     }
       }

       updateNotes(){

       if(this.updateArr.length>0){
        this.updateArr.forEach( res =>{
          if(res.types ==null ){
            res.types = 'NOTES';
          }
         })
            console.log(this.updateArr);
              this.isLoading = true;
              this.finalEntryResArr =[];
                this.notesentryservice.updateNoteList( this.updateArr).subscribe( response => {
              this.notesRes = new Teachernoterespojo();
              this.finalEntryResArr = response;
              console.log(this.notesRes);
                this.updateArr =[];
             this.notesArr =[];
             console.log(this.finalEntryResArr);
             this.Reset();
              this.notesRes = response;
              bootbox.alert('Submitted Successfuly')
                this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Submit Notes ' + error.status, className: "text-danger h5", closeButton: false } );
              this.isLoading = false;
          } );  
      }else{
          bootbox.alert('No Records to be Editted');
      }
      }
      Reset(){
          this.classid=0;
          this.departmentId=0;
          this.sectionid=0;
          this.fromDate=null;
          this.descriptionToAll=null;
          this.typeToAll=null;
      }
}
