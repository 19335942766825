
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { AreaMasterService } from '../../Masters/area-master/area-master.service';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { FeeCollectionReportDetailsPojo } from 'src/app/pojo/fee-collection-report-detail.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { Router } from '@angular/router';
import { BusFeeRefundPojo } from 'src/app/pojo/bus-fee-refund.pojo';
import { StaticVariable } from 'src/app/globle.class';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-reund-report',
  templateUrl: './reund-report.component.html',
  styleUrls: ['./reund-report.component.scss'],
  providers:[AcademicSettingService]
})
export class ReundReportComponent implements OnInit {
   model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;
    loggedUser = new MUserMasterPojo();
    addEntryDate: NgbDateStruct;
    editEntryDate: NgbDateStruct;
    refundArr: BusFeeRefundPojo[] = [];
    tempPushArr:FeeAllocationPojo[]=[];
    classMasterArr: ClassMasterPojo[] = [];
    addMaster = new FeeAllocationPojo();
    editMaster = new FeeAllocationPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = false;
    feeStructArr: StandardTermPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
     standardArr: AcademicStandardDetailsPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    studentMasterAllArr: StudentMasterPojo[] = [];
    studentEditMasterArr: StudentMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    startDate;
    endDate;
    resultFromDate;
    resultToDate;
    classid;
    sectionid;
    departmentId;
    academicYr;
    resultclassid;
    resultdepartmentId;
    resultacademicYr;
    resulttermid;
    resultstandardTermId;
    termArr: TermPojo[] = [];
    termid;
    standardTermId;
    filteredFeeStructArr:StandardTermPojo[]=[];
    reportPojo = new FeeCollectionReportDetailsPojo();
    filterId:string;
    tempArr:FeeAllocationPojo[]=[];
    areaArr:AreaMasterPojo[]=[];
    pickspaidledgersByAccountGroup:LedgerPojo[]=[];
    public paidacc: number[] = [1, 2];
    documentTime:string;
    body = [];
    content = [];
    accountMasterArr:AccountMasterPojo[]=[];
    constructor(private reportService:ReportService,private departmentMasterService:DepartmentMasterService,private classMasterService: ClassMasterService,private router:Router,
      private feeTypemasterserivce: TermMasterService,private feeAloocationService:FeeAllocationMasterService,private ledgercreationService:LedgercreationService,
      private accountMasterService:AccountMasterService,private settingService:AcademicSettingService,private sectionMasterService:SectionMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
    this.academicSettingAll();
       this.getDepartmentAll();
        this.getClassMasterAll();
        this.getSectionMasterAll();
         this.getTermAll();
         this.accountMasterAll();
          this.ledgercreationService.getLedgerByAccountGroup( this.paidacc,this.loggedUser.accId).subscribe( log => {
          this.pickspaidledgersByAccountGroup = log;
      } ); 
      $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
            ( start, end, label ) => {
                this.startDate = moment(start).format('YYYY-MM-DD');
                this.endDate = moment(end).format('YYYY-MM-DD');
                 
                 }
        );
        }
        accountMasterAll() {
            this.accountMasterService.getAllAccountMaster().subscribe(response => {
              this.accountMasterArr = response;
             
            }, error => {
              bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
            });
          }
          getAccountNameById(id) {
            if (this.accountMasterArr.find(r => r.accId == id)) {
              return this.accountMasterArr.find(r => r.accId == id).accName;
            } else {
              return id;
            }
          }
          getAccountPlaceById(id) {
            if (this.accountMasterArr.find(r => r.accId == id)) {
              return this.accountMasterArr.find(r => r.accId == id).place;
            } else {
              return id;
            }
          }
      getPaidLedgerById( id ) {
        if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
            return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
        } else {
            return id;
        }
      }
        getTermAll() {
          this.isLoading = true;
          this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
              this.termArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              this.isLoading = false;
          } );
      }
       getStandardTermById(){  
        this.filteredFeeStructArr=[];
        this.feeAloocationService.getBusStandardTermByClassidAndTermId(this.classid,this.termid,this.loggedUser.accId).subscribe( response => {
          this.filteredFeeStructArr= response;
            }, error => {
          bootbox.alert( { message: 'Get STandard Term By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
      }
        getAllocationDetailsById(){
            if(this.startDate == undefined && this.endDate == undefined){
                this.startDate = moment().format('YYYY-MM-DD');
                this.endDate = moment().format('YYYY-MM-DD');
                          
               }
          this.refundArr =[];
          this.tempArr=[];
          this.isLoading = true;
          this.feeAloocationService.getBusFeeRefundReportById(this.academicYr,this.classid,this.standardTermId,this.loggedUser.accId,this.startDate,this.endDate).subscribe( response => {
               this.refundArr= response;
               this.studentMasterArr=[];
               this.standardArr=[];
               this.resultFromDate=this.startDate;
               this.resultToDate=this.endDate;
              this.resultdepartmentId=this.departmentId;
               this.resultclassid=this.classid;
               this.resulttermid=this.termid;
               this.resultstandardTermId=this.standardTermId;
               this.resultacademicYr=this.academicYr;
               this.reportService.getClassWiseReportById( this.departmentId, this.classid, 0,this.academicYr,this.loggedUser.accId ).subscribe( response => {
                this.standardArr = response;
                for(let i=0;i<this.standardArr.length;i++){
                    this.studentMasterArr.push(this.standardArr[i].student);
                }
                      this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
                this.isLoading = false;
            } );
             // this.isLoading = false;
             }, error => {
            bootbox.alert( { message: 'Get Bus Fee Collection Report Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;   
          } );
        }
 getStudentNameById( id ) {
          if ( this.studentMasterArr.find( r => r.studentId == id ) ) {
              return this.studentMasterArr.find( r => r.studentId == id ).name;
          } else {
              return id;
          }
      }
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
        this.departmentArr = run;
    }, error => {
        bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
    }
  getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
             }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                 this.academicYr = this.academic[i].academicYearId;
                  }
        }
        
          }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
   getAcademicYrById( id ) {
    if ( this.academic.find( r => r.academicYearId == id ) ) {
        return this.academic.find( r => r.academicYearId == id ).academicYear;
    } else {
        return id;
    }
  }
  getStandardTermNameById( id ) {
    if ( this.filteredFeeStructArr.find( r => r.standardTermId == id ) ) {
        return this.filteredFeeStructArr.find( r => r.standardTermId == id ).standardTerm;
    } else {
        return id;
    }
  }
  getClassNameById( id ) {
    if ( this.classMasterArr.find( r => r.classId == id ) ) {
        return this.classMasterArr.find( r => r.classId == id ).className;
    } else {
        return id;
    }
  }
  getSectionNameById( id ) {
    if ( this.sectionArr.find( r => r.sectionId == id ) ) {
        return this.sectionArr.find( r => r.sectionId == id ). sectionName;
    } else {
        return id;
    }
  }
  getTermNameById( id ) {
    if ( this.termArr.find( r => r.termId == id ) ) {
        return this.termArr.find( r => r.termId == id ).termName;
    } else {
        return id;
    }
  }
  getDeptNameById( id ) {
    if ( this.departmentArr.find( r => r.deptId == id ) ) {
        return this.departmentArr.find( r => r.deptId == id ).deptName;
    } else {
        return id;
    }
  }
  downloadPDF() {
       
    this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
     this.content.push( {
         text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
        } );
             this.content.push( {
                text: 'BUS FEE REFUND REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
            } );
         this.content.push( {
         text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
         fontSize: 10, bold: true, alignment: 'center'
     } );
     this.content.push( {
        text: 'REPORT DATE FROM'+' [ '+moment(this.resultFromDate,'YYYY-MM-DD').format('DD-MM-YYYY')+' TO '+moment(this.resultToDate,'YYYY-MM-DD').format('DD-MM-YYYY') +' ]', fontSize: 14, alignment: 'center', font: 'TimesNewRoman', bold: true
    } );
     this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDeptNameById(this.resultdepartmentId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
   } );
   this.content.push( {text: '\nSELECTED CLASS :'+this.getClassNameById(this.resultclassid)+' - '+'ALL SECTION', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
  } );
    this.content.push( {text: '\nSELECTED TERM :'+this.getTermNameById(this.resulttermid)+' - '+ this.getStandardTermNameById(this.resultstandardTermId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
 } );
  

    this.content.push( {
         text: '\n', fontSize: 8, alignment: 'center'
     } );

     this.body.push( [
         { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Refund Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Receipt No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Admission No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Paid Ledger', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Bus Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Refund Amount ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            ] );

     for ( let i = 0; i < this.refundArr.length; i++ ) {
        
         this.body.push( [
             { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: moment(this.refundArr[i].refundDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.refundArr[i].receiptId, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.refundArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
            { text: this.getStudentNameById(this.refundArr[i].studentId), fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
             { text: this.getPaidLedgerById(this.refundArr[i].paidLedgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: this.refundArr[i].busAmount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: this.refundArr[i].refundAmount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                  ] );
     }

     this.content.push( {
         'table': {
             headerRows: 1,
             'body': this.body,
             widths: ['5%', '12%','12%', '10%', '30%', '10%', '11%', '10%']
         }
     } );
    

     const dd = {
         'content': this.content,
         pageOrientation: 'landscape',
         pageSize: 'A4',
         footer: ( currentPage, pageCount ) => {
             return {
                 margin: 10,
                 columns: [
                     {
                         fontSize: 9,
                         text: [
                             {
                                 text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                 '\n',
                                 margin: [0, 20]
                             },
                             {
                                 text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                             }
                         ],
                         alignment: 'center'
                     }
                 ]
             };
         }
     };

     pdfMake.createPdf( dd ).open();
     this.body = [];
     this.content = [];

 }
 
}