import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { PaymentPojo } from "src/app/pojo/payment-pojo";
import { Http } from '@angular/http';
@Injectable( {
    providedIn: 'root'
} )

export class PaymentEntryService {

    constructor( private httpclient: HttpClient,private http: Http ) { }

    getPaymentAll(cid): Observable<PaymentPojo[]> {
        const url = StaticVariable.porturl + 'GetPaymentAll/'+cid;
        return this.httpclient.get<PaymentPojo[]>( url );
    }
    
    addPayment( arg: PaymentPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddPayment';
        return this.http.post( url, arg );
    }
    
    updatePayment( arg: PaymentPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdatePayment';
        return this.http.put( url, arg );
    }
    
    deletePayment( arg: number,cid, uid:number  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeletePayment/' + arg + '/'+cid+'/' + uid;
        return this.httpclient.delete( url );
    }
    getJournalByDate( fdate: string, tdate: string,cid ): Observable<PaymentPojo[]> {
        const url = StaticVariable.porturl + 'GetPaymentByDateRange/' + fdate + '/' + tdate+'/'+cid;
        //  alert(url);
        return this.httpclient.get<PaymentPojo[]>(url);
    }
    getAutoGeneratePaymentNo(accid):  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGeneratePaymentNo/'+accid;
        return this.httpclient.get<any>(url);
    }
    getLedgerByLedgerId(Id:number,cid):  Observable<any> {
        const url = StaticVariable.porturl + 'PaymentById/'+Id+'/'+cid;
        return this.httpclient.get<any>(url);
    }
}