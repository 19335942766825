import { Component, OnInit } from '@angular/core';
import { SmsMasterPojo, SmsTypeMasterPojo } from "src/app/pojo/sms-master.pojo";
import { SmsHistoryPojo } from "src/app/pojo/sms-history.pojo";
import { SmsMasterService } from "src/app/management/SMS/sms-master/sms-master.service";
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { SmsAbstractPojo } from 'src/app/pojo/sms-abstract.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-sms-history',
    templateUrl: './sms-history.component.html',
    styleUrls: ['./sms-history.component.scss']
} )

export class SmsHistoryComponent implements OnInit {

    smsHistoryArr: SmsHistoryPojo[] = [];
    smsAbstractArr: SmsAbstractPojo[] = [];
    smsMasterArr: SmsMasterPojo[] = [];
    smsHistPojo = new SmsHistoryPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    startDate;
    endDate;
    body = [];
    content = [];
    documentTime:string;
    isLoading: boolean = true;
    smsId:number;
    status:string;
    loggedUser = new MUserMasterPojo();
    smsTypePojo = new SmsTypeMasterPojo();
    smsTypeArr:SmsTypeMasterPojo[] = [];
    smsTypeId:number;
    resultFromDate;
    resultToDate;
    totalSMS;
    deliverdSMS;
    failedSMS;
    pendingSMS;
    accountMasterArr:AccountMasterPojo[]=[];
    constructor( private smsMasterService: SmsMasterService,private accountMasterService:AccountMasterService ) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        $( '#editAfterClick' ).hide();
        $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
            ( start, end, label ) => {
                this.startDate = moment(start).format('YYYY-MM-DD');
                this.endDate = moment(end).format('YYYY-MM-DD');
                 
                 }
        );
             this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
            this.smsAbstractArr=[];
           this.smsHistoryArr = [];
            this.isLoading = true;
            this.smsMasterService.getSmsAbstractData(this.startDate,this.endDate,0,this.loggedUser.accId).subscribe( response => {
                this.smsAbstractArr = response;
                if(this.smsAbstractArr.length>0){
                    for(let i=0;i<this.smsAbstractArr.length;i++){
                        this.smsAbstractArr[i].sendTime = moment(this.smsAbstractArr[i].sendTime,'HH:mm').format('hh:mm A');
                    }
                }
               
                this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Get SmsHistory Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                this.isLoading = false;
            } );
        this.getSmsTypeAll();
        this.getSmsMasterAll();
        this.accountMasterAll();
    }
    accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
      getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      } getAccountDistrictById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).district;
        } else {
          return id;
        }
      }
    getSmsMasterAll() {
        this.isLoading = true;
        this.smsMasterService.getSmsMasterAll(this.loggedUser.accId).subscribe( response => {
            this.smsMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Sms Master All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getSmsTypeAll() {
         this.smsMasterService.getSmsTypeAllByAccId(this.loggedUser.accId).subscribe( response => {
            this.smsTypeArr = response;
           }, error => {
            bootbox.alert( { message: 'Get Sms Type Master All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getSmsTypeById( id ) {
        if ( this.smsTypeArr.find( r => r.smsTypeId == id ) ) {
            return this.smsTypeArr.find( r => r.smsTypeId == id ).smsType;
        } else {
            return id;
        }
    }
   
    fetchSmsHistory() {
       if(this.startDate == undefined && this.endDate == undefined){
        this.startDate = moment().format('YYYY-MM-DD');
        this.endDate = moment().format('YYYY-MM-DD');
                  
       }
       this.smsAbstractArr=[];
       this.smsHistoryArr = [];
        this.isLoading = true;
        this.smsMasterService.getSmsAbstractData(this.startDate,this.endDate,this.smsTypeId,this.loggedUser.accId).subscribe( response => {
            this.smsAbstractArr = response;
            this.resultFromDate=this.startDate;
            this.resultToDate=this.endDate;
            if(this.smsAbstractArr.length>0){
                for(let i=0;i<this.smsAbstractArr.length;i++){
                    this.smsAbstractArr[i].sendTime = moment(this.smsAbstractArr[i].sendTime,'HH:mm').format('hh:mm A');
                }
            }
           
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get SmsHistory Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    viewSmsHistory(arg:SmsAbstractPojo){
        this.smsHistoryArr =[];
     this.smsHistoryArr = arg.list;
     if(this.smsHistoryArr.length>0){
        for(let i=0;i<this.smsHistoryArr.length;i++){
            this.smsHistoryArr[i].sendTime = moment(this.smsHistoryArr[i].sendTime,'HH:mm').format('hh:mm A');
        }
    }
    this.totalSMS=arg.smsCount;
    this.deliverdSMS=arg.successCount;
    this.failedSMS=arg.failureCount;
    this.pendingSMS=arg.pendingCount;
    }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
         this.content.push( {
             text: 'SMS HISTORY REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );

        
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         this.content.push( {
            text: 'REPORT DATE FROM'+' [ '+moment(this.resultFromDate,'YYYY-MM-DD').format('DD-MM-YYYY')+' TO '+moment(this.resultToDate,'YYYY-MM-DD').format('DD-MM-YYYY') +' ]', fontSize: 14, alignment: 'center', font: 'TimesNewRoman', bold: true
        } );
      
    this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'SMS Type', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'SMS Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Send Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Send Time', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Total', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Delivered', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Failed ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Pending ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'SMS Contents ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            ] );
   
         for ( let i = 0; i < this.smsAbstractArr.length; i++ ) {
            let temp='';
            if ( this.smsAbstractArr[i].sendDate != null ) {
                temp = moment( this.smsAbstractArr[i].sendDate,'YYYY-MM-DD' ).format( 'DD/MM/YYYY' ) ;
                this.smsAbstractArr[i].sendTime = moment(this.smsAbstractArr[i].sendTime,'HH:mm').format('hh:mm A');
             
            } else {
                temp = "-";
            }
            let smsType='';
            if(this.smsAbstractArr[i].smsTypeId==0){
            smsType='ALL';
            }else{
                smsType=this.getSmsTypeById(this.smsAbstractArr[i].smsTypeId);
            }
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: smsType, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.smsAbstractArr[i].smsName, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: temp, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text: this.smsAbstractArr[i].sendTime, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    { text: this.smsAbstractArr[i].smsCount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.smsAbstractArr[i].successCount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.smsAbstractArr[i].failureCount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.smsAbstractArr[i].pendingCount, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.smsAbstractArr[i].smsContent, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                     ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '12%', '13%', '8%','8%', '7%', '7%', '7%', '7%', '26%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
     print(){
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
        this.content.push( {
            text: 'SMS HISTORY DETAILS REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
        } );

       
        this.content.push( {
            text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
            fontSize: 10, bold: true, alignment: 'center'
        } );
      this.content.push( {
            text: '\n', fontSize: 8, alignment: 'center'
        } );
  
        this.body.push( [
            { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'SMS Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Mobile No. ', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Send Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Send Time', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'SMS Status', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Delivered Status', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'SMS Content', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
              ] );
  
        for ( let i = 0; i < this.smsHistoryArr.length; i++ ) {
           let temp='';
           if ( this.smsHistoryArr[i].sendDate != null ) {
               temp = moment( this.smsHistoryArr[i].sendDate,'YYYY-MM-DD' ).format( 'DD/MM/YYYY' ) ;
               this.smsHistoryArr[i].sendTime = moment(this.smsHistoryArr[i].sendTime,'HH:mm').format('hh:mm A');
            
           } else {
               temp = "-";
           }
          
            this.body.push( [
                { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.smsHistoryArr[i].smsName, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.smsHistoryArr[i].mobileNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: temp, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                { text: this.smsHistoryArr[i].sendTime, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                   { text: this.smsHistoryArr[i].smsStatus, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.smsHistoryArr[i].deliverStatus, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.smsHistoryArr[i].smsContent, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    ] );
        }
   
        this.body.push( [
               { text: 'Total SMS', fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'right',colSpan:7 },{},{},{},{},{},{},
            { text: this.totalSMS, fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'center' },
                ] );
                this.body.push( [
                    { text: 'Total Delivered SMS', fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'right',colSpan:7  },{},{},{},{},{},{},
                 { text: this.deliverdSMS, fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'center' },
                     ] );
                     this.body.push( [
                        { text: 'Total Failed SMS', fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'right',colSpan:7  },{},{},{},{},{},{},
                     { text: this.failedSMS, fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'center' },
                         ] );
                         this.body.push( [
                            { text: 'Total Pending SMS', fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'right',colSpan:7  },{},{},{},{},{},{},
                         { text: this.pendingSMS, fontSize: 11,bold:'true', font: 'TimesNewRoman', alignment: 'center' },
                             ] );
    
          this.content.push( {
            'table': {
                headerRows: 1,
                'body': this.body,
                widths: ['5%', '15%', '13%', '8%','8%', '10%', '15%', '26%']
            }
        } );
       
  
        const dd = {
            'content': this.content,
            pageOrientation: 'landscape',
            pageSize: 'A4',
            footer: ( currentPage, pageCount ) => {
                return {
                    margin: 10,
                    columns: [
                        {
                            fontSize: 9,
                            text: [
                                {
                                    text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                    '\n',
                                    margin: [0, 20]
                                },
                                {
                                    text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                }
                            ],
                            alignment: 'center'
                        }
                    ]
                };
            }
        };
  
        pdfMake.createPdf( dd ).open();
        this.body = [];
        this.content = [];
   
     }
}
