import { Component, OnInit } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { SmsAbstractPojo } from 'src/app/pojo/sms-abstract.pojo';
import { AccountsReportsService } from '../account-reports.service';
import { CreditDebitReportPojo } from 'src/app/pojo/credit-debit-report.pojo';
import { LedgerStatementService } from '../../Reports/ledger-statement/ledger-statement.service';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-cash-book-report',
  templateUrl: './cash-book-report.component.html',
  styleUrls: ['./cash-book-report.component.scss']
})
export class CashBookReportComponent implements OnInit {
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';

  startDate;
  endDate;
  resultFromDate;
  resultToDate;
  body = [];
  content = [];
  documentTime: string;
  isLoading: boolean = true;
  loggedUser = new MUserMasterPojo();
  creditDebitPojo = new CreditDebitReportPojo();
  ledgers: LedgerPojo[] = [];
  accountMasterArr:AccountMasterPojo[]=[];
  constructor(private accountMasterService:AccountMasterService,private accountsReportService: AccountsReportsService, private ledgercreationService: LedgercreationService, ) { }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

    $('.daterangeAdd').daterangepicker({ opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf('day'), endDate: moment().endOf('day') },
      (start, end, label) => {
        this.startDate = moment(start).format('YYYY-MM-DD');
        this.endDate = moment(end).format('YYYY-MM-DD');

      }
    );
    this.getAllLedger();
    this.startDate = moment().format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');
    this.isLoading = true;
    this.accountsReportService.getCashBookReportByDate(this.startDate, this.endDate, this.loggedUser.accId).subscribe(response => {
      this.creditDebitPojo = response;
      this.resultFromDate = this.startDate;
      this.resultToDate = this.endDate;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get CashBook Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
    this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
      this.ledgers = rs;
       }, error => {
      bootbox.alert('Error' + error);
    });
  }
  getLedgerById(id) {
    if (this.ledgers.find(r => r.ledgerId == id)) {
      return this.ledgers.find(r => r.ledgerId == id).ledgerName;
    }
    else {
      return id;
    }
  }

  fetch() {
    if (this.startDate == undefined && this.endDate == undefined) {
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');

    }
    this.isLoading = true;
    this.creditDebitPojo =new CreditDebitReportPojo();
    this.accountsReportService.getCashBookReportByDate(this.startDate, this.endDate, this.loggedUser.accId).subscribe(response => {
      this.creditDebitPojo = response;
      this.resultFromDate = this.startDate;
      this.resultToDate = this.endDate;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get CashBook Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
  }
  downloadPDF() {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push( {
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
     } );
    this.content.push({
      text: 'CASH BOOK REPORT\n', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
    });
    this.content.push({
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
    });
 
    this.content.push({
      text: 'REPORT FROM : ' + moment(this.resultFromDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' TO ' + moment(this.resultToDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\n\n', fontSize: 8, alignment: 'center'
    });
  this.body.push([
      { text: 'Sl.No', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,rowSpan:2 },
      { text: 'Date', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,rowSpan:2 },
      { text: 'Ref.No', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,rowSpan:2 },
      { text: 'Particulars', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,rowSpan:2 },
      { text: 'CREDIT', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,colSpan:2 },{},
      { text: 'DEBIT', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,colSpan:2 },{},
       ]);
       this.body.push([
         {},{},{},{},
         { text: 'Amount', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
        { text: 'Bank Amt', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
        { text: 'Amount', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,},
        { text: 'Bank Amt', fontSize: 11, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
         ]);
    this.body.push([
      { text: 'Opening Balance', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 4 },{}, {}, {},
      { text: this.creditDebitPojo.openingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
     { text: this.creditDebitPojo.bankOpeningBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
      { text: '', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true, }, 
      { text: '', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
    ]);

 
    if (this.creditDebitPojo.dets.length >0) {
      for (let i = 0; i <this.creditDebitPojo.dets.length; i++) {
      this.body.push(
        [
          { text: i+1, style: 'e', alignment: 'center' },
          { text: moment(this.creditDebitPojo.dets[i].transactionDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'e', alignment: 'center' },
          { text: this.creditDebitPojo.dets[i].transactionNo, style: 'e', alignment: 'center' },
          { text: this.creditDebitPojo.dets[i].receiptHeadName+'-'+this.creditDebitPojo.dets[i].narration, style: 'e', alignment: 'left' },
          { text: this.creditDebitPojo.dets[i].creditAmount.toFixed(2), style: 'e', alignment: 'right' },
       { text: this.creditDebitPojo.dets[i].creditBankAmount.toFixed(2), style: 'e', alignment: 'right' },
           { text: this.creditDebitPojo.dets[i].debitAmount.toFixed(2), style: 'e', alignment: 'right' },
         { text: this.creditDebitPojo.dets[i].debitBankAmount.toFixed(2), style: 'e', alignment: 'right' },
        ]
      );
      }
    }
    this.body.push([
      { text: 'Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 4 },{}, {}, {},
      { text: this.creditDebitPojo.creditTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankCreditTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
       { text: this.creditDebitPojo.debitTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
     { text: this.creditDebitPojo.bankDebitTotal.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);
    this.body.push([
      { text: 'Closing Balance', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 4 },{}, {}, {},
      { text: '', fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
     { text: '', fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
       { text: this.creditDebitPojo.closingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
      { text: this.creditDebitPojo.bankClosingBalance.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);
    this.body.push([
      { text: 'Overall Total Amount', fontSize: 12, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, colSpan: 4 },{}, {}, {},
      { text: this.creditDebitPojo.creditSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
    { text: this.creditDebitPojo.bankCreditSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
          { text: this.creditDebitPojo.debitSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
     { text: this.creditDebitPojo.bankDebitSum.toFixed(2), fontSize: 9, alignment: 'right', style: 'tableHeader', font: 'TimesNewRoman', bold: true, },
    ]);

    this.content.push({
      'table': {
         'body': this.body,
        widths: ['6%', '7%','8%', '47%', '8%','8%', '8%', '8%' ]
      }
    });


    const dd = {
      'content': this.content,
      pageOrientation: 'landscape',
      pageSize: 'A4',
      styles: {
        tableHeader: {
          fontSize: 8,
          alignment: 'center',
          bold: true
        },
        e: {
          fontSize: 9,
          font: 'TimesNewRoman'
        },
      },
      footer: (currentPage, pageCount) => {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 20]
                },
                {
                  text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      }
    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];

  }
}
