import { Injectable } from '@angular/core';
import { StaticVariable } from 'src/app/globle.class';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserSettingsPojo } from 'src/app/pojo/userSettingsPojo';
import { ResponsePojo } from 'src/app/pojo/response.pojo';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

constructor(private httpClient: HttpClient) { }


addUserSettings(arg: UserSettingsPojo): Observable<UserSettingsPojo> {
  const url = StaticVariable.porturl + 'AddUserSettings';
  return this.httpClient.post<UserSettingsPojo>(url, arg);
}

updateUserSettings(arg: UserSettingsPojo): Observable<UserSettingsPojo> {
  const url = StaticVariable.porturl + 'UpdateUserSettings';
  return this.httpClient.put<UserSettingsPojo>(url, arg);
}

getUserSettingsAll(): Observable<UserSettingsPojo[]> {
  const url = StaticVariable.porturl + 'GetUserSettingsAll';
  return this.httpClient.get<UserSettingsPojo[]>(url);
}

getUserSettingsById(UserSettingsId: number): Observable<UserSettingsPojo> {
  const url = StaticVariable.porturl + 'GetUserSettingsById' + '/' + UserSettingsId;
  return this.httpClient.get<UserSettingsPojo>(url);
}

getSettingsTypeList(): Observable<UserSettingsPojo[]> {
  const url = StaticVariable.porturl + 'GetSettingsTypeList';
  return this.httpClient.get<UserSettingsPojo[]>(url);
}

getUserSettingsListByTypeName(settingsType: string): Observable<UserSettingsPojo[]> {
  const url = StaticVariable.porturl + 'GetUserSettingsListByTypeName' + '/' + settingsType;
  return this.httpClient.get<UserSettingsPojo[]>(url);
}

deleteUserSettings(id: number): Observable<ResponsePojo> {
  const url = StaticVariable.porturl + 'DeleteUserSettingsById' + '/' + id;
  return this.httpClient.delete<ResponsePojo>(url);
}


}
