
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SmsTypeMasterPojo } from 'src/app/pojo/sms-master.pojo';
import { SmsMasterService } from '../sms-master/sms-master.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-sms-type-content-settings',
  templateUrl: './sms-type-content-settings.component.html',
  styleUrls: ['./sms-type-content-settings.component.scss']
})
export class SmsTypeContentSettingsComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    smsTypeArr: SmsTypeMasterPojo[] = [];
    addSmsType = new SmsTypeMasterPojo();
    editSmsType = new SmsTypeMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private smsmastetService: SmsMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getSmsTypeAll();
    }

    getSmsTypeAll() {
        this.isLoading = true;
        this.smsmastetService. getSmsTypeAllByAccId(this.loggedUser.accId).subscribe( response => {
            this.smsTypeArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addSmsType.userId = this.loggedUser.userId;
        this.addSmsType.accId=this.loggedUser.accId;
          this.smsmastetService.addSmsTypeMaster( this.addSmsType ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getSmsTypeAll();
        }, error => {
            bootbox.alert( { message: 'Add Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editSmsType = Object.assign( {}, arg );
    }

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editSmsType.userId = this.loggedUser.userId;
        this.editSmsType.accId=this.loggedUser.accId;
        this.smsmastetService.updateSmsTypeMaster( this.editSmsType ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getSmsTypeAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.smsTypeArr.indexOf( this.smsTypeArr.find( r => r.smsTypeId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.smsmastetService.deleteSmsType( this.id, this.loggedUser.loggedUserId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.smsTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.smsTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getSmsTypeAll();
        }, error => {
            bootbox.alert( { message: 'Delete  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
