export class UserSettingsPojo {

  userSettingsId: number;
settingsType: string;
settingsName: string;
valueType: string;
valueBoolean: boolean;
valueInt: number;
valueString: string;
keyDescription: string;
status: boolean;

}
