import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { StaticVariable } from "src/app/globle.class";

@Injectable( {
    providedIn: 'root'
} )

export class DepartmentMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllDepartment(accId): Observable<any> {
        const url = StaticVariable.porturl + 'GetDepartmentAll'+'/'+accId;
        return this.httpclient.get<DepartmentMasterPojo[]>( url );
    }
    
    addDepartment( arg: DepartmentMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddDepartment';
        return this.httpclient.post( url, arg );
    }
    
    updateDepartment( arg: DepartmentMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateDepartment';
        return this.httpclient.put( url, arg );
    }
    
    deleteDepartment( id: number, loggedId: number,accId ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteDepartment/' + id + '/' + loggedId+'/'+accId;
        return this.httpclient.delete( url );
    }

}