import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { HolidayListPojo } from 'src/app/pojo/holiday-list.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class HolidayListMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllHolidayListMaster(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetHolidayListAll/'+accid;
        return this.httpclient.get<HolidayListPojo[]>( url );
    }
    
    addHolidayListMaster( arg: HolidayListPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddHolidayList';
        return this.httpclient.post( url, arg );
    }
    
    updateHolidayListMaster( arg: HolidayListPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateHolidayList';
        return this.httpclient.put( url, arg );
    }
    
    deleteHolidayListMaster( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteHolidayList/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}