import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { TransferDetailsPojo } from 'src/app/pojo/tc-generation.pojo';
import { TcGenerationService } from '../tc-generation/tc-generation.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-tc-generation-report',
  templateUrl: './tc-generation-report.component.html',
  styleUrls: ['./tc-generation-report.component.scss']
})
export class TcGenerationReportComponent implements OnInit {
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    startDate;
    endDate;
    body = []; 
    content = [];
    documentTime:string;
    isLoading: boolean = true;
    loggedUser = new MUserMasterPojo();
    transArr:TransferDetailsPojo[]=[];
    classArr:ClassMasterPojo[]=[];
    resultFromDate;
    resultToDate
    tcPojo= new TransferDetailsPojo();
    academic:AcademicYearMasterPojo[]=[];
    accountMasterArr:AccountMasterPojo[]=[];
  constructor(private transferService:TcGenerationService,private classMasterService:ClassMasterService,
    private settingService:AcademicSettingService,private tcGenerateService:TcGenerationService,
    private accountMasterService:AccountMasterService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
    $( '#editAfterClick' ).hide();
    $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true, locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
        ( start, end, label ) => {
            this.startDate = moment(start).format('YYYY-MM-DD');
            this.endDate = moment(end).format('YYYY-MM-DD');
             
             }
    );
    this.academicSettingAll();
    this.getClassMasterAll();
      this.startDate = moment().format('YYYY-MM-DD');
      this.endDate = moment().format('YYYY-MM-DD');
      this.transArr=[];
      this.isLoading = true;
      this.transferService.getTransferDetailsByDate(this.startDate,this.endDate,this.loggedUser.accId).subscribe( response => {
          this.transArr = response;
          this.resultFromDate=this.startDate;
          this.resultToDate=this.endDate;
         
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get TC Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
      this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  } getAccountDistrictById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).district;
    } else {
      return id;
    }
  }
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
         }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
   getAcademicYrById( id ) {
    if ( this.academic.find( r => r.academicYearId == id ) ) {
        return this.academic.find( r => r.academicYearId == id ).academicYear;
    }
    else {
        return id;
    }
}
  fetch() {
    if(this.startDate == undefined && this.endDate == undefined){
     this.startDate = moment().format('YYYY-MM-DD');
     this.endDate = moment().format('YYYY-MM-DD');
               
    }
    this.transArr=[];
     this.isLoading = true;
     this.transferService.getTransferDetailsByDate(this.startDate,this.endDate,this.loggedUser.accId).subscribe( response => {
         this.transArr = response;
         this.resultFromDate=this.startDate;
         this.resultToDate=this.endDate;
        
         this.isLoading = false;
     }, error => {
         bootbox.alert( { message: 'Get TC Report Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
         this.isLoading = false;
     } );
 }
 getClassMasterAll() {
  this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
      this.classArr = response;
      this.isLoading = false;
  }, error => {
      bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;
  } );
}
getClassNameById( id ) {
  if ( this.classArr.find( r => r.classId == id ) ) {
      return this.classArr.find( r => r.classId == id ).className;
  }
  else {
      return id;
  }
}
clickEdit(item:TransferDetailsPojo){
  this.tcPojo=item;
  if(this.tcPojo.applicationDate != null && this.tcPojo.applicationDate != undefined){
    ( <HTMLFormElement>document.getElementById( 'addApplicationDate' ) ).value = this.tcPojo.applicationDate;
    }
    if(this.tcPojo.dateOfIssue != null && this.tcPojo.dateOfIssue != undefined){
        ( <HTMLFormElement>document.getElementById( 'addIssuesDate' ) ).value = this.tcPojo.dateOfIssue;
        }
        if(this.tcPojo.actuallyLeftDate != null && this.tcPojo.actuallyLeftDate != undefined){
          ( <HTMLFormElement>document.getElementById( 'addSchoolLeftDate' ) ).value = this.tcPojo.actuallyLeftDate;
          }
}
edit() {
  ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
  $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
 this.tcPojo.feeDue = moment().toDate();
 this.tcPojo.dateOfIssue=moment().toDate();
this.tcPojo.actuallyLeftDate=moment(( <HTMLInputElement>document.getElementById( 'addSchoolLeftDate' ) ).value).toDate();
this.tcPojo.applicationDate=moment(( <HTMLInputElement>document.getElementById( 'addApplicationDate' ) ).value).toDate();
this.tcPojo.dateOfIssue=moment(( <HTMLInputElement>document.getElementById( 'addIssuesDate' ) ).value).toDate();
this.tcPojo.accId = this.loggedUser.accId;
  this.tcPojo.userId = this.loggedUser.userId;
  let d=moment(this.tcPojo.doa,'YYYY-MM-DD').format('YYYY');
  let e=moment(this.tcPojo.actuallyLeftDate,'YYYY-MM-DD').format('YYYY');
  this.tcPojo.academicYears=d+' - '+e;

    this.tcGenerateService.updateTransfer(this.tcPojo).subscribe( response => {
    
      this.tcPojo= new TransferDetailsPojo();
    this.tcPojo=response;
    bootbox.alert({message: "Successfully Updated", className: 'text-success h5' });
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        $( '#editModal' ).modal( 'hide' );
          }, error => {
      bootbox.alert( { message: 'Update  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
      $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
  } );
 
}


 downloadPDF() {
       
  this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
   this.content.push( {
       text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
      } );
   this.content.push( {
       text: 'STUDENT TRANSFER CERTIFICATE REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
   } );

   this.content.push( {
       text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
       fontSize: 10, bold: true, alignment: 'center'
   } );
   this.content.push( {text: 'REPORT DATE FROM : '+ moment(this.resultFromDate,'YYYY-MM-DD').format('DD-MM-YYYY')+' TO '+moment(this.resultToDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
 } );

   this.content.push( {
       text: '\n', fontSize: 8, alignment: 'center'
   } );

   this.body.push( [
       { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
       { text: 'Issue Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
       { text: 'Certificate Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
       { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
       { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
       { text: 'Class', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        ] );

   for ( let i = 0; i < this.transArr.length; i++ ) {
     
       this.body.push( [
           { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
           { text: moment(this.transArr[i].dateOfIssue,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
           { text: this.transArr[i].certificateNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
           { text: this.transArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
          { text: this.transArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
           { text: this.getClassNameById(this.transArr[i].lastStudiedClassId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
               ] );
   }

   this.content.push( {
       'table': {
           headerRows: 1,
           'body': this.body,
           widths: ['5%', '12%', '15%', '15%', '43%', '10%']
       }
   } );
  

   const dd = {
       'content': this.content,
       pageOrientation: 'landscape',
       pageSize: 'A4',
       footer: ( currentPage, pageCount ) => {
           return {
               margin: 10,
               columns: [
                   {
                       fontSize: 9,
                       text: [
                           {
                               text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                               '\n',
                               margin: [0, 20]
                           },
                           {
                               text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                           }
                       ],
                       alignment: 'center'
                   }
               ]
           };
       }
   };

   pdfMake.createPdf( dd ).open();
   this.body = [];
   this.content = [];

}

}

