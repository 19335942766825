import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MUserMasterPojo, ManagementRolePojo } from "src/app/pojo/management-user-pojo";

declare var bootbox: any;

@Injectable()

export class ManagementAuthenticationGuard implements CanActivate {

    loggedUser = new MUserMasterPojo();
    role = new ManagementRolePojo();

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        console.log(this.loggedUser);
        this.role = JSON.parse( this.loggedUser.roleKey.roleKey );
console.log(this.role);
        switch ( route.data.roles ) {
            case 'studentRegister':
                if ( this.role.studentRegister ) return true;
                break;
            case 'smsCreation':
                if ( this.role.smsCreation ) return true;
                break;
            case 'staffMaster':
                if ( this.role.staffMaster ) return true;
                break;
            case 'staffTypeMaster':
                if ( this.role.staffTypeMaster ) return true;
                break;
            case 'departmentMaster':
                if ( this.role.departmentMaster ) return true;
                break;
            case 'sectionMaster':
                if ( this.role.sectionMaster ) return true;
                break;
            case 'classMaster':
                if ( this.role.classMaster ) return true;
                break;
            case 'studentMaster':
                if ( this.role.studentMaster ) return true;
                break;
            case 'roleMaster':
                if ( this.role.roleMaster ) return true;
                break;
            case 'userCreation':
                if ( this.role.userCreation ) return true;
                break;
            case 'userSettings':
                 console.log('ddd')
                 if(this.role.userSettings) return true;
                 break;
            case 'changePassword':
                if ( this.role.changePassword ) return true;
                break;
            case 'editStudentRegister':
                if ( this.role.editStudentRegister ) return true;
                break;
            case 'smsMaster':
                if ( this.role.smsMaster ) return true;
                break;
            case 'editSmsMaster':
                if ( this.role.editSmsMaster ) return true;
                break;
            case 'smsTemplateMaster':
                if ( this.role.smsTemplateMaster ) return true;
                break;
            case 'journalentry':
                if ( this.role.journalentry ) return true;
                break;
            case 'ledgercreation':
                if ( this.role.ledgercreation ) return true;
                break;
            case 'accountmaster':
                if ( this.role.accountmaster ) return true;
                break;
            case 'payment':
                if ( this.role.payment ) return true;
                break;
            case 'receiptentry':
                if ( this.role.receiptentry ) return true;
                break;
            case 'receipthead':
                if ( this.role.receipthead ) return true;
                break;
                case 'subjectmaster':
                if ( this.role.subjectmaster ) return true;
                break;
                case 'receiptDetails':
                    if ( this.role.receiptDetails ) return true;
                    break;
                    case 'quickAdmission':
                    if ( this.role.quickAdmission ) return true;
                    break;
                    case 'academicSetting':
                    if ( this.role.academicSetting ) return true;
                    break;
                    case 'genderwise':
                    if ( this.role.genderwise ) return true;
                    break;
                    case 'classwise':
                    if ( this.role.classwise ) return true;
                    break;
                    case 'communitywise':
                        if ( this.role.communitywise ) return true;
                        break;
                        case 'ptrintstudentmaster':
                            if ( this.role.ptrintstudentmaster ) return true;
                            break;
                            case 'physicallydisabled':
                            if ( this.role.physicallydisabled ) return true;
                            break;
                            case 'communitymaster':
                            if ( this.role.communitymaster ) return true;
                            break;
                            case 'castemaster':
                            if ( this.role.castemaster ) return true;
                            break;
                            case 'strength':
                                if ( this.role.strength ) return true;
                                break;
                                case 'promotion':
                                    if ( this.role.promotion ) return true;
                                    break;
                                    case 'sectionallocation':
                                        if ( this.role.sectionallocation ) return true;
                                        break;
                                        case 'feecollection':
                                    if ( this.role.feecollection ) return true;
                                    break;
                                    case 'feeallocation':
                                        if ( this.role.feeallocation ) return true;
                                        break;
                                        case 'term':
                                            if ( this.role.term ) return true;
                                            break;
                                            case 'standardstructure':
                                                if ( this.role.standardstructure ) return true;
                                                break;
                                                case 'entryJournal':
                                                    if ( this.role.entryJournal ) return true;
                                                    break;
                                                    case 'entryReceipt':
                                                        if ( this.role.entryReceipt ) return true;
                                                        break;
                                                        case 'entryPayment':
                                                            if ( this.role.entryPayment ) return true;
                                                            break; 
                                                            case 'ledgerStatement':
                                                                if ( this.role.ledgerStatement ) return true;
                                                                break;  
                                                                 case 'examMaster':
                                                                if ( this.role.examMaster ) return true;
                                                                break; 
                                                                case 'holidayList':
                                                                    if ( this.role.holidayList ) return true;
                                                                    break;  
                                                                     case 'hoursMaster':
                                                                    if ( this.role.hoursMaster ) return true;
                                                                    break;
                                                                    case 'arrearsReport':
                                                                        if ( this.role.arrearsReport ) return true;
                                                                        break; 
                                                                        case 'attendanceEntry':
                                                                        if ( this.role.attendanceEntry ) return true;
                                                                        break; 
                                                                        case 'notesEntry':
                                                                            if ( this.role.notesEntry ) return true;
                                                                            break; 
                                                                        case 'notesMaster':
                                                                            if ( this.role.notesMaster ) return true;
                                                                            break; 
                                                                            case 'daywiseAttendanceEntry':
                                                                            if ( this.role.daywiseAttendanceEntry ) return true;
                                                                            break; 
                                                                            case 'standardTermWiseReport':
                                                                                if ( this.role.standardTermWiseReport ) return true;
                                                                                break; 
                                                                                case 'discountMaster':
                                                                                if ( this.role.discountMaster ) return true;
                                                                                break;
                                                                                case 'discountFixing':
                                                                                    if ( this.role.discountFixing ) return true;
                                                                                    break; 
                                                                                    case 'discountTypeMaster':
                                                                                        if ( this.role.discountTypeMaster ) return true;
                                                                                        break;
                                                                                        case 'areaMaster':
                                                                                            if ( this.role.areaMaster ) return true;
                                                                                            break; 
                                                                                            case 'examSchedule':
                                                                                                if ( this.role.examSchedule ) return true;
                                                                                                break;
                                                                                                case 'studentMarkList':
                                                                                                    if ( this.role.studentMarkList ) return true;
                                                                                                    break;  
                                                                                                    case 'studExamReport':
                                                                                                        if ( this.role.studExamReport ) return true;
                                                                                                        break; 
                                                                                                        case 'busFeeAllocation':
                                                                                                            if ( this.role.busFeeAllocation ) return true;
                                                                                                            break; 
                                                                                                            case 'tcGeneration':
                                                                                                                if ( this.role.tcGeneration ) return true;
                                                                                                                break;
                                                                                                                case 'tcGenerationReport':
                                                                                                                    if ( this.role.tcGenerationReport ) return true;
                                                                                                                    break; 
                                                                                                                    case 'examMarkSheet':
                                                                                                                    if ( this.role.examMarkSheet ) return true;
                                                                                                                    break; 
                                                                                                                    case 'refund':
                                                                                                                        if ( this.role.refund ) return true;
                                                                                                                        break;
                                                                                                                        case 'overallAccounts':
                                                                                                                            if ( this.role.overallAccounts ) return true;
                                                                                                                            break; 
                                                                                                                            case 'refundReport':
                                                                                                                                if ( this.role.refundReport ) return true;
                                                                                                                                break;
                                                                                                                                case 'feeCollectionDetails':
                                                                                                                                    if ( this.role.feeCollectionDetails ) return true;
                                                                                                                                    break;
                                                                                                                                    case 'smsSettings':
                                                                                                                                        if ( this.role.smsSettings ) return true;
                                                                                                                                        break;
                                                                                                                                        case 'smsContentSettings':
                                                                                                                                            if ( this.role.smsContentSettings ) return true;
                                                                                                                                            break;
                                                                                                                                            case 'absentList':
                                                                                                                                            if ( this.role.absentList ) return true;
                                                                                                                                            break;
                                                                                                                                            case 'feeAllocationSms':
                                                                                                                                                if ( this.role.feeAllocationSms ) return true;
                                                                                                                                                break;
                                                                                                                                                case 'busStudentsReport':
                                                                                                                                                if ( this.role.busStudentsReport ) return true;
                                                                                                                                                break;
                                                                                                                                                case 'attendanceGen':
                                                                                                                                                    if ( this.role.attendanceGen ) return true;
                                                                                                                                                    break;
                                                                                                                                                    case 'bonafideGen':
                                                                                                                                                        if ( this.role.bonafideGen ) return true;
                                                                                                                                                        break;
                                                                                                                                                        case 'conductGen':
                                                                                                                                                            if ( this.role.conductGen ) return true;
                                                                                                                                                            break;
                                                                                                                                                            case 'courseGen':
                                                                                                                                                                if ( this.role.courseGen ) return true;
                                                                                                                                                                break;
                                                                                                                                                                case 'genuineGen':
                                                                                                                                                                    if ( this.role.genuineGen ) return true;
                                                                                                                                                                    break;
                                                                                                                                                                    case 'certificateReprint':
                                                                                                                                                                    if ( this.role.certificateReprint ) return true;
                                                                                                                                                                    break;
                                                                                                                                                                    case 'attendanceReport':
                                                                                                                                                    if ( this.role.attendanceReport ) return true;
                                                                                                                                                    break;
                                                                                                                                                    case 'bonafideReport':
                                                                                                                                                        if ( this.role.bonafideReport ) return true;
                                                                                                                                                        break;
                                                                                                                                                        case 'conductReport':
                                                                                                                                                            if ( this.role.conductReport ) return true;
                                                                                                                                                            break;
                                                                                                                                                            case 'courseReport':
                                                                                                                                                                if ( this.role.courseReport ) return true;
                                                                                                                                                                break;
                                                                                                                                                                case 'genuineReport':
                                                                                                                                                                    if ( this.role.genuineReport ) return true;
                                                                                                                                                                    break;
                                                                                                                                                                    case 'resultPostingSms':
                                                                                                                                                                        if ( this.role.resultPostingSms ) return true;
                                                                                                                                                                        break;
                                                                                                                                                                        case 'cashBookReport':
                                                                                                                                                                            if ( this.role.cashBookReport ) return true;
                                                                                                                                                                            break;
                                                                                                                                                                            case 'incomeExpenditureReport':
                                                                                                                                                                            if ( this.role.incomeExpenditureReport ) return true;
                                                                                                                                                                            break; case 'trialbalance':
                                                                                                                                                                            if ( this.role.trialbalance ) return true;
                                                                                                                                                                            break;case 'event':
                                                                                                                                                                            if ( this.role.event ) return true;
                                                                                                                                                                            break;case'leaveApplication':
                                                                                                                                                                            if(this.role.leaveApplication) return true;
                                                                                                                                                                            break;case'studentRemarks':
                                                                                                                                                                            if(this.role.studentRemarks) return true;
                                                                                                                                                
                                                                                                                                               case 'smsHistory':
                                                                                                                                            if ( this.role.smsHistory ) return true;
        }
        bootbox.alert( { message: 'ACCESS DENIED!', className: 'text-danger text-center h2 bounceIn animated', centerVertical: true, closeButton: false } );
        return false;
    }
}