
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ArrearReportPojo, ArrearReportStudentDetailsPojo } from 'src/app/pojo/arrears-report.pojo';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceResponsePojo, AttendancePojo } from 'src/app/pojo/attendance.pojo';
import { AttendanceEntryService } from './attendance-entry.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-attendance-entry',
  templateUrl: './attendance-entry.component.html',
  styleUrls: ['./attendance-entry.component.scss']
})
export class AttendanceEntryComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
  
    fromDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

      sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltDate:number;
    searchOption = 'ALL';
    typeId;
    academic:AcademicYearMasterPojo[] =[];
    academicYear;
     feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
    standTermArr: StandardTermPojo[] = [];
    classPojo = new ClassMasterPojo();
    hoursId:number;
    attendanceRes = new AttendanceResponsePojo();
    attendanceArr:AttendancePojo[]=[];
    resultHoursId:number;
    updateArr:AttendancePojo[]=[];
    finalEntryResArr:AttendancePojo[] =[];
    accountMasterArr:AccountMasterPojo[]=[];
     constructor( private standardTermService:StandardStructureMasterService,
        private feeAllocationService:FeeAllocationMasterService,private settingService:AcademicSettingService,
        private reportService:ReportService,private studentMasterService: StudentMasterService, 
        private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, 
        private classMasterService: ClassMasterService, private router: Router,
        private accountMasterService:AccountMasterService,private attendanceService:AttendanceEntryService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        (<HTMLFormElement>document.getElementById('fromEntryDate')).value = moment().format('YYYY-MM-DD');
   
        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.academicSettingAll();
        this.getStandrdTermAll();
            
        this.accountMasterAll();
    }
    accountMasterAll() {
      this.accountMasterService.getAllAccountMaster().subscribe(response => {
        this.accountMasterArr = response;
       
      }, error => {
        bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
      });
    }
    getAccountNameById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).accName;
      } else {
        return id;
      }
    }
    getAccountPlaceById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).place;
      } else {
        return id;
      }
    }
    academicSettingAll(){
      this.academic = [];
      this.settingService.getAcademicYrAll().subscribe( response => {
          this.academic = response;
          for(let i=0;i<this.academic.length;i++){
              if(this.academic[i].academicFlag == 1){
                  this.academicYear = this.academic[i].academicYearId;
              }
          }
             }, error => {
          bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
           } );
     }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionMasterArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classMasterArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getClassMasterHoursListById() {
        this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe( response => {
            this.classPojo = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
  getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getStandrdTermAll() {
         this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
            this.standTermArr = response;
               }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
      }
    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getSectionNameById( id ) {
        if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
            return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }
    getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        } else {
            return id;
        }
      }
      getStandardTermById( id ) {
        if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
            return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
        } else {
            return id;
        }
      }
      getHoursNameById( id ) {
        if ( this.classPojo.list.find( r => r.hoursId == id ) ) {
            return this.classPojo.list.find( r => r.hoursId == id ).hoursName;
        } else {
            return id;
        }
      }
     getReportById() {
        this.attendanceArr=[];
        this.isLoading = true;
        this.attendanceRes = new AttendanceResponsePojo();
        let arg1 =(<HTMLFormElement>document.getElementById('fromEntryDate')).value;
       
        this.attendanceService.getAttendaceEntryList( this.academicYear, this.classid, this.sectionid,arg1,this.hoursId,this.loggedUser.accId ).subscribe( response => {
             if(response.code == 101){
                bootbox.alert('Selected Date is Holiday!');
            }
            else if(response.code == 102){
                bootbox.confirm('Attendance already Completed !\n Do you want to Edit ?', ( result ) => {
                    if(result){
                        this.attendanceRes = response;
                       this.attendanceArr = this.attendanceRes.list;
                        this.resultDept= this.departmentId;
                        this.resultClass= this.classid;
                        this.resultSection=this.sectionid;
                        this.resultHoursId = this.hoursId;
                         this.isLoading = false;
                    }else{
                        this.isLoading = false;
                    }
                });
               }else{
                this.attendanceRes = response;
                this.attendanceArr = this.attendanceRes.list;
                this.resultDept= this.departmentId;
            this.resultClass= this.classid;
            this.resultSection=this.sectionid;
            this.resultHoursId = this.hoursId;
            this.resulltDate = arg1;
           }
                this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    submitAttendance(){
        this.isLoading = true;
        this.finalEntryResArr=[];
        this.attendanceRes.hoursId = this.resultHoursId;
        this.attendanceRes.classId = this.resultClass;
        this.attendanceRes.sectionId = this.resultSection;
        this.attendanceRes.academicYearId = this.academicYear;
        this.attendanceRes.attendanceDate = this.resulltDate;
        this.attendanceRes.list =[];
        this.attendanceRes.list = this.attendanceArr;
        this.attendanceRes.accId=this.loggedUser.accId;
       this.attendanceService.addAttendanceLis( this.attendanceRes ).subscribe( response => {
            this.attendanceRes = new AttendanceResponsePojo();
                this.attendanceRes = response;
                this.finalEntryResArr = this.attendanceRes.list;
                 this.attendanceArr=[];
            this.Reset();
            bootbox.confirm('Submitted Successfulyy !\n Do you want to Download PDF ?', ( result ) => {
                if(result){
                    this.downloadPDF();
                }
            });this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Submit Attendance ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }
    onChangeAttendanceStatus(item){
        let e = new AttendancePojo();
       e = item;
       if(e.attendanceStatus == 'PRESENT'){
        e.reason ="";
    }
          if(this.updateArr.length > 0){
             for ( let i = 0; i < this.updateArr.length; i++ ) {
               if(this.updateArr[i].studentId == e.studentId){
                       this.updateArr.splice( i );
                       i++;
                    this.updateArr.push(e);
                    }else{
                   this.updateArr.push(e); 
               }
           } 
       }else{
              this.updateArr.push(e);  
       }
         }
         updateAttendance(){
             if(this.updateArr.length>0){
                this.isLoading = true;
                this.finalEntryResArr =[];
                  this.attendanceService.updateAttendanceList(  this.updateArr).subscribe( response => {
                this.attendanceRes = new AttendanceResponsePojo();
                this.finalEntryResArr = response;
                  this.updateArr =[];
               this.attendanceArr =[];
               this.Reset();
                this.attendanceRes = response;
                bootbox.confirm('Submitted Successfulyy !\n Do you want to Download PDF ?', ( result ) => {
                    if(result){
                        this.downloadPDF();
                    }
                  });  this.isLoading = false;
            }, error => {
                bootbox.alert( { message: 'Submit Attendance ' + error.status, className: "text-danger h5", closeButton: false } );
                this.isLoading = false;
            } );  
        }else{
            bootbox.alert('No Records to be Editted');
        }
        }
        Reset(){
            this.classid=0;
            this.departmentId=0;
            this.sectionid=0;
            this.hoursId=0;
            this.fromDate=null;
        }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
                this.content.push( {
                    text: 'ATTENDANCE ENTRY REPORT\t[ '+moment(this.resulltDate,'YYYY-MM-DD').format('DD-MM-YYYY') +' ]', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
                } );
           
           this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
       this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+ this.getSectionNameById(this.resultSection), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );
       this.content.push( {text: '\nSELECTED HOURS:'+this.getHoursNameById(this.resultHoursId), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
      } );
  
         this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Roll Number', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
              { text: 'Status', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Reason', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                 ] );
   
         for ( let i = 0; i < this.finalEntryResArr.length; i++ ) {
            
             this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].rollNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.finalEntryResArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left', },
                   { text: this.finalEntryResArr[i].attendanceStatus, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                    { text: this.finalEntryResArr[i].reason, fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                     ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '15%','15%', '35%', '10%', '20%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
     
}
