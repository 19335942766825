
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import { PaymentEntryService } from "src/app/management/Accounts/payment/payment.service";
import { LedgerPojo } from "src/app/pojo/ledger.pojo";
import { PaymentPojo } from "src/app/pojo/payment-pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LedgercreationService } from "src/app/management/Masters/ledgercreation/ledgercreation.service";
import { AccountMasterPojo } from "src/app/pojo/account-master.pojo";
import { AccountMasterService } from "src/app/management/Admin/account-master/account-master.service";
import { ReceiptEntryService } from './receipt-entry.service';
import { ReceiptPojo } from 'src/app/pojo/receipt.pojo';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { ReceiptHeadService } from '../receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-receipt-entry',
  templateUrl: './receipt-entry.component.html',
  styleUrls: ['./receipt-entry.component.scss']
})
export class ReceiptEntryComponent implements OnInit {
    model: NgbDateStruct;
   date: { year: number, month: number };
   form: boolean = false;
   
   
   editEntryDate: NgbDateStruct;
   
   editInstrumentDate: NgbDateStruct;
   
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    ledgers: LedgerPojo[];
    
    editReceipt = new ReceiptPojo();
    receipts: ReceiptPojo[] = [];
    receiptPojo = new ReceiptPojo();
    receiptHeadArr:ReceiptHeadPojo[] = [];
    receiptSpecHeadArr:ReceiptHeadPojo[]=[];
    body = [];
    content = [];
    documentTime:string;
    //picksledgersByAccountGroup: LedgerPojo[] = [];
    pickspaidledgersByAccountGroup: LedgerPojo[] = [];
    ledgerId: LedgerPojo[];
    //public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    editEntryAmount:number;
    addEntryAmount:number;
    accountMasterArr: AccountMasterPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    specLedgers:LedgerPojo[]=[];
    ledgerGrpArr:LedgerGroupPojo[]=[];
    constructor( private receiptService: ReceiptEntryService,
        private receiptheadService:ReceiptHeadService, private ledgercreationService: LedgercreationService,private accountmasterserivce: AccountMasterService,
        private settingService:AcademicSettingService,private departmentMasterService: DepartmentMasterService,private studentMasterService:StudentMasterService
           ) { }

  ngOnInit() {
     
     
      $( '#editAfterClick' ).hide();
      $( '#deleteAfterClick' ).hide();
      this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
      this.getLedgerByAccountGroup();
      this.getAllLedger();
      this.getAllReceipt();
      this.getDepartmentAll();
      this.getAccountMasterAll();
      this.getAllReceiptHead();
      this.getStudentAll();
      this.academicSettingAll();
      this.getLedgerGrpAll();
  }
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
  getAccountMasterAll() {
      this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
          this.accountMasterArr = response;
          }, error => {
          bootbox.alert( { message: 'Get Account All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
         } );
  }getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  getLedgerGrpAll() {
    this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
        this.ledgerGrpArr = response;
      }, error => {
        bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
  }
  getLedgerByLedgerGroupId(event){
    this.specLedgers=[];
    if(this.editReceipt.ledgerGroupId>0){
    this.ledgercreationService.getLedgerByLedgerGroupId(this.editReceipt.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
      this.specLedgers = response;
    }, error => {
      bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    } );
  }
  }
  getAllReceiptHead() {
     this.receiptheadService.getReceiptHeadAll(this.loggedUser.accId).subscribe( rs => {
        this.receiptHeadArr = rs;
       
    }, error => {
        bootbox.alert( 'Error' + error );
       } );
}
  getLedgerByAccountGroup() {
      this.ledgercreationService.getLedgerByType( 'PAID',this.loggedUser.accId).subscribe( log => {
          this.pickspaidledgersByAccountGroup = log;
      } );
  }

  getLedgerById( id ) {

    if ( this.ledgers.find( r => r.ledgerId == id ) ) {
        return this.ledgers.find( r => r.ledgerId == id ).ledgerName;
    }
    else {
        return id;
    }
}
getStudentNameById( id ) {

    if ( this.studentMasterArr.find( r => r.studentId == id ) ) {
        return this.studentMasterArr.find( r => r.studentId == id ).name;
    }
    else {
        return id;
    }
}
getPaidLedgerById( id ) {

  if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
      return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
  }
  else {
      return id;
  }
}
  getAllLedger() {
      this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
          this.ledgers = rs;
      }, error => {
          alert( error );
      } );
  }  
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
         this.departmentArr = run;
    }, error => {
        bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
       } );
}
  getStudentAll() {
   // this.isLoading = true;
    this.studentMasterService.getStudentAll(this.loggedUser.accId).subscribe( response => {
        this.studentMasterArr = response;
       // this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Student By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
      //  this.isLoading = false;
    } );
}

  clickAdd(){
   /* this.receiptService.getAutoGenerateReceiptNo().subscribe( rs => {
        this.addReceipt.receiptNo = rs;
         }, error => {
        bootbox.alert( 'Error' + error );
        } );*/
  }
  getAllReceipt() {
      this.isLoading = true;
      this.receiptService.getReceiptAll(this.loggedUser.accId).subscribe( rs => {
          this.receipts = rs;
          this.isLoading = false;

      }, error => {
          bootbox.alert( 'Error' + error );
          this.isLoading = false;
      } );
  }
  getAllReceiptHeadByLedgerId() {
    this.receiptSpecHeadArr=[];
     this.receiptheadService.getReceiptHeadByLedgerId(this.editReceipt.ledgerId,this.loggedUser.accId).subscribe( rs => {
        this.receiptSpecHeadArr = rs;
       
    }, error => {
        bootbox.alert( 'Error' + error );
       } );
  }
  clickEdit( arg ) {
      this.editReceipt = Object.assign( {}, arg );
      if(this.editReceipt.receiptDate != null && this.editReceipt.receiptDate != undefined){
        ( <HTMLFormElement>document.getElementById( 'editReceiptDate' ) ).value = this.editReceipt.receiptDate;
        }
        if(this.editReceipt.instrumentDate != null && this.editReceipt.instrumentDate != undefined){
            ( <HTMLFormElement>document.getElementById( 'editInstrumenEntrytDate' ) ).value = this.editReceipt.instrumentDate;
            }
            this.getAllReceiptHeadByLedgerId();
            this.getLedgerByLedgerGroupId(event);
         }  

  editClass() {
      let editEntry = new ReceiptPojo();
      this.editReceipt.accId = this.loggedUser.accId;
      this.editReceipt.userId = this.loggedUser.userId;
      this.editReceipt.detList =[];
      editEntry = this.editReceipt;
       ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
      $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
      this.editReceipt.receiptDate = moment(( <HTMLInputElement>document.getElementById( 'editReceiptDate' ) ).value ).toDate();
      this.editReceipt.instrumentDate = moment(( <HTMLInputElement>document.getElementById( 'editInstrumenEntrytDate' ) ).value ).toDate();

         this.receiptService.updateReceipt(editEntry).subscribe( response => {
          bootbox.alert( { message: 'Receipt has been successfully updated.', className: 'text-success h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
          $( '#editModal' ).modal( 'hide' );
          this.getAllReceipt();
      }, error => {
          bootbox.alert( { message: 'Update Receipt Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
          $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
      } );
  }

  delete( arg ) {
      this.id = arg;
  }

  deleteConfirm() {
      let no;
      const i = this.receipts.indexOf( this.receipts.find( r => r.receiptId == this.id ) );
      $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
      this.receiptService.deleteReceipt( this.id, this.loggedUser.loggedUserId ).subscribe( response => {
          no = response;
          if ( no == 0 ) {
              bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
              this.receipts.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          } else {
              this.receipts.splice( i, 1 );
              $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          }
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
          $( '#deleteModal' ).modal( 'hide' );
          this.getAllReceipt();
      }, error => {
          bootbox.alert( { message: 'Delete  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
      } );
  }
  downloadPDF() {
       
    this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
    this.content.push( {
        text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
       } );
        this.content.push( {text: 'RECEIPT ENTRY REPORT  ', fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
    } );
       this.content.push( {
         text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
         fontSize: 10, bold: true, alignment: 'center'
     } );
     
   
     this.content.push( {
         text: '\n', fontSize: 8, alignment: 'center'
     } );

     this.body.push( [
         { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Receipt Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Receipt No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
          { text: 'Ledger Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Paid Ledger', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         { text: 'Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            ] );

     for ( let i = 0; i < this.receipts.length; i++ ) {
        
         this.body.push( [
             { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: moment(this.receipts[i].receiptDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.receipts[i].receiptNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text: this.getLedgerById(this.receipts[i].ledgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'left', },
              { text: this.getPaidLedgerById(this.receipts[i].paidType), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
              { text: this.receipts[i].amount.toFixed(2), fontSize: 9, font: 'TimesNewRoman', alignment: 'right' },
                  ] );
     }

     this.content.push( {
         'table': {
             headerRows: 1,
             'body': this.body,
             widths: ['5%', '12%', '12%','29%', '30%', '12%']
         }
     } );
    

     const dd = {
         'content': this.content,
         pageOrientation: 'landscape',
         pageSize: 'A4',
         footer: ( currentPage, pageCount ) => {
             return {
                 margin: 10,
                 columns: [
                     {
                         fontSize: 9,
                         text: [
                             {
                                 text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                 '\n',
                                 margin: [0, 20]
                             },
                             {
                                 text:this.getAccountPlaceById(this.loggedUser.accId)+ ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                             }
                         ],
                         alignment: 'center'
                     }
                 ]
             };
         }
     };

     pdfMake.createPdf( dd ).open();
     this.body = [];
     this.content = [];

 }
}
