import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";

import { Http } from '@angular/http';
import { DiscountFixingPojo } from 'src/app/pojo/discount-fixing.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class DiscountFixingService {

    constructor( private httpclient: HttpClient,private http: Http ) { }

    getDiscountFixingAll(accid): Observable<DiscountFixingPojo[]> {
        const url = StaticVariable.porturl + 'GetDiscountFixingAll/'+accid;
        return this.httpclient.get<DiscountFixingPojo[]>( url );
    }
    
    addDiscountFixing( arg: DiscountFixingPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddDiscountFixing';
        return this.httpclient.post( url, arg );
    }
    
    updateDiscountFixing( arg: DiscountFixingPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateDiscountFixing';
        return this.httpclient.put( url, arg );
    }
    
    deleteDiscountFixing( arg: number, uid:number,accid:number  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteDiscountFixing/' + arg +'/' + uid+'/'+accid;
        return this.httpclient.delete( url );
    }
    getDiscountFixingById(id,accid:number): Observable<DiscountFixingPojo[]> {
        const url = StaticVariable.porturl + 'GetDiscountFixingById/'+id+'/'+accid;
        return this.httpclient.get<DiscountFixingPojo[]>( url );
    }
}