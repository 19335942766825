
import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo, NotesMasterPojo } from "src/app/pojo/student-master.pojo";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { Subject, Observable } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

declare var $: any;
declare var bootbox: any;

import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { AcademicSettingService } from 'src/app/management/Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import * as printJs from 'print-js'
import { NotesMasterService } from '../../Masters/notes-master/notes-master.service';
import { TransferDetailsPojo, TransferDetailsResponsePojo } from 'src/app/pojo/tc-generation.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { TcGenerationService } from './tc-generation.service';
import * as converter from 'number-to-words';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
@Component({
  selector: 'app-tc-generation',
  templateUrl: './tc-generation.component.html',
  styleUrls: ['./tc-generation.component.scss'],
  providers:[AcademicSettingService]
})
export class TcGenerationComponent implements OnInit {


    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    loggedUser = new MUserMasterPojo();
    tcPojo = new TransferDetailsPojo();
    tcResponse = new TransferDetailsResponsePojo();
    editStudentMaster = new StudentMasterPojo();
    dueDate;

    mediumArr: MediumMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];

    imagePojo: FtpImagePojo = new FtpImagePojo();

    isLoading: boolean = true;
   
    academic:AcademicYearMasterPojo[] =[];
    departmentArr:DepartmentMasterPojo[]=[];
    path;
    body = [];
    content = [];
    documentTime:string;
    departmentId;
    classid;
    sectionid;
    studentid;
    currentClassId;
    studentMasterArr:AcademicStandardDetailsPojo[]=[];
    accountMasterArr:AccountMasterPojo[]=[];
   

    constructor( private activatedRoute: ActivatedRoute, private router: Router, private calendar: NgbCalendar, 
        private mediumMasterService: MediumMasterService, private sectionMasterService: SectionMasterService, 
        private studentMasterService: StudentMasterService, private classMasterService: ClassMasterService,
        private notesMasterService:NotesMasterService,private settingService:AcademicSettingService,
        private departmentMasterService:DepartmentMasterService,
        private tcGenerateService:TcGenerationService,private accountmasterserivce:AccountMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
       $( '#addAfterClick' ).hide();
        this.academicSettingAll();
        this.getDepartmentAll();
        this.getMediumMasterAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
         $( '#addResult' ).focus();  
         this.getAutoCertificateNo();
         this.getAccountMasterAll();
         }  
    getStudentMaster() {
      if ( this.departmentId != "" && this.classid != "" && this.sectionid != "" ) {
           this.studentMasterArr = [];
          this.isLoading = true;
           this.studentMasterService.getDiscontinueStudentsById( this.departmentId, this.classid, this.sectionid,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
              this.studentMasterArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( 'Get Student All Service Error! - HTTP Status ' + error.status );
              this.isLoading = false;
          } );
      }
  }
  noEnter( e ) {
    return !( window.event && e.keyCode == 13 );
  }
  getAccountMasterAll() {
    this.isLoading = true;
    this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
        this.accountMasterArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        this.isLoading = false;
    } );
}
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
             }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
       getAcademicYrById( id ) {
        if ( this.academic.find( r => r.academicYearId == id ) ) {
            return this.academic.find( r => r.academicYearId == id ).academicYear;
        }
        else {
            return id;
        }
    }
   
    getClassNameById( id ) {
      if ( this.classArr.find( r => r.classId == id ) ) {
          return this.classArr.find( r => r.classId == id ).className;
      }
      else {
          return id;
      }
  }
  
  getMediumNameById( id ) {
    if ( this.mediumArr.find( r => r.mediumId == id ) ) {
        return this.mediumArr.find( r => r.mediumId == id ).mediumName;
    }
    else {
        return id;
    }
}


    getMediumMasterAll() {
        this.mediumMasterService.getMediumAll().subscribe( response => {
            this.mediumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( {
                message: 'Get Medium All Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
            this.isLoading = false;
        } );
    }

    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getDepartmentAll() {
      this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
          this.departmentArr = run;
      }, error => {
          bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStudentMasterById( studentId ) {
      this.currentClassId=this.classid;
        this.studentMasterService.getStudentById( studentId,this.loggedUser.accId ).subscribe( response => {
            this.editStudentMaster = response;
            this.tcPojo.name=this.editStudentMaster.name;
            this.tcPojo.fatherName=this.editStudentMaster.fatherName;
            this.tcPojo.motherName=this.editStudentMaster.motherName;
            this.tcPojo.admissionNo=this.editStudentMaster.admissionNo;
            this.tcPojo.nationality=this.editStudentMaster.nationality;
            this.tcPojo.religion=this.editStudentMaster.religion;
            this.tcPojo.caste=this.editStudentMaster.cast;
            this.tcPojo.community=this.editStudentMaster.community;
            this.tcPojo.dob=this.editStudentMaster.dob;
            this.tcPojo.doa=this.editStudentMaster.doa;
            this.tcPojo.gender=this.editStudentMaster.gender;
            this.tcPojo.joiningClassId=this.editStudentMaster.classId;
            this.tcPojo.emisNo=this.editStudentMaster.emisNo;
            this.tcPojo.academicYearId=this.editStudentMaster.joiningAcademicYear;
            this.tcPojo.aadharNo=this.editStudentMaster.aadharNo;
            this.tcPojo.personalMarks1=this.editStudentMaster.identificationMark1;
            this.tcPojo.personalMarks2=this.editStudentMaster.identificationMark2;
              
          let yr=moment(this.tcPojo.doa,'YYYY-MM-DD').format('YYYY');
    let fullYrwrds=converter.toWords(yr);
    var fullYr = fullYrwrds.replace(",", "");
    let fullMontn=moment(this.tcPojo.doa,'YYYY-MM-DD').format('MMMM');
     let fullDays=converter.toWords(moment(this.tcPojo.doa,'YYYY-MM-DD').format('DD'));
    this.tcPojo.dojWords= (fullDays+' - '+fullMontn+' - '+fullYr).toUpperCase();
    let dobyr=moment(this.tcPojo.dob,'YYYY-MM-DD').format('YYYY');
    let dobfullYrwrds=converter.toWords(dobyr);
    var dobfullYr = dobfullYrwrds.replace(",", "");
    let dobfullMontn=moment(this.tcPojo.dob,'YYYY-MM-DD').format('MMMM');
     let dobfullDays=converter.toWords(moment(this.tcPojo.dob,'YYYY-MM-DD').format('DD'));
    this.tcPojo.dobWords= (dobfullDays+' - '+dobfullMontn+' - '+dobfullYr).toUpperCase();
     
    if(this.editStudentMaster.mediumId==1){
              this.tcPojo.medium="TAMIL";
            }
            if(this.editStudentMaster.mediumId==1){
              this.tcPojo.medium="ENGLISH";
            }
             this.tcPojo.nameTamil=this.editStudentMaster.nameTamil;
                }, error => {
            bootbox.alert( {
                message: 'Get Student By Id Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
        } );
    }
   getSectionMasterByDeptId(id) {
        this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getAutoCertificateNo() {
      this.tcGenerateService.autoGenCertificateNo(this.loggedUser.accId).subscribe( response => {
          this.tcPojo.certificateNo = response;
           }, error => {
          bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
    add() {
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
     this.tcPojo.feeDue = moment().toDate();
     this.tcPojo.dateOfIssue=moment().toDate();
    this.tcPojo.actuallyLeftDate=moment(( <HTMLInputElement>document.getElementById( 'addSchoolLeftDate' ) ).value).toDate();
    this.tcPojo.applicationDate=moment(( <HTMLInputElement>document.getElementById( 'addApplicationDate' ) ).value).toDate();
    this.tcPojo.dateOfIssue=moment(( <HTMLInputElement>document.getElementById( 'addIssuesDate' ) ).value).toDate();
    this.tcPojo.accId = this.loggedUser.accId;
      this.tcPojo.userId = this.loggedUser.userId;
      for(let i=0;i<this.accountMasterArr.length;i++){
        if(this.accountMasterArr[i].accId==this.loggedUser.accId){
          this.tcPojo.schoolName=this.accountMasterArr[i].schoolName;
          this.tcPojo.educationalDistrict=this.accountMasterArr[i].educationalDistrict;
          this.tcPojo.revenueDistrict=this.accountMasterArr[i].revenueDistrict;
        }
      }
      let d=moment(this.tcPojo.doa,'YYYY-MM-DD').format('YYYY');
      let e=moment(this.tcPojo.actuallyLeftDate,'YYYY-MM-DD').format('YYYY');
      this.tcPojo.academicYears=d+' - '+e;
   
        this.tcGenerateService.addTcTransfer(this.tcPojo).subscribe( response => {
         let d= new TransferDetailsResponsePojo();
         d=response;
         if(d.code==101){
          bootbox.alert({message: "Already Generated! Please try Anyother Student..", className: 'text-danger h5'});
          this.tcPojo= new TransferDetailsPojo();
         }else{
          this.tcPojo= new TransferDetailsPojo();
        this.tcPojo=d.tpojo;
        bootbox.alert({message: "Successfully Added", className: 'text-success h5' });
      this.print(); 
          }
           ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              }, error => {
          bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
          $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      } );
     
    }
    reset(){
      this.getAutoCertificateNo();
      this.tcPojo = new TransferDetailsPojo();
      this.departmentId = '';
      this.classid =0;
      this.sectionid ='';
      this.studentid = null;
      this.date = null;
    }
    print(){
      let content=[];
      let body=[];
      this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      let regNo;
      let tmrNo;
      let markSheetNo;
     if(this.tcPojo.registerNo>0){regNo=this.tcPojo.registerNo;}
      else{regNo='';
     }if(this.tcPojo.tmrCode>0){tmrNo=this.tcPojo.tmrCode;}
      else{tmrNo='';
     }if(this.tcPojo.markSheetNo>0){markSheetNo=this.tcPojo.markSheetNo;}
      else{markSheetNo='';  
     }
                                     
                                     body.push( [
                                       { text: [{text:'Admission No.\t'+this.tcPojo.admissionNo , style: 'e2' }, {text:'\n' + 'T.C.SL.No. ', style: 'e3' },{text:this.tcPojo.certificateNo , style: 'e2' },
                                       {text:'\n' + 'Register No.\t', style: 'e3' },{text:regNo , style: 'e2' },{text:'\n' + 'Mark Sheet Sl.No.\t ', style: 'e3' },{text:markSheetNo , style: 'e2' },
                                       {text:'\n' + 'TMR Code No.\t ', style: 'e3' },{text:tmrNo , style: 'e2' }, ],border:[true, true, false, true],lineHeight:1.3,colSpan:2},{},
                                        {image: StaticVariable.logo, width:65,height:90, border:[false, true, false, true] },
                                       { text: [{text:'GOVERNMENT OF TAMILNADU\n' , style: 'e' }, {text: 'DEPARTMENT OF SCHOOL EDUCATION',alignment:'center', style: 'e3' },{text:'\n' + 'TRANSFER CERTIFICATE',alignment:'left', style: 'e0' },
                                       {text:'\nRecognised by the Director of School Education',alignment:'left', style: 'e3' },{text:'\nEMIS No.\t', style: 'e3' },{text:this.tcPojo.emisNo, style: 'e2' },
                                       ,{text:'\nAadhar No. ', style: 'e3' },{text:this.tcPojo.aadharNo, style: 'e2' }, ],border:[false, true, true, true],colSpan:3},{},{}] ); 
                                       body.push( [
                                         {text:'1.', style: 'e2',border:[true, true, false, false]},
                                         {text:'(a) Name of the School', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                         {text: this.tcPojo.schoolName,alignment:'center', style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                         body.push( [
                                           {text:'', style: 'e2',border:[true, false, false, false]},
                                           {text:'(b) Name of the Educational District', style: 'e2',border:[false, false, false, false],colSpan:2},{},
                                           {text: this.tcPojo.educationalDistrict,alignment:'center',bold:true, style: 'e4',border:[true, false, true, false],colSpan:3},{},{}] );      
                                           body.push( [
                                             {text:'', style: 'e2',border:[true, false, false, false]},{text:'(c) Name of the Revenue District', style: 'e2',border:[false, false, false, false],colSpan:2},{},
                                             {text: this.tcPojo.revenueDistrict,alignment:'center',bold:true, style: 'e4',border:[true, false, true, false],colSpan:3},{},{}] );      
                                         body.push( [
                                                {text:'2.', style: 'e2',border:[true, true, false, false]},
                                               {text:'Name of the Pupil( in Block Letters )', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                               {text: this.tcPojo.name,alignment:'center', style: 'e2',border:[true, true, true, false],colSpan:3},{},{}] );      
                                               body.push( [
                                                 {text:'', style: 'e2',border:[true, false, false, false]},
                                                 {text:'Name in Tamil', style: 'e2',border:[false, false, false, false],colSpan:2},{},
                                                {text: this.tcPojo.nameTamil,alignment:'center',bold:true,font:'Vijaya', style: 'e4',border:[true, false, true, false],colSpan:3},{},{}] );      
                                        body.push( [
                                          {text:'3.', style: 'e2',border:[true, true, false, false]},
                                                {text:'Name of the Father or Mother of the Pupil', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                {text: this.tcPojo.fatherName,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                        body.push( [
                                          {text:'4.', style: 'e2',border:[true, true, false, false]},
                                                  {text:'Nationality,Religion and Caste', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                  {text: this.tcPojo.nationality+' - '+this.tcPojo.religion,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                  body.push( [
                                                   {text:'5.', style: 'e2',border:[true, true, false, false]},
                                                            {text:'Community', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                             {text: 'REFER COMMUNITY CERTIFICATE ISSUED BY',alignment:'center', style: 'e4',border:[true, false, true, false],colSpan:3},{},{}] );      
                                                  body.push( [
                                                   {text:'', style: 'e2',border:[true, false, false, false]},
                                                               {text:'Whether He / She belongs to', style: 'e2',border:[false, false, false, false],colSpan:2},{},
                                                                {text: ' REVENUE AUTHORITIES',alignment:'center', style: 'e4',border:[true, false, true, false],colSpan:3},{},{}] );      
                                                 body.push( [
                                                   {text:'', style: 'e2',border:[true, true, false, false]},
                                                                 {text:'(a) Adi Dravidar ( S.C ot S.T )', style: 'e2',border:[false, true, false, false],colSpan:2,},{},
                                                                 {text: '--',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                  body.push( [
                                                   {text:'', style: 'e2',border:[true, true, false, false]},
                                                                   {text:'(b) Backward Class', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                   {text: '--',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                  body.push( [
                                                   {text:'', style: 'e2',border:[true, true, false, false]},
                                                                     {text:'(c) Most Backward Class', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                     {text: '--',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                     body.push( [
                                                                       {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                         {text:'(d) Converted to Christianity from S.C or S.T', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                         {text: '--',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                body.push( [
                                                                                 {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                           {text:'(e) Denotified Communities', style: 'e2',border:[false, true, false, false],colSpan:2,},{},
                                                                                           {text: '--',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                           body.push( [
                                                                                             {text:'6.', style: 'e2',border:[true, true, false, false]},
                                                                                             {text:'Sex', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                             {text: this.tcPojo.gender,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                             body.push( [
                                                                                               {text:'7.', style: 'e2',border:[true, true, false, false]},
                                                                                               {text:'Date of Birth as Entered in the Admission Register in figures and words', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                               {text: moment(this.tcPojo.dob,'YYYY-MM-DD').format('DD/MM/YYYY')+'\n'+this.tcPojo.dobWords,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                               if(this.tcPojo.personalMarks1==null){
                                                                                                this.tcPojo.personalMarks1="";
                                                                                               }
                                                                                               if(this.tcPojo.personalMarks2==null){
                                                                                                this.tcPojo.personalMarks2="";
                                                                                               }
                                                                                               if(this.tcPojo.educationalType==='GENERAL'){
                                                                                                this.tcPojo.generalEducation=this.tcPojo.courseOfStudy;
                                                                                                this.tcPojo.vocationalEducation="--";
                                                                                              }
                                                                                              if(this.tcPojo.educationalType==='VOCATIONAL'){
                                                                                                this.tcPojo.vocationalEducation=this.tcPojo.courseOfStudy;
                                                                                                this.tcPojo.generalEducation="--";
                                                                                              }
                                                                                               body.push( [
                                                                                                 {text:'8.', style: 'e2',border:[true, true, false, false]},
                                                                                                 {text:'Personal Marks of Identification', style: 'e2',border:[false, true, false, false]},
                                                                                                 {text:'a)\nb)', style: 'e2',border:[false, true, true, false]},
                                                                                                 {text: this.tcPojo.personalMarks1+'\n'+this.tcPojo.personalMarks2,alignment:'center', style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                 body.push( [
                                                                                                   {text:'9.', style: 'e2',border:[true, true, false, false]},
                                                                                                   {text:'Date of Admission and Standard in which Admitted', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                   {text: moment(this.tcPojo.doa,'YYYY-MM-DD').format('DD-MM-YYYY')+'\t\t'+this.getClassNameById(this.tcPojo.joiningClassId)+'\n'+this.tcPojo.dojWords,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                   body.push( [
                                                                                                     {text:'10.', style: 'e2',border:[true, true, false, false]},
                                                                                                     {text:'a) Standard in which the Pupil was studying at the time of leaving ( in words ) ', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                     {text: this.getClassNameById(this.tcPojo.lastStudiedClassId)+'\n'+this.tcPojo.lastStudiedClassWords,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                     body.push( [
                                                                                                       {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                                       {text:'b) The course offered i.e.,General or Vocational Education', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                       {text: this.tcPojo.educationalType+' EDUCATION',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                       body.push( [
                                                                                                         {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                                         {text:'c) In the case of General Education, The Subject offered under the Part-lll Group and Medium of Instruction', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                         {text: this.tcPojo.generalEducation,alignment:'center', style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                         body.push( [
                                                                                                           {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                                           {text:'d) In the case of Vocational Education, The Vocational Subject under Part-lll Group-B and the related subject offered under Part-lll Group (A) ', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                           {text: this.tcPojo.vocationalEducation,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                           body.push( [
                                                                                                             {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                                             {text:'e) Language offered under Part-l ', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                             {text: this.tcPojo.languageOffered,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                             body.push( [
                                                                                                               {text:'', style: 'e2',border:[true, true, false, false]},
                                                                                                               {text:'f) Medium of Study', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                               {text: this.tcPojo.medium,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                               body.push( [
                                                                                                                 {text:'11.', style: 'e2',border:[true, true, false, false]},
                                                                                                                 {text:'Whether qualified for Promotion to Higher standard', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                 {text: this.tcPojo.PromotionStatus,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                 body.push( [
                                                                                                                   {text:'12.', style: 'e2',border:[true, true, false, false]},
                                                                                                                   {text:'Whether the Pupil was in receipt of any Scholarship ( Nature of the Scholarship to be Specified )', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                   {text: this.tcPojo.scholarship,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                   body.push( [
                                                                                                                     {text:'13.', style: 'e2',border:[true, true, false, false]},
                                                                                                                     {text:'Whether the Pupil has undergone Medical Inspection during the last academic year ( First or Repeat to be Specified )', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                     {text: this.tcPojo.medicalInspection,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                     body.push( [
                                                                                                                       {text:'14.', style: 'e2',border:[true, true, false, false]},
                                                                                                                       {text:'Date on which the Pupil actually left the School', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                       {text: moment(this.tcPojo.actuallyLeftDate,'YYYY-MM-DD').format('DD-MMMM-YYYY'),alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                       body.push( [
                                                                                                                         {text:'15.', style: 'e2',border:[true, true, false, false]},
                                                                                                                         {text:"The Pupil's Conduct and Character", style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                         {text: this.tcPojo.conduct,alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                         body.push( [
                                                                                                                           {text:'16.', style: 'e2',border:[true, true, false, false]},
                                                                                                                           {text:'Date on which Application for Transfer Certificate was made on behalf of the Pupil by the Parent or Guardian', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                           {text: moment(this.tcPojo.applicationDate,'YYYY-MM-DD').format('DD-MMMM-YYYY'),alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                           body.push( [
                                                                                                                             {text:'17.', style: 'e2',border:[true, true, false, false]},
                                                                                                                             {text:'Date of the Transfer Certificate', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                             {text: moment(this.tcPojo.dateOfIssue,'YYYY-MM-DD').format('DD-MMMM-YYYY'),alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                             body.push( [
                                                                                                                               {text:'18.', style: 'e2',border:[true, true, false, false]},
                                                                                                                               {text:'Course of Study', style: 'e2',border:[false, true, false, false],colSpan:2},{},
                                                                                                                               {text: '',alignment:'center',bold:true, style: 'e4',border:[true, true, true, false],colSpan:3},{},{}] );      
                                                                                                                               body.push( [
                                                                                                                                 {text:'Name of the School', style: 'e2',alignment:'center',border:[true, true, true, true],colSpan:2},{},
                                                                                                                                 {text:'Academic Year(s)', style: 'e2',alignment:'center',border:[true, true, true, true]},
                                                                                                                                 {text:'Standard(s) Studied', style: 'e2',alignment:'center',border:[true, true, true, true]},
                                                                                                                                 {text:'First Language', style: 'e2',alignment:'center',border:[true, true, true, true]},
                                                                                                                                 {text:'Medium of Instruction', style: 'e2',alignment:'center',border:[true, true, true, true]},
                                                                                                                                ] );   
                                                                                                                                body.push( [
                                                                                                                                  {text:this.tcPojo.schoolName, style: 'e4',bold:'true',alignment:'center',border:[true, true, true, true],colSpan:2},{},
                                                                                                                                  {text:this.tcPojo.academicYears, style: 'e4',bold:'true',alignment:'center',border:[true, true, true, true]},
                                                                                                                                  {text:this.getClassNameById(this.tcPojo.joiningClassId)+' - '+this.getClassNameById(this.tcPojo.lastStudiedClassId), style: 'e4',bold:'true',alignment:'center',border:[true, true, true, true]},
                                                                                                                                  {text:'TAMIL', style: 'e4',bold:'true',alignment:'center',border:[true, true, true, true]},
                                                                                                                                  {text:this.tcPojo.medium, style: 'e4',bold:'true',alignment:'center',border:[true, true, true, true]},
                                                                                                                                 ] );      
                                                                                                                                body.push( [
                                                                                                                                 {text:'19.', style: 'e2',border:[true, true, false, false]},
                                                                                                                                 {text:'Signature of the Headmaster with Date and School Seal\n\n\n\n\n', style: 'e2',border:[false, true, true, true],colSpan:5},{},{},{},{}
                                                                                                                             ] );
                                                                                                                             body.push( [
                                                                                                                               {text:'Note.', style: 'e2',border:[true, true, false, false]},
                                                                                                                               {text:'Erasures and Unauthorised of Fraudulent alterations in the Certificate will lead to its Cancellation\nHead of the Institution who will be held responsible for the Correctness of the entries', style: 'e5',border:[false, true, true, false],colSpan:5},{},{},{},{}
                                                                                                                           ] ); 
                                                                                                                           body.push( [
                                                                                                                               {text:'DECLARATION BY THE PARENT OR GUARDIAN',alignment:'center', style: 'e2',border:[true, false, true, false],colSpan:6},{},{},{},{},{}
                                                                                                                         ] ); 
                                                                                                                         body.push( [
                                                                                                                           {text:'I here by declare that the particulars recorded against items 2 to 7 are correct and that no changes will be demanded by me in future', style: 'e5',border:[true, false, true, false],colSpan:6},{},{},{},{},{}
                                                                                                                     ] );  
                                                                                                                     body.push( [
                                                                                                                       {text:"\n\nStudent's Signature", style: 'e2',border:[true, false, false, true],colSpan:3,margin:[15,0,0,0]},{},{},
                                                                                                                       {text:'\n\nSignature of the Parent / Guardian',alignment:'center', style: 'e2',border:[false, false, true, true],colSpan:3},{},{}
                                                                                                                     ] );      
                                                                                                                                                                                                                                                                                      
     this.content.push( {
           'table': {
                'body': body,
                 widths: ['5%','43%','10%', '15%','15%','12%']
                      }
                      } );
      const dd = {
          'content': this.content,
          pageOrientation: 'portrait',
          pageSize: 'legal',
          pageMargins: [ 10, 5, 20, 5 ],
          styles: {
           e0: {
             font: 'TimesNewRoman',
              bold: true, 
              fontSize: 17,
                },
           e: {
               font: 'TimesNewRoman',
                  fontSize: 12,
                  bold:true,
                    },
           e2: {
             font: 'TimesNewRoman',
              bold: true, 
              fontSize: 11,
              },
         e3: {
           font: 'TimesNewRoman',
          fontSize: 11,
              },
          e4: {
               font: 'TimesNewRoman',
              fontSize: 9,
              },
             e5: {
                  font: 'TimesNewRoman',
                  fontSize: 9,
                      },
         }
       
      };
    
      pdfMake.createPdf( dd ).open();
      this.body = [];
      this.content = [];
     
    }
}

