import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class SectionMasterService {

    constructor( private httpclient: HttpClient ) { }

    getSectionMasterAll(accid:number): Observable<any> {
        const url = StaticVariable.porturl + 'GetSectionAll/'+accid;
        return this.httpclient.get<SectionMasterPojo>( url );
    }

    addSection( arg: SectionMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddSection';
        return this.httpclient.post( url, arg );
    }

    updateSection( arg: SectionMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateSection';
        return this.httpclient.put( url, arg );
    }

    deleteSection( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteSection/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
}
