import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class MediumMasterService {

    constructor( private httpclient: HttpClient ) { }

    getMediumAll(): Observable<any> {
        const url = StaticVariable.porturl + 'GetMediumAll/';
        return this.httpclient.get<MediumMasterPojo>(url);
    }
}
