export class ReceiptPojo {

	 receiptId:number;
	 receiptNo:string;
	 receiptHeadId:number;
	 receiptDate:Date;
	 companyId:number;
	 ledgerId:number;
	 name:string;
	 address:string;
	 amount:number;  
	 instrumentType:string;
	 instrumentNo:string;
	 instrumentDate:Date;
	 instrumentDetail:string;
	 paidType:number;
	 narration:string;
	 userId:number;
	 mobileNo:string;
	 academicYear:number;
	 mRecNo:string;
	 studentType:string;
	 studentNo:string;
	 accId:number;
	 studentId:number;
	 ledgerGroupId:number;
	 detList:ReceiptDetailsPojo[];
}

export class ReceiptDetailsPojo
{
    
    receiptDetailsId:number;
    receiptId: number;
    receiptHeadId: number;
    amount: number;
}