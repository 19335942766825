import { Component, OnInit, Input } from '@angular/core';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { FeeAllocationReportByTermWisePojo, FeeCollectionPojo, FeeCollectionDetailsPojo } from 'src/app/pojo/fee-collection.pojo';
import { ReceiptPojo } from 'src/app/pojo/receipt.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';

import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { FeeCollectionService } from '../fee-collection/fee-collection.service';
import { DiscountFixingService } from '../../Masters/discount-master/discount-master.service';
import { DiscountFixingPojo } from 'src/app/pojo/discount-fixing.pojo';
import { CommonReceiptComponent } from '../../CommonReceipt';
import { FeeCollectionReceiptPojo, FeeCollectionReceiptDetailsPojo } from 'src/app/pojo/temporary-fees-collection-receipt.pojo';
import { DiscountTypeMasterService } from '../../Masters/dicount-type-master/discount-type.service';
import { DiscountTypeMasterPojo } from 'src/app/pojo/discount-type-master.pojo';
import { DiscountDetailsPojo } from 'src/app/pojo/discount-detail.pojo';
import { DiscountDetailsResponsePojo } from 'src/app/pojo/discount-detail-response.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-discount-fixing',
  templateUrl: './discount-fixing.component.html',
  styleUrls: ['./discount-fixing.component.scss']
})
export class DiscountFixingComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  addEntryDate: NgbDateStruct;

  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  studentMasterArr: StudentMasterPojo[] = [];
  sectionArr: SectionMasterPojo[] = [];
  academic: AcademicYearMasterPojo[] = [];
  standardArr: AcademicStandardDetailsPojo[] = [];
  academicYr;
  departmentId;
  classid;
  sectionid;
  studentid;
  isLoading: boolean = false;
  loggedUser = new MUserMasterPojo();
  ob: boolean[] = [];
  public CData: number;
   tempPushArr: DiscountDetailsPojo[] = [];
   receiptHeadArr: ReceiptHeadPojo[] = [];
   overAllOriginalTotal: number = 0;
  overAllAlreadyPaidTotal: number = 0;
  studentMasterAllArr: StudentMasterPojo[] = [];
  discountAmount: number = 0;
  discountArr: DiscountFixingPojo[] = [];
  classMasterArr: ClassMasterPojo[] = [];
  discountTypeArr: DiscountTypeMasterPojo[] = [];
  disTypeId: number;
  resAcademicStndArr: AcademicStandardDetailsPojo[] = [];
  
  finalPushArr:DiscountDetailsPojo[]=[];
  discountDetArr:DiscountDetailsPojo[]=[];
  alreadyFixedArr:DiscountDetailsPojo[]=[];
  resAcdemicYr;
  resClassId;
  resDiscountTypeId;
  currentDate;
  responsePojo = new DiscountDetailsResponsePojo();
   constructor(private discountFixingService: DiscountFixingService, private feeTypemasterserivce: TermMasterService,
    private receiptheadService: ReceiptHeadService, private feeCollectionService: FeeCollectionService,
    private feeStructuremasterserivce: StandardStructureMasterService, private ledgercreationService: LedgercreationService,
    private feeAloocationService: FeeAllocationMasterService, private reportService: ReportService,
    private studentMasterService: StudentMasterService, private settingService: AcademicSettingService,
    private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService,
    private classMasterService: ClassMasterService, private discountTypeMasterserivce: DiscountTypeMasterService) { }


  ngOnInit() {
    $('#addAfterClick').hide();
    $('#updateAfterClick').hide();

    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.getDepartmentAll();
    this.academicSettingAll();
    this.getStudentMasterAll();
    this.getAllDiscountFixing();
    this.getDiscountTypeAll();
    // CommonReceiptComponent.FeeCollectionReceipt(this.temp);
  }
  getDiscountTypeAll() {
    this.isLoading = true;
    this.discountTypeMasterserivce.getAllDiscountType(this.loggedUser.accId).subscribe(response => {
      this.discountTypeArr = response;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
  }

  getAllDiscountFixing() {
    this.discountArr = [];
    this.discountFixingService.getDiscountFixingAll(this.loggedUser.accId).subscribe(rs => {
      this.discountArr = rs;
    }, error => {
      bootbox.alert('Error' + error);
    });
  }
  
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe(response => {
      this.sectionArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
      this.classArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe(run => {
      this.departmentArr = run;
    }, error => {
      bootbox.alert({ message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getClassMasterByDeptId(id) {
    this.studentMasterService.getClassMasterBydeptId(id,this.loggedUser.accId).subscribe(response => {
      this.classMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
 
 
  getStudentMasterAll() {
    this.studentMasterArr = [];
    this.studentMasterService.getStudentAll(this.loggedUser.accId).subscribe(response => {
      this.studentMasterAllArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Student Master All Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
    });
  }
 
  academicSettingAll() {
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe(response => {
      this.academic = response;
      for (let i = 0; i < this.academic.length; i++) {
        if (this.academic[i].academicFlag == 1) {
          this.academicYr = this.academic[i].academicYearId;
        }
      }

    }, error => {
      bootbox.alert('Setting Service Error! - HTTP Status ' + error.status);
    });
  }
  getAcademicYrById(id) {
    if (this.academic.find(r => r.academicYearId == id)) {
      return this.academic.find(r => r.academicYearId == id).academicYear;
    } else {
      return id;
    }
  }
  getClassNameById(id) {
    if (this.classArr.find(r => r.classId == id)) {
      return this.classArr.find(r => r.classId == id).className;
    } else {
      return id;
    }
  }
  getSectionNameById(id) {
    if (this.sectionArr.find(r => r.sectionId == id)) {
      return this.sectionArr.find(r => r.sectionId == id).sectionName;
    } else {
      return id;
    }
  }
  getStudentNameById(id) {
    if (this.studentMasterAllArr.find(r => r.studentId == id)) {
      return this.studentMasterAllArr.find(r => r.studentId == id).name;
    } else {
      return id;
    }
  }
 
  getDiscountPercentageById(id) {
    if (this.discountArr.find(r => r.discountFixingId == id)) {
      return this.discountArr.find(r => r.discountFixingId == id).percentage;
    } else {
      return id;
    }
  }
  getFeeAllocationById(){
    this.discountDetArr =[];
    this.alreadyFixedArr=[];
    this.tempPushArr=[];
    this.isLoading = true;  
//this.reset();
    this.feeAloocationService.getAcademicDetById(this.academicYr,this.classid,this.disTypeId,this.loggedUser.accId).subscribe( response => {
      this.responsePojo=response;
      this.resAcdemicYr = this.academicYr;
      this.resClassId = this.classid;
      this.resDiscountTypeId= this.disTypeId;
         if(response.code == 101){
          bootbox.confirm('Already Completed !\n Do you want to Edit ?', ( result ) => {
            if(result){
              for(let i=0;i<response.detList.length;i++){
                if(response.detList[i].status == 'NONE')
                {
                  this.discountDetArr.push(response.detList[i]);
                }
                if(response.detList[i].status == 'FIXED')
                {
                  this.alreadyFixedArr.push(response.detList[i]);
                }
            }
          }
          });
         }else{
           if(response.detList != null){
          for(let i=0;i<response.detList.length;i++){
            if(response.detList[i].status == 'NONE')
            {
              this.discountDetArr.push(response.detList[i]);
            }
            if(response.detList[i].status == 'FIXED')
            {
              this.alreadyFixedArr.push(response.detList[i]);
            }
        }
         }
        }
       this.isLoading = false;
       }, error => {
      bootbox.alert( { message: 'Get Fee Collection Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;   
    } );
  }
  
  noEnter(e) {
    return !(window.event && e.keyCode == 13);
  }
  selectTermAll(variable: any) {
    if (variable.checked) {
      this.confirmSelectTermAll();
    } else {
      this.deSelectTermAll();
    }
  }  
  confirmSelectTermAll() {
    this.tempPushArr = [];
    for (let i = 0; i < this.discountDetArr.length; i++) {
      let d = this.tempPushArr.findIndex(r => r.studentId == (this.discountDetArr[i].studentId));
      if (d < 0) {
        let e = new DiscountDetailsPojo();
        e=this.discountDetArr[i];
      this.tempPushArr.push(e);
      }
    }
  }
  deSelectTermAll() {
    for (let j = 0; j < this.discountDetArr.length; j++) {
      let d = this.tempPushArr.findIndex(r => r.studentId == (this.discountDetArr[j].studentId));
      if (d >= 0) {
        this.tempPushArr.splice(d, 1);
      }
    }
  }
  isSelectedTermAll() {

    for (let j = 0; j < this.discountDetArr.length; j++) {
      if (!this.tempPushArr.find(r => r.studentId == (this.discountDetArr[j].studentId))) {
        return false;
      }
    }
    return true;
  }

  isSelectedTerm(item) {
    if (this.tempPushArr.find(r => r.studentId == (item.studentId))) {
      return true;
    }
    return false;
  }
  selectTerm(variable, item:DiscountDetailsPojo) {
    let arg = new DiscountDetailsPojo();
    arg = item;
    if (variable.checked) {
      let d: DiscountDetailsPojo = new DiscountDetailsPojo();
      this.tempPushArr.push(arg);
      
    } else {
      let d = this.tempPushArr.findIndex(r => r.studentId == (item.studentId));
      if (d >= 0) {
        this.tempPushArr.splice(d, 1);
      }
    }
  }
  remove(item){
    let d = this.alreadyFixedArr.findIndex(r => r.studentId == (item.studentId));
    if (d >= 0) {
      this.alreadyFixedArr.splice(d, 1);
    }
  }
  funcBoth(){
    for(let i=0;i<this.tempPushArr.length;i++){
      let d= new DiscountDetailsPojo();
      d = this.tempPushArr[i];
      this.finalPushArr.push(d);
      if(this.alreadyFixedArr.length >0){
      if(i== this.tempPushArr.length-1){
        for(let i=0;i<this.alreadyFixedArr.length;i++){
          let d= new DiscountDetailsPojo();
          d = this.alreadyFixedArr[i];
          this.finalPushArr.push(d);
        }
        }
      }
    }
  }
  funcOnlyNewEntry(){
    for(let i=0;i<this.tempPushArr.length;i++){
      let d= new DiscountDetailsPojo();
      d = this.tempPushArr[i];
      this.finalPushArr.push(d);
     
    }
  }
  funcOnlyAlreadyEnteredList(){
    for(let i=0;i<this.alreadyFixedArr.length;i++){
      let d= new DiscountDetailsPojo();
      d = this.alreadyFixedArr[i];
      this.finalPushArr.push(d);
    }
    
  }
  submit() {
     if(this.tempPushArr.length >0 && this.alreadyFixedArr.length >0){
      this.funcBoth();
      this.submitService();
    }else if(this.tempPushArr.length >0){
      this.funcOnlyNewEntry();
      this.submitService();
    }else if(this.alreadyFixedArr.length >0){
      this.funcOnlyAlreadyEnteredList();
      this.submitService();
    }else{
      bootbox.alert('No Records to be Found !');
    }
  
   
  }
  submitService(){
     if(this.responsePojo.code == 101){
      this.responsePojo.detList=[];
      this.responsePojo.detList=this.finalPushArr;
      (<HTMLInputElement>document.getElementById('updateButton')).disabled = true;
     this.responsePojo.academicYearId= this.resAcdemicYr;
      this.responsePojo.classId=this.resClassId;
      this.responsePojo.discountTypeId=this.resDiscountTypeId;
     
      this.feeCollectionService.updateDiscountDetails(this.responsePojo).subscribe(response => {
        bootbox.alert( { message: 'Successfully Updated', className: 'text-success h5', closeButton: false } );
        (<HTMLInputElement>document.getElementById('updateButton')).disabled = false;
        // this.isLoading = false;
        this.reset();
    
      }, error => {
        bootbox.alert({ message: ' Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
        (<HTMLInputElement>document.getElementById('updateButton')).disabled = false;
         // this.isLoading = false;
      });
    }else{
      (<HTMLInputElement>document.getElementById('addButton')).disabled = true;
   this.feeCollectionService.addDiscountDetails(this.finalPushArr).subscribe(response => {
      bootbox.alert( { message: 'Successfully Submitted', className: 'text-success h5', closeButton: false } );
      (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
      // this.isLoading = false;
      this.reset();
  
    }, error => {
      bootbox.alert({ message: ' Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      (<HTMLInputElement>document.getElementById('addButton')).disabled = false;
       // this.isLoading = false;
    });
  }
  }
  reset() {
 this.tempPushArr=[];
 this.alreadyFixedArr=[];
  this.discountDetArr=[];
  this.finalPushArr=[];
  this.departmentId=0;
  this.classid=0;
  this.disTypeId=0;
  this.responsePojo= new DiscountDetailsResponsePojo();

  }
}

