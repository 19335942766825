export class FeeAllocationPojo {

	 feeAllocationId:number;
	 feeAllocationDate:Date;
	 standardTermId:number;
	 studentId:number;
	 admissionNo:string;
	 amount:number;
	 gst:number;
	 totalAmount:number;
	 discount:number;
	 paidAmount:number;
	 balance:number;
	 paidStatus:string;
	 academicYearId:number;
	  receiptId:number;
	 deptId:number;
	 classId:number;
	 sectionId:number;
	 code:number;
	 discountAmount:number;
	 name:string;
	 discountFlag:string;
	 busAmount:number;
	 status:string;

}
export class FeeAllocationReportPojo {
	code:number;
	response:Text;
	flist:FeeAllocationPojo[] =[];



}
