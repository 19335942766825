
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ExamMasterPojo } from 'src/app/pojo/exam-master.pojo';
import { ExamMasterService } from './exam-master.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-exam-master',
  templateUrl: './exam-master.component.html',
  styleUrls: ['./exam-master.component.scss']
})
export class ExamMasterComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
    
    addFromDate: NgbDateStruct;
    addToDate: NgbDateStruct;
    editFromDate: NgbDateStruct;
    editToDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

    
    examArr: ExamMasterPojo[] = [];
    addExam = new ExamMasterPojo();
    editExam = new ExamMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private exammasterserivce: ExamMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getFeeTypeAll();
    }

    getFeeTypeAll() {
        this.isLoading = true;
        this.exammasterserivce.getAllExamMaster(this.loggedUser.accId).subscribe( response => {
            this.examArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Exam All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addExam.examFrom = moment( this.addFromDate.year + '-' + this.addFromDate.month + '-' + this.addFromDate.day, 'YYYY-MM-DD' ).toDate();
        this.addExam.examTo = moment( this.addToDate.year + '-' + this.addToDate.month + '-' + this.addToDate.day, 'YYYY-MM-DD' ).toDate();
        this.addExam.accId=this.loggedUser.accId;
        this.exammasterserivce.addExamMaster( this.addExam ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editExam = Object.assign( {}, arg );
        if(this.editExam.examFrom != null && this.editExam.examFrom != undefined){
            ( <HTMLFormElement>document.getElementById( 'editfromDate' ) ).value = this.editExam.examFrom;
            }
            if(this.editExam.examTo != null && this.editExam.examTo != undefined){
                ( <HTMLFormElement>document.getElementById( 'edittoDate' ) ).value = this.editExam.examTo;
                }
    }  

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editExam.examFrom = moment(( <HTMLInputElement>document.getElementById( 'editfromDate' ) ).value).toDate();
        this.editExam.examTo = moment(( <HTMLInputElement>document.getElementById( 'edittoDate' ) ).value).toDate();
      
       // this.editExam.examFrom = moment( this.editFromDate.year + '-' + this.editFromDate.month + '-' + this.editFromDate.day, 'YYYY-MM-DD' ).toDate();
        //this.editExam.examTo = moment( this.editToDate.year + '-' + this.editToDate.month + '-' + this.editToDate.day, 'YYYY-MM-DD' ).toDate();
        this.editExam.accId=this.loggedUser.accId;
        this.exammasterserivce.updateExamMaster( this.editExam ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.examArr.indexOf( this.examArr.find( r => r.examMasterId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.exammasterserivce.deleteExamMaster( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.examArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.examArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
