import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { LeaveApplicationPojo } from 'src/app/pojo/leave-application.pojo';
import { LeaveApplicationResPojo } from 'src/app/pojo/leaveapplicationres.pojo';
import { ImageDataPojo } from 'src/app/pojo/imagedata.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class LeaveApplicationService {

    constructor( private httpclient: HttpClient ) { }

    getLeaveList(academicYrId,classId,sectionId,sdate,edate,accid): Observable<LeaveApplicationResPojo> {
        const url = StaticVariable.porturl + 'GetLeaveList/'+academicYrId+'/'+classId+'/'+sectionId+'/'+sdate+'/'+edate+'/'+accid;
        return this.httpclient.get<LeaveApplicationResPojo>( url );
    }
    
    // addNoteLis( arg: Teachernoterespojo ): Observable<any> {
    //     const url = StaticVariable.porturl + 'AddNote';
    //     return this.httpclient.post( url, arg );
    // }
    
    getimageDetails(id):Observable<ImageDataPojo>{
        const url=StaticVariable.porturl + 'GetFtpImageById/'+id;
        return this.httpclient.get<ImageDataPojo>( url );
    }


    updateApplication( arg: LeaveApplicationPojo[] ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateApplication';
        return this.httpclient.put( url, arg );
    }
    
}