import { FeeAllocationPojo } from './fee-allocation.pojo';

export class FeeAllocationReportByTermWisePojo {

	termId:number;
    termName:string;
    academicYearId:number;
    flist:FeeAllocationPojo[];
}
export class FeeCollectionPojo{
    collectionId:number;
    collectionDate:Date;
    collectionTime:string;
    classId:number;
    sectionId:number;
    studentId:number;
    receiptId:number;
    receiptNo:number;
    overAllPaidTotal:number;
    overAllDiscountTotal:number;
    academicYearId:number;
    admissionNo:string;
    paidLedgerId:number;
    userId:number;
    accId:number;
    instrumentType:string;
    instrumentNo:string;
    instrumentDate;
    instrumentDetails:string;
    overAllOriginalTotal:number;
    overAllBalanceTotal:number;
    overAllAlreadyPaidTotal:number;
    name:string;
	mobileNo:string;
	className:string;
	sectionName:string;
    flist:FeeCollectionDetailsPojo[] =[];
}
export class FeeCollectionDetailsPojo{
    detailId:number;
    collectionId:number;  
    termId:number;
    standardTermId:number;
    feeAllocationId:number;
    originalAmount:number;
    discountPercentage:number;
    discountAmount:number;
    paidAmount:number;
    finalPaidAmount:number;
    alreadyPaidAmount:number;
    balance:number;
    termName:string;
	standardTerm:string;
}