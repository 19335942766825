import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { AccountMasterPojo, AccountGroupPojo } from "src/app/pojo/account-master.pojo";
import { AccountMasterService } from "src/app/management/Admin/account-master/account-master.service";
import { AccountGroupMasterService } from "src/app/management/Admin/account-master/account-group-master.service";

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-account-master',
    templateUrl: './account-master.component.html',
    styleUrls: ['./account-master.component.scss']
  })

export class AccountMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    accountMasterArr: AccountMasterPojo[] = [];
    accountGrpMasterArr:AccountGroupPojo[]=[];

    addAccountMaster = new AccountMasterPojo();
    editAccountMaster = new AccountMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    

    constructor( private accountmasterserivce: AccountMasterService,private accountgroupmasterService: AccountGroupMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
       this.getAccountGrpAll();
       this.getAccountMasterAll();
    }

    getAccountGrpAll() {
        this.accountgroupmasterService.getAllAccountGrpMaster().subscribe( response => {
            this.accountGrpMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getAccountMasterAll() {
        this.isLoading = true;
        this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
            this.accountMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

  
    addClass() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addAccountMaster.userId = this.loggedUser.userId;
        this.accountmasterserivce.addAccountMaster( this.addAccountMaster ).subscribe( response => {
            bootbox.alert( { message: 'Account has been added successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addAccountForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getAccountMasterAll();
        }, error => {
            bootbox.alert( { message: 'Add Account Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editAccountMaster = Object.assign( {}, arg );
    }

    editClass() {
        let editAccount = new AccountMasterPojo();
        this.editAccountMaster.userId = this.loggedUser.userId;
        editAccount = this.editAccountMaster;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.accountmasterserivce.updateAccountMaster( editAccount ).subscribe( response => {
            bootbox.alert( { message: 'Account has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getAccountMasterAll();
        }, error => {
            bootbox.alert( { message: 'Update Account Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.accountMasterArr.indexOf( this.accountMasterArr.find( r => r.accId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.accountmasterserivce.deleteAccountMaster( this.id).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.accountMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.accountMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getAccountMasterAll();
        }, error => {
            bootbox.alert( { message: 'Delete Account Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
