import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { JournalEntryPojo } from "src/app/pojo/journalEntry.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class JournalEntryService {

    constructor( private httpclient: HttpClient ) { }

    getJournalEntryAll(accid): Observable<JournalEntryPojo[]> {
        const url = StaticVariable.porturl + 'GetJournalEntryAll/'+accid;
        return this.httpclient.get<JournalEntryPojo[]>( url );
    }
    
    addJournalEntry( arg: JournalEntryPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddJournalEntry';
        return this.httpclient.post( url, arg );
    }
    
    updateJournalEntry( arg: JournalEntryPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateJournalEntry';
        return this.httpclient.put( url, arg );
    }
    
    deleteJournalEntry( arg: number,uid:number,accid  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteJournalEntry/' + arg + '/' + uid+'/'+accid;
        return this.httpclient.delete( url );
    }
    getJournalByDate( fdate: string, tdate: string,accid ): Observable<JournalEntryPojo[]> {
        const url = StaticVariable.porturl + 'GetJournalEntryByDateRange/' + fdate + '/' + tdate+'/'+accid;
        //  alert(url);
        return this.httpclient.get<JournalEntryPojo[]>(url);
    }
}