export class SmsHistoryPojo {
     smsHistoryId:number;
	 smsId:number;
	 smsName:string;
	 smsContent:string;
	 sendDate:Date;
	 sendTime:string;
	 smsStatus:string;
	 mobileNo:string;
	 errorCode:string;
	 errorMessage:string;
	 msdId:string;
	 partId:string;
	 deliverStatus:string;
	 accId:number;
	 userId:number;
}