import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { ReportService } from '../report-service';
import * as moment from 'moment';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { StrengthReportPojo } from 'src/app/pojo/strength-pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-strength',
  templateUrl: './strength.component.html',
  styleUrls: ['./strength.component.scss']
})
export class StrengthComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    standardArr: AcademicStandardDetailsPojo[] = [];
    standard = new AcademicStandardDetailsPojo();
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    departmentId: number;
    studentMasterArr: StudentMasterPojo[] = [];
    sectionMasterArr: SectionMasterPojo[] = [];
    classid:number;
    sectionid:number;
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';
    classMasterArr:ClassMasterPojo[] =[];
    id: number;
    isLoading: boolean = false;
    body = [];
    content = [];
    documentTime:string;
    resultDept:number;
    resultClass:number;
    resultSection:number;
    resulltGender:string;
    searchOption = 'ALL';
    strength = new StrengthReportPojo();
    accountMasterArr:AccountMasterPojo[]=[];
    constructor( private reportService:ReportService,private studentMasterService: StudentMasterService, private departmentMasterService: DepartmentMasterService, 
        private sectionMasterService: SectionMasterService, private classMasterService: ClassMasterService, private router: Router,private accountMasterService:AccountMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#editAfterClick' ).hide();
        this.getDepartmentAll();
        this.getSectionMasterAll();
        this.getClassMasterAll();
        this.accountMasterAll();
       // this.getStudentByDepartmentId( 1 );
    }
    accountMasterAll() {
        this.accountMasterService.getAllAccountMaster().subscribe(response => {
          this.accountMasterArr = response;
         
        }, error => {
          bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
        });
      }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getAccountNameById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).accName;
        } else {
          return id;
        }
      }
      getAccountPlaceById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).place;
        } else {
          return id;
        }
      } getAccountDistrictById(id) {
        if (this.accountMasterArr.find(r => r.accId == id)) {
          return this.accountMasterArr.find(r => r.accId == id).district;
        } else {
          return id;
        }
      }
    getSectionNameBySectionId( id ) {
        if ( this.sectionArr.find( response => response.sectionId == id ) ) {
            return this.sectionArr.find( response => response.sectionId == id ).sectionName;
        } else {
            return id;
        }
    }

    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }
    getClassMasterByDeptId(id) {
      this.studentMasterService.getClassMasterBydeptId(id,this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Class By Department Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getSectionMasterByDeptId(id) {
      this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
          this.sectionMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.departmentArr = run;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getDepartmentNameBydepartmentId( id ) {
        if ( this.departmentArr.find( r => r.deptId == id ) ) {
            return this.departmentArr.find( r => r.deptId == id ).deptName;
        } else {
            return id;
        }
    }
    getClassNameById( id ) {
        if ( this.classMasterArr.find( r => r.classId == id ) ) {
            return this.classMasterArr.find( r => r.classId == id ).className;
        } else {
            return id;
        }
    }
    getDeptNameById( id ) {
      if ( this.classMasterArr.find( r => r.classId == id ) ) {
          return this.classMasterArr.find( r => r.classId == id ).deptId;
      } else {
          return id;
      }
  }
    getSectionNameById( id ) {
      if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
          return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
      } else {
          return id;
      }
  }
 

    getReportById() {
        if(this.departmentId !=null &&this.classid !=null&&this.sectionid != null && this.loggedUser.academicYearId != null){
    
      this.strength = new StrengthReportPojo();
        this.isLoading = true;
        this.reportService.getStrengthReportById( this.departmentId, this.classid, this.sectionid,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
            this.strength = response;
              this.resultDept= this.departmentId;
            this.resultClass= this.classid;
            this.resultSection=this.sectionid;
             this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
    }else{
        bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );
   
    }
    }
    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         this.content.push( {
             text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
            } );
         this.content.push( {
             text: 'STUDENT STRENGTH REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );
   
         this.content.push( {
             text: '------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
         if(this.resultDept == 0){
          this.content.push( {text: 'SELECTED DEPARTMENT : '+ 'ALL', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
        } );
         }else{
          this.content.push( {text: 'SELECTED DEPARTMENT : '+ this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
        } );
          }
          if(this.resultClass == 0 && this.resultSection == 0){
            this.content.push( {text: '\nSELECTED TYPE :'+'ALL CLASS'+' - '+ 'ALL SECTION', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
          } ); }else if(this.resultClass == 0 && this.resultSection != 0) {
            this.content.push( {text: '\nSELECTED TYPE :'+'ALL CLASS'+' - '+ this.getSectionNameById(this.resultSection)+' SECTION', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
          } );  
          }else if(this.resultClass != 0 && this.resultSection == 0) {
            this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+'CLASS'+' - '+'ALL SECTION', fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
          } );  
          }else{
                 this.content.push( {text: '\nSELECTED TYPE :'+this.getClassNameById(this.resultClass)+' - '+ this.getSectionNameById(this.resultSection), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
     } );
    }
    
         this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: '1', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Total Number of Students', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: this.strength.totalNoOfStudents, fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                ] );
                this.body.push( [
                  { text: '2', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                  { text: 'Total Number of Male Students', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                  { text: this.strength.totalNoOfMale, fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                     ] );
                     this.body.push( [
                      { text: '3', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                      { text: 'Total Number of Female Students', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                      { text: this.strength.totalNoOfFemale, fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                         ] );
                         this.body.push( [
                          { text: '4', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                          { text: 'Total Number of Transgender Students', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                          { text: this.strength.totalNoOfTransGender, fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                             ] );
   
        
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['10%', '70%', '20%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'portrait',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
}
