import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { TermPojo } from 'src/app/pojo/term-pojo';
import { ExamMasterPojo } from 'src/app/pojo/exam-master.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class ExamMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllExamMaster(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetExamMasterAll/'+accid;
        return this.httpclient.get<ExamMasterPojo[]>( url );
    }
    
    addExamMaster( arg: ExamMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddExamMaster';
        return this.httpclient.post( url, arg );
    }
    
    updateExamMaster( arg: ExamMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateExamMaster';
        return this.httpclient.put( url, arg );
    }
    
    deleteExamMaster( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteExamMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}