import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { SubjectMasterService } from '../subject-master/subject-master.service';
import { CurriculumMasterPojo } from 'src/app/pojo/curriculum-master.pojo';
import { MediumMasterPojo } from 'src/app/pojo/medium-master.pojo';
import { CurriculumMasterService } from '../curriculum-master/curriculum-master.service';
import { MediumMasterService } from '../../Medium/medium-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { ClassMasterService } from '../class-master/class-master.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-subject-master',
  templateUrl: './subject-master.component.html',
  styleUrls: ['./subject-master.component.scss']
})

export class SubjectMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    subjectMasterArr: SubjectMasterPojo[] = [];
    curriculumArr: CurriculumMasterPojo[] = [];
     mediumMasterArr: MediumMasterPojo[] = [];
     classMasterArr: ClassMasterPojo[] = [];
    addMaster = new SubjectMasterPojo();
    editMaster = new SubjectMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private classMasterService: ClassMasterService,private subjectmasterserivce: SubjectMasterService, private curriculumMasterService: CurriculumMasterService, private mediumMasterService: MediumMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
        this.getCurriculumAll();
        this.getMediumAll();
        this.getClassMasterAll();
        this.getSubjectAll();
    }

    getSubjectAll() {
        this.isLoading = true;
        this.subjectmasterserivce.getAllSubject(this.loggedUser.accId).subscribe( response => {
            this.subjectMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Subject All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
          this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  }
  getClassNameById( id ) {
    if ( this.classMasterArr.find( r => r.classId == id ) ) {
        return this.classMasterArr.find( r => r.classId == id ).className;
    } else {
        return id;
    }
}
    getCurriculumAll() {
        this.curriculumMasterService.getCurriculumAll().subscribe( response => {
            this.curriculumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Curriculum All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getMediumAll() {
        this.mediumMasterService.getMediumAll().subscribe( response => {
            this.mediumMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Medium All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

   
    addSubject() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addMaster.accId = this.loggedUser.accId;
        this.addMaster.userId = this.loggedUser.userId;
        this.subjectmasterserivce.addSubject( this.addMaster ).subscribe( response => {
            bootbox.alert( { message: 'Subject has been added successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getSubjectAll();
        }, error => {
            bootbox.alert( { message: 'Add Subject Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editMaster = Object.assign( {}, arg );
    }

    editSubject() {
        let editSubject = new SubjectMasterPojo();
        this.editMaster.accId = this.loggedUser.accId;
        this.editMaster.userId = this.loggedUser.userId;
        editSubject = this.editMaster;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.subjectmasterserivce.updateSubject( editSubject ).subscribe( response => {
            bootbox.alert( { message: 'Subject has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getSubjectAll();
        }, error => {
            bootbox.alert( { message: 'Update Subject Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.subjectMasterArr.indexOf( this.subjectMasterArr.find( r => r.subjectId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.subjectmasterserivce.deleteSubject( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.subjectMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.subjectMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getSubjectAll();
        }, error => {
            bootbox.alert( { message: 'Delete Subject Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
