import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class AreaMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllArea(accid): Observable<any> {
        const url = StaticVariable.porturl + 'GetAreaMasterAll/'+accid;
        return this.httpclient.get<AreaMasterPojo[]>( url );
    }
    
    addArea( arg: AreaMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddAreaMaster';
        return this.httpclient.post( url, arg );
    }
    
    updateArea( arg: AreaMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateAreaMaster';
        return this.httpclient.put( url, arg );
    }
    
    deleteArea( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteAreaMaster/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
    getAreaMasterById(id,accid:number): Observable<any> {
        const url = StaticVariable.porturl + 'GetAreaMasterById/'+id+'/'+accid;
        return this.httpclient.get<AreaMasterPojo>( url );
    }
   
}