import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'SubjectMasterPipe'
} )

export class SubjectMasterPipe implements PipeTransform {

    transform( array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.subjectName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.subjectName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}
@Pipe( {
    name: 'SubjectMasterIdPipe'
} )

export class SubjectMasterIdPipe implements PipeTransform {

    transform( array: any[], query: any): any {
        if ( query ) {
            if ( _.filter( array, row => row.classId == query ) != '' ) {
                return _.filter( array, row => row.classId == query );
            }    
            }
        return [];
    }

}
