import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe( {
    name: 'promotionPipe'
} )

export class PromotionPipe implements PipeTransform {

    transform( array: any[], query: string ): any {
        if ( query ) {
            if ( _.filter( array, row => row.student.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.student.admissionNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.student.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.student.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.student.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.student.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}
