
export class TransferDetailsPojo {

	 transferDetailsId:number;
	 admissionNo:string;
	 certificateNo:string;
	 name:string;
	 fatherName:string;
	 motherName:string;
	 nationality:string;
	 religion:string;
	 community:string;
	 caste:string;
	 dob:Date;
	 joiningClassId:number;
	 doa:Date;
	 lastStudiedClassId:number;
	 conduct:string;
	 entryDate:Date;
	 dateOfIssue:Date;
	 accId:number;
	 userId:number;
	//new variables	
	 registerNo:number;
	 markSheetNo:number;
	 tmrCode:number;
	 emisNo:string;
	 aadharNo:string;
	 schoolName:string;
	 educationalDistrict:string;
	 revenueDistrict:string; 
	 nameTamil:string;
	 gender:string;
	 personalMarks1:string;
	 personalMarks2:string;
	 educationalType:string;
	 generalEducation:string;
	 vocationalEducation:string;
	 languageOffered:string;
	 medium:string;
	 PromotionStatus:string;
	 scholarship:string;
	 medicalInspection:string;
	 actuallyLeftDate:Date;
	 applicationDate:Date;
	 courseOfStudy:string;
	 academicYearId:number;
	//unused variables
	 lastExaminationTakenId:number;
	 lastExaminationResult:string;
	 noOfFailed:number;
	 promotedClassId:number;
	 feeDue:Date;
	 libraryDue:string;
	 extraActivities:string;
	 reason:string;
	 otherRemarks:string;
	 scoutDetails:string;
     dobWords:string;
	 dojWords:string;
	 joiningClassWords:string;
	 lastStudiedClassWords:string;
	 academicYears:string;
	 standardsStuided:string;
}
export class TransferDetailsResponsePojo {

	 code:number;
	 response:string;
	 admissionNo:string;
     tpojo=new TransferDetailsPojo(); 
}