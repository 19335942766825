export class ArrearReportPojo {

	 academicYearId:number;
	 classId:number;
	 className:string;
	 sectionId:number;
	 sectionName:string;
     detList:ArrearReportStudentDetailsPojo[] =[];
}
export class ArrearReportStudentDetailsPojo {

	 studentId:number;
	 admissionNo:string;
	 rollNo:string;
	 name:string;
	 classId:number;
	 className:string;
	 sectionId:number;
	 sectionName:string;
	 overAllBalance:number;
	 overAllPaid:number;
	 overAllTotal:number;
	 mobileNo:string;
}
	
	