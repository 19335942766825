export class AttendanceCertificatePojo {

	 attedanceCertificateNo:number;
	 accId:number;
	 academicYearId:number;
	 studentId:number;
	 admissionNo:string;
	 userId:number;
	 name:string;
	 fatherName:string;
	 classId:number;
	 presentDays:number;
	 totalDays:number;
	 attendancePercentage:number;
	 certificateDate:Date;
	 station:string;
	 code:number;
	
}
export class BonafideCertificatePojo {

	 bonafideCertificateNo:number;
	 accId:number;
	 academicYearId:number;
	 studentId:number;
	 admissionNo:string;
	 userId:number;
	 name:string;
	 fatherName:string;
	 schoolName:string;
	 district:string;
	 classId:number;
	 dob:Date;
	 dobWords:string;
	 nationality:string;
	 religion:string;
	 caste:string;
	 certificateDate:Date;
	 station:string;
	 code:number;
}
export class CourseCertificatePojo {

	 courseCertificateNo:number;
	 accId:number;
	 academicYearId:number;
	 studentId:number;
	 admissionNo:string;
	 userId:number;
	 name:string;
	 fatherName:string;
	 schoolName:string;
	 district:string;
	 classId:number;
	 dob:Date;
	 dobWords:string;
	 nationality:string;
	 religion:string;
	 caste:string;
	 certificateDate:Date;
	 station:string;
	 sectionId:number;
	 gender:string;
	 code:number;
}
export class ConductCertificatePojo {

	 conductCertificateNo:number;
	 accId:number;
	 academicYearId:number;
	 studentId:number;
	 admissionNo:string;
	 userId:number;
	 name:string;
	 fatherName:string;
	 joinedClassId:number;
	 leavingClassId:number;
	 joinedYear:number;
	 leavingYear:number;
	 reason:string;
	 certificateDate:Date;
	 station:string;
	 code:number;
}
export class GenuineCertificatePojo {

	 genuineCertificateNo:number;
	 accId:number;
	 academicYearId:number;
	 studentId:number;
	 admissionNo:string;
	 userId:number;
	 name:string;
	 fatherName:string;
	 schoolName:string;
	 district:string;
	 classId:number;
	 dob:Date;
	 dobWords:string;
	 nationality:string;
	 religion:string;
	 caste:string;
	 certificateDate:Date;
	 station:string;
	 sectionId:number;
	 gender:string;
	 code:number;
}