import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SmsTemplatePojo } from "src/app/pojo/sms-template.pojo";
import { SmsTemplateService } from "src/app/management/SMS/sms-template/sms-template.service";
import { Router } from "@angular/router";

import * as moment from 'moment';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-sms-template',
    templateUrl: './sms-template.component.html',
    styleUrls: ['./sms-template.component.scss']
} )

export class SmsTemplateComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    smsTemplateMasterArr: SmsTemplatePojo[] = [];

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    tableLoading: boolean = true;

    constructor( private smsTemplateService: SmsTemplateService, private router: Router ) { }

    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.getSmsMasterAll();
    }

    getSmsMasterAll() {
        this.tableLoading = true;
        this.smsTemplateService.getSmsTemplateAll(this.loggedUser.accId).subscribe( response => {
            this.smsTemplateMasterArr = response;
            this.tableLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Sms Template Master All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.tableLoading = false;
        } );
    }

    useThisTemplate( id ) {
        let smsId = id;
        this.router.navigate( [`/console/templateSmsCreation`, smsId] );
    }
    createTemplate(){
        this.router.navigate( [`/console/smsTemplateCreation`] );
    }
}
