export class StudentQuickAdmissionPojo {

	studentId:number;
	admissionNoSerial:number;
	admissionNo:string;
	doa:Date;
	dob:Date;
	gender:string;
	fatherName:string;
	motherName:string;
	guardianName:string;
	address:string;
	pincode:string;
	mobileNo:string;
	mobileNo2:string;
	telephoneNo:string;
	classId:number;
	joiningAcademicYear:number;
	lastYearStatus:string;
	name:string;
	nameTamil:string;
	accId:number
	
}