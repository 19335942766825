import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { TransferDetailsResponsePojo, TransferDetailsPojo } from 'src/app/pojo/tc-generation.pojo';
  
@Injectable( {
    providedIn: 'root'
} )

export class TcGenerationService {

    constructor( private httpclient: HttpClient ) { }

    getTransferAll(accid): Observable<TransferDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetTransferDetailsAll/'+accid;
        return this.httpclient.get<TransferDetailsPojo[]>( url );
    }
    
    addTcTransfer( arg: TransferDetailsPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddTransferDetails';
        return this.httpclient.post( url, arg );
    }
    updateTransfer( arg: TransferDetailsPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateTransferDetails';
        return this.httpclient.put( url, arg );
    }
  deletetransferDetails( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteTransferDetails/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
    getTransferDetailsById(id,accid:number): Observable<TransferDetailsPojo> {
        const url = StaticVariable.porturl + 'GetTransferDetailsById/'+id+'/'+accid;
        return this.httpclient.get<TransferDetailsPojo>( url );
    }
    getTransferDetailsByAdmissionNo(no,accid): Observable<TransferDetailsPojo> {
        const url = StaticVariable.porturl + 'GetTransferDetailsByAdmissionNo/'+no+'/'+accid;
        return this.httpclient.get<TransferDetailsPojo>( url );
    }
    getTransferDetailsByDate(from,to,accid:number): Observable<TransferDetailsPojo[]> {
        const url = StaticVariable.porturl + 'GetTransferDetailsByDateOfIssue/'+from+'/'+to +'/'+accid;
        return this.httpclient.get<TransferDetailsPojo[]>( url );
    }
    autoGenCertificateNo(accid:number): Observable<any> {  
        const url = StaticVariable.porturl + 'GetAutoGenerateCertificateNo/'+accid;
        return this.httpclient.get<any>( url );
    }
    
}