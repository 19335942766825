
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceResponsePojo, AttendancePojo } from 'src/app/pojo/attendance.pojo';
import { AttendanceEntryService } from '../../Attendance/attendance-entry/attendance-entry.service';
import { DayWiseAttendancePojo, DayWiseAttendanceHoursPojo } from 'src/app/pojo/daywise-attendance-entry.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-daywise-attendance-entry',
  templateUrl: './daywise-attendance-entry.component.html',
  styleUrls: ['./daywise-attendance-entry.component.scss']
})
export class DaywiseAttendanceEntryComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  fromDate: NgbDateStruct;
  loggedUser = new MUserMasterPojo();

  sectionArr: SectionMasterPojo[] = [];
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  departmentId: number;
  sectionMasterArr: SectionMasterPojo[] = [];
  classid: number;
  sectionid: number;
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';
  classMasterArr: ClassMasterPojo[] = [];
  id: number;
  isLoading: boolean = false;
  body = [];
  content = [];
  documentTime: string;
  resultDept: number;
  resultClass: number;
  resultSection: number;
  resulltDate: number;
  searchOption = 'ALL';
  typeId;
  academic: AcademicYearMasterPojo[] = [];
  academicYear;
  standTermArr: StandardTermPojo[] = [];
  classPojo = new ClassMasterPojo();
  finalEntryResArr: AttendancePojo[] = [];
  attendanceEntryArr: DayWiseAttendancePojo[] = [];
  accountMasterArr:AccountMasterPojo[]=[];
  constructor(private standardTermService: StandardStructureMasterService,
    private feeAllocationService: FeeAllocationMasterService, private settingService: AcademicSettingService,
    private reportService: ReportService, private studentMasterService: StudentMasterService,
    private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService,
    private classMasterService: ClassMasterService, private router: Router,private accountMasterService:AccountMasterService,
    private attendanceService: AttendanceEntryService) { }

  ngOnInit() {

    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));
    $('#editAfterClick').hide();
    this.getDepartmentAll();
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.academicSettingAll();
    this.getStandrdTermAll();
this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  academicSettingAll() {
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe(response => {
      this.academic = response;
      for (let i = 0; i < this.academic.length; i++) {
        if (this.academic[i].academicFlag == 1) {
          this.academicYear = this.academic[i].academicYearId;
        }
      }
    }, error => {
      bootbox.alert('Setting Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe(response => {
      this.sectionMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
      this.classMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getClassMasterHoursListById() {
    this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe(response => {
      this.classPojo = response;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe(run => {
      this.departmentArr = run;
    }, error => {
      bootbox.alert({ message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getStandrdTermAll() {
    this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe(response => {
      this.standTermArr = response;
    }, error => {
      bootbox.alert({ message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getDepartmentNameBydepartmentId(id) {
    if (this.departmentArr.find(r => r.deptId == id)) {
      return this.departmentArr.find(r => r.deptId == id).deptName;
    } else {
      return id;
    }
  }
  getClassNameById(id) {
    if (this.classMasterArr.find(r => r.classId == id)) {
      return this.classMasterArr.find(r => r.classId == id).className;
    } else {
      return id;
    }
  }
  getSectionNameById(id) {
    if (this.sectionMasterArr.find(r => r.sectionId == id)) {
      return this.sectionMasterArr.find(r => r.sectionId == id).sectionName;
    } else {
      return id;
    }
  }
  getAcademicYrById(id) {
    if (this.academic.find(r => r.academicYearId == id)) {
      return this.academic.find(r => r.academicYearId == id).academicYear;
    } else {
      return id;
    }
  }
  getStandardTermById(id) {
    if (this.standTermArr.find(r => r.standardTermId == id)) {
      return this.standTermArr.find(r => r.standardTermId == id).standardTerm;
    } else {
      return id;
    }
  }
  getHoursNameById(id) {
    if (this.classPojo.list.find(r => r.hoursId == id)) {
      return this.classPojo.list.find(r => r.hoursId == id).hoursName;
    } else {
      return id;
    }
  }
  getReportById() {
    this.attendanceEntryArr = [];  
    this.isLoading = true;
    let arg1 = (<HTMLFormElement>document.getElementById('fromEntryDate')).value;
    if(this.classid !=null&&this.sectionid != null && this.academicYear != null && arg1 != null){
   
    this.reportService.getDayWiseAttendanceEntryReportById(this.academicYear, this.classid, this.sectionid, arg1,this.loggedUser.accId).subscribe(response => {
      this.attendanceEntryArr = response;
      this.resultDept = this.departmentId;
      this.resultClass = this.classid;
      this.resultSection = this.sectionid;
      this.resulltDate = arg1;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
      this.isLoading = false;
    });
  }else{
    bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );

}
  }

  Reset() {
    this.classid = 0;
    this.departmentId = 0;
    this.sectionid = 0;
    this.fromDate = null;
  }
  downloadPDF() {

    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.content.push({
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
    });
    this.content.push({
      text: 'DAY WISE ATTENDANCE ENTRY REPORT\t[ ' + moment(this.resulltDate, 'YYYY-MM-DD').format('DD-MM-YYYY') + ' ]', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
    });

    this.content.push({
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
    });
    this.content.push({
      text: 'SELECTED DEPARTMENT : ' + this.getDepartmentNameBydepartmentId(this.resultDept), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\nSELECTED TYPE :' + this.getClassNameById(this.resultClass) + ' - ' + this.getSectionNameById(this.resultSection), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
    });
    this.content.push({
      text: '\n', fontSize: 8, alignment: 'center'
    });
    this.body = [];

    let r1head: any[] = [];
    let r2head: any[] = [];
    let r3head: any[] = [];

      r1head.push({
        text: 'S.No',alignment: 'center', fontSize: 10,bold:'true'
      });

      r1head.push({
        text: 'Student Name', alignment: 'center', fontSize: 10,bold:'true'
      });
      for (let j = 0; j < this.attendanceEntryArr.length; j++) {  
          let tempArr:DayWiseAttendanceHoursPojo[]=[];
          if(j==0){
            tempArr = this.attendanceEntryArr[j].list;
            for(let i=0;i<tempArr.length;i++){
            r1head.push({
              text: tempArr[i].hoursName,  alignment: 'center', fontSize: 10,bold:'true'
            });
          }
          this.body.push(r1head);
          }
        }
     for (let j = 0; j < this.attendanceEntryArr.length; j++) {  
      r3head =[];
      r3head.push({ text: j + 1,alignment: 'center', fontSize: 10 });
      r3head.push({ text: this.attendanceEntryArr[j].name, alignment: 'center', fontSize: 10 });
      let tempArr:DayWiseAttendanceHoursPojo[]=[];
        tempArr = this.attendanceEntryArr[j].list;
      for (let k = 0; k < tempArr.length; k++) {
        let status ="";
        if(tempArr[k].attendanceStatus == 'PRESENT'){
          status = 'P';
        }if(tempArr[k].attendanceStatus == 'ABSENT'){
          status = 'A';
        }if(tempArr[k].attendanceStatus != 'PRESENT' && tempArr[k].attendanceStatus != 'ABSENT'){
          status = '-';
        }
        r3head.push({ text: status, alignment: 'center', fontSize: 10,font: 'ArialNarrow' });
        }
        this.body.push(r3head);
        }
     this.content.push({
      columns: [
          { 'table': { 'body': this.body, alignment: 'center' }, width: 'auto',alignment:'center' }
      ]
    });


    const dd = {
      'content': this.content,
      pageOrientation: 'landscape',
      pageSize: 'A4',
      footer: (currentPage, pageCount) => {
        return {
          margin: 10,
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                    '\n',
                  margin: [0, 20]
                },
                {
                  text: this.getAccountNameById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                }
              ],
              alignment: 'center'
            }
          ]
        };
      }
    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];

  }

}
