import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterPojo, NotesMasterPojo } from "src/app/pojo/student-master.pojo";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { ActivatedRoute, Router } from "@angular/router";

import { Subject, Observable } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';

declare var $: any;
declare var bootbox: any;

import * as moment from 'moment';
import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { AcademicSettingService } from 'src/app/management/Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { CommunityMasterService } from '../../community-master/community.service';
import { CasteMasterService } from '../../caste-master/caste-master.service';
import { CommunityPojo, CastePojo } from 'src/app/pojo/community-caste.pojo';
import { AreaMasterService } from '../../area-master/area-master.service';
import { DiscountTypeMasterService } from '../../dicount-type-master/discount-type.service';
import { DiscountTypeMasterPojo } from 'src/app/pojo/discount-type-master.pojo';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { NotesMasterService } from '../../notes-master/notes-master.service';

@Component( {
    selector: 'app-edit-student-master',
    templateUrl: './edit-student-master.component.html',
    styleUrls: ['./edit-student-master.component.scss'],
    providers:[AcademicSettingService]
} )

export class EditStudentMasterComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    loggedUser = new MUserMasterPojo();

    editStudentMaster = new StudentMasterPojo();

    mediumArr: MediumMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];

    imagePojo: FtpImagePojo = new FtpImagePojo();

    isLoading: boolean = true;
    editDOA: NgbDateStruct;
    editDOB: NgbDateStruct;

    studentId;
    fileObj: any;
    showSaveBtn: boolean = false;
    imageUploadLoading: boolean = false;
    imageUploadStatus: boolean = false;
    tempFtpArr:FtpImagePojo[]=[];
    selectedFiles: File[] = [];
    newFile : boolean = false;
    fileincreament:number = 0;
    // toggle webcam on/off
    public showWebcam = false;
    public mirrorImage = "always";
    documentListPojo = new FtpImagePojo();
    // latest snapshot
    public webcamImage: WebcamImage = null;

    deleteFileIncreament = 0;
    addFiles:boolean=false;
    deleteFiles:boolean=false;
    bothFunction:boolean=false;
    onlyUpdate:boolean=false;
    serviceCount:number=0;
    notesArr : NotesMasterPojo[] =[];
    notesArrAll:NotesMasterPojo [] =[];
    editNotes = new NotesMasterPojo();
    academic:AcademicYearMasterPojo[] =[];
    communityArr: CommunityPojo[] = [];
    casteArr: CastePojo[] = [];
    discountTypeArr: DiscountTypeMasterPojo[] = [];
    areaArr: AreaMasterPojo[] = [];
    path;
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();

    public triggerSnapshot(): void {
        this.trigger.next();
        this.showWebcam = false;
        this.showSaveBtn = true;
    }

    public handleImage( webcamImage: WebcamImage ): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    constructor( private areamasterserivce: AreaMasterService,private discountTypeMasterserivce:DiscountTypeMasterService,
        private castemasterserivce: CasteMasterService,private communitymasterserivce: CommunityMasterService,private activatedRoute: ActivatedRoute, private router: Router, private calendar: NgbCalendar, 
        private mediumMasterService: MediumMasterService, private sectionMasterService: SectionMasterService, 
        private studentMasterService: StudentMasterService, private classMasterService: ClassMasterService,
        private notesMasterService:NotesMasterService,private settingService:AcademicSettingService, ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
//console.log(this.loggedUser);
        this.activatedRoute.params.subscribe( response => {
            console.log(response);
            this.studentId = response['studentId'];
            this.path = response['path'];
            
        } );
        this.academicSettingAll();
        this.getStudentMasterById( this.studentId );

        this.getMediumMasterAll();
      //  this.getSectionMasterAll();
        this.getClassMasterAll();
        this.getNotesMasterAll();
        this.getCasteAll();
        this.getCommunityAll();
        this.getAreaAll();
        this.getDiscountTypeAll();
    }
    getAreaAll() {
        this.areamasterserivce.getAllArea(this.loggedUser.accId).subscribe( response => {
           this.areaArr = response;
             }, error => {
           bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
   }
   handleInitError(e){
       
   }
   getDiscountTypeAll() {
       this.discountTypeMasterserivce.getAllDiscountType(this.loggedUser.accId).subscribe( response => {
           this.discountTypeArr = response;
             }, error => {
           bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
   }
    getCasteAll() {
        this.isLoading = true;
        this.castemasterserivce.getAllCaste(this.loggedUser.accId).subscribe( response => {
            this.casteArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Caste All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getCommunityAll() {
        this.isLoading = true;
        this.communitymasterserivce.getAllCommunity(this.loggedUser.accId).subscribe( response => {
            this.communityArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Community All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
             }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
    getNotesMasterAll() {
        this.notesMasterService.getNotesAll(this.loggedUser.accId).subscribe( response => {
            this.notesArrAll = response;
             }, error => {
            bootbox.alert( { message: 'Get Notes All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getNotesByNotesId(id){
        this.editNotes.description = this.getNotesById(id);
        this.editNotes.name = this.getNotesNameById(id);
        
    }
    getNotesById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).description;
        }
        else {
            return id;
        }
    }
    getNotesNameById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).name;
        }
        else {
            return id;
        }
    }
    LoadCamera() {
        this.showWebcam = true;
        this.webcamImage = null;
        this.showSaveBtn = false;
    }

    readURL() {
        this.webcamImage = null;
        let imageStr = '';
        let input = ( <HTMLInputElement>document.getElementById( 'chooseFile' ) );
        if ( input.files && input.files[0] ) {
            this.fileObj = input.files[0];
            const reader = new FileReader();
            reader.onload = function( e ) {
                imageStr = reader.result as string;
                $( '#imagePreview' ).attr( 'src', imageStr );
            };
            reader.readAsDataURL( input.files[0] );
        }
        this.showSaveBtn = true;
    }

    setImage() {
        let img = $('#imagePreview').attr('src');
        let imgCam = $('#webcamImagePreview').attr('src');
       
        if (imgCam != null) {
            $('#imageProfile').attr('src', imgCam);
           // $('#imageProfile').addClass('flip');
            var file = this.dataURLtoFile(imgCam,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
             $( '#webcamImagePreview' ).attr( 'src', str );
         
        } else {
            $('#imageProfile').attr('src', img);
            $('#imageProfile').removeClass('flip');
            var file = this.dataURLtoFile(img,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
            $( '#imagePreview' ).attr( 'src', str );
          
        }

        this.uploadFile(this.fileObj);
    }

     dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
        uploadFile(file: File) {
            //  console.log(JSON.stringify(file));
              this.imageUploadLoading = true;
              //alert(this.editStudentMaster.profile.imagePath);
              if(this.editStudentMaster.profile.imagePath != null && this.editStudentMaster.profile.imagePath != undefined){
              //  alert('if');
                this.studentMasterService.deleteFtpImage(this.editStudentMaster.profile.imageId,1).subscribe( response => {
                    this.studentMasterService.uploadImage(file, 1, 0).subscribe((res) => {
                        this.imagePojo = res;
                        this.imageUploadLoading = false;
                        if (this.imagePojo.imageId != 0) {
                            this.imageUploadStatus = true;
                            this.editStudentMaster.profile.imagePath = '';
                            this.editStudentMaster.profile.imageName = this.imagePojo.imageName;
                            this.editStudentMaster.profile.imagePath = this.imagePojo.imagePath;
                            this.editStudentMaster.profile.imageId = this.imagePojo.imageId;
                            this.editStudentMaster.profile.uniqueId = this.studentId;
                            $( '#openModal' ).modal( 'hide' );
                            
                        } else {
                            bootbox.alert('Failed to uploading a image!');
                        }
                    }, error => {
                        bootbox.alert({ message: 'Add Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
                        this.imageUploadLoading = false;
                    });   
                }, error => {
                    bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                     } );
              }else{
                //  alert('e');
                this.studentMasterService.uploadImage(file, 1, 0).subscribe((res) => {
                    this.imagePojo = res;
                    this.imageUploadLoading = false;
                    if (this.imagePojo.imageId != 0) {
                        this.imageUploadStatus = true;
                        this.editStudentMaster.profile.imagePath = '';
                        this.editStudentMaster.profile.imageName = this.imagePojo.imageName;
                        this.editStudentMaster.profile.imagePath = this.imagePojo.imagePath;
                        this.editStudentMaster.profile.imageId = this.imagePojo.imageId;
                        this.editStudentMaster.profile.uniqueId = this.studentId;
                        $( '#openModal' ).modal( 'hide' );
                        
                    } else {
                        bootbox.alert('Failed to uploading a image!');
                    }
                }, error => {
                    bootbox.alert({ message: 'Add Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
                    this.imageUploadLoading = false;
                });
              }
              
           
              
          }
    selectToday() {
        this.model = this.calendar.getToday();
    }

    changeForm2() {
        this.form = true;
    }

    changeForm1() {
        this.form = false;
    }

    phoneNumValidation( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'mobileNumber' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#mobileNumber' ).focus();
        }
    }

    phoneNumValidation2( num ) {
        const contactNum = /^[6-9]\d{9}$/;
        if ( num.match( contactNum ) || num == '' ) {
        } else {
            ( <HTMLInputElement>document.getElementById( 'mobileNumber2' ) ).value = '';
            alert( 'Please check the mobile number. This number is not valid.' );
            $( '#mobileNumber2' ).focus();
        }
    }

    getMediumMasterAll() {
        this.mediumMasterService.getMediumAll().subscribe( response => {
            this.mediumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( {
                message: 'Get Medium All Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
            this.isLoading = false;
        } );
    }

    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStudentMasterById( studentId ) {
        this.studentMasterService.getStudentById( studentId,this.loggedUser.accId ).subscribe( response => {
            this.editStudentMaster = response;
            this.getSectionMasterByDeptId(this.editStudentMaster.classId);
            this.notesArr = this.editStudentMaster.notes;
            this.editDOA = { day: moment( this.editStudentMaster.doa ).date(), month: moment( this.editStudentMaster.doa ).month() + 1, year: moment( this.editStudentMaster.doa ).year() };
            this.editDOB = { day: moment( this.editStudentMaster.dob ).date(), month: moment( this.editStudentMaster.dob ).month() + 1, year: moment( this.editStudentMaster.dob ).year() };
        }, error => {
            bootbox.alert( {
                message: 'Get Student By Id Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
        } );
    }
    modalShow(){
        $( '#myModal1' ).modal( 'show' );
    }
    removeFile(images,i) {
        this.selectedFiles.splice(i,1);
  }
  removeFileForEst(item,i){
     
      this.tempFtpArr.push(item);
      this.editStudentMaster.imageList.splice(i,1);
     
  }
  setFiles(images) {
    for(let i=0;i<images.files.length;i++) { 
    this.selectedFiles.push(images.files[i]);
}    this.newFile = true;
   // this.status = "Documents Still Not Uploading";
}
uploadFiles(){
   // this.editStudentMaster.imageList = [];
    this.fileincreament = 0;
    this.uploadDoc();
}
uploadDoc() {
     if(this.selectedFiles.length !=0){
        $('#addBeforeClick').hide();$('#addAfterClick').show();
    if (this.selectedFiles.length !== this.fileincreament) {
        this.studentMasterService.uploadImage(this.selectedFiles[this.fileincreament], 2,0).subscribe((res) => {

            this.documentListPojo = res;
            this.fileincreament++;
            this.uploadDoc();
            if (this.documentListPojo.imageId != 0) {
                this.editStudentMaster.imageList.push(this.documentListPojo);
            } else {
                alert("Failed Image Uploading.....");
                 $('#addBeforeClick').show();$('#addAfterClick').hide();
            }
        }, er => {
            alert("Failed image uploading..." + er);
            $('#addBeforeClick').show();$('#addAfterClick').hide();
        });
        if (this.selectedFiles.length !== this.fileincreament) {
            $('#myModal1').modal('hide');
        }
    }
}else{
    alert('No files to upload');
}
}
uploadFinalFiles() {
     if(this.selectedFiles.length !== this.fileincreament) {
     this.studentMasterService.uploadImage( this.selectedFiles[this.fileincreament] , 2,0 ).subscribe(( res ) => {
        this.imagePojo = res;
        this.fileincreament++;
        this.uploadFinalFiles();
        if ( this.imagePojo.imageId != 0 ) {
            this.editStudentMaster.fileName = this.imagePojo.imageName;
            this.editStudentMaster.fileAddressPath = this.imagePojo.imagePath;
            this.editStudentMaster.imageId = this.imagePojo.imageId;
        } else {
            alert( "Failed Image Uploading....." );
            $('#addBeforeClick').show();$('#addAfterClick').hide();
        }
    }, er => { alert( "Failed image uploading..." + er ); 
    $('#addBeforeClick').show();$('#addAfterClick').hide();
    } );
    }else{
        this.serviceCount++;
        this.addFiles=true;
        this.selectedFiles = [];
    }
    }
    deleteImageFiles(){
        if(this.tempFtpArr.length!=this.deleteFileIncreament){
                this.studentMasterService.deleteFtpImage(this.tempFtpArr[this.deleteFileIncreament].imageId,2).subscribe( response => {
                    this.deleteFileIncreament++;  
                    this.deleteImageFiles();  
                }, error => {
                    bootbox.alert( 'Delete Service Error! - HTTP Status ' + error.status );
                     } );
                }else{
                    this.serviceCount++;
                    this.deleteFiles=true;
                }
    }
   /* addFilesWithUpdate(){
        alert(this.addFiles);
        if(this.addFiles == true && this.serviceCount ==1)
            {
            this.updateStudent();
            }
        if(this.onlyUpdate == true &&  this.serviceCount == 1){
            this.uploadFinalFiles();
        }
         if(this.serviceCount == 2 && this.onlyUpdate == true && this.addFiles == true){
         $( '#editModal' ).modal( 'hide' );
         bootbox.alert('Successfully Updated');
         $('#addBeforeClick').show();$('#addAfterClick').hide();
      
     }  
    }*/
    deleteWithUpdate(){
        this.deleteImageFiles();
        this.updateStudent();
        if(this.serviceCount == 2){
            alert('Success');
            $( '#editModal' ).modal( 'hide' );
            $('#addBeforeClick').show();$('#addAfterClick').hide();
         
        }  
    }
    addAndDeleteWithUpdate(){
        this.uploadFinalFiles();
        this.deleteImageFiles();
        this.updateStudent();
        if(this.serviceCount == 3){
            alert('Success');
            $( '#editModal' ).modal( 'hide' );
            $('#addBeforeClick').show();$('#addAfterClick').hide();
         
        }  
    }
    onlyUpdateWithoutAddAndDelete(){
        this.updateStudent();
        if(this.serviceCount == 1){
            alert('Success');
            $( '#editModal' ).modal( 'hide' );
            $('#addBeforeClick').show();$('#addAfterClick').hide();
         
        }  
    }
    update(){
         // $('#addBeforeClick').hide();$('#addAfterClick').show();
     if(this.selectedFiles.length != 0 && this.tempFtpArr.length !=0){
           this.addAndDeleteWithUpdate();
    }
    else if(this.selectedFiles.length == 0 && this.tempFtpArr.length ==0){
         this.onlyUpdateWithoutAddAndDelete();
    }else if(this.tempFtpArr.length != 0){
        this.deleteWithUpdate();
       }else if(this.selectedFiles.length != 0){
    this.updateStudent();
     }
    
    }

    getSectionMasterByDeptId(id) {
        this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    
    updateStudent() {
        let editStudent = new StudentMasterPojo();
        this.editStudentMaster.doa = moment( this.editDOA.year + '-' + this.editDOA.month + '-' + this.editDOA.day, 'YYYY-MM-DD' ).toDate();
        this.editStudentMaster.dob = moment( this.editDOB.year + '-' + this.editDOB.month + '-' + this.editDOB.day, 'YYYY-MM-DD' ).toDate();
        if (this.selectedFiles.length > 0) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.editStudentMaster.imageList[i].typeId = 1;
            }
        }
        if(this.editStudentMaster.busFlag=="FALSE"){
            this.editStudentMaster.areaId=0;
         }
         if(this.editStudentMaster.discountFlag=="FALSE"){
            this.editStudentMaster.discountTypeId=0;
         }
        editStudent = this.editStudentMaster;
        this.isLoading = true;
         editStudent.accId=this.loggedUser.accId;
        this.studentMasterService.updateStudent( editStudent ).subscribe( response => {
            if(response == 101 ){
                bootbox.alert('Admission Number already Exist');
                this.isLoading = false;
                // this.studentMasterService.getAutoGenerateAdmissionNo(this.loggedUser.accId).subscribe( rs => {
                //     this.editStudentMaster.admissionNo = rs;
                //     this.updateStudent()
                //    }, error => {
                //     bootbox.alert( 'Error' + error );
                //     } );
            }if(response == 102){
            bootbox.alert( {
                message: 'successfully updated !\n'+'Your Admission Number is -'+this.editStudentMaster.admissionNo,
                className: 'text-success h5',
                closeButton: false
            } );
            this.isLoading = false;
            if(this.path == 1){
            this.router.navigate( ['/console/studentMaster'] );
            }
            if(this.path == 2){
                this.router.navigate( ['/console/classwise'] );
                }
            this.editStudentMaster = new StudentMasterPojo();
            ( <HTMLFormElement>document.getElementById( 'formSecond' ) ).reset();
            this.changeForm1();
            this.serviceCount++;
            this.onlyUpdate=true;
        }
        }, error => {
            bootbox.alert( {
                message: 'Update Student Service Error! HTTP Status ' + error.status,
                className: 'text-danger h5',
                closeButton: false
            } );
            this.isLoading = false;
        } );
    }
    editNotesMaster() {
        this.editNotes.accId = this.loggedUser.accId;
        this.editNotes.userId = this.loggedUser.userId;
         this.notesArr.push(this.editNotes);
        this.editNotes = new NotesMasterPojo();
    }
    editNotesMasterDelete(i,id) {
             this.notesArr.splice(i, 1);
              }
    submitEditNotes() {
        this.editStudentMaster.notes = [];
        this.editStudentMaster.notes = this.notesArr;
        $('#editNotesModal').modal('hide');
    }
}
