

import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { DiscountTypeMasterPojo } from 'src/app/pojo/discount-type-master.pojo';
import { DiscountTypeMasterService } from './discount-type.service';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-dicount-type-master',
  templateUrl: './dicount-type-master.component.html',
  styleUrls: ['./dicount-type-master.component.scss']
})

export class DicountTypeMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    discountTypeArr: DiscountTypeMasterPojo[] = [];
    addDis = new DiscountTypeMasterPojo();
    editDis = new DiscountTypeMasterPojo();
    receiptHeadArr:ReceiptHeadPojo[]=[];

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private discountTypeMasterserivce:DiscountTypeMasterService,
        private receiptheadService:ReceiptHeadService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getDiscountTypeAll();
          this.getAllReceiptHead();
    }

    getDiscountTypeAll() {
        this.isLoading = true;
        this.discountTypeMasterserivce.getAllDiscountType(this.loggedUser.accId).subscribe( response => {
            this.discountTypeArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getAllReceiptHead() {
        this.receiptheadService.getReceiptHeadForReceipt(this.loggedUser.accId).subscribe( rs => {
            this.receiptHeadArr = rs;
             }, error => {
            bootbox.alert( 'Error' + error );
           } );
    }
    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addDis.userId = this.loggedUser.userId;
        this.addDis.accId = this.loggedUser.accId;
          this.discountTypeMasterserivce.addDiscountType( this.addDis ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getDiscountTypeAll();
        }, error => {
            bootbox.alert( { message: 'Add Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editDis = Object.assign( {}, arg );
    }

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editDis.userId = this.loggedUser.userId;
        this.editDis.accId = this.loggedUser.accId;
        this.discountTypeMasterserivce.updateDiscountType( this.editDis ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getDiscountTypeAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.discountTypeArr.indexOf( this.discountTypeArr.find( r => r.discountTypeId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.discountTypeMasterserivce.deleteDiscountType( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.discountTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.discountTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getDiscountTypeAll();
        }, error => {
            bootbox.alert( { message: 'Delete  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
