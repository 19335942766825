import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FeeCollectionReportDetailsPojo } from 'src/app/pojo/fee-collection-report-detail.pojo';
import { TempFeeCollectionReportDetailsPojo } from '../refund.component';
import { LedgercreationService } from 'src/app/management/Masters/ledgercreation/ledgercreation.service';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { BusFeeRefundPojo } from 'src/app/pojo/bus-fee-refund.pojo';
import { FeeCollectionService } from '../../fee-collection/fee-collection.service';
import * as moment from 'moment';
import { StaticVariable } from '../../../../globle.class';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import * as converter from 'number-to-words';
import { AccountMasterService } from 'src/app/management/Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $:any;
declare var bootbox:any;
@Component({
  selector: 'app-refund-form',
  templateUrl: './refund-form.component.html',
  styleUrls: ['./refund-form.component.scss']
})
export class RefundFormComponent implements OnInit {
  feeCollectionPojo = new TempFeeCollectionReportDetailsPojo();
  pickspaidledgersByAccountGroup:LedgerPojo[]=[];
  public paidacc: number[] = [1, 2];
  loggedUser = new MUserMasterPojo();
  busRef=new BusFeeRefundPojo();
  isLoading:boolean=false;
  busResponse= new BusFeeRefundPojo();
  accountMasterArr:AccountMasterPojo[]=[];
  constructor(private activatedRoute: ActivatedRoute, private router: Router,
    private accountMasterService:AccountMasterService
  ,private ledgercreationService:LedgercreationService,private feeCollectionService:FeeCollectionService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

    this.activatedRoute.params.subscribe( arg => {
        this.feeCollectionPojo.collectionDate=arg.collectionDate;
        this.feeCollectionPojo.collectionTime=arg.collectionTime;
        this.feeCollectionPojo.collectionId=arg.collectionId;
        this.feeCollectionPojo.name=arg.name;
        this.feeCollectionPojo.admissionNo=arg.admissionNo;
        this.feeCollectionPojo.academicYearId=arg.academicYearId;
        this.feeCollectionPojo.classId=arg.classId;
        this.feeCollectionPojo.sectionId=arg.sectionId;
        this.feeCollectionPojo.receiptId=arg.receiptId;
        this.feeCollectionPojo.receiptNo=arg.receiptNo;
        this.feeCollectionPojo.overAllPaidTotal=arg.overAllPaidTotal;
        this.feeCollectionPojo.paidLedgerId=arg.paidLedgerId;
        this.feeCollectionPojo.termId=arg.termId;
        this.feeCollectionPojo.standardTermId=arg.standardTermId;
        this.feeCollectionPojo.studentId=arg.studentId;
        this.feeCollectionPojo.feeAllocationId=arg.feeAllocationId;
        this.feeCollectionPojo.academicYear=arg.academicYear;
        this.feeCollectionPojo.className=arg.className;
        this.feeCollectionPojo.sectionName=arg.sectionName;
        this.feeCollectionPojo.paidLedgerName=arg.paidLedgerName;  
        this.feeCollectionPojo.standardTermName=arg.standardTermName;
        this.feeCollectionPojo.termName=arg.termName;
          } );
          this.ledgercreationService.getLedgerByType( 'PAID',this.loggedUser.accId).subscribe( log => {
            this.pickspaidledgersByAccountGroup = log;
        } ); 
        this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getPaidLedgerById( id ) {
    if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
        return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
    } else {
        return id;
    }
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  add() {
     if(this.busRef.refundAmount>0 && this.busRef.refundAmount<=this.feeCollectionPojo.overAllPaidTotal){
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
      this.isLoading = true;
      this.busRef.classId=this.feeCollectionPojo.classId;
      this.busRef.feeAllocationId=this.feeCollectionPojo.feeAllocationId;
      this.busRef.busAmount=this.feeCollectionPojo.overAllPaidTotal;
      this.busRef.sectionId=this.feeCollectionPojo.sectionId;
      this.busRef.standardTermId=this.feeCollectionPojo.standardTermId;
      this.busRef.studentId=this.feeCollectionPojo.studentId;
      this.busRef.termId=this.feeCollectionPojo.termId;
      this.busRef.receiptId=this.feeCollectionPojo.receiptId;
      this.busRef.admissionNo=this.feeCollectionPojo.admissionNo;
      this.busRef.academicYearId=this.feeCollectionPojo.academicYearId;
      this.busRef.accId=this.loggedUser.accId;
      this.busRef.refundDate=moment().toDate();
     // this.busRef.areaId=this.feeCollectionPojo.;
    this.feeCollectionService.addBusFeeRefund(this.busRef).subscribe( response => {
      this.busResponse=response;
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
      this.isLoading = false;
      this.print();  
      }, error => {
      bootbox.alert( { message: 'Get Bus Fee Collection Report Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      this.isLoading = false;
        } );
}else{
  bootbox.alert('Please check the Amount!you cannot refund,, greater than paid amount!..');
}
  }

  print(){
    let content =[];
    let body =[];
      let documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
      content.push(
         {
             columns: [
                  {
                     image: StaticVariable.logo, width:50 },
                     {width:'4%',
                      text:''},
                     {
                         width: '80%',
                         
                         text: [{text:this.getAccountNameById(this.loggedUser.accId) , style: 'e' },
                         {text:'\n'+this.getAccountPlaceById(this.loggedUser.accId)+'\n', style: 'e3' },{text:'BusFee Refund Receipt\n', style: 'e' },{text:'Academic Year:'+this.feeCollectionPojo.academicYear,alignment:'right', style: 'e2' },
                         ,{text:'\nNo.- '+this.feeCollectionPojo.receiptNo,alignment:'right', style: 'e2' },
                     ]
                         
                     },
              ] } );
             content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
             
                 content.push( {
                  columns:[{
                      width:'40%',
                      text: 'Name of Pupil ',fontSize: 13, font: 'TimesNewRoman'
                   },
                   {
                    width:'3%',
                    text: ': ',fontSize: 13, font: 'TimesNewRoman'
                 },
                   {
                     width:'57%',
                     text: this.feeCollectionPojo.name,bold:'true',fontSize: 13, font: 'TimesNewRoman'
                  } ],lineHeight: 1.6
             });
             content.push( {
              columns:[{
                  width:'40%',
                  text: 'Admission Number ',fontSize: 13, font: 'TimesNewRoman'
               },{
                width:'3%',
                text: ': ',fontSize: 13, font: 'TimesNewRoman'
             },
               {
                 width:'57%',
                text: this.feeCollectionPojo.admissionNo,bold:'true',fontSize: 13, font: 'TimesNewRoman'
              } ],lineHeight: 1.6
         });
         content.push( {
          columns:[{
              width:'40%',
              text: 'Class ',fontSize: 13, font: 'TimesNewRoman'
           },
           {
            width:'3%',
            text: ': ',fontSize: 13, font: 'TimesNewRoman'
         },
           {
             width:'57%',
              text: this.feeCollectionPojo.className+'-'+this.feeCollectionPojo.sectionName,bold:'true',fontSize: 13, font: 'TimesNewRoman'
          } ],lineHeight: 1.6
     });
     content.push( {
      columns:[{
          width:'40%',
          text: 'Term Name ',fontSize: 13, font: 'TimesNewRoman'
       },
       {
        width:'3%',
        text: ': ',fontSize: 13, font: 'TimesNewRoman'
     },
       {
         width:'57%',
          text: this.feeCollectionPojo.termName,bold:'true',fontSize: 13, font: 'TimesNewRoman'
      } ],lineHeight: 1.6
 });
 content.push( {
  columns:[{
      width:'40%',
      text: 'StandardTerm  ',fontSize: 13, font: 'TimesNewRoman'
   },
   {
    width:'3%',
    text: ': ',fontSize: 13, font: 'TimesNewRoman'
 },
   {
     width:'57%',
     text:this.feeCollectionPojo.standardTermName,bold:'true',fontSize: 13, font: 'TimesNewRoman'
  } ],lineHeight: 1.6
});
content.push( {
  columns:[{
      width:'40%',
      text: 'Total Paid Amount ',fontSize: 13, font: 'TimesNewRoman'
   },
   {
    width:'3%',
    text: ': ',fontSize: 13, font: 'TimesNewRoman'
 },
   {
     width:'57%',
     text:this.feeCollectionPojo.overAllPaidTotal,bold:'true',fontSize: 13, font: 'TimesNewRoman'
  } ],lineHeight: 1.6
});

content.push( {
  columns:[{
      width:'40%',
      text: 'Paid Ledger ',fontSize: 13, font: 'TimesNewRoman'
   },
   {
    width:'3%',
    text: ': ',fontSize: 13, font: 'TimesNewRoman'
 },
   {
     width:'57%',
   text:this.getPaidLedgerById(this.busResponse.paidLedgerId),bold:'true',fontSize: 13, font: 'TimesNewRoman'
  } ],lineHeight: 1.6
});
          body.push( [
                    {text: 'Refund Amount ', fontSize: 15,  font: 'TimesNewRoman',border:[false,false,false,false]} ,{
                   text: this.busResponse.refundAmount, fontSize: 15,  font: 'TimesNewRoman',bold:'true',alignment:'center',border:[true,true,true,true]}
                    ]);
   
   //content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
   content.push( {
    'table': {
        headerRows: 1,
        'body': body,
        widths: ['40%', '30%']
    }
} );
  
          let amount =converter.toWords( this.busResponse.refundAmount);
          amount = amount.toUpperCase();
          content.push({text:'\n\n'});
          content.push( [{  text: [{ text: 'Refundable with a sum of Rupees', fontSize: 12, font: 'TimesNewRoman', alignment: 'left',},
                     {text:'\t'+amount+'\t('+'₹ '+ this.busResponse.refundAmount+ ' ) ', fontSize: 10, font: 'Roboto', alignment: 'left',bold: 'true'},
                               ],lineHeight:1.2,
                 }]);
                 content.push({text:'\n\n'});
                 content.push( {
                  columns:[{
                      width:'60%',
                      text: 'Date: '+moment(this.busResponse.refundDate,'YYYY-MM--DD').format('DD-MM-YYYY'),fontSize: 13, font: 'TimesNewRoman'
                   },
                   {
                     width:'40%',
                     text: 'Clerk',fontSize: 13, font: 'TimesNewRoman',alignment:'center'
                  } ],lineHeight: 1.6
                });
              
      
                 
    const dd = {
          'content':content,  
          pageOrientation: 'portrait',
          pageSize: 'A5',  
        
         margins: [0, 0, 0, 0],
          styles: {
             e: {
                 font: 'TimesNewRoman',
                  bold: true, 
                  fontSize: 14,
                  alignment: 'center'
             },
             e2: {
                 font: 'TimesNewRoman',
                  bold: true, 
                  fontSize: 11,
                  alignment: 'center'
             },
             e3: {
                 font: 'TimesNewRoman',
                  fontSize: 10,
                  alignment: 'center'
             },
             e4: {
                 font: 'TimesNewRoman',
                  fontSize: 11,
                  bold: true, 
                  alignment: 'center'
             },
         },
         
      };
  
      pdfMake.createPdf( dd ).open();
     body = [];
     content =[];
    this.reset();
     
  }
  reset(){
    this.feeCollectionPojo=new TempFeeCollectionReportDetailsPojo();
    this.busRef=new BusFeeRefundPojo();
    this.router.navigate( [`/console/refund`] );
  }
}