
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { CurriculumMasterPojo } from 'src/app/pojo/curriculum-master.pojo';
import { MediumMasterPojo } from 'src/app/pojo/medium-master.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { CommunityMasterService } from './community.service';
import { CommunityPojo } from 'src/app/pojo/community-caste.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-community-master',
  templateUrl: './community-master.component.html',
  styleUrls: ['./community-master.component.scss']
})

export class CommunityMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    communityArr: CommunityPojo[] = [];
    addComm = new CommunityPojo();
    editComm = new CommunityPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private communitymasterserivce: CommunityMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getCommunityAll();
    }

    getCommunityAll() {
        this.isLoading = true;
        this.communitymasterserivce.getAllCommunity(this.loggedUser.accId).subscribe( response => {
            this.communityArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Community All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    addCommunity() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addComm.accId=this.loggedUser.accId;
          this.communitymasterserivce.addCommunity( this.addComm ).subscribe( response => {
            bootbox.alert( { message: 'Community has been added successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getCommunityAll();
        }, error => {
            bootbox.alert( { message: 'Add Community Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editComm = Object.assign( {}, arg );
    }

    editCommunity() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editComm.accId=this.loggedUser.accId;
        this.communitymasterserivce.updateCommunity( this.editComm ).subscribe( response => {
            bootbox.alert( { message: 'Community has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getCommunityAll();
        }, error => {
            bootbox.alert( { message: 'Update Community Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.communityArr.indexOf( this.communityArr.find( r => r.communityId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.communitymasterserivce.deleteCommunity( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.communityArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.communityArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getCommunityAll();
        }, error => {
            bootbox.alert( { message: 'Delete Community Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
