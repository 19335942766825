import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { Http } from '@angular/http';

@Injectable( {
    providedIn: 'root'
} )

export class UserCreationService {

    constructor( private httpclient: HttpClient,private http:Http ) { }

    getManagementUser(accId): Observable<MUserMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetMUserMasterAll/'+accId;
        return this.httpclient.get<MUserMasterPojo[]>( url );
    }

    getManagementUserById( userId: number ): Observable<any> {
        const url = StaticVariable.porturl + 'MUserMasterById/' + userId;
        return this.httpclient.get( url );
    }
   
    checkUserName( login:MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'CheckUserName';
        return this.http.post( url,login );
    }

    addManagementUser( arg: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddMUserMaster';
        return this.httpclient.post( url, arg );
    }

    deleteManagementUser( userId: number, loggedUserId: number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteMUserMaster/' + userId + '/' + loggedUserId;
        return this.httpclient.delete<any>( url );
    }

    updateManagementUser( arg: MUserMasterPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateMUserMaster';
        return this.httpclient.put( url, arg );
    }

}
