import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPojo } from 'src/app/pojo/payment-pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { PaymentEntryService } from '../../Accounts/payment/payment.service';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import * as moment from 'moment';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-payment-entry',
  templateUrl: './payment-entry.component.html',
  styleUrls: ['./payment-entry.component.scss']
})
export class PaymentEntryComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;
  
  addEntryDate: NgbDateStruct;
  addPayment = new PaymentPojo();
  picksledgersByAccountGroup: LedgerPojo[] = [];
    pickspaidledgersByAccountGroup: LedgerPojo[] = [];
    ledgerId: LedgerPojo[];
    public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    addEntryAmount:number;
    accountMasterArr: AccountMasterPojo[] = [];
    addInstrumentDate: NgbDateStruct;
    isLoading:boolean = true;
    ledgers: LedgerPojo[];
    specledgers: LedgerPojo[];
    receiptHeadArr:ReceiptHeadPojo[] = [];
    ledgerGrpArr:LedgerGroupPojo[]=[];
    
    constructor( private paymentService: PaymentEntryService, private receiptheadService:ReceiptHeadService, 
      private ledgercreationService: LedgercreationService,private accountmasterserivce: AccountMasterService,
     ) { }
  ngOnInit() {
    $( '#addAfterClick' ).hide();
    this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
    this.getLedgerByAccountGroup( this.acc);
    this.getAllLedger();
    this.getAccountMasterAll();
    this.clickAdd();  
    this.getLedgerGrpAll();
    this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
  }
  noEnter( e ) {
    return !( window.event && e.keyCode == 13 );
}
getLedgerGrpAll() {
  this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
      this.ledgerGrpArr = response;
    }, error => {
      bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    } );
}
getLedgerByLedgerGroupId(event){
  this.specledgers=[];
  if(this.addPayment.ledgerGroupId>0){
  this.ledgercreationService.getLedgerByLedgerGroupId(this.addPayment.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
    this.specledgers = response;
  }, error => {
    bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
  } );
}
}
  getAccountMasterAll() {
    this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
        this.accountMasterArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Account All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        this.isLoading = false;
    } );
}
getAllReceiptHeadByLedgerId(event:any) {
  this.receiptHeadArr=[];
   this.receiptheadService.getReceiptHeadByLedgerId(this.addPayment.ledgerId,this.loggedUser.accId).subscribe( rs => {
      this.receiptHeadArr = rs;
     
  }, error => {
      bootbox.alert( 'Error' + error );
     } );
}
getLedgerByAccountGroup( Acc ) {
    this.ledgercreationService.getLedgerByAccountGroup( this.acc,this.loggedUser.accId ).subscribe( log => {
        this.picksledgersByAccountGroup = log;
    } );
    this.ledgercreationService.getLedgerByType( 'PAID',this.loggedUser.accId).subscribe( log => {
        this.pickspaidledgersByAccountGroup = log;
    } );
}

getLedgerById( id ) {

  if ( this.picksledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
      return this.picksledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
  }
  else {
      return id;
  }
}
getPaidLedgerById( id ) {

if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
    return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
}
else {
    return id;
}
}
getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
        this.ledgers = rs;
    }, error => {
        alert( error );
    } );
}  
clickAdd(){
  this.paymentService.getAutoGeneratePaymentNo(this.loggedUser.accId).subscribe( rs => {
      this.addPayment.paymentNo = rs;
       }, error => {
      bootbox.alert( 'Error' + error );
      } );
}
dateClose(e){
  if(e.keyCode==13){
      $( '#closeCalendar' ).click();  
      $( '#addaccountId' ).focus();  
  }
}
closeInstdate(e){
  if(e.keyCode==13){
    $( '#closeInstCalendar' ).click();  
    $( '#addInstDetails' ).focus();  
}
}
ledgerFocus(e){
  if(e.keyCode==13){
    $( '#addLedger' ).focus();  
  } 
}
amntFocus(e){
  if(e.keyCode==13){
    $( '#addAmount' ).focus();  
  } 
}
paidLedFocus(e){
  if(e.keyCode==13){
    $( '#addPaidLedger' ).focus();  
  } 
}
instTypeFocus(e){
  if(e.keyCode==13){
    if(this.addPayment.dLedgerId ==1){
      $( '#addButton' ).click();  
     } else{
    $( '#addInstrumentType' ).focus();  
  }
}
}
instNoFocus(e){
  if(e.keyCode==13){
    $( '#addInstNo' ).focus();  
  } 
}
instDateFocus(e){
  if(e.keyCode==13){
    $( '#addInstrumenEntrytDate' ).focus();  
  } 
}
descPaymentFocus(e){
  if(e.keyCode==13){
    $( '#addDescription' ).focus();  
  } 
}
saveBtnFocus(e){
  if(e.keyCode==13){
    $( '#addButton' ).click();  
  } 
}
add() {
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
  $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
  this.addPayment.paymentDate =  moment(( <HTMLInputElement>document.getElementById( 'addPaymentDate' ) ).value).toDate();
 if(this.addPayment.dLedgerId==1){
  this.addPayment.instrumentDate=moment().toDate();
  this.addPayment.instrumentType="CASH";
  this.addPayment.instrumentNo='0';
  this.addPayment.instrumentDetail="cash"
 }else{
  this.addPayment.instrumentDate = moment(( <HTMLInputElement>document.getElementById( 'addInstrumenEntrytDate' ) ).value).toDate();
 
 }
 this.addPayment.accId = this.loggedUser.accId;
  this.paymentService.addPayment(this.addPayment).subscribe( response => {
    bootbox.alert({message: "Successfully Added", className: 'text-success h5',
     callback: function(){ 
       $( '#addPaymentDate' ).focus(); }});
       ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
       $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
       this.addPayment = new PaymentPojo();
       this.addEntryDate = null;
       this.addInstrumentDate = null;
       this.clickAdd();
       this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
  
        }, error => {
      bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
      $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
  } );
}
}
