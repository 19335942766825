export class CreditDebitReportPojo {

	openingBalance:number;
	bankOpeningBalance:number;
	closingBalance:number;
	bankClosingBalance:number;
	creditTotal:number;
	bankCreditTotal:number;
	debitTotal:number;
	bankDebitTotal:number;
	creditSum:number; // openingbal+credittotal
	debitSum:number; // closingbal + debittotal
	bankCreditSum:number; //bankopenbal+bankCredittot
	bankDebitSum:number; //bankclosingbal_banddebittot
	 fromDate:Date;
	 toDate:Date;
	 accId:number;
	cpojo:CreditReportPojo[]=[];
	dpojo:DebitReportPojo[]=[];
	dets:CreditDebitDetailsPojo[]=[];
}
export class CreditDebitDetailsPojo {

	 ledgerId:number;
	 transactionDate:Date;
	 transactionNo:string;
	 narration:string;
	 creditAmount:number;
	 creditBankAmount:number;
	 debitAmount:number;
	 debitBankAmount:number;
	 accId:number;
	 refNo:string;
	 receiptHeadName:string;
}
export class CreditReportPojo {

	 ledgerId:number;
	 transactionDate:Date;
	 transactionNo:string;
	 narration:string;
	amount:number;
	bankAmount:number;
	 accId:number;
	 refNo:string;
	 receiptHeadName:string;
}
export class DebitReportPojo {

	 ledgerId:number;
	 transactionDate:Date;
	 transactionNo:string;
	 narration:string;
	amount:number;
	bankAmount:number;
	 accId:number;
	 refNo:string;
	 receiptHeadName:string;
}