import { Component, OnInit } from '@angular/core';
import { StaticVariable } from "src/app/globle.class";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { LoginService } from "src/app/management/login/login.service";
import { Router } from '@angular/router';
import { AccountGroupMasterService } from '../Admin/account-master/account-group-master.service';
import { AccountMasterService } from '../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [LoginService]
} )

export class LoginComponent implements OnInit {

    schoolName: string;
    schoolPlace: string;
    loggedUser = new MUserMasterPojo();
    accountMasterArr:AccountMasterPojo[]=[];
    checkingFlag:string;
    selectedAccId;
    isLoading:Boolean=false;
    constructor( private loginService: LoginService, private router: Router,
        private accountmasterserivce:AccountMasterService ) { }

    ngOnInit() {
        this.schoolName = StaticVariable.loginTitle;
        this.schoolPlace = StaticVariable.loginPlace;
         this.getAccountMasterAll();
        /*( function() {
            'use strict';
            window.addEventListener( 'load', function() {
                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.getElementsByClassName( 'needs-validation' );
                // Loop over them and prevent submission
                var validation = Array.prototype.filter.call( forms, function( form ) {
                    form.addEventListener( 'submit', function( event ) {
                        if ( form.checkValidity() === false ) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        form.classList.add( 'was-validated' );
                    }, false );
                } );
            }, false );
        } )();*/
        $( '#checkSpinner' ).hide();
        $( '#submitAfterClick' ).hide();
    }
    getAccountMasterAll() {
        this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
            this.accountMasterArr = response;
            }, error => {
            bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
    }
    chkUser(e){
        if(e.keyCode==13){
          let userName= ( <HTMLInputElement>document.getElementById( 'userName' ) ).value;  
            this.checkUserName(userName);
        }
    }
    checkUserName(username){
        if(username != null && username != undefined && username !=''){
        let login = new MUserMasterPojo();
        this.loggedUser=new MUserMasterPojo();
        login.accId=this.selectedAccId;
        this.loggedUser.userName = username;
        login.userName=username;
       $( '#checkIcon' ).hide(); $( '#checkSpinner' ).show();
         
        this.loginService.checkLoginUser( login ).subscribe( response => {
              if(response.text()=='EXISTS'){
                  this.checkingFlag='TRUE';
                  $('#passWrd').focus();
         
              }else{
             this.checkingFlag='FALSE';
             bootbox.alert( { message: 'Please check the UserName.It does not exist on this Account...', className: 'text-danger h5', closeButton: false } );
             ( <HTMLInputElement>document.getElementById( 'userName' ) ).value = '';  
            }
           $( '#checkIcon' ).show(); $( '#checkSpinner' ).hide();
        }, error => {
            bootbox.alert( { message: 'Check UserName Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
               $( '#checkIcon' ).show(); $( '#checkSpinner' ).hide();
        } );
        }else{
            bootbox.alert( { message: 'Kindly Fill all Fields..' , className: 'text-danger h5', closeButton: false } );
             
        }
    }
    userNameKeyPress(e){
        if(e.keyCode==13){
            this.signIn();
        }
    }
    signIn() {

        let login = new MUserMasterPojo();
        login.accId = this.selectedAccId;
        login.userName = ( <HTMLInputElement>document.getElementById( 'userName' ) ).value;
        login.password =  ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value;
        if ( login.password != "" && login.password != undefined && login.password != null ) {
            ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = true;
           this.isLoading=true;
            this.loginService.checkLogin( login ).subscribe( response => {
                this.loggedUser = response;
                 ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = false;
                $( '#submitBeforeClick' ).show(); $( '#submitAfterClick' ).hide();
             if ( this.loggedUser.userName == login.userName && this.loggedUser.password == login.password ) {
                    sessionStorage.setItem( 'currentManagementSession', 'true' );
                    sessionStorage.setItem( 'schoolManagementUserDetail', JSON.stringify( this.loggedUser ) );
                    this.loggedUser.roleKeyJson = JSON.stringify( this.loggedUser.roleKey.roleKey );
              
                    this.router.navigate( [`/console`] );
                } else if ( this.loggedUser.userName == login.userName && this.loggedUser.password != login.password ) {
                    ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value = '';
                    bootbox.alert( { message: 'Please check the password', className: 'text-danger h5', closeButton: false } );
                    sessionStorage.setItem( 'currentManagementSession', 'false' );
                } else if ( this.loggedUser.userName != login.userName && this.loggedUser.password == login.password ) {
                    ( <HTMLInputElement>document.getElementById( 'userName' ) ).value = '';
                    ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value = '';
                    bootbox.alert( { message: 'Please check the username', className: 'text-danger h5', closeButton: false } );
                    sessionStorage.setItem( 'currentManagementSession', 'false' );
                } else {
                    ( <HTMLInputElement>document.getElementById( 'userName' ) ).value = '';
                    ( <HTMLInputElement>document.getElementById( 'passWord' ) ).value = '';
                    bootbox.alert( { message: 'Please check the username & password', className: 'text-danger h5', closeButton: false } );
                    sessionStorage.setItem( 'currentManagementSession', 'false' );
                }
                this.isLoading=false;
            }, error => {
                bootbox.alert( { message: 'Login Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
                ( <HTMLInputElement>document.getElementById( 'signin' ) ).disabled = false;
               this.isLoading=false;
            } );
        } else {
            bootbox.alert( { message: 'Kindly Enter Your Password!', className: 'text-danger h5', closeButton: false } );
           
        }
    }

}
