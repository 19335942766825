
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import * as moment from 'moment';
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReportService } from '../../Reports/report-service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AttendanceResponsePojo, AttendancePojo } from 'src/app/pojo/attendance.pojo';
import { AttendanceEntryService } from '../../Attendance/attendance-entry/attendance-entry.service';
import { DayWiseAttendancePojo, DayWiseAttendanceHoursPojo } from 'src/app/pojo/daywise-attendance-entry.pojo';
import { AbsentListService } from './absent-list.service';
import { AttendanceAbsentDetailsPojo, AttendanceAbsentSummaryPojo } from 'src/app/pojo/attendance-absent-details.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-absent-list',
  templateUrl: './absent-list.component.html',
  styleUrls: ['./absent-list.component.scss']
})
export class AbsentListComponent implements OnInit {
 model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  fromDate: NgbDateStruct;
  loggedUser = new MUserMasterPojo();

  sectionArr: SectionMasterPojo[] = [];
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  departmentId: number;
  sectionMasterArr: SectionMasterPojo[] = [];
  classid: number;
  sectionid: number;
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';
  classMasterArr: ClassMasterPojo[] = [];
  id: number;
  isLoading: boolean = false;
  body = [];
  content = [];
  documentTime: string;
  resultDept: number;
  resultClass: number;
  resultSection: number;
  resulltDate: number;
  searchOption = 'ALL';
  typeId;
  academic: AcademicYearMasterPojo[] = [];
  academicYear;
  standTermArr: StandardTermPojo[] = [];
  classPojo = new ClassMasterPojo();
  finalEntryResArr: AttendancePojo[] = [];
  absentListArr: AttendanceAbsentDetailsPojo[] = [];
  absentpojo = new AttendanceAbsentSummaryPojo();
  
  constructor(
   private settingService: AcademicSettingService,private absentListService:AbsentListService,
     private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService,
    private classMasterService: ClassMasterService, private router: Router,
   ) { }

  ngOnInit() {

    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));
    $('#editAfterClick').hide();
    this.getDepartmentAll();
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.academicSettingAll();
    }
  academicSettingAll() {
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe(response => {
      this.academic = response;
      for (let i = 0; i < this.academic.length; i++) {
        if (this.academic[i].academicFlag == 1) {
          this.academicYear = this.academic[i].academicYearId;
        }
      }
    }, error => {
      bootbox.alert('Setting Service Error! - HTTP Status ' + error.status);
    });
  }
  
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe(response => {
      this.sectionMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
      this.classMasterArr = response;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
 
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe(run => {
      this.departmentArr = run;
    }, error => {
      bootbox.alert({ message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
    });
  }
 
  getClassNameByClassId(id) {
    if (this.classMasterArr.find(r => r.classId == id)) {
      return this.classMasterArr.find(r => r.classId == id).className;
    } else {
      return id;
    }
  }
  getSectionNameBySectionId(id) {
    if (this.sectionMasterArr.find(r => r.sectionId == id)) {
      return this.sectionMasterArr.find(r => r.sectionId == id).sectionName;
    } else {
      return id;
    }
  }
 
  getReportById() {
    this.absentListArr = [];  
    this.isLoading = true;
    let arg1 = (<HTMLFormElement>document.getElementById('fromEntryDate')).value;
    if(this.classid !=null&& this.academicYear != null && arg1 != null){
   
    this.absentListService.getAbsendtStudentDetailsById(this.academicYear, this.classid, arg1,this.loggedUser.accId).subscribe(response => {
      this.absentpojo = response;
      if(this.absentpojo.code ==101){
        bootbox.alert({ message: 'There is No Attendance Entry on this date!', className: "text-danger h5", closeButton: false });
      }else{
        this.absentListArr = this.absentpojo.list;
        this.resultDept = this.departmentId;
        this.resultClass = this.classid;
        this.resultSection = this.sectionid;
        this.resulltDate = arg1;
      
      }
       this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Absent Student Details By Id Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
      this.isLoading = false;
    });
  }else{
    bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );

}
  }

  submitReport() {
     this.isLoading = true;
     this.absentListService.sendSmsToAbsentList(this.absentListArr,this.loggedUser.accId,this.loggedUser.userId).subscribe(response => {
      if(response == 101){
        bootbox.alert( { message: 'SMS Status for this type is Inactive!Please change the Status to Active..', className: "text-danger h5", closeButton: false } );
        }else{
          bootbox.alert( { message: 'Successfully sent SMS to these Students..', className: "text-success h5", closeButton: false } );
          this.absentListArr = [];  
          this.Reset();
        }
        this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Sms to Absent Student Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
      this.isLoading = false;
    });
  
  }

  Reset() {
    this.classid = 0;
    this.departmentId = 0;
    this.sectionid = 0;
    this.fromDate = null;
  }
  
}
