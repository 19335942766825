import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EventPojo } from 'src/app/pojo/event.pojo';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { EventService } from './event.service';


declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {


  loggedUser = new MUserMasterPojo();

  eventArr: EventPojo[] = [];
  addEvent = new EventPojo();
  editEvent = new EventPojo();
  fromDate:Date;
  editfromEntryDate1: NgbDateStruct;
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public filterQuery = '';
  public sortOrder = 'asc';

  id: number;
  isLoading: boolean = true;
  constructor(private eventservice: EventService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
    // (<HTMLFormElement>document.getElementById('fromEntryDate')).value = moment().format('YYYY-MM-DD');
    // (<HTMLFormElement>document.getElementById('editfromEntryDate1')).value = moment().format('YYYY-MM-DD');
    $( '#addAfterClick' ).hide();
    $( '#editAfterClick' ).hide();
    $( '#deleteAfterClick' ).hide();
      this.getEventAll();
  }
  getEventAll() {
    this.isLoading = true;
    this.eventservice.getEventAll(this.loggedUser.accId).subscribe( response => {
        this.eventArr = response;
        this.isLoading = false;
    }, error => {
        bootbox.alert( { message: 'Get Notes All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        this.isLoading = false;
    } );
}


add() {
    ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
    $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
    this.addEvent.eventDate =(<HTMLFormElement>document.getElementById('fromEntryDate')).value;
    this.addEvent.userId = this.loggedUser.userId;
    this.addEvent.accId = this.loggedUser.accId;
      this.eventservice.addEvent( this.addEvent ).subscribe( response => {
        bootbox.alert( { message: ' Successfully Added', className: 'text-success h5', closeButton: false } );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        $( '#addModal' ).modal( 'hide' );
        this.getEventAll();
    }, error => {
        bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
    } );
}

clickEdit( arg ) {
    this.editEvent = Object.assign( {}, arg );
    if(this.editEvent.eventDate != null && this.editEvent.eventDate != undefined){
      ( <HTMLFormElement>document.getElementById( 'editfromEntryDate1' ) ).value = this.editEvent.eventDate;
      }
}

edit() {
       ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
    $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
    this.editEvent.eventDate =(<HTMLFormElement>document.getElementById('editfromEntryDate1')).value;
    this.editEvent.userId = this.loggedUser.userId;
    this.editEvent.accId = this.loggedUser.accId;
    this.eventservice.updateEvent( this.editEvent ).subscribe( response => {
        bootbox.alert( { message: 'successfully updated.', className: 'text-success h5', closeButton: false } );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        $( '#editModal' ).modal( 'hide' );
        this.getEventAll();
    }, error => {
        bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
        $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
    } );
}

delete( arg ) {
    this.id = arg;
}

deleteConfirm() {
    let no;
    const i = this.eventArr.indexOf( this.eventArr.find( r => r.eventId == this.id ) );
    $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
    this.eventservice.deleteEvent( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
        no = response;
        if ( no == 0 ) {
            bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
            this.eventArr.splice( i, 1 );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } else {
            this.eventArr.splice( i, 1 );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        }
        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        $( '#deleteModal' ).modal( 'hide' );
        this.getEventAll();
    }, error => {
        bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
    } );
}
}
