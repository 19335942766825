import * as moment from 'moment';
import { StaticVariable } from '../globle.class';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { FeeCollectionPojo } from '../pojo/fee-collection.pojo';
import { AcademicYearMasterPojo } from '../pojo/academic-year.pojo';
import { MUserMasterPojo } from '../pojo/management-user-pojo';
import { StandardTermPojo } from '../pojo/standard-term.pojo';
import * as converter from 'number-to-words';
import { FeeCollectionReceiptPojo, FeeCollectionReceiptDetailsPojo } from '../pojo/temporary-fees-collection-receipt.pojo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var bootbox: any;
export class CommonReceiptComponent{
    static academic:AcademicYearMasterPojo[] =[]; 
    loggedUser = new MUserMasterPojo();
    static standTermArr: StandardTermPojo[] = [];  
    static FeeCollectionReceipt(res:FeeCollectionReceiptPojo){
        let arg = new FeeCollectionReceiptPojo();
        arg.detList =[];
        arg=res;
            let content =[];
       let body =[];
         let documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
         content.push(
            {
                columns: [
                     {
                        image: StaticVariable.logo, width:50 },
                        {width:'4%',
                         text:''},
                        {
                            width: '80%',
                            
                            text: [{text:arg.schoolName , style: 'e' }, 
                            {text:'\n'+arg.placeName+'\n', style: 'e3' },{text:'Fee Receipt\n', style: 'e' },{text:'Academic Year:'+arg.academicYear,alignment:'right', style: 'e2' },
                            ,{text:'\nNo.- '+arg.receiptNo,alignment:'right', style: 'e2' },
                        ]
                            
                        },
                 ] } );
                //  content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
                content.push( {
                    text:[{text: 'Name of Pupil : ', fontSize: 11,  font: 'TimesNewRoman'} ,{
                   text: arg.studentName, fontSize: 11,  font: 'TimesNewRoman',bold:'true'}
                    ],lineHeight: 1.6 });
                content.push( {
                    columns:[{
                        width:'17%',
                        text: 'Class / Sec : ',fontSize: 11, font: 'TimesNewRoman'
                     },
                     {
                       width:'48%',
                       text: arg.className+'-'+arg.sectionName,bold:'true',fontSize: 11, font: 'TimesNewRoman'
                    },
                    {
                       width:'15%',
                       text: 'Ad.No : ',fontSize: 11, font: 'TimesNewRoman',alignment:'right'
                   },
                    {
                       width:'20%',
                       text: arg.admissionNo,bold:'true',fontSize: 11, font: 'TimesNewRoman',alignment:'right'
                   } ]
               });
                 content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
                 content.push( {
                    columns:[{
                        width:'80%',
                        text: 'Particulars  ',fontSize: 11, font: 'TimesNewRoman',bold:'true',alignment:'left'
                     },
                     {
                       width:'20%',
                       text: 'Paid Amount\t\t',bold:'true',fontSize: 11, font: 'TimesNewRoman',alignment:'right'
                    }]
               });

                 content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
                 for(let i=0;i<arg.detList.length;i++){
                    let d = new FeeCollectionReceiptDetailsPojo();
                     d=arg.detList[i];
               
                 content.push( {
                    columns:[{
                        width:'80%',
                        text: d.termName +'-' +d.standardTermName ,fontSize: 10, font: 'TimesNewRoman',lineHeight:1.2,
                     },
                     {
                       width:'20%',
                       text: d.paidAmount,fontSize: 11, font: 'TimesNewRoman', alignment: 'right',lineHeight:1.2,
                    }]
               });
            }
       content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
      content.push( {
        columns:[{
            width:'75%',
            text: 'Total Amount :', fontSize: 11, font: 'ArialNarrow', alignment: 'right',
         },
         {
           width:'25%',
           text: arg.overAllPaid.toFixed(2), fontSize: 11, font: 'ArialNarrow', alignment: 'right', bold: 'true',
        }]
   }); 
      content.push({text:'------------------------------------------------------------------------------------------------------\n'});   
               
    //  body.push( [
    //         { text: 'Particulars', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'ArialNarrow', bold: 'true',border:[true, true, true, true] },
    //         { text: 'Paid', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'ArialNarrow', bold: 'true',border:[true, true, true, true] },
    //        // { text: 'Paid', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'Roboto', bold: 'true',border:[true, true, true, true] },
           
    //     ] );
    //          for(let i=0;i<arg.detList.length;i++){
    //            let d = new FeeCollectionReceiptDetailsPojo();
    //             d=arg.detList[i];
    //         body.push( [
    //             { text: d.termName +'-' +d.standardTermName, fontSize: 10, font: 'TimesNewRoman', alignment: 'left', border:[true, false, true, false]},
    //            // { text: d.originalAmount, fontSize: 10, font: 'Roboto', alignment: 'right', border:[false, false, false, false] },
    //             { text: d.paidAmount, fontSize: 10, font: 'TimesNewRoman', alignment: 'right', border:[true, false, true, false]},
    //             ] );
    //     }
        
    //            body.push([
    //                         {text:'\n',border:[true,false,false,false]},
    //                        // {text:'\n',border:[true,false,false,false]},
    //                         {text:'\n',border:[true,false,true,false]},
    //                     ]);
        //   body.push( [
        //     { text: 'Total Amount: ', fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, false, true, false],lineHeight:1.2 },
        //     { text: arg.overAllPaid.toFixed(2), fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, true, true, false],lineHeight:1.2  },
        //      ] );
             let amount =converter.toWords( arg.overAllPaid);
             amount = amount.toUpperCase();
            
             content.push( [{  text: [{ text: 'Received with a sum of Rupees', fontSize: 10, font: 'TimesNewRoman', alignment: 'left',},
                        {text:'\t'+amount+'\t('+'₹ '+ arg.overAllPaid+ ' ) ', fontSize: 10, font: 'Roboto', alignment: 'left',bold: 'true'},
                    {text:'only towards Fees as detailed above.', fontSize: 10, font: 'TimesNewRoman', alignment: 'left',}
                                 ],lineHeight:1.2,
                    }]);
                    content.push({text:'\n'}); 
                    // body.push( [{ text: '\nDate: '+moment(arg.currentDate,'YYYY-MM--DD').format('DD-MM-YYYY'), fontSize: 10,lineHeight:1.2 , font: 'Roboto', alignment: 'left', border:[true, false, false, true] },
                    // { text: '\nClerk', fontSize: 10, font: 'Roboto',alignment:'center',border:[false, false, true, true],lineHeight:1.2  }
                    // ]);
                    let overAllPaid:number=0;
                    overAllPaid=arg.overAllAlreadyPaid+arg.overAllPaid;
                    body.push( [
                        { text: 'Total Fee Amount: ', fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, true, true, false],lineHeight:1.2 },
                        { text: arg.overAllTotal.toFixed(2), fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, true, true, false],lineHeight:1.2  },
                         {text:'',border:[false,false,false,false]}] );
                    // body.push( [
                    //     { text: 'Received Amount: ', fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, false, true, false] ,lineHeight:1.2 },
                    //     { text: arg.overAllPaid.toFixed(2), fontSize: 10, font: 'ArialNarrow',alignment: 'right', bold: 'true', border:[true, false, true, false] ,lineHeight:1.2 },
                    //      ] );
                         body.push( [
                            { text: 'Total Paid Amount: ', fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, false, true, false] ,lineHeight:1.2 },
                            { text: overAllPaid.toFixed(2), fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, false, true, false] ,lineHeight:1.2 },
                            {text:'Cash Officer',border:[false,false,false,false],alignment:'right',bold:'true', fontSize: 10, font: 'ArialNarrow', }
                        ] );
                         body.push( [
                            { text: 'Balance Amount :', fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, false, true, true] ,lineHeight:1.2 },
                            { text: arg.overAllBalance.toFixed(2), fontSize: 10, font: 'ArialNarrow', alignment: 'right', bold: 'true', border:[true, true, true, true],lineHeight:1.2  },
                            {text:'( Paid Date :'+moment(arg.currentDate,'YYYY-MM--DD').format('DD-MM-YYYY')+' )',border:[false,false,false,false],alignment:'right' ,fontSize: 10, font: 'ArialNarrow', } ] );
                        
               
        content.push( {
            'table': {
                headerRows: 1,
                'body': body,
                widths: ['30%', '20%','50%']
            }
        } );
  
       const dd = {
             'content':content,  
             pageOrientation: 'portrait',
             pageSize: 'A5',  
           
            margins: [0, 0, 0, 0],
             styles: {
                e: {
                    font: 'TimesNewRoman',
                     bold: true, 
                     fontSize: 14,
                     alignment: 'center'
                },
                e2: {
                    font: 'TimesNewRoman',
                     bold: true, 
                     fontSize: 11,
                     alignment: 'center'
                },
                e3: {
                    font: 'TimesNewRoman',
                     fontSize: 10,
                     alignment: 'center'
                },
                e4: {
                    font: 'TimesNewRoman',
                     fontSize: 11,
                     bold: true, 
                     alignment: 'center'
                },
            },
            
         };
   
         pdfMake.createPdf( dd ).open();
        body = [];
        content =[];
        }
    }
