export class LeaveApplicationPojo {
    leaveId:number;
	leaveStartDate:Date;
	leaveEndDate:Date;
	leaveType:string;
    reason:string;
	status:boolean;
	studentId:number;
	imageId:number;
    name:string;
	mobileno:number;
	admissionno: number;
	accId:number
	staffId:number;
	userId:number;
}
