export class FeeCollectionReceiptPojo{
    studentName:string;
    academicYear:string;
    receiptNo:number;
    className:string;
    sectionName:string;
    admissionNo:string;
    overAllTotal:number;
    overAllPaid:number;
    overAllBalance:number;
    overAllAlreadyPaid:number;
    overAllDiscount:number;
    currentDate;
    detList:FeeCollectionReceiptDetailsPojo[]=[];

    //only for receipt
    schoolName:string;
    placeName:string;
  
  }
  export class FeeCollectionReceiptDetailsPojo{
    termName:string;
    standardTermName:string;
    paidAmount:number;
    originalAmount:number;
  
  }
  