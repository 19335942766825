import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'UserCreationPipe'
})
export class UserCreationPipe implements PipeTransform {

    transform(array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.userId == query ) != '' ) {
                return _.filter( array, row => row.userId == query );
            } else if ( _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.name.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else if ( _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

}
