
import { Component, OnInit } from '@angular/core';
import { SmsMasterPojo } from "src/app/pojo/sms-master.pojo";
import { StudentMasterPojo } from "src/app/pojo/student-master.pojo";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { SmsChildPojo } from "src/app/pojo/sms-child.pojo";
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { SmsMasterService } from "src/app/management/SMS/sms-master/sms-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { NgbDateStruct, NgbTimepickerConfig } from "@ng-bootstrap/ng-bootstrap";
import { SmsTemplateService } from "src/app/management/SMS/sms-template/sms-template.service";
import { ActivatedRoute } from "@angular/router";

import * as moment from 'moment';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-create-sms-template',
  templateUrl: './create-sms-template.component.html',
  styleUrls: ['./create-sms-template.component.scss']
})
export class CreateSmsTemplateComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    smsMaster = new SmsMasterPojo();

    sendTime;
    sendDate;
    studentAllSelection;
    staffAllSelection;
    studDept = "";
    studClass = "";
    studSection = "";
    staffDept = "";
    studentMasterArr: AcademicStandardDetailsPojo[] = [];
    staffMasterArr: StaffMasterPojo[] = [];
    selectedUserArr: SmsChildPojo[] = [];
    departmentMasterArr: DepartmentMasterPojo[] = [];
    classMaster: ClassMasterPojo[] = [];
    sectionMaster: SectionMasterPojo[] = [];
    filterQueryStaff = '';
    filterQuerySelected = '';

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    smsId: number;
    editSendDate: NgbDateStruct;

    constructor( config: NgbTimepickerConfig, private activatedRoute: ActivatedRoute, private smsMasterService: SmsMasterService, private smsTemplateService: SmsTemplateService, private studentMasterService: StudentMasterService, private staffMasterService: StaffMasterService, private departmentMasterService: DepartmentMasterService, private classMasterService: ClassMasterService, private sectionMasterService: SectionMasterService ) {
        config.size = 'small';
        config.meridian = true;
        config.spinners = false;
        config.minuteStep = 30;
    }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

       
        this.getDepartmentAll();
        this.getClassAll();
        this.getSectionAll();
    }

    getStudentMaster() {
        if ( this.studDept != "" && this.studClass != "" && this.studSection != "" ) {
            if(this.studClass == '0'){
                this.studSection = '0';
            }
            this.studentMasterArr = [];
            this.isLoading = true;
             this.studentMasterService.getAcademicStuDetailsById( this.studDept, this.studClass, this.studSection,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
                this.studentMasterArr = response;
                this.isLoading = false;
            }, error => {
                bootbox.alert( 'Get Student All Service Error! - HTTP Status ' + error.status );
                this.isLoading = false;
            } );
        }
    }

    getStaffMaster() {
        if ( this.staffDept != "" ) {
            this.staffMasterService.getStaffByDeptId( this.staffDept,this.loggedUser.accId ).subscribe( response => {
                this.staffMasterArr = response;
                this.isLoading = false;
            }, error => {
                bootbox.alert( 'Get Student All Service Error! - HTTP Status ' + error.status );
                this.isLoading = false;
            } );
        }
    }

    getDepartmentAll() {
        this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
            this.isLoading = false;
            this.departmentMasterArr = run;
        }, error => {
            bootbox.alert( 'Get Department All Service Error! - HTTP Status ' + error.status );
            this.isLoading = false;
        } );
    }

    getClassAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( run => {
            this.isLoading = false;
            this.classMaster = run;
        }, error => {
            bootbox.alert( 'Get Department All Service Error! - HTTP Status ' + error.status );
            this.isLoading = false;
        } );
    }

    getSectionAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( run => {
            this.isLoading = false;
            this.sectionMaster = run;
        }, error => {
            bootbox.alert( 'Get Department All Service Error! - HTTP Status ' + error.status );
            this.isLoading = false;
        } );
    }

   
    selectStudentAll( variable ) {
        if ( variable.checked ) {
            this.confirmSelectStudentAll();
        } else {
            this.deSelectStudentAll();
        }
    }

    selectStudent( variable, item ) {
        if ( variable.checked ) {
            let d: SmsChildPojo = new SmsChildPojo();
            d.mobileNo = item.student.mobileNo;
            d.userCategory = 'STUDENT';
            d.userId = 'STUD' + item.studentId + '';
            d.userName = item.student.name;
            if(item.smsFlag == 'TRUE'){
                this.selectedUserArr.push( d );
            }
            
        } else {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STUD' + item.studentId ) );
            if ( d >= 0 ) {
                this.selectedUserArr.splice( d, 1 );
            }
        }
    }

    confirmSelectStudentAll() {
        for ( let i = 0; i < this.studentMasterArr.length; i++ ) {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STUD' + this.studentMasterArr[i].studentId ) );
            if ( d < 0 ) {
                let d: SmsChildPojo = new SmsChildPojo();
                d.mobileNo = this.studentMasterArr[i].student.mobileNo;
                d.userCategory = 'STUDENT';
                d.userId = 'STUD' + this.studentMasterArr[i].studentId + '';
                d.userName = this.studentMasterArr[i].student.name;
                if(this.studentMasterArr[i].student.smsFlag == 'TRUE'){
                this.selectedUserArr.push( d );
                }
            }
        }
    }

    deSelectStudentAll() {
        for ( let i = 0, j = 0; j < this.selectedUserArr.length; i++ , j++ ) {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STUD' + this.studentMasterArr[i].studentId ) );
            if ( d >= 0 ) {
                this.selectedUserArr.splice( d, 1 );
                j--;
            }
        }
    }

    isSelectedStudentAll() {
        for ( let i = 0; i < this.studentMasterArr.length; i++ ) {
            if ( !this.selectedUserArr.find( r => r.userId == ( 'STUD' + this.studentMasterArr[i].studentId ) ) ) {
                return false;
            }
        }
        return true;
    }

    isSelectedStudent( item ) {
        if ( this.selectedUserArr.find( r => r.userId == ( 'STUD' + item.studentId ) ) ) {
            return true;
        }
        return false;
    }

    selectStaffAll( variable ) {
        if ( variable.checked ) {
            this.confirmSelectStaffAll();
        } else {
            this.deSelectStaffAll();
        }
    }

    selectStaff( variable, item ) {
        if ( variable.checked ) {
            let d: SmsChildPojo = new SmsChildPojo();
            d.mobileNo = item.mobileNo;
            d.userCategory = 'STAFF';
            d.userId = 'STAFF' + item.staffId + '';
            d.userName = item.staffName;
            this.selectedUserArr.push( d );
        } else {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STAFF' + item.staffId ) );
            if ( d >= 0 ) {
                this.selectedUserArr.splice( d, 1 );
            }
        }
    }

    confirmSelectStaffAll() {
        for ( let i = 0; i < this.staffMasterArr.length; i++ ) {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STAFF' + this.staffMasterArr[i].staffId ) );
            if ( d < 0 ) {
                let d: SmsChildPojo = new SmsChildPojo();
                d.mobileNo = this.staffMasterArr[i].mobileNo;
                d.userCategory = 'STAFF';
                d.userId = 'STAFF' + this.staffMasterArr[i].staffId + '';
                d.userName = this.staffMasterArr[i].staffName;
                this.selectedUserArr.push( d );
            }
        }
    }

    deSelectStaffAll() {
        for ( let i = 0, j = 0; j < this.selectedUserArr.length; i++ , j++ ) {
            let d = this.selectedUserArr.findIndex( r => r.userId == ( 'STAFF' + this.staffMasterArr[i].staffId ) );
            if ( d >= 0 ) {
                this.selectedUserArr.splice( d, 1 );
                j--;
            }
        }
    }

    isSelectedStaffAll() {
        for ( let i = 0; i < this.staffMasterArr.length; i++ ) {
            if ( !this.selectedUserArr.find( r => r.userId == ( 'STAFF' + this.staffMasterArr[i].staffId ) ) ) {
                return false;
            }
        }
        return true;
    }

    isSelectedStaff( item ) {
        if ( this.selectedUserArr.find( r => r.userId == ( 'STAFF' + item.staffId ) ) ) {
            return true;
        }
        return false;
    }

    deleteAllSelectedUser() {
        for ( let j = 0; j < this.selectedUserArr.length; j++ ) {
            if ( j >= 0 ) {
                this.selectedUserArr.splice( j, 1 );
                j--;
            }
        }
    }
    
    deleteSelectedUser( arg ) {
        this.selectedUserArr.splice( arg, 1 );
    }

    onChangeStudentAll( studentAll ) {
        if ( studentAll.checked ) {
            this.deSelectStudentAll();
        }
    }

    onChangeStaffAll( staffAll ) {
        if ( staffAll.checked ) {
            this.deSelectStaffAll();
        }
    }

    createSMS() {
        this.smsMaster.sendTime = moment( this.sendTime.hour + ':' + this.sendTime.minute, 'HH:mm' ).format( 'HH:mm:ss' );
        this.smsMaster.sendDate = moment( this.editSendDate.year + '-' + this.editSendDate.month + '-' + this.editSendDate.day, 'YYYY-MM-DD' ).toDate();
        this.smsMaster.studentAllSelection = this.studentAllSelection ? 1 : 0;
        this.smsMaster.staffAllSelection = this.staffAllSelection ? 2 : 0;
        this.smsMaster.smschildpojo = this.selectedUserArr;
       // this.smsMaster.manualSelection = 0;
        this.smsMaster.accId = this.loggedUser.accId;
        this.smsMaster.userId = this.loggedUser.userId;
         this.isLoading = true;
        this.smsTemplateService.addSmsTemplateMaster( this.smsMaster ).subscribe( response => {
            bootbox.alert( { message: 'Sms has been successfully Created', className: 'text-success h5', closeButton: false } );
            this.reset();
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Add Sms Master Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
  
    reset() {
        this.smsMaster = new SmsMasterPojo();
        this.sendTime = null;
        this.editSendDate = null;
        this.studentAllSelection = 0;
        this.staffAllSelection = 0;
        this.deleteAllSelectedUser();
    }
}