import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
@Pipe({
  name: 'smsHistory'
})
export class SmsHistoryPipe implements PipeTransform {

  transform( array: any[], query: string): any {
    if ( query ) {
        if ( _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
            return _.filter( array, row => row.mobileNo.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
        }else
            return [];
    }
   return array;
}


}
@Pipe({
  name: 'smsAbstract'
})
export class SmsAbstractPipe implements PipeTransform {

  transform( array: any[], query: string): any {
    if ( query ) {
        if ( _.filter( array, row => row.smsName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
            return _.filter( array, row => row.smsName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
        }else
            return [];
    }
   return array;
}


}

