
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { Router } from "@angular/router";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import * as moment from 'moment';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import { CertificateAllService } from '../certificate.service';
import { AttendanceCertificatePojo } from 'src/app/pojo/certificate.pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';

declare var $: any;
declare var bootbox: any;

@Component( {
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss'],
  providers:[AcademicSettingService]
} )

export class AttendanceReportComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];
   
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    startDate;
    endDate;
    resultStartDate;
    resultEndDate;
    id: number;
    isLoading: boolean = false;

    attendanceArr:AttendanceCertificatePojo[]=[];

    body = [];
    content = [];
    documentTime:string;
    accountMasterArr:AccountMasterPojo[]=[];
    constructor(private accountMasterService:AccountMasterService,private classMasterService: ClassMasterService, private router: Router,private certificateService:CertificateAllService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
        this.getClassMasterAll();
          $( '.daterangeAdd' ).daterangepicker( { opens: 'left', showDropdowns: true,
        
     locale: { format: 'DD/MM/YYYY' }, startDate: moment().startOf( 'day' ), endDate: moment().endOf( 'day' ) },
        ( start, end, label ) => {
            this.startDate = moment(start).format('YYYY-MM-DD');
            this.endDate = moment(end).format('YYYY-MM-DD');
             
             }
    );this.accountMasterAll();
}
accountMasterAll() {
  this.accountMasterService.getAllAccountMaster().subscribe(response => {
    this.accountMasterArr = response;
   
  }, error => {
    bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
  });
}
getAccountNameById(id) {
  if (this.accountMasterArr.find(r => r.accId == id)) {
    return this.accountMasterArr.find(r => r.accId == id).accName;
  } else {
    return id;
  }
}
getAccountPlaceById(id) {
  if (this.accountMasterArr.find(r => r.accId == id)) {
    return this.accountMasterArr.find(r => r.accId == id).place;
  } else {
    return id;
  }
}
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }

    getClassNameByClassId( id ) {
        if ( this.classArr.find( response => response.classId == id ) ) {
            return this.classArr.find( response => response.classId == id ).className;
        } else {
            return id;
        }
    }

   
    getReport() {
        if(this.startDate == undefined && this.endDate == undefined){
            this.startDate = moment().format('YYYY-MM-DD');
            this.endDate = moment().format('YYYY-MM-DD');
                      
           }
           this.attendanceArr =[];
         this.isLoading = true;
        this.certificateService.getAttendanceCertificateBydate( this.startDate,this.endDate ).subscribe( response => {
            this.attendanceArr = response;
            this.resultStartDate=this.startDate;
            this.resultEndDate=this.endDate;
             this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Attendance By Date Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
            this.isLoading = false;
        } );
     }
  

    downloadPDF() {
       
        this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
         this.content.push( {
             text: 'ATTENDANCE  CERTIFICATE REPORT\t', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );
   
         this.content.push( {
             text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
             fontSize: 10, bold: true, alignment: 'center'
         } );
        
         this.content.push( {text: 'REPORT DATE FROM : '+moment(this.resultStartDate,'YYYY-MM-DD+').format('DD-MM-YYYY')+' TO '+moment(this.resultEndDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 12, bold: true, alignment: 'center', font: 'TimesNewRoman'
       } );
       this.content.push( {
             text: '\n', fontSize: 8, alignment: 'center'
         } );
   
         this.body.push( [
             { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Issue Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Certificate No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Admission No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Student Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Father Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Class Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
             { text: 'Attendance %', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
                ] );
   
         for ( let i = 0; i < this.attendanceArr.length; i++ ) {
               this.body.push( [
                 { text: ( i + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: moment(this.attendanceArr[i].certificateDate,'YYYY-MM-DD').format('DD/MM/YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.attendanceArr[i].attedanceCertificateNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.attendanceArr[i].admissionNo, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.attendanceArr[i].name, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text:  this.attendanceArr[i].fatherName, fontSize: 9, font: 'TimesNewRoman', alignment: 'left' },
                 { text: this.getClassNameByClassId(this.attendanceArr[i].classId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                 { text: this.attendanceArr[i].attendancePercentage+'%', fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                 ] );
         }
   
         this.content.push( {
             'table': {
                 headerRows: 1,
                 'body': this.body,
                 widths: ['5%', '9%', '12%', '12%', '22%', '20%', '10%', '10%']
             }
         } );
        
   
         const dd = {
             'content': this.content,
             pageOrientation: 'landscape',
             pageSize: 'A4',
             footer: ( currentPage, pageCount ) => {
                 return {
                     margin: 10,
                     columns: [
                         {
                             fontSize: 9,
                             text: [
                                 {
                                     text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                     '\n',
                                     margin: [0, 20]
                                 },
                                 {
                                     text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                 }
                             ],
                             alignment: 'center'
                         }
                     ]
                 };
             }
         };
   
         pdfMake.createPdf( dd ).open();
         this.body = [];
         this.content = [];
   
     }
}
