import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { LedgerPojo } from "src/app/pojo/ledger.pojo";
import { JournalEntryPojo } from "src/app/pojo/journalEntry.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { JournalEntryService } from "src/app/management/Accounts/journal-entry/journal-entry.service";
import { LedgercreationService } from "src/app/management/Masters/ledgercreation/ledgercreation.service";
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';

declare var $: any;
declare var bootbox: any;
@Component({
    selector: 'app-journal-entry',
    templateUrl: './journal-entry.component.html',
    styleUrls: ['./journal-entry.component.scss']
})
export class JournalEntryComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    editEntryDate: NgbDateStruct;

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    ledgers: LedgerPojo[];
    addEntry = new JournalEntryPojo();
    editEntry = new JournalEntryPojo();
    journalEntrys: JournalEntryPojo[] = [];
    journalEntry = new JournalEntryPojo();

    body = [];
    content = [];
    documentTime: string;
    //picksledgersByAccountGroup: LedgerPojo[] = [];
    pickspaidledgersByAccountGroup: LedgerPojo[] = [];
    ledgerId: LedgerPojo[];
    //  public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    editEntryAmount: number;
    addEntryAmount: number;
    ledgerGrpArr: LedgerGroupPojo[] = [];
    allledgerGrpArr: LedgerGroupPojo[] = [];
    specLedgers: LedgerPojo[] = [];
    accountMasterArr:AccountMasterPojo[]=[];
    constructor(private journalEntryService: JournalEntryService,
        private accountMasterService:AccountMasterService,private ledgercreationService: LedgercreationService,
    ) { }

    ngOnInit() {

        $('#editAfterClick').hide();
        $('#deleteAfterClick').hide();
        this.loggedUser = JSON.parse(sessionStorage.getItem("schoolManagementUserDetail"));
        this.getLedgerByAccountGroup();
        this.getAllLedger();
        this.getAllJournalEntry();
        this.getLedgerGrpAll();
        this.getAllLedgerGrpIncludingPaid();
        this.accountMasterAll();
    }
    accountMasterAll() {
      this.accountMasterService.getAllAccountMaster().subscribe(response => {
        this.accountMasterArr = response;
       
      }, error => {
        bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
      });
    }
    getAccountNameById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).accName;
      } else {
        return id;
      }
    }
    getAccountPlaceById(id) {
      if (this.accountMasterArr.find(r => r.accId == id)) {
        return this.accountMasterArr.find(r => r.accId == id).place;
      } else {
        return id;
      }
    }
    getLedgerByAccountGroup() {

        this.ledgercreationService.getLedgerByType('PAID', this.loggedUser.accId).subscribe(log => {
            this.pickspaidledgersByAccountGroup = log;
        });
    }
    getLedgerGrpAll() {
        this.ledgercreationService.getLedgerGroupAll().subscribe(response => {
            this.ledgerGrpArr = response;
        }, error => {
            bootbox.alert({ message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
        });
    }
    getAllLedgerGrpIncludingPaid() {
        this.ledgercreationService.getAllLedgerGroup().subscribe(response => {
            this.allledgerGrpArr = response;
        }, error => {
            bootbox.alert({ message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
        });
    }
    getLedgerByLedgerGroupId(event) {
        this.specLedgers = [];
        if (this.editEntry.ledgerGroupId > 0) {
            this.ledgercreationService.getLedgerByLedgerGroupId(this.editEntry.ledgerGroupId, this.loggedUser.accId).subscribe(response => {
                this.specLedgers = response;
            }, error => {
                bootbox.alert({ message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            });
        }
    }
    getLedgerGroupById(id) {

        if (this.allledgerGrpArr.find(r => r.ledgerGroupId == id)) {
            return this.allledgerGrpArr.find(r => r.ledgerGroupId == id).ledgerGroupName;
        }
        else {
            return id;
        }
    }
    getLedgerById(id) {

        if (this.ledgers.find(r => r.ledgerId == id)) {
            return this.ledgers.find(r => r.ledgerId == id).ledgerName;
        }
        else {
            return id;
        }
    }
    getPaidLedgerById(id) {

        if (this.pickspaidledgersByAccountGroup.find(r => r.ledgerId == id)) {
            return this.pickspaidledgersByAccountGroup.find(r => r.ledgerId == id).ledgerName;
        }
        else {
            return id;
        }
    }

    getAllLedger() {
        this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe(rs => {
            this.ledgers = rs;
        }, error => {
            alert(error);
        });
    }
    getAllJournalEntry() {
        this.isLoading = true;
        this.journalEntryService.getJournalEntryAll(this.loggedUser.accId).subscribe(rs => {
            this.journalEntrys = rs;
            this.isLoading = false;

        }, error => {
            bootbox.alert('Error' + error);
            this.isLoading = false;
        });
    }

    clickEdit(arg) {
        this.editEntry = Object.assign({}, arg);
        if (this.editEntry.journalEntryDate != null && this.editEntry.journalEntryDate != undefined) {
            (<HTMLFormElement>document.getElementById('editJournalEntryDate')).value = this.editEntry.journalEntryDate;
        }

        if (this.editEntry.credit == 0) {
            this.editEntry.amount = this.editEntry.debit;
            this.editEntry.accountType = "DEBIT";
        } if (this.editEntry.debit == 0) {
            this.editEntry.amount = this.editEntry.credit;
            this.editEntry.accountType = "CREDIT";
        }
        this.getLedgerByLedgerGroupId(event);
    }

    editClass() {
        let editJournalEntry = new JournalEntryPojo();

        (<HTMLInputElement>document.getElementById('editButton')).disabled = true;
        $('#editBeforeClick').hide(); $('#editAfterClick').show();
        this.editEntry.journalEntryDate = moment((<HTMLInputElement>document.getElementById('editJournalEntryDate')).value).toDate();

        if (this.editEntry.accountType == "CREDIT") {
            this.editEntry.credit = this.editEntry.amount;
            this.editEntry.debit = 0;
        } if (this.editEntry.accountType == "DEBIT") {
            this.editEntry.debit = this.editEntry.amount;
            this.editEntry.credit = 0;
        }
        this.editEntry.accId = this.loggedUser.accId;
        editJournalEntry = this.editEntry;
        this.journalEntryService.updateJournalEntry(editJournalEntry).subscribe(response => {
            bootbox.alert({ message: 'Successfully updated.', className: 'text-success h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
            $('#editModal').modal('hide');
            this.getAllJournalEntry();
        }, error => {
            bootbox.alert({ message: 'Update Journal Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            (<HTMLInputElement>document.getElementById('editButton')).disabled = false;
            $('#editBeforeClick').show(); $('#editAfterClick').hide();
        });
    }

    delete(arg) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.journalEntrys.indexOf(this.journalEntrys.find(r => r.journalEntryId == this.id));
        $('#deleteBeforeClick').hide(); $('#deleteAfterClick').show();
        this.journalEntryService.deleteJournalEntry(this.id, this.loggedUser.loggedUserId, this.loggedUser.accId).subscribe(response => {
            no = response;
            if (no == 0) {
                bootbox.alert({ message: 'This row already deleted!', className: 'text-danger h5', closeButton: false });
                this.journalEntrys.splice(i, 1);
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            } else {
                this.journalEntrys.splice(i, 1);
                $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            }
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
            $('#deleteModal').modal('hide');
            this.getAllJournalEntry();
        }, error => {
            bootbox.alert({ message: 'Delete Journal Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            $('#deleteBeforeClick').show(); $('#deleteAfterClick').hide();
        });
    }
    printData(arg:JournalEntryPojo) {
        arg.amount=0;
        arg.accountType="";
         if(arg.credit==0){arg.amount = arg.debit;arg.accountType='Debit Account'}
         if(arg.debit==0){arg.amount = arg.credit;arg.accountType='Credit Account'}
        this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
        this.content.push({
            text: 'CASH BOOK RECEIPT  ', fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
        });
        this.content.push({
            text: '------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
            fontSize: 10, bold: true, alignment: 'center'
        });


        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Entry Date', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: moment(arg.journalEntryDate,'YYYY-MM-DD').format('DD-MM-YYYY'), font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Account Type', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: arg.accountType, font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Amount', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8, 
                }, {
                    width: '50%',
                    text: arg.amount.toFixed(2), bold: true,font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Ledger Group', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: this.getLedgerGroupById(arg.ledgerGroupId), font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Ledger Name', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: this.getLedgerById(arg.ledgerId), font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Paid Ledger Name', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: this.getPaidLedgerById(arg.dLedgerId), font: 'TimesNewRoman', fontSize: 14, alignment: 'left',lineHeight:1.8
                },]
        });
        this.content.push({
            columns: [
                {
                    width: '48%',
                    text: 'Description', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'left',lineHeight:1.8
                }, {
                    width: '2%',
                    text: ':', font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center',lineHeight:1.8
                }, {
                    width: '50%',
                    text: arg.narration, font: 'TimesNewRoman', fontSize: 11, alignment: 'left',lineHeight:1.6
                },]
        });
       
        const dd = {
            'content': this.content,
            pageOrientation: 'portrait',
            pageSize: 'A4',
            footer: (currentPage, pageCount) => {
                return {
                    margin: 10,
                    columns: [
                        {
                            fontSize: 9,
                            text: [
                                {
                                    text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                        '\n',
                                    margin: [0, 20]
                                },
                                {
                                    text: this.getAccountPlaceById(this.loggedUser.accId) + ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                }
                            ],
                            alignment: 'center'
                        }
                    ]
                };
            }
        };

        pdfMake.createPdf(dd).open();
        this.body = [];
        this.content = [];

    }
    downloadPDF() {

        this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
        this.content.push( {
            text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
           } );
        this.content.push({
            text: 'CASH BOOK ENTRY REPORT  ', fontSize: 14, bold: true, alignment: 'center', font: 'TimesNewRoman'
        });
        this.content.push({
            text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
            fontSize: 10, bold: true, alignment: 'center'
        });


        this.content.push({
            text: '\n', fontSize: 8, alignment: 'center'
        });

        this.body.push([
            { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Entry Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Ledger Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Paid Ledger', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Credit Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
            { text: 'Debit Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        ]);

        for (let i = 0; i < this.journalEntrys.length; i++) {

            this.body.push([
                { text: (i + 1), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: moment(this.journalEntrys[i].journalEntryDate, 'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.getLedgerById(this.journalEntrys[i].ledgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
                { text: this.getPaidLedgerById(this.journalEntrys[i].dLedgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center', },
                { text: this.journalEntrys[i].credit.toFixed(2), fontSize: 9, font: 'TimesNewRoman', alignment: 'right' },
                { text: this.journalEntrys[i].debit.toFixed(2), fontSize: 9, font: 'TimesNewRoman', alignment: 'right' },
            ]);
        }

        this.content.push({
            'table': {
                headerRows: 1,
                'body': this.body,
                widths: ['5%', '12%', '29%', '30%', '12%', '12%']
            }
        });


        const dd = {
            'content': this.content,
            pageOrientation: 'landscape',
            pageSize: 'A4',
            footer: (currentPage, pageCount) => {
                return {
                    margin: 10,
                    columns: [
                        {
                            fontSize: 9,
                            text: [
                                {
                                    text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                        '\n',
                                    margin: [0, 20]
                                },
                                {
                                    text: this.getAccountPlaceById(this.loggedUser.accId)+ ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                                }
                            ],
                            alignment: 'center'
                        }
                    ]
                };
            }
        };

        pdfMake.createPdf(dd).open();
        this.body = [];
        this.content = [];

    }
}
