import { Component, OnInit } from '@angular/core';
import { JournalEntryPojo } from 'src/app/pojo/journalEntry.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import * as moment from 'moment';
import { JournalEntryService } from '../../Accounts/journal-entry/journal-entry.service';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-journal-entry',
  templateUrl: './journal-entry.component.html',
  styleUrls: ['./journal-entry.component.scss']
})
export class JournalComponent implements OnInit {
  model: NgbDateStruct;
   date: { year: number, month: number };
   form: boolean = false;
   
   addEntryDate: NgbDateStruct;
  ledgers: LedgerPojo[];
  addEntry = new JournalEntryPojo();
  public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    picksledgersByAccountGroup: LedgerPojo[] = [];
    pickspaidledgersByAccountGroup: LedgerPojo[] = [];
    ledgerGrpArr:LedgerGroupPojo[]=[];
    constructor( private journalEntryService: JournalEntryService,
      private ledgercreationService: LedgercreationService,
     ) { }

  ngOnInit() {
    $( '#addAfterClick' ).hide();
    this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
    this.getLedgerByAccountGroup( this.acc);
  //  this.getAllLedger();
    this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
   this.getLedgerGrpAll();
   }
  getLedgerByAccountGroup( Acc ) {
    this.ledgercreationService.getLedgerByAccountGroup( this.acc ,this.loggedUser.accId).subscribe( log => {
        this.picksledgersByAccountGroup = log;
    } );
    this.ledgercreationService.getLedgerByType('PAID',this.loggedUser.accId).subscribe( log => {
        this.pickspaidledgersByAccountGroup = log;
    } );
}
getLedgerGrpAll() {
  this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
      this.ledgerGrpArr = response;
    }, error => {
      bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    } );
}
getLedgerByLedgerGroupId(event){
  this.ledgers=[];
  if(this.addEntry.ledgerGroupId>0){
  this.ledgercreationService.getLedgerByLedgerGroupId(this.addEntry.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
    this.ledgers = response;
  }, error => {
    bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
  } );
}
}
getAllLedger() {
  this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
      this.ledgers = rs;
  }, error => {
      alert( error );
  } );
}
noEnter( e ) {
  return !( window.event && e.keyCode == 13 );
}
dateClose(e){
  if(e.keyCode==13){
      $( '#closeCalendar' ).click();  
      $( '#accType' ).focus();  
  }
}
amntFocus(e){
  if(e.keyCode==13){
    $( '#addAmnt' ).focus();  
   } 
}
ledgerFocus(e){
  if(e.keyCode==13){
    $( '#addLedger' ).focus();  
   }
}
paidLedgerFocus(e){
  if(e.keyCode==13){
    $( '#paidLedger' ).focus();  
   }
}
descriptionFocus(e){
  if(e.keyCode==13){
    $( '#desc' ).focus();  
   }
}
submitBtnFocus(e){
  if(e.keyCode==13){
    $( '#addButton' ).click();  
   }
}
add() {
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
  $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
  this.addEntry.journalEntryDate = moment(( <HTMLInputElement>document.getElementById( 'addJournalEntryDate' ) ).value).toDate();
 if(this.addEntry.accountType =="CREDIT"){
     this.addEntry.credit = this.addEntry.amount;
 } if(this.addEntry.accountType =="DEBIT"){
     this.addEntry.debit = this.addEntry.amount;
 }
 this.addEntry.accId = this.loggedUser.accId;
  this.journalEntryService.addJournalEntry(this.addEntry).subscribe( response => {
    bootbox.alert({
       message: "Successfully Added",
        className: 'text-success h5',
      callback: function(){ 
        $( '#addJournalEntryDate' ).focus();  
        $( '#addJournalEntryDate' ).click();  
       }
  });
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
       $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
      this.addEntryDate = null;
      this.addEntry = new JournalEntryPojo();
      this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
  
        }, error => {
      bootbox.alert( { message: 'Add Journal Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
      $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
  } );
}


}
