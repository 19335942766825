
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { StandardStructureMasterService } from './standard-structure.service';
import { TermMasterService } from '../term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { ClassMasterService } from '../class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-standard-structure',
  templateUrl: './standard-structure.component.html',
  styleUrls: ['./standard-structure.component.scss'],
  providers:[AcademicSettingService]
})

export class StandardStructureComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    feeStructArr: StandardTermPojo[] = [];
    addFee = new StandardTermPojo();
    editFee = new StandardTermPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    receiptHeadArr:ReceiptHeadPojo[] = [];
    termArr: TermPojo[] = [];
    classArr: ClassMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    tempOrderNo:boolean=true;
    tempEditOrderNo:boolean;
    constructor(  private settingService:AcademicSettingService,private classMasterService: ClassMasterService,private feeTypemasterserivce: TermMasterService,private receiptheadService:ReceiptHeadService,private feeStructuremasterserivce: StandardStructureMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getFeeStructureAll();
          this.getAllReceiptHead();
          this.getFeeTypeAll();
          this.getClassMasterAll();
          this.academicSettingAll();
    }
    academicSettingAll(){
        this.academic = [];
        this.settingService.getAcademicYrAll().subscribe( response => {
            this.academic = response;
            for(let i=0;i<this.academic.length;i++){
                if(this.academic[i].academicFlag == 1){
                    this.addFee.academicYearId = this.academic[i].academicYearId;
                      }
            }
            
              }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
            this.classArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getClassNameById( id ) {
        if ( this.classArr.find( r => r.classId == id ) ) {
              return this.classArr.find( r => r.classId == id ).className;
          }
          else {
              return id;
          }
      }
    getFeeTypeAll() {
         this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
            this.termArr = response;
              }, error => {
            bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              } );
    }
    getTerNameById( id ) {
        if ( this.termArr.find( r => r.termId == id ) ) {
              return this.termArr.find( r => r.termId == id ).termName;
          }
          else {
              return id;
          }
      }
    getAllReceiptHead() {
        this.receiptheadService.getReceiptHeadForReceipt(this.loggedUser.accId).subscribe( rs => {
          this.receiptHeadArr = rs;
           }, error => {
          bootbox.alert( 'Error' + error );
           } );
  }
  getReceiptHeadNameById( id ) {
  if ( this.receiptHeadArr.find( r => r.receiptHeadId == id ) ) {
        return this.receiptHeadArr.find( r => r.receiptHeadId == id ).receiptHeadName;
    }
    else {
        return id;
    }
}
getReceiptHeadOrderNoById( id ) {
    if ( this.receiptHeadArr.find( r => r.receiptHeadId == id ) ) {
          return this.receiptHeadArr.find( r => r.receiptHeadId == id ).orderNo;
      }
      else {
          return id;
      }
  }
getReceOrderNo(){
for(let i=0;i<this.receiptHeadArr.length;i++){
    if(this.addFee.receiptHeadId==this.receiptHeadArr[i].receiptHeadId){
        if(this.receiptHeadArr[i].orderNo==2){
            this.tempOrderNo=false;
        }else{
            this.tempOrderNo=true;
        }
    }
}
}
getEditReceOrderNo(){
    for(let i=0;i<this.receiptHeadArr.length;i++){
        if(this.editFee.receiptHeadId==this.receiptHeadArr[i].receiptHeadId){
            if(this.receiptHeadArr[i].orderNo==2){
                this.tempEditOrderNo=false;
            }else{
                this.tempEditOrderNo=true;
            }
        }
    }
    }
 getFeeStructureAll() {
        this.isLoading = true;
        this.feeStructuremasterserivce.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
            this.feeStructArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addFee.discount =0;
        this.addFee.accId=this.loggedUser.accId;
        // if(this.tempOrderNo==false){
        //     this.addFee.amount=0;
        // }
        this.addFee.receiptOrderNo=this.getReceiptHeadOrderNoById(this.addFee.receiptHeadId);
          this.feeStructuremasterserivce.addStandardTerm( this.addFee ).subscribe( response => {
              let d= new StandardTermPojo();
              d=response;
              if(d.code == 102){
                bootbox.alert('Term already assigned for this class!');
                ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
                $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
              }
              else{
                bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
                ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
                ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
                $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
                $( '#addModal' ).modal( 'hide' );
                this.getFeeStructureAll();
            }
                }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editFee = Object.assign( {}, arg );
    }  

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editFee.discount =0;
        this.editFee.accId=this.loggedUser.accId;
        // if(this.tempEditOrderNo==false){
        //     this.editFee.amount=0;
        // }
        this.editFee.receiptOrderNo=this.getReceiptHeadOrderNoById(this.editFee.receiptHeadId);
        this.feeStructuremasterserivce.updateStandardTerm( this.editFee ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getFeeStructureAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.feeStructArr.indexOf( this.feeStructArr.find( r => r.standardTermId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.feeStructuremasterserivce.deleteStandardTerm( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.feeStructArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.feeStructArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getFeeStructureAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}

