import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { ExamSchedulePojo } from 'src/app/pojo/exam-schedule.pojo';


@Injectable( {
    providedIn: 'root'
} )

export class ExamScheduleMasterService {

    constructor( private httpclient: HttpClient ) { }

    getAllExamScheduleMaster(accid:number): Observable<any> {
        const url = StaticVariable.porturl + 'GetExamScheduleAll/'+accid;
        return this.httpclient.get<ExamSchedulePojo[]>( url );
    }
    
    addExamScheduleMaster( arg: ExamSchedulePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddExamSchedule';
        return this.httpclient.post( url, arg );
    }
    
    updateExamScheduleMaster( arg: ExamSchedulePojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateExamSchedule';
        return this.httpclient.put( url, arg );
    }
    
    deleteExamScheduleMaster( id: number, loggedId: number,accid:number ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteExamSchedule/' + id + '/' + loggedId+'/'+accid;
        return this.httpclient.delete( url );
    }
   
}