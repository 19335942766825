import { AcademicStandardDetailsPojo } from './academic-standard-details.pojo';

export class PromotionReportPojo {

	 fromAcademicYearId:number;
	 toAcademicYearId:number;
	 fromClassId:number;
	 userId:number;
	 staffId:number;
	 accId:number;
    selectList:AcademicStandardDetailsPojo[] =[];
}
