
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";

import { CastePojo } from 'src/app/pojo/community-caste.pojo';
import { CasteMasterService } from './caste-master.service';
import { HoursMasterService } from '../hours-master/hoursmaster.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-caste-master',
  templateUrl: './caste-master.component.html',
  styleUrls: ['./caste-master.component.scss']
})

export class CasteMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    casteArr: CastePojo[] = [];
    addCaste = new CastePojo();
    editCaste = new CastePojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private castemasterserivce: CasteMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getCasteAll();
    }
    
    getCasteAll() {
        this.isLoading = true;
        this.castemasterserivce.getAllCaste(this.loggedUser.accId).subscribe( response => {
            this.casteArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Caste All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addCaste.accId=this.loggedUser.accId;
          this.castemasterserivce.addCaste( this.addCaste ).subscribe( response => {
            bootbox.alert( { message: 'Caste has been added successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getCasteAll();
        }, error => {
            bootbox.alert( { message: 'Add caste Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this. editCaste = Object.assign( {}, arg );
    }

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editCaste.accId=this.loggedUser.accId;
        this.castemasterserivce.updateCaste( this.editCaste ).subscribe( response => {
            bootbox.alert( { message: 'Caste has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getCasteAll();
        }, error => {
            bootbox.alert( { message: 'Update Caste Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.casteArr.indexOf( this.casteArr.find( r => r.casteId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.castemasterserivce.deleteCaste( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.casteArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.casteArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getCasteAll();
        }, error => {
            bootbox.alert( { message: 'Delete Caste Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
