
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { StaticVariable } from 'src/app/globle.class';
import * as moment from 'moment';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { CertificateAllService } from '../certificate.service';
import { AttendanceCertificatePojo, BonafideCertificatePojo, ConductCertificatePojo, CourseCertificatePojo, GenuineCertificatePojo } from 'src/app/pojo/certificate.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-certificate-reprint',
  templateUrl: './certificate-reprint.component.html',
  styleUrls: ['./certificate-reprint.component.scss']
})
export class CertificateReprintComponent implements OnInit {
  isLoading: boolean = true;
  loggedUser = new MUserMasterPojo();
  fetchLoading: boolean = false;
  admissionNum: string = "";
  certificateType: string = "";
  body = [];
  content = [];
  documentTime: string;
  classArr: ClassMasterPojo[] = [];
  academic: AcademicYearMasterPojo[] = [];
  sectionArr:SectionMasterPojo[]=[];
  accountMasterArr:AccountMasterPojo[]=[];
  constructor(private certificateService: CertificateAllService, private classMasterService: ClassMasterService,
    private accountmasterserivce:AccountMasterService,private settingService: AcademicSettingService,private sectionMasterService:SectionMasterService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));
    this.getClassMasterAll();
    this.academicSettingAll();
    this.getSectionMasterAll();
    this.getAccountMasterAll();
  }
  getAccountMasterAll() {
      this.isLoading = true;
      this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
          this.accountMasterArr = response;
              this.isLoading = false;
      }, error => {
          bootbox.alert( { message: 'Get Account Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          this.isLoading = false;
      } );
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  } getAccountDistrictById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).district;
    } else {
      return id;
    }
  }
  academicSettingAll() {
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe(response => {
      this.academic = response;
    }, error => {
      bootbox.alert('Setting Service Error! - HTTP Status ' + error.status);
    });
  }
  getAcademicYrById(id) {
    if (this.academic.find(r => r.academicYearId == id)) {
      return this.academic.find(r => r.academicYearId == id).academicYear;
    }
    else {
      return id;
    }
  }
  getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
      this.classArr = response;
      this.isLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.isLoading = false;
    });
  }
  getClassNameById(id) {
    if (this.classArr.find(r => r.classId == id)) {
      return this.classArr.find(r => r.classId == id).className;
    }
    else {
      return id;
    }
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}


getSectionNameBySectionId( id ) {
    if ( this.sectionArr.find( response => response.sectionId == id ) ) {
        return this.sectionArr.find( response => response.sectionId == id ).sectionName;
    } else {
        return id;
    }
}
  fetch() {
    this.fetchLoading = true;
    if (this.certificateType == '1') {
      this.attendance();
    } else if (this.certificateType == '2') {
      this.bonafide();
    } else if (this.certificateType == '3') {
      this.conduct();
    } else if (this.certificateType == '4') {
      this.course();
    } else if (this.certificateType == '5') {
      this.genuine();
    }


  }
  attendance() {
    this.certificateService.getAttendanceCertificateByAdmissionNum(this.admissionNum).subscribe(response => {
      let d = new AttendanceCertificatePojo();
      d = response;
      if (d.admissionNo != null && d.admissionNo != undefined && d.admissionNo != "") {
        this.printAttendance(d);
      } else {
        bootbox.alert({ message: 'Invalid Admission Number', className: 'text-danger h5', closeButton: false });
      }
      this.fetchLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Attendance Certificate ByAdmission Number Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.fetchLoading = false;
    });
  }
  bonafide() {
    this.certificateService.getBonafideCertificateByAdmissionNum(this.admissionNum).subscribe(response => {
      let d = new BonafideCertificatePojo();
      d = response;
      if (d.admissionNo != null && d.admissionNo != undefined && d.admissionNo != "") {
     this.printBonafide(d);
      } else {
        bootbox.alert({ message: 'Invalid Admission Number', className: 'text-danger h5', closeButton: false });
      }
      this.fetchLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Bonafide Certificate ByAdmission Number Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.fetchLoading = false;
    });
  }
  conduct() {
    this.certificateService.getConductCertificateByAdmissionNum(this.admissionNum).subscribe(response => {
      let d = new ConductCertificatePojo();
      d = response;
      if (d.admissionNo != null && d.admissionNo != undefined && d.admissionNo != "") {
        this.printConduct(d);
      } else {
        bootbox.alert({ message: 'Invalid Admission Number', className: 'text-danger h5', closeButton: false });
      }
      this.fetchLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Conduct Certificate ByAdmission Number Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.fetchLoading = false;
    });
  }
  course() {
    this.certificateService.getCourseCertificateByAdmissionNum(this.admissionNum).subscribe(response => {
      let d = new CourseCertificatePojo();
      d = response;
      if (d.admissionNo != null && d.admissionNo != undefined && d.admissionNo != "") {
        this.printCourse(d);
      } else {
        bootbox.alert({ message: 'Invalid Admission Number', className: 'text-danger h5', closeButton: false });
      }
      this.fetchLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Course Certificate ByAdmission Number Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.fetchLoading = false;
    });
  }
  genuine() {
    this.certificateService.getGenuineCertificateByAdmissionNum(this.admissionNum).subscribe(response => {
      let d = new GenuineCertificatePojo();
      d = response;
      if (d.admissionNo != null && d.admissionNo != undefined && d.admissionNo != "") {
        this.printGenuine(d);
      } else {
        bootbox.alert({ message: 'Invalid Admission Number', className: 'text-danger h5', closeButton: false });
      }
      this.fetchLoading = false;
    }, error => {
      bootbox.alert({ message: 'Get Genuine Certificate ByAdmission Number Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
      this.fetchLoading = false;
    });
  }
  printAttendance(arg: AttendanceCertificatePojo) {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.body.push([
      { text: this.getAccountNameById(this.loggedUser.accId), fontSize: 14, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
    this.body.push([
      { text: this.getAccountPlaceById(this.loggedUser.accId)+','+this.getAccountDistrictById(this.loggedUser.accId)+ ' ( Dis )', fontSize: 12, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);

    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    this.body.push([

      { text: '', border: [false, false, false, false] },
      {
        image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
      }, {},

      { text: 'ATTENDANCE CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
      { text: '', border: [false, false, false, false], colSpan: 2 }, {},
    ]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'This is to Certify that', colSpan: 3, style: 'nontxtleft', border: [false, false, false, false] }, {}, {},
    { text: arg.name, colSpan: 7, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'D/o', style: 'nontxtleft', border: [false, false, false, false] },
    { text: arg.fatherName, colSpan: 6, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {},
    { text: 'was a bonafide student of', colSpan: 3, style: 'nontxtright', border: [false, false, false, false] }, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'this School was studied in', style: 'nontxtleft', colSpan: 3, border: [false, false, false, false] }, {}, {},
    { text: this.getClassNameById(arg.classId), colSpan: 5, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {},
    { text: 'Standard', style: 'nontxtright', border: [false, false, false, false], alignment: 'right', colSpan: 2 }, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'during the academic year of', style: 'nontxtleft', colSpan: 3, border: [false, false, false, false] }, {}, {},
    { text: this.getAcademicYrById(arg.academicYearId), colSpan: 2, style: 'txt', border: [false, false, false, true], }, {},
    { text: 'she attended', style: 'nontxtcenter', border: [false, false, false, false], alignment: 'right', colSpan: 2, }, {},
    { text: arg.presentDays, colSpan: 2, style: 'txt', border: [false, false, false, true], }, {},
    { text: 'days', style: 'nontxtright', border: [false, false, false, false], },]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'out of', style: 'nontxtleft', border: [false, false, false, false] },
    { text: arg.totalDays, colSpan: 3, style: 'txt', border: [false, false, false, true], }, {}, {},
    { text: 'and it is', style: 'nontxtcenter', border: [false, false, false, false], alignment: 'right' },
    { text: arg.attendancePercentage.toFixed(2) + ' %', colSpan: 2, style: 'txt', border: [false, false, false, true], }, {},
    { text: 'of the attendance.', style: 'nontxtleft', border: [false, false, false, false], colSpan: 3 }, {}, {}]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);

    this.content.push({
      'table': {
        headerRows: 1,
        alignment: 'center',
          'body': this.body,
        widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      }
    });

    const dd = {
      'content': this.content,
      pageOrientation: 'portrait',
      pageSize: 'A4',
      styles: {
        txt: {
          font: 'TimesNewRoman',
          bold: true,
          fontSize: 12,
          alignment: 'center'
        },
        nontxtleft: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'left',

        },
        nontxtright: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'right',

        },
        nontxtcenter: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'center',

        },
      },

    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
  }
  printBonafide(arg: BonafideCertificatePojo) {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
      this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    this.body.push([

      { text: '', border: [false, false, false, false] },
      {
        image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
      }, {},

      { text: 'BONAFIDE CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
      { text: '', border: [false, false, false, false], colSpan: 2 }, {},
    ]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{text:'', border: [false, false, false, false] },{ text: 'THIS IS TO CERTIFY THAT', colSpan: 3, style: 'nontxtleft', border: [false, false, false, false] }, {}, {},
    { text: arg.name, colSpan: 6, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'D/o', style: 'nontxtleft', border: [false, false, false, false] },
    { text: arg.fatherName, colSpan: 5, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {},
    { text: 'IS A BONAFIDE STUDENT OF OUR', colSpan: 4, style: 'nontxtright', border: [false, false, false, false] },{}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: arg.schoolName,style:'txt', colSpan: 10, border: [false, false, false, true] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'STUDYING IN STANDARD', style: 'nontxtleft', colSpan: 3, border: [false, false, false, false] }, {}, {},
    { text: this.getClassNameById(arg.classId), colSpan: 3, style: 'txt', border: [false, false, false, true], }, {}, {},  
    { text: 'DURING THE ACADEMIC YEAR OF', style: 'nontxtright', border: [false, false, false, false], alignment: 'right', colSpan: 4 },{},{}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: this.getAcademicYrById(arg.academicYearId), style: 'txt', colSpan: 3, border: [false, false, false, true] }, {}, {},
    { text: 'HER DATE OF BIRTH IS', colSpan: 3, style: 'nontxtcenter', border: [false, false, false, false], }, {},{},
    { text: moment(arg.dob,'YYYY-MM-DD').format('DD-MM-YYYY'), style: 'txt', border: [false, false, false, true], colSpan: 4, }, {},{},{}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: arg.dobWords,style:'txt', colSpan: 10, border: [false, false, false, true] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: '\nAS PER OUR SCHOOL RECORDS.',style:'nontxtleft', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'ADMISSION NO :',style:'nontxtleft', border: [false, false, false, false] ,colSpan:2},{}, 
    {text:arg.admissionNo,style:'txt',alignment:'left',border:[false,false,false,false],colSpan:8}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'NATIONALITY,RELIGION & CASTE :',style:'nontxtleft',colSpan:4, border: [false, false, false, false] },{},{},{}, 
    {text:arg.nationality+' - '+arg.religion+' - '+arg.caste,style:'txt',alignment:'left',border:[false,false,false,false],colSpan:6},  {}, {}, {}, {}, {}, ]);
      this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);

    this.content.push({
      'table': {
        headerRows: 1,
        alignment: 'center',
          'body': this.body,
        widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      }
    });

    const dd = {
      'content': this.content,
      pageOrientation: 'portrait',
      pageSize: 'A4',
      styles: {
        txt: {
          font: 'TimesNewRoman',
          bold: true,
          fontSize: 10,
          alignment: 'center'
        },
        nontxtleft: {
          font: 'TimesNewRoman',
          fontSize: 10,
          alignment: 'left',

        },
        nontxtright: {
          font: 'TimesNewRoman',
          fontSize: 10,
          alignment: 'right',

        },
        nontxtcenter: {
          font: 'TimesNewRoman',
          fontSize: 10,
          alignment: 'center',

        },
      },

    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
  }
  printConduct(arg: ConductCertificatePojo) {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.body.push([
      { text: this.getAccountNameById(this.loggedUser.accId), fontSize: 14, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
    this.body.push([
      { text: this.getAccountPlaceById(this.loggedUser.accId)+','+this.getAccountDistrictById(this.loggedUser.accId)+ ' ( Dis )', fontSize: 12, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);

    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    this.body.push([

      { text: '', border: [false, false, false, false] },
      {
        image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
      }, {},

      { text: 'CONDUCT CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
      { text: '', border: [false, false, false, false], colSpan: 2 }, {},
    ]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'This is to Certify that', colSpan: 3, style: 'nontxtleft', border: [false, false, false, false] }, {}, {},
    { text: arg.name, colSpan: 7, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'D/o', style: 'nontxtleft', border: [false, false, false, false] },
    { text: arg.fatherName, colSpan: 9, style: 'txt', border: [false, false, false, true], }, {}, {}, {}, {}, {},{}, {}, {},]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'was studying in this School from', style: 'nontxtleft', colSpan: 4, border: [false, false, false, false] },{}, {}, {},
    { text: arg.joinedYear+' to '+arg.leavingYear, colSpan: 6, style: 'txt',alignment:'center', border: [false, false, false, true], },  {}, {}, {},{},{}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'in STANDARDS', style: 'nontxtleft', colSpan: 2, border: [false, false, false, false] }, {}, 
    { text: this.getClassNameById(arg.joinedClassId)+' to '+this.getClassNameById(arg.leavingClassId), colSpan: 8, style: 'txt',alignment:'center', border: [false, false, false, true], }, {}, {}, {}, {},{},{},{}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
     this.body.push([{ text: 'and his/her conduct and character during the period was', style: 'nontxtleft', colSpan: 6, border: [false, false, false, false] }, {},{}, {},{}, {},
    { text: arg.reason, colSpan: 4, style: 'txt', border: [false, false, false, true], },{}, {},{}]);
     this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);

    this.content.push({
      'table': {
        headerRows: 1,
        alignment: 'center',
          'body': this.body,
        widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      }
    });

    const dd = {
      'content': this.content,
      pageOrientation: 'portrait',
      pageSize: 'A4',
      styles: {
        txt: {
          font: 'TimesNewRoman',
          bold: true,
          fontSize: 12,
          alignment: 'center'
        },
        nontxtleft: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'left',

        },
        nontxtright: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'right',

        },
        nontxtcenter: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'center',

        },
      },

    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
  }
  printCourse(arg: CourseCertificatePojo) {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.body.push([
      { text: this.getAccountNameById(this.loggedUser.accId), fontSize: 14, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
    this.body.push([
      { text: this.getAccountPlaceById(this.loggedUser.accId)+','+this.getAccountDistrictById(this.loggedUser.accId)+ ' ( Dis )', fontSize: 12, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    this.body.push([

      { text: '', border: [false, false, false, false] },
      {
        image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
      }, {},

      { text: 'COURSE CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
      { text: '', border: [false, false, false, false], colSpan: 2 }, {},
    ]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
      { text: '1. NAME', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
    { text: ':\t'+arg.name, colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
    { text: '2. FATHER NAME', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
  { text: ':\t'+arg.fatherName, colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
  this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
  { text: '3. ADMISSION NUMBER', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
{ text: ':\t'+arg.admissionNo, colSpan: 5, style: 'txt', border: [false, false, false, false], }, {}, {}, {}, {}]);
this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
      { text: '4. STUDIED YEAR', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
    { text: ':\t'+this.getAcademicYrById(arg.academicYearId), colSpan: 5, style: 'txt', border: [false, false, false, false], },{}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
    { text: '5. GENDER', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
  { text: ':\t'+arg.gender, colSpan: 5, style: 'txt', border: [false, false, false, false], }, {}, {}, {}, {}]);
  this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
  { text: '6. DATE OF BIRTH', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
{ text: ':\t'+moment(arg.dob,'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'This is to certify that this student studied in', colSpan: 5, border: [false, false, false, false] }, {}, {}, {}, {},
     {text:this.getClassNameById(arg.classId),style:'txt',alignment:'center',border: [false, false, false, true],colSpan:3 },{}, {},
     {text:'Standard',style:'nontxtright',border: [false, false, false, false] ,colSpan:2},{}]);
     this.body.push([{ text: 'in this School.', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {},{},{}]);
     this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);

    this.content.push({
      'table': {
        headerRows: 1,
        alignment: 'center',
          'body': this.body,
        widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      }
    });

    const dd = {
      'content': this.content,
      pageOrientation: 'portrait',
      pageSize: 'A4',
      styles: {
        txt: {
          font: 'TimesNewRoman',
          bold: true,
          fontSize: 12,
          alignment: 'left'
        },
        nontxtleft: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'left',

        },
        nontxtright: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'right',

        },
        nontxtcenter: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'center',

        },
      },

    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
  }
  printGenuine(arg:GenuineCertificatePojo) {
    this.documentTime = moment().format('DD-MM-YYYY  h:mm:ss A');
    this.body.push([
      { text: this.getAccountNameById(this.loggedUser.accId), fontSize: 14, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);
    this.body.push([
      { text: this.getAccountPlaceById(this.loggedUser.accId)+','+this.getAccountDistrictById(this.loggedUser.accId)+ ' ( Dis )', fontSize: 12, font: 'TimesNewRoman', alignment: 'center', bold: 'true', border: [false, false, false, false], colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
    ]);

    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);

    this.body.push([

      { text: '', border: [false, false, false, false] },
      {
        image: StaticVariable.logo, width: 55, border: [false, false, false, false], colSpan: 2, alignment: 'center'
      }, {},

      { text: 'GENUINE CERTIFICATE', fontSize: 16, font: 'TimesNewRoman', margin: [5, 16, 5, 0], alignment: 'center', bold: 'true', border: [true, true, true, true], colSpan: 5, }, {}, {}, {}, {},
      { text: '', border: [false, false, false, false], colSpan: 2 }, {},
    ]);
    this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
      { text: '1. NAME', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
    { text: ':\t'+arg.name, colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
    { text: '2. FATHER NAME', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
  { text: ':\t'+arg.fatherName, colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
  this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
  { text: '3. ADMISSION NUMBER', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
{ text: ':\t'+arg.admissionNo, colSpan: 5, style: 'txt', border: [false, false, false, false], }, {}, {}, {}, {}]);
this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
{ text: '4. STANDARD', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
{ text: ':\t'+this.getClassNameById(arg.classId)+' - '+this.getSectionNameBySectionId(arg.sectionId), colSpan: 5, style: 'txt', border: [false, false, false, false], }, {}, {}, {}, {}]);
this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
      { text: '4. STUDIED YEAR', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
    { text: ':\t'+this.getAcademicYrById(arg.academicYearId), colSpan: 5, style: 'txt', border: [false, false, false, false], },{}, {}, {}, {}]);
    this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
    { text: '5. GENDER', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
  { text: ':\t'+arg.gender, colSpan: 5, style: 'txt', border: [false, false, false, false], }, {}, {}, {}, {}]);
  this.body.push([{text:'',border:[false,false,false,false],colSpan:2},{},
  { text: '6. DATE OF BIRTH', colSpan: 3, style: 'txt', border: [false, false, false, false] }, {}, {},
{ text: ':\t'+moment(arg.dob,'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 5, style: 'txt', border: [false, false, false, false], },  {}, {}, {}, {}]);
    this.body.push([{ text: '', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'The above particulars are found correct as per our School records.',fontSize:16, colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {},{},]);
     this.body.push([{ text: '\n\n', colSpan: 10, border: [false, false, false, false] }, {}, {}, {}, {}, {}, {}, {}, {}, {}]);
    this.body.push([{ text: 'Station', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + arg.station, colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);
    this.body.push([{ text: 'Date', colSpan: 1, style: 'nontxtleft', border: [false, false, false, false] },
    { text: ': ' + moment(arg.certificateDate, 'YYYY-MM-DD').format('DD/MM/YYYY'), colSpan: 9, style: 'txt', alignment: 'left', border: [false, false, false, false], }, {}, {}, {}, {}, {}, {}, {}, {},]);

    this.content.push({
      'table': {
        headerRows: 1,
        alignment: 'center',
          'body': this.body,
        widths: ['10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%', '10%']
      }
    });

    const dd = {
      'content': this.content,
      pageOrientation: 'portrait',
      pageSize: 'A4',
      styles: {
        txt: {
          font: 'TimesNewRoman',
          bold: true,
          fontSize: 12,
          alignment: 'left'
        },
        nontxtleft: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'left',

        },
        nontxtright: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'right',

        },
        nontxtcenter: {
          font: 'TimesNewRoman',
          fontSize: 12,
          alignment: 'center',

        },
      },

    };

    pdfMake.createPdf(dd).open();
    this.body = [];
    this.content = [];
  }
}


