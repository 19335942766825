import { Component, OnInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptPojo } from 'src/app/pojo/receipt.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { ReceiptEntryService } from '../../Accounts/receipt-entry/receipt-entry.service';
import { ReceiptHeadService } from '../../Accounts/receipt-head/receipt-head.service';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import * as moment from 'moment';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
import { StaticVariable } from 'src/app/globle.class';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-receipt-entry',
  templateUrl: './receipt-entry.component.html',
  styleUrls: ['./receipt-entry.component.scss']
})
export class ReceiptComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;
  addEntryDate: NgbDateStruct;
  addInstrumentDate: NgbDateStruct;
  addReceipt = new ReceiptPojo();
  ledgers: LedgerPojo[];
  picksledgersByAccountGroup: LedgerPojo[] = [];
  pickspaidledgersByAccountGroup: LedgerPojo[] = [];
  ledgerId: LedgerPojo[];
    public acc: number[] = [3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    public paidacc: number[] = [1, 2];
    loggedUser = new MUserMasterPojo();
    addEntryAmount:number;
    accountMasterArr: AccountMasterPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    receiptHeadArr:ReceiptHeadPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    classMasterArr: ClassMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
    standardArr: AcademicStandardDetailsPojo[] = [];
    deptId;
    classId;
    sectionId;
    ledgerGrpArr:LedgerGroupPojo[]=[];
    specledgers:LedgerPojo[]=[];
    constructor( private receiptService: ReceiptEntryService,
      private receiptheadService:ReceiptHeadService, private ledgercreationService: LedgercreationService,private accountmasterserivce: AccountMasterService,
      private sectionMasterService:SectionMasterService,private settingService:AcademicSettingService,private departmentMasterService: DepartmentMasterService,private classMasterService: ClassMasterService,
       private reportService:ReportService ) { }

  ngOnInit() {
    $( '#addAfterClick' ).hide();
    this.loggedUser = JSON.parse( sessionStorage.getItem( "schoolManagementUserDetail" ) );
    this.getLedgerByAccountGroup( this.acc);
    this.getAllLedger();
    this.getDepartmentAll();
    this.getAccountMasterAll();
     this.academicSettingAll();
    this.getClassMasterAll();
    this.getSectionMasterAll();
    this.getLedgerGrpAll();
   // this.getStudentAll();
   this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
    
  }
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                this.addReceipt.academicYear = this.academic[i].academicYearId;
            }
        }
        
          }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
   getLedgerGrpAll() {
    this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
        this.ledgerGrpArr = response;
      }, error => {
        bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
  }
  getLedgerByLedgerGroupId(event){
    this.specledgers=[];
    if(this.addReceipt.ledgerGroupId>0){
    this.ledgercreationService.getLedgerByLedgerGroupId(this.addReceipt.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
      this.specledgers = response;
    }, error => {
      bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    } );
  }
  }
   getStudentAdmissionNoById( id ) {
    if ( this.studentMasterArr.find( r => r.studentId == id ) ) {
        return this.studentMasterArr.find( r => r.studentId == id ).admissionNo;
    } else {
        return id;
    }
  }
   getStudentById() {
    this.studentMasterArr =[];
    this.reportService.getClassWiseReportById( this.deptId, this.classId, this.sectionId,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
      this.standardArr = response;
      for(let i=0;i<this.standardArr.length;i++){
          this.studentMasterArr.push(this.standardArr[i].student);
      }
      }, error => {
      bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
     } );
}
   getClassMasterAll() {
    this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
        this.classMasterArr = response;
           }, error => {
        bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
}
getSectionMasterAll() {
  this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
      this.sectionArr = response;
      }, error => {
      bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      } );
}
  getAccountMasterAll() {
    this.accountmasterserivce.getAllAccountMaster().subscribe( response => {
        this.accountMasterArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Account All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
       } );
}
getAllReceiptHeadByLedgerId(event:any) {
  this.receiptHeadArr=[];
   this.receiptheadService.getReceiptHeadByLedgerId(this.addReceipt.ledgerId,this.loggedUser.accId).subscribe( rs => {
      this.receiptHeadArr = rs;
     
  }, error => {
      bootbox.alert( 'Error' + error );
     } );
}
getLedgerByAccountGroup( Acc ) {
    this.ledgercreationService.getLedgerByAccountGroup( this.acc,this.loggedUser.accId ).subscribe( log => {
        this.picksledgersByAccountGroup = log;
    } );
    this.ledgercreationService.getLedgerByType( 'PAID',this.loggedUser.accId).subscribe( log => {
        this.pickspaidledgersByAccountGroup = log;
    } );
}

getLedgerById( id ) {

  if ( this.picksledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
      return this.picksledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
  }
  else {
      return id;
  }
}
getPaidLedgerById( id ) {

if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
    return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
}
else {
    return id;
}
}
getAllLedger() {
    this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
        this.ledgers = rs;
    }, error => {
        alert( error );
    } );
}  
getDepartmentAll() {
  this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
       this.departmentArr = run;
  }, error => {
      bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
     } );
}
noEnter( e ) {
  return !( window.event && e.keyCode == 13 );
}
dateClose(e){
  if(e.keyCode==13){
      $( '#closeCalendar' ).click();  
      $( '#addReceiptNo' ).focus();  
  }
}
closeInstdate(e){
  if(e.keyCode==13){
    $( '#closeInstCalendar' ).click();  
    $( '#addMobileNo' ).focus();  
}
}
mobileNoFocus(e){
  if(e.keyCode==13){
    $( '#addMobileNo' ).focus();  
  }
}
receHeadFocus(e){
  if(e.keyCode==13){
       $( '#adRecHeadid' ).focus();  
} 
}
ledgerFocus(e){
  if(e.keyCode==13){
       $( '#addLedger' ).focus();  
} 
}
paidLedFocus(e){
  if(e.keyCode==13){
       $( '#addPaidLedger' ).focus();  
} 
}
nameFocus(e){
  if(e.keyCode==13){
       $( '#addName' ).focus();  
} 
}
amntFocus(e){
  if(e.keyCode==13){
       $( '#addAmount' ).focus();  
} 
}
addressFocus(e){
  if(e.keyCode==13){
       $( '#addAddress' ).focus();  
} 
}
instTypeFocus(e){
  if(e.keyCode==13){
    if(this.addReceipt.paidType==1){
      $( '#addMRNo' ).focus();   
    }else{
       $( '#addInstrumentType' ).focus();  
    }
} 
}
instNoFocus(e){
  if(e.keyCode==13){
       $( '#addInstNo' ).focus();  
} 
}
instDateFocus(e){
  if(e.keyCode==13){
       $( '#addInstrumenEntrytDate' ).focus();  
} 
}
instDetFocus(e){
  if(e.keyCode==13){
       $( '#addInstDetails' ).focus();  
} 
}
mrNoFocus(e){
  if(e.keyCode==13){
       $( '#addMRNo' ).focus();  
} 
}
studTypeFocus(e){
  if(e.keyCode==13){
       $( '#studenttype' ).focus();  
} 
}deptFocus(e){
  if(e.keyCode==13){
    if(this.addReceipt.studentType=='STUDENT'){
      $( '#department' ).focus();  
    }else{
      $( '#addDescription' ).focus();  
    }
   
}  
}
classFocus(e){
  if(e.keyCode==13){
    $( '#classname' ).focus();  
}  
}
sectionFocus(e){
  if(e.keyCode==13){
    $( '#editsectionname' ).focus();  
}  
}
studNoFocus(e){
  if(e.keyCode==13){
    $( '#studentNo' ).focus();  
}  
}
descFocus(e){
  if(e.keyCode==13){
    $( '#addDescription' ).focus();  
}  
}
btnFocus(e){
  if(e.keyCode==13){
    $( '#addButton' ).click();  
}  
}
add() {
  ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
  $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
  this.addReceipt.receiptDate = moment(( <HTMLInputElement>document.getElementById( 'addReceiptDate' ) ).value).toDate();
   if(this.addReceipt.paidType==1){
    this.addReceipt.instrumentDate=moment().toDate();
    this.addReceipt.instrumentType="CASH";
    this.addReceipt.instrumentNo='0';
    this.addReceipt.instrumentNo='0';
    this.addReceipt.instrumentDetail="cash"
   }else{
    this.addReceipt.instrumentDate = moment(( <HTMLInputElement>document.getElementById( 'addInstrumenEntrytDate' ) ).value).toDate();
   
   }
   this.addReceipt.accId = this.loggedUser.accId;
  this.addReceipt.userId = this.loggedUser.userId;
  this.addReceipt.detList =[];
  this.addReceipt.studentNo = this.getStudentAdmissionNoById(this.addReceipt.studentId);  
  this.receiptService.addReceipt(this.addReceipt).subscribe( response => {
    console.log("log"+JSON.stringify(response));
    window.open(StaticVariable.porturl+"GetApplicationReceipt/"+response.receiptId,"_blank").focus;
    bootbox.alert({message: "Successfully Added", className: 'text-success h5',
    callback: function(){ 
      $( '#addReceiptDate' ).focus(); }});
       ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
        $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        this.addReceipt = new ReceiptPojo();
        this.deptId = '';
        this.classId = '';
        this.sectionId ='';
        this.addEntryDate = null;
        this.addInstrumentDate = null;
        this.addEntryDate = { day: moment().date(), month: moment().month()+1, year: moment().year() };
        }, error => {
      bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
      ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
      $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
  } );
}

}
