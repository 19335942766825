import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'receiptHead'
})
export class ReceiptHeadPipe implements PipeTransform {
    
    
    transform( array: any[], query: string): any {
        if ( query ) {
            if ( _.filter( array, row => row.receiptHeadName.toLowerCase().indexOf( query.toLowerCase() ) > -1 ) != '' ) {
                return _.filter( array, row => row.receiptHeadName.toLowerCase().indexOf( query.toLowerCase() ) > -1 );
            } else {
                return false;
            }
        }
        return array;
    }

  }
