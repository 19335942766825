import { Component, OnInit } from '@angular/core';
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { DepartmentMasterService } from "src/app/management/Masters/department-master/department-master.service";
import { CurriculumMasterPojo } from "src/app/pojo/curriculum-master.pojo";
import { CurriculumMasterService } from "src/app/management/Masters/curriculum-master/curriculum-master.service";
import { StaffMasterPojo } from "src/app/pojo/staff-master.pojo";
import { StaffMasterService } from "src/app/management/Masters/staff-master/staff-master.service";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";

declare var $: any;
declare var bootbox: any;

@Component( {
    selector: 'app-department-master',
    templateUrl: './department-master.component.html',
    styleUrls: ['./department-master.component.scss'],
    providers: [DepartmentMasterService]
} )

export class DepartmentMasterComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    departmentMasterArr: DepartmentMasterPojo[] = [];
    curriculumArr: CurriculumMasterPojo[] = [];
    staffArr: StaffMasterPojo[] = [];
    mediumMasterArr: MediumMasterPojo[] = [];

    addDepartmentMaster = new DepartmentMasterPojo();
    editDepartmentMaster = new DepartmentMasterPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private departmentmasterserivce: DepartmentMasterService, private curriculumMasterService: CurriculumMasterService, private staffMasterService: StaffMasterService, private mediumMasterService: MediumMasterService ) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();

        this.getStaffAll();
        this.getCurriculumAll();
        this.getMediumAll();
        this.getDepartmentAll();
    }

    getDepartmentAll() {
        this.departmentmasterserivce.getAllDepartment(this.loggedUser.accId).subscribe( response => {
            this.departmentMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getStaffAll() {
        this.staffMasterService.getAllStaff(this.loggedUser.accId).subscribe( response => {
            this.staffArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Staff All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getCurriculumAll() {
        this.curriculumMasterService.getCurriculumAll().subscribe( response => {
            this.curriculumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Curriculum All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getMediumAll() {
        this.mediumMasterService.getMediumAll().subscribe( response => {
            this.mediumMasterArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Medium All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    getDepartmentInchargeNameByStaffId( id ) {
        if ( this.staffArr.find( response => response.staffId == id ) ) {
            return this.staffArr.find( response => response.staffId == id ).staffName;
        } else {
            return id;
        }
    }

    addDepartment() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addDepartmentMaster.accId=this.loggedUser.accId;
        this.departmentmasterserivce.addDepartment( this.addDepartmentMaster ).subscribe( response => {
            bootbox.alert( { message: 'Department has been added successfully.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addDepartmentForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getDepartmentAll();
        }, error => {
            bootbox.alert( { message: 'Add Department Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editDepartmentMaster = Object.assign( {}, arg );
    }

    editDepartment() {
        let editDepartment = new DepartmentMasterPojo();
        editDepartment = this.editDepartmentMaster;
        editDepartment.accId=this.loggedUser.accId;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.departmentmasterserivce.updateDepartment( editDepartment ).subscribe( response => {
            bootbox.alert( { message: 'Department has been successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getDepartmentAll();
        }, error => {
            bootbox.alert( { message: 'Update Department Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.departmentMasterArr.indexOf( this.departmentMasterArr.find( r => r.deptId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.departmentmasterserivce.deleteDepartment( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.departmentMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.departmentMasterArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getDepartmentAll();
        }, error => {
            bootbox.alert( { message: 'Delete Department Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
