import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { CreditDebitReportPojo } from 'src/app/pojo/credit-debit-report.pojo';

@Injectable({
    providedIn: 'root'
})

export class AccountsReportsService {

    constructor(private httpclient: HttpClient) { }


    getCashBookReportByDate(fromDate, toDate, accId): Observable<CreditDebitReportPojo> {
        const url = StaticVariable.porturl + 'GetCashBookReport/' + fromDate + '/' + toDate + '/' + accId;
        return this.httpclient.get<CreditDebitReportPojo>(url);
    }

}