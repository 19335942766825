import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { AcademicStandardDetailsResultPojo, AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class ResultPostingSmsService {

    constructor( private httpclient: HttpClient ) { }

    
    getResultPostedStudentsById(fromyrId,toYrId,cid,accId,): Observable<AcademicStandardDetailsResultPojo> {
        const url = StaticVariable.porturl + 'GetStandardAcademicDetailsByResultPosted/'+fromyrId+'/'+toYrId+'/'+cid+'/'+accId;
        return this.httpclient.get<AcademicStandardDetailsResultPojo>( url );
    }
    sendSmsToResultPostedStudents(arg:AcademicStandardDetailsPojo[],accid,uid): Observable<any> {
        const url = StaticVariable.porturl + 'SmsToStudentResultPosting/'+accid+'/'+uid;
        return this.httpclient.post( url,arg );
    }
   
  
          

    
}