import { Component, OnInit } from '@angular/core';
import { MRolePojo } from "src/app/pojo/role-master.pojo";
import { RoleMasterService } from "src/app/management/Admin/role-master/role-master.service";
import { ManagementRolePojo, MUserMasterPojo } from "src/app/pojo/management-user-pojo";

declare var $: any;
declare var bootbox: any;

@Component({
    selector: 'app-role-master',
    templateUrl: './role-master.component.html',
    styleUrls: ['./role-master.component.scss'],
    providers: [RoleMasterService]
})

export class RoleMasterComponent implements OnInit {
    roleMasterArr: MRolePojo[] = [];
    addRoleFunction = new ManagementRolePojo();
    editRoleFunction = new ManagementRolePojo();
    loggedUser = new MUserMasterPojo();

    temproleMasterArr: MRolePojo[] = [];
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    saveLoading: boolean;

    constructor(private roleMasterService: RoleMasterService) { }

    ngOnInit() {
        this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

        this.getMRoleAll();
        $('#addAfterClick').hide();
        $('#editAfterClick').hide();
    }

    getMRoleAll() {
        this.isLoading = true;
        this.roleMasterArr = [];
        this.temproleMasterArr = [];
        this.roleMasterService.getMRoleAll(this.loggedUser.accId).subscribe(response => {
            this.temproleMasterArr = response;
            if (this.loggedUser.roleId == 1) {
                this.roleMasterArr = this.temproleMasterArr;
            } else {
                for (let i = 0; i < this.temproleMasterArr.length; i++) {

                    if (this.temproleMasterArr[i].roleId != 1) {
                        this.roleMasterArr.push(this.temproleMasterArr[i]);
                    }
                }
            }
            this.isLoading = false;
        }, error => {
            bootbox.alert({ message: 'M Role All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    onChangeRegister(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlRegister')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlStudentRegister')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterEdit')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterPrint')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlStudentRegister')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterEdit')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterPrint')).checked = false;

        }
    }
    onChangeChildElementInRegister(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudentRegister')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterEdit')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudentRegisterPrint')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlRegister')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlRegister')).checked = false;
        }
    }

    onChangeMaster(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlMaster')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlStaffMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDepartmentMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSectionMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlClassMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSubjectMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCasteMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCommunityMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlFeeType')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlFeeStructure')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlReceiptHead')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlExamMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlHolidayMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlHoursMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlNotesMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDiscountMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlAreaMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDiscountTypeMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlExamScheduleMaster')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlStaffMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDepartmentMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSectionMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlClassMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSubjectMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCasteMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCommunityMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlFeeType')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlFeeStructure')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlReceiptHead')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlExamMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlHolidayMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlHoursMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlNotesMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDiscountMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlAreaMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDiscountTypeMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlExamScheduleMaster')).checked = false;
        }
    }

    onChangeChildElementInMaster(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlStaffMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlDepartmentMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSectionMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlClassMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCasteMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCommunityMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCasteMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlFeeType')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlFeeStructure')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlReceiptHead')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlExamMaster')).checked
            && (<HTMLInputElement>document.getElementById('ctrlHolidayMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlHoursMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlNotesMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlDiscountMaster')).checked
            && (<HTMLInputElement>document.getElementById('ctrlAreaMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlDiscountTypeMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlExamScheduleMaster')).checked 

        ) {
            (<HTMLInputElement>document.getElementById('ctrlMaster')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlMaster')).checked = false;
        }
    }

    onChangeSMS(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlSMS')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlSmsCreation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSMSMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSmsTemplateMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSmsContentSettings')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlAbsentList')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlFeeAllocationSms')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlResultPostingSms')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlSmsCreation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSMSMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSmsTemplateMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSmsContentSettings')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlAbsentList')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlFeeAllocationSms')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlResultPostingSms')).checked = false;
        }
    }

    onChangePromotionMenu(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlPromotionMenu')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlPromotion')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSectionAllocation')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlPromotion')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSectionAllocation')).checked = false;
        }
    }
    onChangeChildElementPromotionMenu(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlPromotion')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSectionAllocation')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlPromotionMenu')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlPromotionMenu')).checked = false;
        }
    }
    onChangeFees(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlFees')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlfeeCollection')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlfeeAllocation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlBusFeeAllocation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDiscountFixing')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlArrearsReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStandardTermWiseReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlRefund')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlIndiFeeCollReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDaywiseRefundReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlBusStudentsReport')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlfeeCollection')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlfeeAllocation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlBusFeeAllocation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDiscountFixing')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlArrearsReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStandardTermWiseReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlRefund')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlIndiFeeCollReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDaywiseRefundReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlBusStudentsReport')).checked = false;

        }
    }
    onChangeChildElementFees(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlfeeAllocation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlfeeCollection')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlBusFeeAllocation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlDiscountFixing')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlArrearsReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStandardTermWiseReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlRefund')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlIndiFeeCollReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlDaywiseRefundReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlBusStudentsReport')).checked
        ) {
            (<HTMLInputElement>document.getElementById('ctrlFees')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlFees')).checked = false;
        }
    }

    onChangeChildElementSMS(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlSmsCreation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSMSMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSmsTemplateMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSmsContentSettings')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlAbsentList')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlFeeAllocationSms')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlResultPostingSms')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlSMS')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlSMS')).checked = false;
        }
    }
    onChangeAccounts(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlAccounts')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlJournalEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlPayment')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlReceiptEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlLedgerMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEntryJournal')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEntryPayment')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEntryReceipt')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlLedgerStmnt')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlOverAllAccounts')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCashBook')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlIncomeExpenditure')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlTrialBalance')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlJournalEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlPayment')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlReceiptEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlLedgerMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEntryJournal')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEntryPayment')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEntryReceipt')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlLedgerStmnt')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlOverAllAccounts')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCashBook')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlIncomeExpenditure')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlTrialBalance')).checked = false;
        }
    }
    onChangeChildElementAccounts(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEntryJournal')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEntryPayment')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEntryReceipt')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlJournalEntry')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlPayment')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlReceiptHead')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlReceiptEntry')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlLedgerStmnt')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlOverAllAccounts')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCashBook')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlIncomeExpenditure')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlTrialBalance')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlAccounts')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlAccounts')).checked = false;
        }
    }
    onChangeReports(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlReports')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlGenderWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlClassWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCommunityWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlPhysicallyDisabled')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStrengthWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudentMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudMarkList')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlExamReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlTcGenerate')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlTcGenerateReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudMarkSheet')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlGenderWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlClassWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCommunityWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlPhysicallyDisabled')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStrengthWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudentMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudMarkList')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlExamReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlTcGenerate')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlTcGenerateReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudMarkSheet')).checked = false;

        }
    }
    onChangeChildElementReports(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlGenderWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlClassWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCommunityWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlPhysicallyDisabled')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStrengthWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudentMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudMarkList')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlExamReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlTcGenerate')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlTcGenerateReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlStudMarkSheet')).checked
        ) {
            (<HTMLInputElement>document.getElementById('ctrlReports')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlReports')).checked = false;
        }
    }
    onChangeChildElementCertificate(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlAttendanceGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlBonafideGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlConductGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCourseGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlGenuineGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCertificateReprint')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlAttendanceRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlBonafideRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlConductRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlCourseRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlGenuineRep')).checked
        ) {
            (<HTMLInputElement>document.getElementById('ctrlCertificate')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlCertificate')).checked = false;
        }
    }
    onChangeCertificate(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlCertificate')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlAttendanceGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlBonafideGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlConductGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCourseGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlGenuineGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCertificateReprint')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlAttendanceRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlBonafideRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlConductRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlCourseRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlGenuineRep')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlAttendanceGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlBonafideGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlConductGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCourseGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlGenuineGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCertificateReprint')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlAttendanceRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlBonafideRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlConductRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlCourseRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlGenuineRep')).checked = false;

        }
    }
    onChangeChildElementAdmin(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlRoleMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlUserCreation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlUserProfile')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlAccountMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlAcademicSetting')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlSmsSetting')).checked &&
             (<HTMLInputElement>document.getElementById('ctrlUserSettings')).checked ) {
            (<HTMLInputElement>document.getElementById('ctrlAdmin')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlAdmin')).checked = false;
        }
    }
    onChangeAdmin(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlAdmin')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlRoleMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlUserCreation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlUserProfile')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlAccountMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlAcademicSetting')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlSmsSetting')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlUserSettings')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlRoleMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlUserCreation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlUserProfile')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlAccountMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlAcademicSetting')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlSmsSetting')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlUserSettings')).checked = false;
        }
    }
    onChangeAttendance(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlAttendance')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlAttendanceEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlNotesEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEventMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlLeaveApplication')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlStudentRemarks')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlDayWiseAttendanceEntryReport')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlAttendanceEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlNotesEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEventMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlLeaveApplication')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlStudentRemarks')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlDayWiseAttendanceEntryReport')).checked = false;

        }
    }
    onChangeChildElementAtendance(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlAttendanceEntry')).checked
            && (<HTMLInputElement>document.getElementById('ctrlDayWiseAttendanceEntryReport')).checked && (<HTMLInputElement>document.getElementById('ctrlNotesEntry')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlAttendance')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlAttendance')).checked = false;
        }
    }


    onChangeRegisterEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditRegister')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegister')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterEdit')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterPrint')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegister')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterEdit')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterPrint')).checked = false;
        }
    }
    onChangeChildElementRegisterEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegister')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterPrint')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRegisterEdit')).checked
        ) {
            (<HTMLInputElement>document.getElementById('ctrlEditRegister')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditRegister')).checked = false;
        }
    }
    onChangeMasterEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditMaster')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditStaffMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditDepartmentMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSectionMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditClassMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSubjectMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCasteMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeType')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeStructure')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptHead')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditExamMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditHolidayMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditHoursMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditNotesMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditAreaMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountTypeMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditExamScheduleMaster')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditStaffMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDepartmentMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSectionMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditClassMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSubjectMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCasteMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeType')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeStructure')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptHead')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditExamMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditHolidayMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditHoursMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditNotesMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditAreaMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountTypeMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditExamScheduleMaster')).checked = false;
        }
    }

    onChangeChildElementMasterEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStaffMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditDepartmentMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSectionMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditClassMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSubjectMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCasteMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityMaster')).checked
            && (<HTMLInputElement>document.getElementById('ctrlEditFeeType')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditFeeStructure')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptHead')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditExamMaster')).checked
            && (<HTMLInputElement>document.getElementById('ctrlEditHolidayMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditHoursMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditNotesMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAreaMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountTypeMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditExamScheduleMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRemarks')).checked 
        ) {
            (<HTMLInputElement>document.getElementById('ctrlEditMaster')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditMaster')).checked = false;
        }
    }

    onChangeSMSEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditSMS')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditSmsCreation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMasterEdit')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSMSMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsTemplateMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsContentSettings')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditAbsentList')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeAllocationSms')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditResultPostingSms')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditSmsCreation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMasterEdit')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSMSMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsTemplateMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsContentSettings')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditAbsentList')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditFeeAllocationSms')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditResultPostingSms')).checked = false;
        }
    }

    onChangeChildElementSMSEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsCreation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsMasterEdit')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSMSMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsTemplateMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsContentSettings')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAbsentList')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditFeeAllocationSms')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditResultPostingSms')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditSMS')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditSMS')).checked = false;
        }
    }
    onChangePromotionMenuEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditPromotionMenu')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditPromotion')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSectionAllocation')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditPromotion')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSectionAllocation')).checked = false;
        }
    }
    onChangeFeesEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditFees')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditfeeCollection')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditBusFeeAllocation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditfeeAllocation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountFixing')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditArearsReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStandardTermWiseReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditRefund')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditIndiFeeCollReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditDaywiseRefundReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditBusStudentsReport')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlfeeCollection')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditfeeAllocation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditBusFeeAllocation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountFixing')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditArearsReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStandardTermWiseReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditRefund')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditIndiFeeCollReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDaywiseRefundReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditBusStudentsReport')).checked = false;
        }
    }
    onChangeChildElementFeesEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditFees')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditfeeCollection')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditBusFeeAllocation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditDiscountFixing')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditArearsReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStandardTermWiseReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditRefund')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditIndiFeeCollReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditDaywiseRefundReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditBusStudentsReport')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditFees')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditFees')).checked = false;
        }
    }
    onChangeChildElementPromotionMenuEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSectionAllocation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditPromotion')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditPromotionMenu')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditPromotionMenu')).checked = false;
        }
    }
    onChangeAccountsEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditAccounts')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditJournalEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditPayment')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditEntryJournal')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrleditEntryPayment')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditEntryReceipt')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerStmnt')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditOverAllAccounts')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCashBook')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditIncomeExpenditure')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditTrialBalance')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditJournalEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditPayment')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditEntryJournal')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrleditEntryPayment')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditEntryReceipt')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerStmnt')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditOverAllAccounts')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCashBook')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditIncomeExpenditure')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditTrialBalance')).checked = false;
        }
    }
    onChangeChildElementAccountsEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditJournalEntry')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditPayment')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditReceiptEntry')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditEntryJournal')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditEntryReceipt')).checked &&
            (<HTMLInputElement>document.getElementById('ctrleditEntryPayment')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditLedgerStmnt')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditOverAllAccounts')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCashBook')).checked && 
            (<HTMLInputElement>document.getElementById('ctrlEditIncomeExpenditure')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditTrialBalance')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditAccounts')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditAccounts')).checked = false;
        }
    }
    onChangeReportsEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditReports')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditGenderWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditClassWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditPhysicallyDisabled')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStrengthWise')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkList')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudExamReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerate')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerateReport')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkSheet')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditGenderWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditClassWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditPhysicallyDisabled')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStrengthWise')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkList')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudExamReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerate')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerateReport')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkSheet')).checked = false;
        }
    }


    onChangeChildElementReportsEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditGenderWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditClassWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCommunityWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStrengthWise')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditPhysicallyDisabled')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudentMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkList')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudExamReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerate')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditTcGenerateReport')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditStudMarkSheet')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditReports')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditReports')).checked = false;
        }
    }
    onChangeChildElementEditCertificate(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditConductGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCourseGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineGen')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCertificateReprint')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditConductRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditCourseRep')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineRep')).checked
        ) {
            (<HTMLInputElement>document.getElementById('ctrlEditCertificate')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditCertificate')).checked = false;
        }
    }
    onChangeEditCertificate(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditCertificate')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditConductGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCourseGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineGen')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCertificateReprint')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditConductRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditCourseRep')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineRep')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditConductGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCourseGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineGen')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCertificateReprint')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditBonafideRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditConductRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditCourseRep')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditGenuineRep')).checked = false;

        }
    }
    onChangeAdminEdit(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditAdmin')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditRoleMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditUserCreation')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditUserProfile')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditAccountMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditAcademicSetting')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsSetting')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditUserSettings')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditRoleMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditUserCreation')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditUserProfile')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditAccountMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditAcademicSetting')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditSmsSetting')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditUserSettings')).checked = false;
        }
    }

    onChangeChildElementAdminEdit(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditRoleMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditUserCreation')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditUserProfile')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAccountMaster')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAcademicSetting')).checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditSmsSetting')).checked &&
             (<HTMLInputElement>document.getElementById('ctrlEditUserSettings')).checked ) {
            (<HTMLInputElement>document.getElementById('ctrlEditAdmin')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditAdmin')).checked = false;
        }
    }
    onChangeEditAttendance(eventChange) {
        if (eventChange.checked &&
            (<HTMLInputElement>document.getElementById('ctrlEditAttendance')).checked) {
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditnotesEntry')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditEventMaster')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditLeaveApplication')).checked = true;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRemarks')).checked =true;
            (<HTMLInputElement>document.getElementById('ctrlEditDayWiseAttendanceEntryReport')).checked = true;

        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditAttendanceEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditnotesEntry')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditEventMaster')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditLeaveApplication')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditStudentRemarks')).checked = false;
            (<HTMLInputElement>document.getElementById('ctrlEditDayWiseAttendanceEntryReport')).checked = false;

        }
    }
    onChangeChildElementEditAtendance(eventChange) {
        if (eventChange.checked && (<HTMLInputElement>document.getElementById('ctrlEditAttendanceEntry')).checked
            && (<HTMLInputElement>document.getElementById('ctrlEditDayWiseAttendanceEntryReport')).checked && (<HTMLInputElement>document.getElementById('ctrlEditnotesEntry')).checked
            && (<HTMLInputElement>document.getElementById('ctrlEditEventMaster')).checked && (<HTMLInputElement>document.getElementById('ctrlEditLeaveApplication')).checked && (<HTMLInputElement>document.getElementById('ctrlEditStudentRemarks')).checked ){
            (<HTMLInputElement>document.getElementById('ctrlEditAttendance')).checked = true;
        } else {
            (<HTMLInputElement>document.getElementById('ctrlEditAttendance')).checked = false;
        }
    }
    addRole() {
        if ((<HTMLInputElement>document.getElementById('ctrlMaster')).checked) this.addRoleFunction.master = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudentRegister')).checked) this.addRoleFunction.studentRegister = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudentRegisterEdit')).checked) this.addRoleFunction.editStudentRegister = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStaffMaster')).checked) this.addRoleFunction.staffMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDepartmentMaster')).checked) this.addRoleFunction.departmentMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSectionMaster')).checked) this.addRoleFunction.sectionMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlClassMaster')).checked) this.addRoleFunction.classMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudentMaster')).checked) this.addRoleFunction.studentMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSMS')).checked) this.addRoleFunction.sms = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSmsCreation')).checked) this.addRoleFunction.smsCreation = true;
        if ((<HTMLInputElement>document.getElementById('ctrlEditSmsMaster')).checked) this.addRoleFunction.editSmsMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSMSMaster')).checked) this.addRoleFunction.smsMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSmsTemplateMaster')).checked) this.addRoleFunction.smsTemplateMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAdmin')).checked) this.addRoleFunction.admin = true;
        if ((<HTMLInputElement>document.getElementById('ctrlRoleMaster')).checked) this.addRoleFunction.roleMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlUserCreation')).checked) this.addRoleFunction.userCreation = true;
        if ((<HTMLInputElement>document.getElementById('ctrlUserProfile')).checked) this.addRoleFunction.changePassword = true;
        if ((<HTMLInputElement>document.getElementById('ctrlUserSettings')).checked) this.addRoleFunction.userSettings = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAccounts')).checked) this.addRoleFunction.accounts = true;
        if ((<HTMLInputElement>document.getElementById('ctrlJournalEntry')).checked) this.addRoleFunction.journalentry = true;
        if ((<HTMLInputElement>document.getElementById('ctrlLedgerMaster')).checked) this.addRoleFunction.ledgercreation = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAccountMaster')).checked) this.addRoleFunction.accountmaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlPayment')).checked) this.addRoleFunction.payment = true;
        if ((<HTMLInputElement>document.getElementById('ctrlReceiptEntry')).checked) this.addRoleFunction.receiptentry = true;
        if ((<HTMLInputElement>document.getElementById('ctrlReceiptHead')).checked) this.addRoleFunction.receipthead = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSubjectMaster')).checked) this.addRoleFunction.subjectmaster = true;
        // if ( ( <HTMLInputElement>document.getElementById( 'ctrlReceiptDetails' ) ).checked ) this.addRoleFunction.receiptDetails = true;
        if ((<HTMLInputElement>document.getElementById('ctrlQuickAdmission')).checked) this.addRoleFunction.quickAdmission = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAcademicSetting')).checked) this.addRoleFunction.academicSetting = true;
        if ((<HTMLInputElement>document.getElementById('ctrlGenderWise')).checked) this.addRoleFunction.genderwise = true;
        if ((<HTMLInputElement>document.getElementById('ctrlClassWise')).checked) this.addRoleFunction.classwise = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCommunityWise')).checked) this.addRoleFunction.communitywise = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudentRegisterPrint')).checked) this.addRoleFunction.ptrintstudentmaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlPhysicallyDisabled')).checked) this.addRoleFunction.physicallydisabled = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStrengthWise')).checked) this.addRoleFunction.strength = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCasteMaster')).checked) this.addRoleFunction.castemaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCommunityMaster')).checked) this.addRoleFunction.communitymaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlPromotion')).checked) this.addRoleFunction.promotion = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSectionAllocation')).checked) this.addRoleFunction.sectionallocation = true;
        if ((<HTMLInputElement>document.getElementById('ctrlfeeCollection')).checked) this.addRoleFunction.feecollection = true;
        if ((<HTMLInputElement>document.getElementById('ctrlfeeAllocation')).checked) this.addRoleFunction.feecollection = true;
        if ((<HTMLInputElement>document.getElementById('ctrlFeeType')).checked) this.addRoleFunction.term = true;
        if ((<HTMLInputElement>document.getElementById('ctrlFeeStructure')).checked) this.addRoleFunction.standardstructure = true;
        if ((<HTMLInputElement>document.getElementById('ctrlEntryJournal')).checked) this.addRoleFunction.entryJournal = true;
        if ((<HTMLInputElement>document.getElementById('ctrlEntryPayment')).checked) this.addRoleFunction.entryPayment = true;
        if ((<HTMLInputElement>document.getElementById('ctrlEntryReceipt')).checked) this.addRoleFunction.entryReceipt = true;
        if ((<HTMLInputElement>document.getElementById('ctrlLedgerStmnt')).checked) this.addRoleFunction.ledgerStatement = true;
        if ((<HTMLInputElement>document.getElementById('ctrlExamMaster')).checked) this.addRoleFunction.examMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlHolidayMaster')).checked) this.addRoleFunction.holidayList = true;
        if ((<HTMLInputElement>document.getElementById('ctrlHoursMaster')).checked) this.addRoleFunction.hoursMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlArrearsReport')).checked) this.addRoleFunction.arrearsReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAttendanceEntry')).checked) this.addRoleFunction.attendanceEntry = true;
        if ((<HTMLInputElement>document.getElementById('ctrlNotesMaster')).checked) this.addRoleFunction.notesMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlNotesEntry')).checked) this.addRoleFunction.notesEntry = true;
        if ((<HTMLInputElement>document.getElementById('ctrlEventMaster')).checked) this.addRoleFunction.event = true;
        if ((<HTMLInputElement>document.getElementById('ctrlLeaveApplication')).checked) this.addRoleFunction.leaveApplication = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudentRemarks')).checked) this.addRoleFunction.studentRemarks = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDayWiseAttendanceEntryReport')).checked) this.addRoleFunction.daywiseAttendanceEntry = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDiscountMaster')).checked) this.addRoleFunction.discountMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStandardTermWiseReport')).checked) this.addRoleFunction.standardTermWiseReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDiscountFixing')).checked) this.addRoleFunction.discountFixing = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAreaMaster')).checked) this.addRoleFunction.areaMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDiscountTypeMaster')).checked) this.addRoleFunction.discountTypeMaster = true;
        if ((<HTMLInputElement>document.getElementById('ctrlExamScheduleMaster')).checked) this.addRoleFunction.examSchedule = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudMarkList')).checked) this.addRoleFunction.studentMarkList = true;
        if ((<HTMLInputElement>document.getElementById('ctrlExamReport')).checked) this.addRoleFunction.studExamReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlBusFeeAllocation')).checked) this.addRoleFunction.busFeeAllocation = true;
        if ((<HTMLInputElement>document.getElementById('ctrlTcGenerate')).checked) this.addRoleFunction.tcGeneration = true;
        if ((<HTMLInputElement>document.getElementById('ctrlTcGenerateReport')).checked) this.addRoleFunction.tcGenerationReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlStudMarkSheet')).checked) this.addRoleFunction.examMarkSheet = true;
        if ((<HTMLInputElement>document.getElementById('ctrlRefund')).checked) this.addRoleFunction.refund = true;
        if ((<HTMLInputElement>document.getElementById('ctrlOverAllAccounts')).checked) this.addRoleFunction.overallAccounts = true;
        if ((<HTMLInputElement>document.getElementById('ctrlIndiFeeCollReport')).checked) this.addRoleFunction.feeCollectionDetails = true;
        if ((<HTMLInputElement>document.getElementById('ctrlDaywiseRefundReport')).checked) this.addRoleFunction.refundReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSmsSetting')).checked) this.addRoleFunction.smsSettings = true;
        if ((<HTMLInputElement>document.getElementById('ctrlSmsContentSettings')).checked) this.addRoleFunction.smsContentSettings = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAbsentList')).checked) this.addRoleFunction.absentList = true;
        if ((<HTMLInputElement>document.getElementById('ctrlFeeAllocationSms')).checked) this.addRoleFunction.feeAllocationSms = true;
        if ((<HTMLInputElement>document.getElementById('ctrlBusStudentsReport')).checked) this.addRoleFunction.busStudentsReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAttendanceGen')).checked) this.addRoleFunction.attendanceGen = true;
        if ((<HTMLInputElement>document.getElementById('ctrlBonafideGen')).checked) this.addRoleFunction.bonafideGen = true;
        if ((<HTMLInputElement>document.getElementById('ctrlConductGen')).checked) this.addRoleFunction.conductGen = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCourseGen')).checked) this.addRoleFunction.courseGen = true;
        if ((<HTMLInputElement>document.getElementById('ctrlGenuineGen')).checked) this.addRoleFunction.genuineGen = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCertificateReprint')).checked) this.addRoleFunction.certificateReprint = true;
        if ((<HTMLInputElement>document.getElementById('ctrlAttendanceRep')).checked) this.addRoleFunction.attendanceReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlBonafideRep')).checked) this.addRoleFunction.bonafideReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlConductRep')).checked) this.addRoleFunction.conductReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCourseRep')).checked) this.addRoleFunction.courseReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlGenuineRep')).checked) this.addRoleFunction.genuineReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlResultPostingSms')).checked) this.addRoleFunction.resultPostingSms = true;
        if ((<HTMLInputElement>document.getElementById('ctrlCashBook')).checked) this.addRoleFunction.cashBookReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlIncomeExpenditure')).checked) this.addRoleFunction.incomeExpenditureReport = true;
        if ((<HTMLInputElement>document.getElementById('ctrlTrialBalance')).checked) this.addRoleFunction.trialbalance = true;


        if (this.addRoleFunction.staffMaster == true && this.addRoleFunction.castemaster == true &&
            this.addRoleFunction.communitymaster == true && this.addRoleFunction.departmentMaster == true &&
            this.addRoleFunction.subjectmaster == true && this.addRoleFunction.sectionMaster == true &&
            this.addRoleFunction.classMaster == true &&
            this.addRoleFunction.term == true && this.addRoleFunction.standardstructure == true &&
            this.addRoleFunction.receipthead == true && this.addRoleFunction.holidayList == true &&
            this.addRoleFunction.hoursMaster == true
            && this.addRoleFunction.examMaster == true && this.addRoleFunction.notesMaster == true
            && this.addRoleFunction.discountMaster == true && this.addRoleFunction.areaMaster == true
            && this.addRoleFunction.discountTypeMaster == true && this.addRoleFunction.examSchedule == true) {
            this.addRoleFunction.master = true;
        }
        if (this.addRoleFunction.ptrintstudentmaster == true && this.addRoleFunction.editStudentRegister == true &&
            this.addRoleFunction.studentRegister == true) {
            this.addRoleFunction.register = true;
        }
        if (this.addRoleFunction.smsCreation == true && this.addRoleFunction.editSmsMaster == true
            && this.addRoleFunction.smsMaster == true && this.addRoleFunction.smsTemplateMaster == true &&
            this.addRoleFunction.smsContentSettings == true && this.addRoleFunction.absentList == true &&
            this.addRoleFunction.feeAllocationSms == true && this.addRoleFunction.resultPostingSms == true) {
            this.addRoleFunction.sms = true;
        }
        if (this.addRoleFunction.promotion == true && this.addRoleFunction.sectionallocation == true) {
            this.addRoleFunction.promotionmenu = true;
        } if (this.addRoleFunction.attendanceEntry == true && this.addRoleFunction.daywiseAttendanceEntry == true && this.addRoleFunction.notesEntry == true && this.addRoleFunction.event ==true && this.addRoleFunction.leaveApplication ==true  && this.addRoleFunction.studentRemarks == true) {
            this.addRoleFunction.attendance = true;
        }
        if (this.addRoleFunction.feecollection == true && this.addRoleFunction.feeallocation == true
            && this.addRoleFunction.busFeeAllocation == true
            && this.addRoleFunction.discountFixing == true && this.addRoleFunction.arrearsReport == true
            && this.addRoleFunction.standardTermWiseReport == true && this.addRoleFunction.refund == true
            && this.addRoleFunction.refundReport == true && this.addRoleFunction.feeCollectionDetails == true
            && this.addRoleFunction.busStudentsReport == true
        ) {
            this.addRoleFunction.fees = true;
        }
        if (this.addRoleFunction.entryJournal == true && this.addRoleFunction.entryPayment == true
            && this.addRoleFunction.entryReceipt == true && this.addRoleFunction.journalentry == true &&
            this.addRoleFunction.payment == true && this.addRoleFunction.ledgercreation == true &&
            this.addRoleFunction.ledgerStatement == true && this.addRoleFunction.receiptentry == true &&
            this.addRoleFunction.studentMarkList == true && this.addRoleFunction.studExamReport == true
            && this.addRoleFunction.overallAccounts == true && this.addRoleFunction.cashBookReport == true
            && this.addRoleFunction.incomeExpenditureReport == true && this.addRoleFunction.trialbalance == true) {
            this.addRoleFunction.accounts = true;
        }
        if (this.addRoleFunction.genderwise == true &&
            this.addRoleFunction.classwise == true && this.addRoleFunction.studentMaster == true &&
            this.addRoleFunction.physicallydisabled == true && this.addRoleFunction.strength == true &&
            this.addRoleFunction.communitywise == true && this.addRoleFunction.tcGeneration == true &&
            this.addRoleFunction.tcGenerationReport == true && this.addRoleFunction.examMarkSheet == true
        ) {
            this.addRoleFunction.Reports = true;
        }
        if (this.addRoleFunction.roleMaster == true && this.addRoleFunction.academicSetting == true
            && this.addRoleFunction.accountmaster == true && this.addRoleFunction.userCreation == true && this.addRoleFunction.userSettings ==true
            && this.addRoleFunction.changePassword == true && this.addRoleFunction.smsSettings == true) {
            this.addRoleFunction.admin = true;
        }
        if (this.addRoleFunction.attendanceGen == true && this.addRoleFunction.bonafideGen == true
            && this.addRoleFunction.conductGen == true && this.addRoleFunction.courseGen == true
            && this.addRoleFunction.genuineGen == true && this.addRoleFunction.certificateReprint == true &&
            this.addRoleFunction.attendanceReport == true && this.addRoleFunction.bonafideReport == true
            && this.addRoleFunction.conductReport == true && this.addRoleFunction.courseReport == true
            && this.addRoleFunction.genuineReport == true) {
            this.addRoleFunction.certificates = true;
        }

        this.saveLoading = true;
        let d = new MRolePojo();
        d.accId = this.loggedUser.accId;
        d.userRole = (<HTMLInputElement>document.getElementById('roleName')).value;
        d.roleKey = JSON.stringify(this.addRoleFunction);
        this.roleMasterService.addMRole(d).subscribe(response => {
            bootbox.alert({ message: 'Role has been successfully created.', className: 'text-success h5', closeButton: false });
            (<HTMLFormElement>document.getElementById('addRole')).reset();
            this.saveLoading = false;
            $('#addModal').modal('hide');
            this.getMRoleAll();
        }, error => {
            bootbox.alert({ message: 'Add M Role Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.saveLoading = false;
        });
    }

    clickEdit(arg) {
        this.id = arg;
        const i = this.roleMasterArr.indexOf(this.roleMasterArr.find(r => r.roleId == this.id));
        (<HTMLFormElement>document.getElementById('editRoleName')).value = this.roleMasterArr[i].userRole;
        this.editRoleFunction = JSON.parse(this.roleMasterArr[i].roleKey);
    }

    updateRole() {
        if ((<HTMLInputElement>document.getElementById('ctrlEditMaster')).checked) {
            this.editRoleFunction.master = true;
            this.editRoleFunction.staffMaster = true;
            this.editRoleFunction.departmentMaster = true;
            this.editRoleFunction.sectionMaster = true;
            this.editRoleFunction.classMaster = true;
            this.editRoleFunction.subjectmaster = true;
            this.editRoleFunction.castemaster = true;
            this.editRoleFunction.communitymaster = true;
            this.editRoleFunction.term = true;
            this.editRoleFunction.standardstructure = true;
            this.editRoleFunction.receipthead = true;
            this.editRoleFunction.examMaster = true;
            this.editRoleFunction.holidayList = true;
            this.editRoleFunction.hoursMaster = true;
            this.editRoleFunction.notesMaster = true;
            this.editRoleFunction.discountMaster = true;
            this.editRoleFunction.areaMaster = true;
            this.editRoleFunction.discountTypeMaster = true;
            this.editRoleFunction.examSchedule = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditRegister')).checked) {
            this.editRoleFunction.studentRegister = true;
            this.editRoleFunction.editStudentRegister = true;
            this.editRoleFunction.ptrintstudentmaster = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditPromotionMenu')).checked) {
            this.editRoleFunction.promotion = true;
            this.editRoleFunction.sectionallocation = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditFees')).checked) {
            this.editRoleFunction.feecollection = true;
            this.editRoleFunction.feeallocation = true;
            this.editRoleFunction.busFeeAllocation = true;
            this.editRoleFunction.discountFixing = true;
            this.editRoleFunction.arrearsReport = true;
            this.editRoleFunction.standardTermWiseReport = true;
            this.editRoleFunction.refund = true;
            this.editRoleFunction.feeCollectionDetails = true;
            this.editRoleFunction.refundReport = true;
            this.editRoleFunction.busStudentsReport = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditSMS')).checked) {
            this.editRoleFunction.sms = true;
            this.editRoleFunction.smsCreation = true;
            this.editRoleFunction.editSmsMaster = true;
            this.editRoleFunction.smsMaster = true;
            this.editRoleFunction.smsTemplateMaster = true;
            this.editRoleFunction.smsContentSettings = true;
            this.editRoleFunction.absentList = true;
            this.editRoleFunction.feeAllocationSms = true;
            this.editRoleFunction.resultPostingSms = true;
        }

        if ((<HTMLInputElement>document.getElementById('ctrlEditAdmin')).checked) {
            this.editRoleFunction.admin = true;
            this.editRoleFunction.roleMaster = true;
            this.editRoleFunction.userCreation = true;
            this.editRoleFunction.userSettings=true;
            this.editRoleFunction.changePassword = true;
            this.editRoleFunction.accountmaster = true;
            this.editRoleFunction.academicSetting = true;
            this.editRoleFunction.smsSettings = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditAccounts')).checked) {
            this.editRoleFunction.journalentry = true;
            this.editRoleFunction.payment = true;
            this.editRoleFunction.receiptentry = true;
            this.editRoleFunction.entryJournal = true;
            this.editRoleFunction.entryReceipt = true;
            this.editRoleFunction.entryPayment = true;
            this.editRoleFunction.ledgercreation = true;
            this.editRoleFunction.ledgerStatement = true;
            this.editRoleFunction.overallAccounts = true;
            this.editRoleFunction.cashBookReport = true;
            this.editRoleFunction.incomeExpenditureReport = true;
            this.editRoleFunction.trialbalance = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditAttendance')).checked) {
            this.editRoleFunction.attendanceEntry = true;
            this.editRoleFunction.daywiseAttendanceEntry = true;
            this.editRoleFunction.notesEntry = true;
            this.editRoleFunction.event =true;
            this.editRoleFunction.leaveApplication =true;
            this.editRoleFunction.studentRemarks = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditReports')).checked) {
            this.editRoleFunction.genderwise = true;
            this.editRoleFunction.classwise = true;
            this.editRoleFunction.communitywise = true;
            this.editRoleFunction.physicallydisabled = true;
            this.editRoleFunction.strength = true;
            this.editRoleFunction.studentMaster = true;
            this.editRoleFunction.standardTermWiseReport = true;
            this.editRoleFunction.studentMarkList = true;
            this.editRoleFunction.studExamReport = true;
            this.editRoleFunction.tcGeneration = true;
            this.editRoleFunction.tcGenerationReport = true;
            this.editRoleFunction.examMarkSheet = true;
        }
        if ((<HTMLInputElement>document.getElementById('ctrlEditCertificate')).checked) {
            this.editRoleFunction.attendanceGen = true;
            this.editRoleFunction.bonafideGen = true;
            this.editRoleFunction.conductGen = true;
            this.editRoleFunction.courseGen = true;
            this.editRoleFunction.genuineGen = true;
            this.editRoleFunction.certificateReprint = true;
            this.editRoleFunction.attendanceReport = true;
            this.editRoleFunction.bonafideReport = true;
            this.editRoleFunction.conductReport = true;
            this.editRoleFunction.courseReport = true;
            this.editRoleFunction.genuineReport = true;
        }

        if (this.editRoleFunction.staffMaster == true && this.editRoleFunction.castemaster == true
            && this.editRoleFunction.term == true && this.editRoleFunction.standardstructure == true
            && this.editRoleFunction.communitymaster == true && this.editRoleFunction.examMaster == true
            && this.editRoleFunction.receipthead == true
            && this.editRoleFunction.subjectmaster == true && this.editRoleFunction.departmentMaster == true &&
            this.editRoleFunction.sectionMaster == true && this.editRoleFunction.classMaster == true &&
            this.editRoleFunction.holidayList == true && this.editRoleFunction.hoursMaster &&
            this.editRoleFunction.notesMaster && this.editRoleFunction.discountMaster &&
            this.editRoleFunction.areaMaster && this.editRoleFunction.discountTypeMaster && this.editRoleFunction.examSchedule) {
            this.editRoleFunction.master = true;
        }

        if (this.editRoleFunction.smsCreation == true && this.editRoleFunction.editSmsMaster == true
            && this.editRoleFunction.smsMaster == true && this.editRoleFunction.smsTemplateMaster == true
            && this.editRoleFunction.smsContentSettings == true && this.editRoleFunction.absentList == true
            && this.editRoleFunction.feeAllocationSms == true && this.editRoleFunction.resultPostingSms == true) {
            this.editRoleFunction.sms = true;
        }
        if (this.editRoleFunction.promotion == true && this.editRoleFunction.sectionallocation == true) {
            this.editRoleFunction.promotionmenu = true;
        }
        if (this.editRoleFunction.feeallocation == true && this.editRoleFunction.feecollection == true
            && this.editRoleFunction.discountFixing == true && this.editRoleFunction.arrearsReport == true
            && this.editRoleFunction.standardTermWiseReport == true && this.editRoleFunction.busFeeAllocation == true
            && this.editRoleFunction.refund == true && this.editRoleFunction.feeCollectionDetails == true &&
            this.editRoleFunction.refundReport == true && this.editRoleFunction.busStudentsReport == true) {
            this.editRoleFunction.fees = true;
        }
        if (this.editRoleFunction.classwise == true && this.editRoleFunction.genderwise == true &&
            this.editRoleFunction.physicallydisabled == true && this.editRoleFunction.strength == true &&
            this.editRoleFunction.communitywise == true && this.editRoleFunction.studentMaster == true &&
            this.editRoleFunction.studentMarkList == true && this.editRoleFunction.studExamReport == true
            && this.editRoleFunction.tcGeneration == true && this.editRoleFunction.tcGenerationReport == true &&
            this.editRoleFunction.examMarkSheet == true
        ) {
            this.editRoleFunction.Reports = true;
        }
        if (this.editRoleFunction.studentRegister == true && this.editRoleFunction.editStudentRegister == true
            && this.editRoleFunction.ptrintstudentmaster == true) {
            this.editRoleFunction.register = true;
        }
        if (this.editRoleFunction.roleMaster == true && this.editRoleFunction.academicSetting == true
            && this.editRoleFunction.accountmaster == true && this.editRoleFunction.userCreation == true && this.editRoleFunction.userSettings==true
            && this.editRoleFunction.changePassword == true && this.editRoleFunction.smsSettings == true) {
            this.editRoleFunction.admin = true;
        }
        if (this.editRoleFunction.entryJournal == true && this.editRoleFunction.entryPayment == true &&
            this.editRoleFunction.entryReceipt == true && this.editRoleFunction.journalentry == true &&
            this.editRoleFunction.payment == true && this.editRoleFunction.ledgercreation == true &&
            this.editRoleFunction.receiptentry == true && this.editRoleFunction.ledgerStatement == true
            && this.editRoleFunction.overallAccounts == true && this.editRoleFunction.cashBookReport == true &&
           this.editRoleFunction.incomeExpenditureReport == true && this.editRoleFunction.trialbalance == true) {
            this.editRoleFunction.accounts = true;
        } if (this.editRoleFunction.attendanceEntry == true && this.editRoleFunction.daywiseAttendanceEntry == true && this.editRoleFunction.notesEntry == true && this.editRoleFunction.event == true && this.editRoleFunction.leaveApplication == true  && this.editRoleFunction.studentRemarks == true) {
            this.editRoleFunction.attendance = true;
        }
        if (this.editRoleFunction.attendanceGen == true && this.editRoleFunction.bonafideGen == true
            && this.editRoleFunction.conductGen == true && this.editRoleFunction.courseGen == true
            && this.editRoleFunction.genuineGen == true && this.editRoleFunction.certificateReprint == true &&
            this.editRoleFunction.attendanceReport == true && this.editRoleFunction.bonafideReport == true
            && this.editRoleFunction.conductReport == true && this.editRoleFunction.courseReport == true
            && this.editRoleFunction.genuineReport == true) {
            this.editRoleFunction.certificates = true;
        }
        this.saveLoading = true;
        let d = new MRolePojo();
        const i = this.roleMasterArr.indexOf(this.roleMasterArr.find(r => r.roleId == this.id));
        d.roleId = this.roleMasterArr[i].roleId;
        d.userRole = (<HTMLFormElement>document.getElementById('editRoleName')).value;
        d.roleKey = JSON.stringify(this.editRoleFunction);
        d.userId = 1;
        d.accId = this.loggedUser.accId;
        this.roleMasterService.updateMRole(d).subscribe(response => {
            bootbox.alert({ message: 'Role has been successfully updated.', className: 'text-success h5', closeButton: false });
            this.saveLoading = false;
            $('#editModal').modal('hide');

           this.getMRoleAll();
        }, error => {
            bootbox.alert({ message: 'Update M Role Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.saveLoading = false;
        });
    }

}
