
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { TermPojo } from 'src/app/pojo/term-pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { TermMasterService } from './term.service';
declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: ['./term.component.scss']
})

export class TermComponent implements OnInit {
    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;
    
    addFromDate: NgbDateStruct;
    addToDate: NgbDateStruct;
    editFromDate: NgbDateStruct;
    editToDate: NgbDateStruct;
    loggedUser = new MUserMasterPojo();

    
    feeTypeArr: TermPojo[] = [];
    addFee = new TermPojo();
    editFee = new TermPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;

    constructor( private feeTypemasterserivce: TermMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
          this.getFeeTypeAll();
    }

    getFeeTypeAll() {
        this.isLoading = true;
        this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
            this.feeTypeArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }

    
    add() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addFee.termFrom = moment( this.addFromDate.year + '-' + this.addFromDate.month + '-' + this.addFromDate.day, 'YYYY-MM-DD' ).toDate();
        this.addFee.termTo = moment( this.addToDate.year + '-' + this.addToDate.month + '-' + this.addToDate.day, 'YYYY-MM-DD' ).toDate();
            this.addFee.accId=this.loggedUser.accId;
        this.feeTypemasterserivce.addTerm( this.addFee ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editFee = Object.assign( {}, arg );
        if(this.editFee.termFrom != null && this.editFee.termFrom != undefined){
            ( <HTMLFormElement>document.getElementById( 'editfromDate' ) ).value = this.editFee.termFrom;
            }
            if(this.editFee.termTo != null && this.editFee.termTo != undefined){
                ( <HTMLFormElement>document.getElementById( 'edittoDate' ) ).value = this.editFee.termTo;
                }
    }  

    edit() {
           ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.editFee.termFrom = moment(( <HTMLInputElement>document.getElementById( 'editfromDate' ) ).value).toDate();
        this.editFee.termTo = moment(( <HTMLInputElement>document.getElementById( 'edittoDate' ) ).value).toDate();
      
        //this.editFee.termFrom = moment( this.editFromDate.year + '-' + this.editFromDate.month + '-' + this.editFromDate.day, 'YYYY-MM-DD' ).toDate();
        //this.editFee.termTo = moment( this.editToDate.year + '-' + this.editToDate.month + '-' + this.editToDate.day, 'YYYY-MM-DD' ).toDate();
          this.editFee.accId=this.loggedUser.accId;
        this.feeTypemasterserivce.updateTerm( this.editFee ).subscribe( response => {
            bootbox.alert( { message: 'Successfully updated.', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
            $( '#editModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Update Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.feeTypeArr.indexOf( this.feeTypeArr.find( r => r.termId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.feeTypemasterserivce.deleteTerm( this.id, this.loggedUser.loggedUserId,this.loggedUser.accId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.feeTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.feeTypeArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getFeeTypeAll();
        }, error => {
            bootbox.alert( { message: 'Delete Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
