import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Response, Http } from '@angular/http';
import { Observable } from 'rxjs';
import { StaticVariable } from 'src/app/globle.class';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
declare var bootbox: any;

const httpOptions = {
    headers: new HttpHeaders( { 'Content-Type': 'application/json' } )
};

@Injectable()

export class AcademicSettingService {

    constructor( private httpclient: HttpClient) { }
    
     backUpData(): Observable<any> {
        const url = StaticVariable.porturl + 'BackUpDB';
        return this.httpclient.get( url );
    }
     getSettingById(id): Observable<AcademicYearMasterPojo> {
         const url = StaticVariable.porturl + 'GetAcademicYearByAcademicFlag/'+id;
         return this.httpclient.get<AcademicYearMasterPojo>( url );
     }
     updateSetting( arg: AcademicYearMasterPojo): Observable<any> {
         const url = StaticVariable.porturl + 'SettingUpdateTimeInterval';
         return this.httpclient.put( url, arg );
     }
     
     getAcademicYrAll(): Observable<AcademicYearMasterPojo[]> {
         const url = StaticVariable.porturl + 'GetAcademicYearAll/';
         return this.httpclient.get<AcademicYearMasterPojo[]>( url );
     }
     getCurrentandPreviousYrList(): Observable<AcademicYearMasterPojo[]> {
        const url = StaticVariable.porturl + 'GetCurrentAndPreviousAcademicYearMaster/';
        return this.httpclient.get<AcademicYearMasterPojo[]>( url );
    }
     
     
}