import { Component, OnInit } from '@angular/core';
import { NgbDateStruct, NgbCalendar, NgbDate } from "@ng-bootstrap/ng-bootstrap";
import { StudentMasterPojo, NotesMasterPojo } from "src/app/pojo/student-master.pojo";
import { MediumMasterPojo } from "src/app/pojo/medium-master.pojo";
import { MediumMasterService } from "src/app/management/Medium/medium-master.service";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SectionMasterPojo } from "src/app/pojo/section-master.pojo";
import { SectionMasterService } from "src/app/management/Masters/section-master/section-master.service";
import { StudentMasterService } from "src/app/management/Masters/student-master/student-master.service";
import { ClassMasterService } from "src/app/management/Masters/class-master/class-master.service";
import { ClassMasterPojo } from "src/app/pojo/class-master.pojo";
import { Subject, Observable } from 'rxjs';
import { WebcamImage } from 'ngx-webcam';


declare var $: any;
declare var bootbox: any;

import * as moment from 'moment';
import { FtpImagePojo } from 'src/app/pojo/ftp-image.pojo';
import { AcademicSettingService } from '../Admin/academic-setting/academic-setting.service';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { CommunityMasterService } from '../Masters/community-master/community.service';
import { CommunityPojo, CastePojo } from 'src/app/pojo/community-caste.pojo';
import { CasteMasterService } from '../Masters/caste-master/caste-master.service';
import { DiscountTypeMasterService } from '../Masters/dicount-type-master/discount-type.service';
import { DiscountTypeMasterPojo } from 'src/app/pojo/discount-type-master.pojo';
import { AreaMasterService } from '../Masters/area-master/area-master.service';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { NotesMasterService } from '../Masters/notes-master/notes-master.service';

@Component({
    selector: 'app-student-register',
    templateUrl: './student-register.component.html',
    styleUrls: ['./student-register.component.scss'],
    providers:[AcademicSettingService]
})

export class StudentRegisterComponent implements OnInit {

    model: NgbDateStruct;
    date: { year: number, month: number };
    form: boolean = false;

    loggedUser = new MUserMasterPojo();

    studentMaster = new StudentMasterPojo();
    studentMasterRes = new StudentMasterPojo();

    mediumArr: MediumMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
    classArr: ClassMasterPojo[] = [];

    imagePojo: FtpImagePojo = new FtpImagePojo();
    documentListPojo = new FtpImagePojo();

    addDOA;
    addDOB: NgbDateStruct;

    isLoading: boolean = true;

    fileObj: any;
    showSaveBtn: boolean = false;
    imageUploadLoading: boolean = false;
    imageUploadStatus: boolean = false;
    tempFtpArr: FtpImagePojo[] = [];
    selectedFiles: File[] = [];
    newFile: boolean = false;
    fileincreament: number = 0;
    id: number;
    uniqueid: number = 0;
    notesArr : NotesMasterPojo[] =[];
    editNotes = new NotesMasterPojo();
    notesArrAll:NotesMasterPojo [] =[];
    academic:AcademicYearMasterPojo[] =[];
    communityArr: CommunityPojo[] = [];
    casteArr: CastePojo[] = [];
    discountTypeArr: DiscountTypeMasterPojo[] = [];
    areaArr: AreaMasterPojo[] = [];
    // toggle webcam on/off
    public showWebcam = false;
    public mirrorImage = "always";

    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();

    public triggerSnapshot(): void {
        this.trigger.next();
        this.showWebcam = false;
        this.showSaveBtn = true;
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    constructor(private areamasterserivce: AreaMasterService,private discountTypeMasterserivce:DiscountTypeMasterService,private castemasterserivce: CasteMasterService,private communitymasterserivce: CommunityMasterService,private calendar: NgbCalendar, private mediumMasterService: MediumMasterService, 
        private settingService:AcademicSettingService,private notesMasterService:NotesMasterService,private sectionMasterService: SectionMasterService, private studentMasterService: StudentMasterService, private classMasterService: ClassMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse(sessionStorage.getItem('schoolManagementUserDetail'));

        this.getMediumMasterAll();
       // this.getSectionMasterAll();
        this.getClassMasterAll();
        this.getNotesMasterAll();
        this.academicSettingAll();
        $( '#addAfterClick' ).hide();
        this.autoGenAdmissionNo();
        this.academicSettingAll();
        this.getServerDate();
        this.getCommunityAll();
        this.getCasteAll();
        this.getAreaAll();
        this.getDiscountTypeAll();
  //this.addDOA = { day: moment(this.studentMaster.doa).date(), month: moment(this.studentMaster.doa).month(), year: moment(this.studentMaster.doa).year() };
    }
    getServerDate(){
        this.studentMasterService.getCurrenntDate().subscribe( response => {
            this.addDOA = response;
            this.addDOA = { day: moment( this.addDOA ).date(), month: moment( this.addDOA ).month() + 1, year: moment( this.addDOA ).year() };
           
              }, error => {
            bootbox.alert( { message: 'Get Server Date Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getAreaAll() {
         this.areamasterserivce.getAllArea(this.loggedUser.accId).subscribe( response => {
            this.areaArr = response;
              }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getDiscountTypeAll() {
        this.discountTypeMasterserivce.getAllDiscountType(this.loggedUser.accId).subscribe( response => {
            this.discountTypeArr = response;
              }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
             } );
    }
    getCasteAll() {
        this.isLoading = true;
        this.castemasterserivce.getAllCaste(this.loggedUser.accId).subscribe( response => {
            this.casteArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Caste All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getCommunityAll() {
        this.isLoading = true;
        this.communitymasterserivce.getAllCommunity(this.loggedUser.accId).subscribe( response => {
            this.communityArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert( { message: 'Get Community All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;
        } );
    }
    getNotesMasterAll() {
        this.notesMasterService.getNotesAll(this.loggedUser.accId).subscribe( response => {
            this.notesArrAll = response;
             }, error => {
            bootbox.alert( { message: 'Get Notes All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getNotesByNotesId(id){
        this.editNotes.description = this.getNotesById(id);
        this.editNotes.name = this.getNotesNameById(id);
        
    }
    getNotesById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).description;
        }
        else {
            return id;
        }
    }
    getNotesNameById( id ) {
        if ( this.notesArrAll.find( r => r.notesId == id ) ) {
            return this.notesArrAll.find( r => r.notesId == id ).name;
        }
        else {
            return id;
        }
    }
    LoadCamera() {
        this.showWebcam = true;
        this.webcamImage = null;
        this.showSaveBtn = false;
    }

    readURL() {
        this.webcamImage = null;
        let imageStr = '';
        let input = (<HTMLInputElement>document.getElementById('chooseFile'));
        if (input.files && input.files[0]) {
            this.fileObj = input.files[0];
            const reader = new FileReader();
            reader.onload = function (e) {
                imageStr = reader.result as string;
                $('#imagePreview').attr('src', imageStr);
            };
            reader.readAsDataURL(input.files[0]);
        }
        this.showSaveBtn = true;
    }

    setImage() {
        let img = $('#imagePreview').attr('src');
        let imgCam = $('#webcamImagePreview').attr('src');
       
        if (imgCam != null) {
            $('#imageProfile').attr('src', imgCam);
           // $('#imageProfile').addClass('flip');
            var file = this.dataURLtoFile(imgCam,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
             $( '#webcamImagePreview' ).attr( 'src', str );
         
        } else {
            $('#imageProfile').attr('src', img);
            $('#imageProfile').removeClass('flip');
            var file = this.dataURLtoFile(img,'profile.jpg');
            this.fileObj = file;
            let str = 'assets/image/250x300.png';
            $( '#imagePreview' ).attr( 'src', str );
          
        }

        this.uploadFile(this.fileObj);
    }

     dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }

    uploadFile(file: File) {
      //  console.log(JSON.stringify(file));
        this.imageUploadLoading = true;
        this.studentMasterService.uploadImage(file, 1, 0).subscribe((res) => {
            this.imagePojo = res;
            this.imageUploadLoading = false;
            if (this.imagePojo.imageId != 0) {
                this.imageUploadStatus = true;
                this.studentMaster.profile.imageName = this.imagePojo.imageName;
                this.studentMaster.profile.imagePath = this.imagePojo.imagePath;
                this.studentMaster.profile.imageId = this.imagePojo.imageId;
                this.studentMaster.profile.uniqueId = 0;
                $( '#openModal' ).modal( 'hide' );
                this.showSaveBtn = false;
            } else {
                bootbox.alert('Failed to uploading a image!');
            }
        }, error => {
            bootbox.alert({ message: 'Add Ftp Image Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.imageUploadLoading = false;
        });
    }

    selectToday() {
        this.model = this.calendar.getToday();
    }

    changeForm2() {
        this.form = true;
    }

    changeForm1() {
        this.form = false;
    }

    phoneNumValidation(num) {
        const contactNum = /^[6-9]\d{9}$/;
        if (num.match(contactNum) || num == '') {
        } else {
            (<HTMLInputElement>document.getElementById('mobileNumber')).value = '';
            alert('Please check the mobile number. This number is not valid.');
            $('#mobileNumber').focus();
        }
    }

    phoneNumValidation2(num) {
        const contactNum = /^[6-9]\d{9}$/;
        if (num.match(contactNum) || num == '') {
        } else {
            (<HTMLInputElement>document.getElementById('mobileNumber2')).value = '';
            alert('Please check the mobile number. This number is not valid.');
            $('#mobileNumber2').focus();
        }
    }

    getMediumMasterAll() {
        this.mediumMasterService.getMediumAll().subscribe(response => {
            this.mediumArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert({ message: 'Get Medium All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    getSectionMasterByDeptId(id){
        this.studentMasterService.getSectionMasterByClassId(id,this.loggedUser.accId).subscribe( response => {
            this.sectionArr = response;
             }, error => {
            bootbox.alert( { message: 'Get Section By Class Id Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
    }
    getSectionMasterAll() {
        this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe(response => {
            this.sectionArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert({ message: 'Get Section All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    getClassMasterAll() {
        this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe(response => {
            this.classArr = response;
            this.isLoading = false;
        }, error => {
            bootbox.alert({ message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    modalShow() {
        $('#myModal1').modal('show');
    }
    removeFile(images, i) {
        this.selectedFiles.splice(i, 1);
    }
    removeFileForEst(item, i) {

        this.tempFtpArr.push(item);
        this.selectedFiles.splice(i, 1);

    }
    setFiles(images) {
        for (let i = 0; i < images.files.length; i++) {
            this.selectedFiles.push(images.files[i]);
        } this.newFile = true;
        // this.status = "Documents Still Not Uploading";
    }
    uploadFiles() {
        this.studentMaster.imageList = [];
        this.fileincreament = 0;
        this.uploadDoc();
    }
    uploadDoc() {
        this.uniqueid = 0;
        this.id = 2;
        if(this.selectedFiles.length !=0){
            $('#addBeforeClick').hide();$('#addAfterClick').show();
        if (this.selectedFiles.length !== this.fileincreament) {
            this.studentMasterService.uploadImage(this.selectedFiles[this.fileincreament], this.id, this.studentMasterRes.studentId).subscribe((res) => {

                this.documentListPojo = res;
                this.fileincreament++;
                this.uploadDoc();
                if (this.documentListPojo.imageId != 0) {
                    this.studentMaster.imageList.push(this.documentListPojo);
                } else {
                    alert("Failed Image Uploading.....");
                     $('#addBeforeClick').show();$('#addAfterClick').hide();
                }
            }, er => {
                alert("Failed image uploading..." + er);
                $('#addBeforeClick').show();$('#addAfterClick').hide();
            });
              
        }
        if (this.selectedFiles.length == this.fileincreament) {
            bootbox.alert({message:'Successfully Uploaded', className: 'text-success h5', closeButton: false});
           $('#myModal1').modal('hide');
           $('#addBeforeClick').show();$('#addAfterClick').hide();
           this.selectedFiles = [];
           this.selectedFiles.length = 0;
             }
    }else{
        alert('No files to upload');
    }
    }
    autoGenAdmissionNo(){
       this.studentMasterService.getAutoGenerateAdmissionNo(this.loggedUser.accId).subscribe( rs => {
             this.studentMaster.admissionNo = rs;
              }, error => {
             bootbox.alert( 'Error' + error );
             } );
       }
       academicSettingAll(){
        this.academic = [];
        this.settingService.getCurrentandPreviousYrList().subscribe( response => {
            this.academic = response;
            for(let i=0;i<this.academic.length;i++){
                if(this.academic[i].academicFlag == 1){
                    this.studentMaster.joiningAcademicYear = this.academic[i].academicYearId;
                }
            }
            
              }, error => {
            bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
             } );
       }
       handleInitError(e){}
    addStudent() {
         if(this.studentMaster.notes == undefined){
            this.studentMaster.notes = [];
        }
        this.isLoading = true;
      this.studentMaster.doa = moment(this.addDOA.year + '-' + this.addDOA.month + '-' + this.addDOA.day, 'YYYY-MM-DD').toDate();
      // this.studentMaster.doa = this.addDOA; 
       this.studentMaster.dob = moment(this.addDOB.year + '-' + this.addDOB.month + '-' + this.addDOB.day, 'YYYY-MM-DD').toDate();
        if (this.selectedFiles.length > 0) {
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.studentMaster.imageList[i].typeId = 1;
            }
        }else{
            this.studentMaster.imageList= [];
        }
      //  this.studentMaster.joiningAcademicYear = this.loggedUser.academicYearId;
         if(this.studentMaster.busFlag=="FALSE"){
            this.studentMaster.areaId=0;
         }
         if(this.studentMaster.discountFlag=="FALSE"){
            this.studentMaster.discountTypeId=0;
         }
         this.studentMaster.accId=this.loggedUser.accId;
        this.studentMasterService.addStudent(this.studentMaster).subscribe(response => {
            let d = new StudentMasterPojo();
            d = response;
            if(d.responseCode == 101){
               bootbox.alert('Admission Number already Exist');
                this.isLoading = false;
                // this.studentMasterService.getAutoGenerateAdmissionNo(this.loggedUser.accId).subscribe( rs => {
                //     this.studentMaster.admissionNo = rs;
                //     this.addStudent();
                //    }, error => {
                //     bootbox.alert( 'Error' + error );
                //     } );
            }if(d.responseCode == 102){
            this.studentMasterRes = response;
            bootbox.confirm('Student has been Successfully Registered !\n Do you want to add any Documents?', ( result ) => {
                if ( result ) {
                   this.modalShow();
                   this.isLoading = false;
                   this.studentMaster = new StudentMasterPojo();
                   this.addDOB = null;
                  // this.addDOA = null;
                   this.selectedFiles = [];
                   this.notesArr = [];
                   this.notesArr.length = 0;
                     (<HTMLFormElement>document.getElementById('formSecond')).reset();
                   this.changeForm1();
                   this.autoGenAdmissionNo();
                this.academicSettingAll();
                this.addDOA = { day: moment(this.studentMaster.doa).date(), month: moment(this.studentMaster.doa).month()+1, year: moment(this.studentMaster.doa).year() };
            }
                else {
                    this.isLoading = false;
                    this.studentMaster = new StudentMasterPojo();
                    this.addDOB = null;
                   // this.addDOA = null;
                    this.selectedFiles = [];
                    this.notesArr = [];
                    this.notesArr.length = 0;
                      (<HTMLFormElement>document.getElementById('formSecond')).reset();
                    this.changeForm1(); 
                    this.autoGenAdmissionNo();
                this.academicSettingAll(); 
                this.addDOA = { day: moment(this.studentMaster.doa).date(), month: moment(this.studentMaster.doa).month()+1, year: moment(this.studentMaster.doa).year() };
            }
            } );
          
        }
             }, error => {
            bootbox.alert({ message: 'Add Student Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false });
            this.isLoading = false;
        });
    }
    editNotesMaster() {
        this.editNotes.accId = this.loggedUser.accId;
        this.editNotes.userId = this.loggedUser.userId;
         this.notesArr.push(this.editNotes);
        this.editNotes = new NotesMasterPojo();
    }
    editNotesMasterDelete(i,id) {
             this.notesArr.splice(i, 1);
              }
    submitEditNotes() {
        this.studentMaster.notes = [];
        this.studentMaster.notes = this.notesArr;
        $('#editNotesModal').modal('hide');
    }

}