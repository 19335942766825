export class TeacherNotesPojo {
	teacherNotesId:number;
	types:string;
	
	noteDate:Date;
	title:string;
	name:string;
mobileno:string;
description:string;
admissionno:number;
 accId:number;
 staffId:number;
 userId:number;
 studentId:number;
}