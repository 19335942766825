import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { AreaMasterService } from '../../Masters/area-master/area-master.service';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-bus-fee-allocation',
  templateUrl: './bus-fee-allocation.component.html',
  styleUrls: ['./bus-fee-allocation.component.scss'],
  providers:[AcademicSettingService]
})
export class BusFeeAllocationComponent implements OnInit {
   model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;
    loggedUser = new MUserMasterPojo();
    addEntryDate: NgbDateStruct;
    editEntryDate: NgbDateStruct;
    feeAllocateArr: FeeAllocationPojo[] = [];
    tempPushArr:FeeAllocationPojo[]=[];
    classMasterArr: ClassMasterPojo[] = [];
    addMaster = new FeeAllocationPojo();
    editMaster = new FeeAllocationPojo();

    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = false;
    feeStructArr: StandardTermPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
     standardArr: AcademicStandardDetailsPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    studentMasterAllArr: StudentMasterPojo[] = [];
    studentEditMasterArr: StudentMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    classid;
    sectionid;
    departmentId;
    academicYr;
    termArr: TermPojo[] = [];
    termid;
    standardTermId;
    filteredFeeStructArr:StandardTermPojo[]=[];
    reportPojo = new FeeAllocationReportPojo();
    filterId:string;
    tempArr:FeeAllocationPojo[]=[];
    areaArr:AreaMasterPojo[]=[];
    constructor( private areamasterserivce:AreaMasterService,private studentMasterService: StudentMasterService,private reportService:ReportService,private feeStructuremasterserivce:StandardStructureMasterService,private departmentMasterService:DepartmentMasterService,private classMasterService: ClassMasterService,
      private feeTypemasterserivce: TermMasterService,private sectionMasterService:SectionMasterService,private settingService:AcademicSettingService,private feeAloocationService:FeeAllocationMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
       // this.getFeeAllocationAll();
        this.getFeeStructureAll();
        this.getDepartmentAll();
        this.getClassMasterAll();
        this.getSectionMasterAll();
        this.academicSettingAll();
        this.getStudentMasterAll();
        this.getTermAll();
        this.getAreaAll();
        }
        getAreaAll() {
          this.isLoading = true;
          this.areamasterserivce.getAllArea(this.loggedUser.accId).subscribe( response => {
              this.areaArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              this.isLoading = false;
          } );
      }
      getAreaNameById( id ) {
        if ( this.areaArr.find( r => r.areaId == id ) ) {
            return this.areaArr.find( r => r.areaId == id ).area;
        } else {
            return id;
        }
      }
        getTermAll() {
          this.isLoading = true;
          this.feeTypemasterserivce.getAllTerm(this.loggedUser.accId).subscribe( response => {
              this.termArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Get FeeType All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              this.isLoading = false;
          } );
      }
      calc(){
       // this.editMaster.discountAmount = (this.editMaster.amount*this.editMaster.discount)/100;
        this.editMaster.totalAmount = this.editMaster.amount-this.editMaster.discountAmount;
        this.editMaster.balance = this.editMaster.totalAmount - this.editMaster.paidAmount;
      }
      getStandardTermById(){  
        this.filteredFeeStructArr=[];
        this.feeAloocationService.getBusStandardTermByClassidAndTermId(this.classid,this.termid,this.loggedUser.accId).subscribe( response => {
          this.filteredFeeStructArr= response;
            }, error => {
          bootbox.alert( { message: 'Get STandard Term By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
      }
        getAllocationDetailsById(){
          this.feeAllocateArr =[];
          this.tempArr=[];
          this.isLoading = true;
          this.feeAloocationService.getTempBusFeeAllocationDetailsById(this.academicYr,this.classid,this.standardTermId,this.loggedUser.accId).subscribe( response => {
             if( response.code ==101)//Already Allocated no new Students
            {  
               this.reportPojo= response;
              this.feeAllocateArr = this.reportPojo.flist;
              this.tempArr = this.reportPojo.flist;
              this.filterId="ALL";
           
            }else if( response.code ==103)//No Students
            {  
              bootbox.alert( { message: 'No students in this Class', className: 'text-danger h5', closeButton: false } );
            }else if(response.code ==102 || response.code ==104 )//no allocation to be done
            {  
               this.reportPojo= response;
              this.feeAllocateArr = this.reportPojo.flist;
              this.tempArr = this.reportPojo.flist;
              this.filterId="ALL";
              }
             this.isLoading = false;
             }, error => {
            bootbox.alert( { message: 'Get Fee Allocation Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;   
          } );
        }
        getStudentMasterAll(){
          this.studentMasterArr = [];
             this.studentMasterService.getStudentAll(this.loggedUser.accId).subscribe( response => {
          this.studentMasterAllArr = response;
          }, error => {
              bootbox.alert( { message: 'Get Student Master All Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
               } );
      }
        getFeeAllocationAll() {
           this.feeAloocationService.getAllFeeAllocation(this.loggedUser.accId).subscribe( response => {
            this.feeAllocateArr = response;
              }, error => {
            bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              } );
    }
  getFeeStructureAll() {
        this.feeStructuremasterserivce.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
          this.feeStructArr = response;
           }, error => {
          bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
  }
  getDepartmentAll() {
    this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
        this.departmentArr = run;
    }, error => {
        bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
    }
  getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
             }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
getStudentsById(){
this.reportService.getClassWiseReportById( this.addMaster.deptId, this.addMaster.classId, this.addMaster.sectionId,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
  this.standardArr = response;
  for(let i=0;i<this.standardArr.length;i++){
      this.studentMasterArr.push(this.standardArr[i].student);
  }
  }, error => {
  bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
 } );
}
getDiscountStudents(){ 
   this.feeAllocateArr=[];
if(this.filterId=="ALL"){
  for(let i=0;i<this.tempArr.length;i++){
    this.feeAllocateArr.push(this.tempArr[i]);
}  
}else if(this.filterId=="DISCOUNT"){
   for(let i=0;i<this.tempArr.length;i++){
    if(this.tempArr[i].discountFlag == 'TRUE'){
    this.feeAllocateArr.push(this.tempArr[i]);
}
}
}

}
getEditStudentsById(){
  this.reportService.getClassWiseReportById( this.editMaster.deptId, this.editMaster.classId, this.editMaster.sectionId,this.loggedUser.academicYearId,this.loggedUser.accId ).subscribe( response => {
    this.standardArr = response;
    for(let i=0;i<this.standardArr.length;i++){
        this.studentEditMasterArr.push(this.standardArr[i].student);
    }
    }, error => {
    bootbox.alert( { message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false } );
   } );
}
academicSettingAll(){
  this.academic = [];
  this.settingService.getAcademicYrAll().subscribe( response => {
      this.academic = response;
      for(let i=0;i<this.academic.length;i++){
          if(this.academic[i].academicFlag == 1){
              this.addMaster.academicYearId = this.academic[i].academicYearId;
              this.academicYr = this.academic[i].academicYearId;
                }
      }
      
        }, error => {
      bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
       } );
 }
 getStudentNameById( id ) {
  if ( this.studentMasterAllArr.find( r => r.studentId == id ) ) {
      return this.studentMasterAllArr.find( r => r.studentId == id ).name;
  } else {
      return id;
  }
}
getStudentAdmissionNoById( id ) {
  if ( this.studentMasterArr.find( r => r.studentId == id ) ) {
      return this.studentMasterArr.find( r => r.studentId == id ).admissionNo;
  } else {
      return id;
  }
}getEditStudentAdmissionNoById( id ) {
  if ( this.studentEditMasterArr.find( r => r.studentId == id ) ) {
      return this.studentEditMasterArr.find( r => r.studentId == id ).admissionNo;
  } else {
      return id;
  }
}
getFeeStructureNameById( id ) {
  if ( this.feeStructArr.find( r => r.standardTermId == id ) ) {
      return this.feeStructArr.find( r => r.standardTermId == id ).standardTerm;
  } else {
      return id;
  }
}
submit(){
  if(this.tempPushArr.length>0){
  ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = true;
  this.isLoading = true;
  this.feeAloocationService.addFeeAllocation( this.tempPushArr ).subscribe( response => {
    this.feeAllocateArr =[];
    this.tempPushArr=[];
    this.departmentId ='';
    this.classid ='';
    this.termid ='';
    this.standardTermId ='';
    this.filterQuery ='';
    this.reportPojo = new FeeAllocationReportPojo();
    ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = false;
    this.isLoading = false;
    bootbox.alert( { message: 'Successfully Allocated ', className: 'text-success h5', closeButton: false } );
    
  }, error => {
    bootbox.alert( { message: 'Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
    ( <HTMLInputElement>document.getElementById( 'promoteBtn' ) ).disabled = false;
    this.isLoading = false;
   } );
  }else{
    bootbox.alert('Please select the Student');
  }  
}
selectStudentAll( variable:any) {
  if ( variable.checked ) {
      this.confirmSelectStudentAll();
  } else {
      this.deSelectStudentAll();
  }
}
confirmSelectStudentAll(){
  for ( let j = 0; j < this.feeAllocateArr.length; j++ ){
    let d = this.tempPushArr.findIndex( r => r.studentId == (  this.feeAllocateArr[j].studentId ) );
    if ( d < 0 ) {
      if(this.feeAllocateArr[j].status !='ALLOCATED'){
 this.tempPushArr.push(this.feeAllocateArr[j]);
      }
    }
  }
}
deSelectStudentAll(){
  for ( let j = 0; j < this.feeAllocateArr.length; j++) {
    let d = this.tempPushArr.findIndex( r => r.studentId == ( this.feeAllocateArr[j].studentId ) );
       if ( d >= 0 ) {
        if(this.feeAllocateArr[j].status !='ALLOCATED'){
          this.tempPushArr.splice( d, 1 );
        }
       }
      }
}
isSelectedStudentAll() {
  
  for ( let j = 0; j < this.feeAllocateArr.length; j++ ) {
      if ( !this.tempPushArr.find( r => r.studentId == ( this.feeAllocateArr[j].studentId ) ) ) {
          return false;
      }
  }
  return true;
}

isSelectedStudent( item ) {
  if ( this.tempPushArr.find( r => r.studentId == ( item.studentId ) ) ) {
      return true;
  }
  return false;
}

selectStudent( variable, item) {
   if ( variable.checked ) {
    if(item.status !='ALLOCATED'){
     this.tempPushArr.push(item);
    }
  } else {
    let d = this.tempPushArr.findIndex( r => r.standardTermId == ( item.standardTermId ) );
    if ( d >= 0 ) {
      if(item.status !='ALLOCATED'){
        this.tempPushArr.splice( d, 1 );
      }
    }
  }
}
    
  add() {
         ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addMaster.admissionNo = this.getStudentAdmissionNoById(this.addMaster.studentId);
        this.addMaster.feeAllocationDate = moment( this.addEntryDate.year + '-' + this.addEntryDate.month + '-' + this.addEntryDate.day, 'YYYY-MM-DD' ).toDate();
      this.feeAloocationService.addFeeAllocation( this.tempPushArr ).subscribe( response => {
            bootbox.alert( { message: 'Successfully Added', className: 'text-success h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            ( <HTMLFormElement>document.getElementById( 'addSubjectForm' ) ).reset();
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
            $( '#addModal' ).modal( 'hide' );
            this.getFeeAllocationAll();
        }, error => {
            bootbox.alert( { message: 'Add  Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
       }

  
}

