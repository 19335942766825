import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { FeeAllocationReportByTermWisePojo } from 'src/app/pojo/fee-collection.pojo';
import { LogDetailsPojo } from 'src/app/pojo/log-details-pojo';
import { MUserMasterPojo } from 'src/app/pojo/management-user-pojo';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { FeeAllocationMasterService } from '../../Fees/fee-allocation/fee-allocation.service';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { QueriePojo }from 'src/app/pojo/queriepojo'
import { QuerieResPojo } from 'src/app/pojo/querierespojo';
import { QuerieService } from './Student.component.service';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-Student',
  templateUrl: './Student.component.html',
  styleUrls: ['./Student.component.scss']
})
export class StudentComponent implements OnInit {
  model: NgbDateStruct;
  date: { year: number, month: number };
  form: boolean = false;

  fromDate: NgbDateStruct;
  loggedUser = new MUserMasterPojo();
url:string;
    sectionArr: SectionMasterPojo[] = [];
  classArr: ClassMasterPojo[] = [];
  departmentArr: DepartmentMasterPojo[] = [];
  departmentId: number;
  sectionMasterArr: SectionMasterPojo[] = [];
  classid:number;
  sectionid:number;
  noteDate:Date;
  typeToAll:string;
  descriptionToAll:string;
  public data;
  public sortBy = 'i';
  public rowsOnPage = 10;
  public sortOrder = 'asc';
  classMasterArr:ClassMasterPojo[] =[];
  getFile:any;
  id: number;
  item=[];
  item1=[];
  isLoading: boolean = false;
  body = [];
  content = [];
  documentTime:string;
  resultDept:number;
  resultClass:number;
  resultSection:number;
  resulltDate:number;
  imageid:number;
  resulltDate2:number;
  searchOption = 'ALL';
  typeId;
  academic:AcademicYearMasterPojo[] =[];
  academicYear;
  feeCollectionArr:FeeAllocationReportByTermWisePojo[] =[];
  standTermArr: StandardTermPojo[] = [];
  classPojo = new ClassMasterPojo();
  hoursId:number;
  viewApplication= new QueriePojo();
  notesRes = new QuerieResPojo();
  notesArr:QueriePojo[]=[];
  resultHoursId:number;
  updateArr:QueriePojo[]=[];
  finalEntryResArr:QueriePojo[] =[];
  logpojo = new LogDetailsPojo();
  accountMasterArr:AccountMasterPojo[]=[];
  updatequerie = new QueriePojo();
  finalUpdate : QueriePojo[]=[];

  constructor(private standardTermService:StandardStructureMasterService,private settingService:AcademicSettingService,
    private departmentMasterService: DepartmentMasterService, private sectionMasterService: SectionMasterService, 
    private classMasterService: ClassMasterService, private router: Router,
    private accountMasterService:AccountMasterService,private querieService:QuerieService) { }

  ngOnInit() {
    this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

    (<HTMLFormElement>document.getElementById('fromEntryDate')).value = moment().format('YYYY-MM-DD');
    (<HTMLFormElement>document.getElementById('endEntryDate')).value = moment().format('YYYY-MM-DD');
    $( '#editAfterClick' ).hide();
    this.getDepartmentAll();
    this.getSectionMasterAll();
    this.getClassMasterAll();
    this.academicSettingAll();
    this.getStandrdTermAll();
    this.accountMasterAll();
  }
  accountMasterAll() {
    this.accountMasterService.getAllAccountMaster().subscribe(response => {
      this.accountMasterArr = response;
     
    }, error => {
      bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
    });
  }
  getAccountNameById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).accName;
    } else {
      return id;
    }
  }
  getAccountPlaceById(id) {
    if (this.accountMasterArr.find(r => r.accId == id)) {
      return this.accountMasterArr.find(r => r.accId == id).place;
    } else {
      return id;
    }
  }
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                this.academicYear = this.academic[i].academicYearId;
            }
        }
           }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
  getSectionMasterAll() {
      this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
          this.sectionMasterArr = response;
          }, error => {
          bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
           }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getClassMasterHoursListById() {
      this.classMasterService.getClassMasterById(this.classid,this.loggedUser.accId).subscribe( response => {
          this.classPojo = response;
           }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
getDepartmentAll() {
      this.departmentMasterService.getAllDepartment(this.loggedUser.accId).subscribe( run => {
          this.departmentArr = run;
      }, error => {
          bootbox.alert( { message: 'Get Department All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
          } );
  }
  getStandrdTermAll() {
       this.standardTermService.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
          this.standTermArr = response;
             }, error => {
          bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
           } );
    }
  getDepartmentNameBydepartmentId( id ) {
      if ( this.departmentArr.find( r => r.deptId == id ) ) {
          return this.departmentArr.find( r => r.deptId == id ).deptName;
      } else {
          return id;
      }
  }
  getClassNameById( id ) {
      if ( this.classMasterArr.find( r => r.classId == id ) ) {
          return this.classMasterArr.find( r => r.classId == id ).className;
      } else {
          return id;
      }
  }
  getSectionNameById( id ) {
      if ( this.sectionMasterArr.find( r => r.sectionId == id ) ) {
          return this.sectionMasterArr.find( r => r.sectionId == id ).sectionName;
      } else {
          return id;
      }
  }
  getAcademicYrById( id ) {
      if ( this.academic.find( r => r.academicYearId == id ) ) {
          return this.academic.find( r => r.academicYearId == id ).academicYear;
      } else {
          return id;
      }
    }
    getStandardTermById( id ) {
      if ( this.standTermArr.find( r => r.standardTermId == id ) ) {
          return this.standTermArr.find( r => r.standardTermId == id ).standardTerm;
      } else {
          return id;
      }
    }
    getHoursNameById( id ) {
      if ( this.classPojo.list.find( r => r.hoursId == id ) ) {
          return this.classPojo.list.find( r => r.hoursId == id ).hoursName;
      } else {
          return id;
      }
    }

    getQuerieById() {
      this.notesArr = [];  
      this.isLoading = true;
      this.notesRes = new QuerieResPojo();
      let arg1 = (<HTMLFormElement>document.getElementById('fromEntryDate')).value;
      let arg2 =(<HTMLFormElement>document.getElementById('endEntryDate')).value;
      if(this.classid !=null&&this.sectionid != null && this.academicYear != null && arg1 != null && arg2 != null){    
        this.querieService.getQuerieList( this.academicYear, this.classid, this.sectionid,arg1,arg2,this.loggedUser.accId ).subscribe( response => {
        this.notesRes=response;
        if(this.notesRes.code==102){
          bootbox.alert({ message: 'No Queries In This Section' , className: "h6", closeButton: false });
          this.isLoading = false;
        }else{
        this.notesArr=this.notesRes.list;
          this.resultDept= this.departmentId;
          this.resultClass= this.classid;
          this.resultSection=this.sectionid;
          this.resulltDate = arg1;
          this.resulltDate2 = arg2;
      this.isLoading = false;
        }
    }, error => {
        bootbox.alert({ message: 'Get Report By DeptId ClassId And SectionId Service Error! HTTP Status ' + error.status, className: "text-danger h5", closeButton: false });
        this.isLoading = false;
      });
    }else{
      bootbox.alert( { message: 'Please fill all Fields ' , className: "text-danger h5", closeButton: false } );
      this.isLoading = false;
  }
  }
  clickView(item){
    this.updatequerie.querieReply="";
    this.item1 = item;
  }

  update(item1){
    this.finalUpdate=[];
    let e = new QueriePojo();
    e.parentsQuerieId = item1.parentsQuerieId;
    e.querieReply = this.updatequerie.querieReply;
    this.querieService.update(e).subscribe(res=> {
      this.finalUpdate= res;
      this.getQuerieById();
      this.updatequerie.querieReply="";
      bootbox.alert( { message: 'Reply has been successfully updated.', className: 'text-success h5', closeButton: false } );
    $( '#exampleModalCenter' ).modal( 'hide' );
},error =>{
bootbox.alert({message:'Update Querie Error',className:'text-danger h5',closeButton:false})
});
}
}
