import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { StaticVariable } from "src/app/globle.class";
import { HttpClient } from "@angular/common/http";
import { CurriculumMasterPojo } from "src/app/pojo/curriculum-master.pojo";

@Injectable( {
    providedIn: 'root'
} )

export class CurriculumMasterService {

    constructor( private httpclient: HttpClient ) { }

    getCurriculumAll(): Observable<any> {
        const url = StaticVariable.porturl + 'GetCurriculumAll';
        return this.httpclient.get<CurriculumMasterPojo[]>( url );
    }
}
