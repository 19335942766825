
import { Component, OnInit } from '@angular/core';
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { SubjectMasterPojo } from 'src/app/pojo/subject-master.pojo';
import { StandardTermPojo } from 'src/app/pojo/standard-term.pojo';
import { DepartmentMasterService } from '../../Masters/department-master/department-master.service';
import { DepartmentMasterPojo } from 'src/app/pojo/department-master.pojo';
import { SectionMasterService } from '../../Masters/section-master/section-master.service';
import { SectionMasterPojo } from 'src/app/pojo/section-master.pojo';
import { ReportService } from '../../Reports/report-service';
import { AcademicStandardDetailsPojo } from 'src/app/pojo/academic-standard-details.pojo';
import { StudentMasterPojo } from 'src/app/pojo/student-master.pojo';
import { AcademicYearMasterPojo } from 'src/app/pojo/academic-year.pojo';
import { AcademicSettingService } from '../../Admin/academic-setting/academic-setting.service';
import { FeeAllocationPojo, FeeAllocationReportPojo } from 'src/app/pojo/fee-allocation.pojo';
import { ClassMasterPojo } from 'src/app/pojo/class-master.pojo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ClassMasterService } from '../../Masters/class-master/class-master.service';
import { StandardStructureMasterService } from '../../Masters/standard-structure/standard-structure.service';
import { StudentMasterService } from '../../Masters/student-master/student-master.service';
import { TermMasterService } from '../../Masters/term/term.service';
import { TermPojo } from 'src/app/pojo/term-pojo';
import { FeeAllocationMasterService } from '../fee-allocation/fee-allocation.service';
import { AreaMasterService } from '../../Masters/area-master/area-master.service';
import { AreaMasterPojo } from 'src/app/pojo/area-master.pojo';
import { FeeCollectionReportDetailsPojo } from 'src/app/pojo/fee-collection-report-detail.pojo';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { Router } from '@angular/router';
import { FeeCollectionReportPojo } from 'src/app/pojo/fee-collection-report.pojo';
import { StaticVariable } from 'src/app/globle.class';
import * as pdfMake from 'pdfmake/build/pdfmake.js';
import * as pdfFonts from 'node_modules/pdfmake/build/vfs_fonts.js';
import { AccountMasterService } from '../../Admin/account-master/account-master.service';
import { AccountMasterPojo } from 'src/app/pojo/account-master.pojo';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var bootbox: any;
@Component({
  selector: 'app-feecollection-details-report',
  templateUrl: './feecollection-details-report.component.html',
  styleUrls: ['./feecollection-details-report.component.scss'],
  providers:[AcademicSettingService]
})
export class FeecollectionDetailsReportComponent implements OnInit {

    loggedUser = new MUserMasterPojo();
    feeCollectionArr: FeeCollectionReportDetailsPojo[] = [];
    classMasterArr: ClassMasterPojo[] = [];
     public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = false;
    feeStructArr: StandardTermPojo[] = [];
    departmentArr: DepartmentMasterPojo[] = [];
    sectionArr: SectionMasterPojo[] = [];
     standardArr: AcademicStandardDetailsPojo[] = [];
    studentMasterArr: StudentMasterPojo[] = [];
    studentMasterAllArr: StudentMasterPojo[] = [];
    studentEditMasterArr: StudentMasterPojo[] = [];
    academic:AcademicYearMasterPojo[] =[];
    classid;
    sectionid;
    departmentId;
    academicYr;
    termArr: TermPojo[] = [];
    termid;
    standardTermId;
    filteredFeeStructArr:StandardTermPojo[]=[];
    reportPojo = new FeeCollectionReportDetailsPojo();
    filterId:string;
    tempArr:FeeAllocationPojo[]=[];
    areaArr:AreaMasterPojo[]=[];
    pickspaidledgersByAccountGroup:LedgerPojo[]=[];
    public paidacc: number[] = [1, 2];
    feeCollReportArr:FeeCollectionReportPojo[]=[];
    tempStudname;
    tempClassId;
    tempSectionId;
    tempAcademicYr;
    tempAdmissionNo;
    admissionNo:string;
    documentTime:string;
    body = [];
    content = [];
    accountMasterArr:AccountMasterPojo[]=[];
    constructor(private departmentMasterService:DepartmentMasterService,private classMasterService: ClassMasterService,private router:Router,
      private feeAloocationService:FeeAllocationMasterService,private ledgercreationService:LedgercreationService,
      private settingService:AcademicSettingService,private sectionMasterService:SectionMasterService,
      private accountMasterService:AccountMasterService, private feeStructuremasterserivce:StandardStructureMasterService) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );
       this.academicSettingAll();
         this.getClassMasterAll();
        this.getSectionMasterAll();
        this.getFeeStructureAll();
        this.ledgercreationService.getLedgerByAccountGroup( this.paidacc,this.loggedUser.accId).subscribe( log => {
          this.pickspaidledgersByAccountGroup = log;
      } ); 
      this.accountMasterAll();
        }
        accountMasterAll() {
          this.accountMasterService.getAllAccountMaster().subscribe(response => {
            this.accountMasterArr = response;
           
          }, error => {
            bootbox.alert('Account Service Error! - HTTP Status ' + error.status);
          });
        }
        getAccountNameById(id) {
          if (this.accountMasterArr.find(r => r.accId == id)) {
            return this.accountMasterArr.find(r => r.accId == id).accName;
          } else {
            return id;
          }
        }
        getAccountPlaceById(id) {
          if (this.accountMasterArr.find(r => r.accId == id)) {
            return this.accountMasterArr.find(r => r.accId == id).place;
          } else {
            return id;
          }
        } 
        getFeeStructureAll() {
          this.isLoading = true;
          this.feeStructuremasterserivce.getAllStandardTerm(this.loggedUser.accId).subscribe( response => {
              this.feeStructArr = response;
              this.isLoading = false;
          }, error => {
              bootbox.alert( { message: 'Get All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              this.isLoading = false;
          } );
      }
      getPaidLedgerById( id ) {
        if ( this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ) ) {
            return this.pickspaidledgersByAccountGroup.find( r => r.ledgerId == id ).ledgerName;
        } else {
            return id;
        }
      }
      getStandardTermById( id ) {
        if ( this.feeStructArr.find( r => r.standardTermId == id ) ) {
            return this.feeStructArr.find( r => r.standardTermId == id ).standardTerm;
        } else {
            return id;
        }
      }
       getAllocationDetailsById(){
          this.feeCollReportArr =[];
           this.isLoading = true;
          this.feeAloocationService.getBusFeeCollectionDetailsByIdAndAdmissionNo(this.academicYr,this.admissionNo,this.loggedUser.accId).subscribe( response => {
            this.tempAcademicYr=this.academicYr;
            this.tempAdmissionNo=this.admissionNo;   
            this.feeCollReportArr= response;
               if(this.feeCollReportArr.length>0){
                 if(this.feeCollReportArr[0].list.length>0){
                 this.tempStudname = this.feeCollReportArr[0].list[0].name;
                 this.tempClassId = this.feeCollReportArr[0].list[0].classId;
                 this.tempSectionId = this.feeCollReportArr[0].list[0].sectionId;
                 }
                  }
              this.isLoading = false;
             }, error => {
            bootbox.alert( { message: 'Get Bus Fee Collection Report Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            this.isLoading = false;   
          } );
        }
   getClassMasterAll() {
      this.classMasterService.getClassMasterAll(this.loggedUser.accId).subscribe( response => {
          this.classMasterArr = response;
             }, error => {
          bootbox.alert( { message: 'Get Class All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
  }
  getSectionMasterAll() {
    this.sectionMasterService.getSectionMasterAll(this.loggedUser.accId).subscribe( response => {
        this.sectionArr = response;
        }, error => {
        bootbox.alert( { message: 'Get Section All Service Error! HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
}
  academicSettingAll(){
    this.academic = [];
    this.settingService.getAcademicYrAll().subscribe( response => {
        this.academic = response;
        for(let i=0;i<this.academic.length;i++){
            if(this.academic[i].academicFlag == 1){
                 this.academicYr = this.academic[i].academicYearId;
                  }
        }
        
          }, error => {
        bootbox.alert( 'Setting Service Error! - HTTP Status ' + error.status );
         } );
   }
   getAcademicYrById( id ) {
    if ( this.academic.find( r => r.academicYearId == id ) ) {
        return this.academic.find( r => r.academicYearId == id ).academicYear;
    } else {
        return id;
    }
  }
 
  getClassNameById( id ) {
    if ( this.classMasterArr.find( r => r.classId == id ) ) {
        return this.classMasterArr.find( r => r.classId == id ).className;
    } else {
        return id;
    }
  }
  getSectionNameById( id ) {
    if ( this.sectionArr.find( r => r.sectionId == id ) ) {
        return this.sectionArr.find( r => r.sectionId == id ). sectionName;
    } else {
        return id;
    }
  }
  
  downloadPDF() {

    this.documentTime = moment().format( 'DD-MM-YYYY  h:mm:ss A' );
    this.content.push( {
      text: this.getAccountNameById(this.loggedUser.accId) + '\n' + this.getAccountPlaceById(this.loggedUser.accId), font: 'TimesNewRoman', bold: true, fontSize: 14, alignment: 'center', margins: [0, 5, 0, 0]
     } );
          this.content.push( {
             text: 'INDIVIDUAL FEE COLLECTION DETAILS REPORT', fontSize: 18, alignment: 'center', font: 'TimesNewRoman', bold: true
         } );
      this.content.push( {
      text: '----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------\n',
      fontSize: 10, bold: true, alignment: 'center'
  } );
  this.content.push( {
    columns:[{
        width:'70%',
        text: 'Student Name : '+this.tempStudname, fontSize: 12, bold: true, alignment: 'left', font: 'TimesNewRoman'
     },
    {
      width:'30%',
      text: 'Admission No. : '+this.tempAdmissionNo, fontSize: 12, bold: true, alignment: 'right', font: 'TimesNewRoman'
   }]  
});

this.content.push( {
  text: '\n', fontSize: 8, alignment: 'center'
} );
this.content.push( {
  columns:[{
      width:'70%',
      text: 'Class Name : '+this.getClassNameById(this.tempClassId)+' - '+this.getSectionNameById(this.tempSectionId), fontSize: 12, bold: true, alignment: 'left', font: 'TimesNewRoman'
   },
  {
    width:'30%',
    text: 'Academic Year : '+this.getAcademicYrById(this.tempAcademicYr), fontSize: 12, bold: true, alignment: 'right', font: 'TimesNewRoman'
 }]  
});
     this.content.push( {
        text: '\n', fontSize: 8, alignment: 'center'
    } );

  
    for ( let i = 0; i < this.feeCollReportArr.length; i++ ) {
        
    this.body.push( [
                     { text: this.feeCollReportArr[i].termName, fontSize: 12, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true,colSpan:6 },
{},{},{},{},{}]);
    this.body.push( [
        { text: 'S.No', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        { text: 'Bill Date', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        { text: 'Receipt No.', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        { text: 'Standard Term Name', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        { text: 'Paid Ledger', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
        { text: 'Paid Amount', fontSize: 10, alignment: 'center', style: 'tableHeader', font: 'TimesNewRoman', bold: true },
         ] );

    for ( let j = 0; j < this.feeCollReportArr[i].list.length; j++ ) {
       
        this.body.push( [
            { text: ( j + 1 ), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: moment(this.feeCollReportArr[i].list[j].collectionDate,'YYYY-MM-DD').format('DD-MM-YYYY'), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: this.feeCollReportArr[i].list[j].receiptId, fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
             { text:this.getStandardTermById(this.feeCollReportArr[i].list[j].standardTermId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: this.getPaidLedgerById(this.feeCollReportArr[i].list[j].paidLedgerId), fontSize: 9, font: 'TimesNewRoman', alignment: 'center' },
            { text: this.feeCollReportArr[i].list[j].overAllPaidTotal, fontSize: 9, font: 'TimesNewRoman', alignment: 'right' },
               ] );
    }

    }
    this.content.push( {
        'table': {
            headerRows: 1,
            'body': this.body,
            widths: ['5%', '10%', '14%' ,'35%', '24%', '12%']
        }
    } );
      const dd = {
        'content': this.content,
        pageOrientation: 'landscape',
        pageSize: 'A4',
        footer: ( currentPage, pageCount ) => {
            return {
                margin: 10,
                columns: [
                    {
                        fontSize: 9,
                        text: [
                            {
                                text: '-----------------------------------------------------------------------------------------------------------------------------------------------------------' +
                                '\n',
                                margin: [0, 20]
                            },
                            {
                                text: this.getAccountPlaceById(this.loggedUser.accId)+ ' Page No : ' + currentPage.toString() + ' of ' + pageCount + ', Print Date : ' + this.documentTime,
                            }
                        ],
                        alignment: 'center'
                    }
                ]
            };
        }
    };

    pdfMake.createPdf( dd ).open();
    this.body = [];
    this.content = [];

}
}

