
import { Component, OnInit } from '@angular/core';
import { DepartmentMasterPojo } from "src/app/pojo/department-master.pojo";
import { MUserMasterPojo } from "src/app/pojo/management-user-pojo";
import { ReceiptHeadPojo } from 'src/app/pojo/receipt-head.pojo';
import { ReceiptHeadService } from './receipt-head.service';
import { LedgercreationService } from '../../Masters/ledgercreation/ledgercreation.service';
import { LedgerPojo } from 'src/app/pojo/ledger.pojo';
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';

declare var $: any;
declare var bootbox: any;

@Component({
  selector: 'app-receipt-head',
  templateUrl: './receipt-head.component.html',
  styleUrls: ['./receipt-head.component.scss']
})

export class ReceiptHeadComponent implements OnInit {

    loggedUser = new MUserMasterPojo();

    
    addHead = new ReceiptHeadPojo();
    editHead = new ReceiptHeadPojo();
    receiptHeadArr:ReceiptHeadPojo[] = [];
    picksledgersByAccountGroup: LedgerPojo[] = [];
    public acc: number[] = [ 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 23];
    
    public data;
    public sortBy = 'i';
    public rowsOnPage = 10;
    public filterQuery = '';
    public sortOrder = 'asc';

    id: number;
    isLoading: boolean = true;
    ledgers: LedgerPojo[];
    ledgerGrpArr:LedgerGroupPojo[]=[];
    specledgers:LedgerPojo[]=[];
    constructor( private receiptheadService:ReceiptHeadService,private ledgercreationService: LedgercreationService,) { }

    ngOnInit() {

        this.loggedUser = JSON.parse( sessionStorage.getItem( 'schoolManagementUserDetail' ) );

        $( '#addAfterClick' ).hide();
        $( '#editAfterClick' ).hide();
        $( '#deleteAfterClick' ).hide();
      // this.getLedgerByAccountGroup(this.acc);
       this.getAllReceiptHead();
       this.getAllLedger();
       this.getLedgerGrpAll();
        }
        getAllLedger() {
            this.ledgercreationService.getLedgerAllByCid(this.loggedUser.accId).subscribe( rs => {
                this.ledgers = rs;
            }, error => {
                alert( error );
            } );
        }  
        getLedgerGrpAll() {
            this.ledgercreationService.getLedgerGroupAll().subscribe( response => {
                this.ledgerGrpArr = response;
              }, error => {
                bootbox.alert( { message: 'Get Ledger Group All Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
              } );
          }
          getLedgerByLedgerGroupId(event){
            this.specledgers=[];
            if(this.addHead.ledgerGroupId>0){
            this.ledgercreationService.getLedgerByLedgerGroupId(this.addHead.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
              this.specledgers = response;
            }, error => {
              bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            } );
          }
          }
        getAllReceiptHead() {
          this.isLoading = true;
          this.receiptheadService.getReceiptHeadAll(this.loggedUser.accId).subscribe( rs => {
              this.receiptHeadArr = rs;
              this.isLoading = false;
    
          }, error => {
              bootbox.alert( 'Error' + error );
              this.isLoading = false;
          } );
      }
     
      getLedgerById( id ) {

        if ( this.ledgers.find( r => r.ledgerId == id ) ) {
            return this.ledgers.find( r => r.ledgerId == id ).ledgerName;
        }
        else {
            return id;
        }
    }
    addClass() {
        ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = true;
        $( '#addBeforeClick' ).hide(); $( '#addAfterClick' ).show();
        this.addHead.accId = this.loggedUser.accId;
        this.addHead.userId = this.loggedUser.userId;
        this.receiptheadService.addReceiptHead( this.addHead ).subscribe( response => {
           let d = new ReceiptHeadPojo();
           d = response;
              if(d.code == 102){
                bootbox.alert('Order Number already assigned to another Receipt head..');
            }
            else{
                bootbox.alert( { message: 'Receipt Head has been added successfully.', className: 'text-success h5', closeButton: false } );
                ( <HTMLFormElement>document.getElementById( 'addReceiptHeadForm' ) ).reset();
                $( '#addModal' ).modal( 'hide' );
                this.getAllReceiptHead();
            }
             ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
              $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
           
        }, error => {
            bootbox.alert( { message: 'Add receipt head Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'addButton' ) ).disabled = false;
            $( '#addBeforeClick' ).show(); $( '#addAfterClick' ).hide();
        } );
    }

    clickEdit( arg ) {
        this.editHead = Object.assign( {}, arg );
        this.getEditLedgerByLedgerGroupId(event);
    }
    getEditLedgerByLedgerGroupId(event){
        this.specledgers=[];
        if(this.editHead.ledgerGroupId>0){
        this.ledgercreationService.getLedgerByLedgerGroupId(this.editHead.ledgerGroupId,this.loggedUser.accId).subscribe( response => {
          this.specledgers = response;
        }, error => {
          bootbox.alert( { message: 'Get Ledger Group By Id Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
        } );
      }
      }

    editClass() {
        let edithead = new ReceiptHeadPojo();
        this.editHead.accId = this.loggedUser.accId;
        this.editHead.userId = this.loggedUser.userId;
         edithead = this.editHead;
        ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = true;
        $( '#editBeforeClick' ).hide(); $( '#editAfterClick' ).show();
        this.receiptheadService.updateReceiptHead( edithead ).subscribe( response => {
            let d = new ReceiptHeadPojo();
           d = response;
             if(d.code == 102){
                bootbox.alert('Order Number already assigned to another Receipt head..');
            }else{
                bootbox.alert( { message: 'Receipt Head has been successfully updated.', className: 'text-success h5', closeButton: false } );
                $( '#editModal' ).modal( 'hide' );
                this.getAllReceiptHead();
            }
             ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
           
        }, error => {
            bootbox.alert( { message: 'Update Receipt head Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            ( <HTMLInputElement>document.getElementById( 'editButton' ) ).disabled = false;
            $( '#editBeforeClick' ).show(); $( '#editAfterClick' ).hide();
        } );
    }

    delete( arg ) {
        this.id = arg;
    }

    deleteConfirm() {
        let no;
        const i = this.receiptHeadArr.indexOf( this.receiptHeadArr.find( r => r.receiptHeadId == this.id ) );
        $( '#deleteBeforeClick' ).hide(); $( '#deleteAfterClick' ).show();
        this.receiptheadService.deleteReceiptHead( this.id,this.loggedUser.loggedUserId ).subscribe( response => {
            no = response;
            if ( no == 0 ) {
                bootbox.alert( { message: 'This row already deleted!', className: 'text-danger h5', closeButton: false } );
                this.receiptHeadArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            } else {
                this.receiptHeadArr.splice( i, 1 );
                $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            }
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
            $( '#deleteModal' ).modal( 'hide' );
            this.getAllReceiptHead();
        }, error => {
            bootbox.alert( { message: 'Delete Receipt Head Service Error! - HTTP Status ' + error.status, className: 'text-danger h5', closeButton: false } );
            $( '#deleteBeforeClick' ).show(); $( '#deleteAfterClick' ).hide();
        } );
    }
}
