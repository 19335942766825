export class PaymentPojo {

    paymentId:number;
    paymentNo:number;
    paymentDate:Date;
    accId:number;
    ledgerId:number;
    dLedgerId:number;
    instrumentType:string;
    instrumentNo:string;
    instrumentDate:Date;
    instrumentDetail:string;
    amount:number;
    narration:string;
    userId:number;
    receiptHeadId:number;
    ledgerGroupId:number;
}