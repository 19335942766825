import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http } from '@angular/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";
import { LedgerPojo } from "src/app/pojo/ledger.pojo";
import { LedgerGroupPojo } from 'src/app/pojo/ledger-group.pojo';
import { LedgerWiseReportPojo } from 'src/app/pojo/ledger-wise-report.pojo';

@Injectable( {
    providedIn: 'root'
} )

export class LedgercreationService {

    constructor( private httpclient: HttpClient ,private http: Http ) { }

    getLedgerAll(): Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerAll';
        return this.httpclient.get<LedgerPojo[]>( url );
    }
    getLedgerAllByAid(aid): Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerAll/'+aid;
        return this.httpclient.get<LedgerPojo[]>( url );
    }
    
    addLedger( arg: LedgerPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddLedger';
        return this.http.post( url, arg );
    }
    
    updateLedger( arg: LedgerPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateLedger';
        return this.http.put( url, arg );
    }
    
    deleteLedger( arg: number,cid:number,uid:number  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteLedger/' + arg + '/'+cid+'/' + uid;
        return this.httpclient.delete( url );
    }
    getLedgerAllByCid(cid:number): Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerAllByCId/'+cid;
        return this.httpclient.get<LedgerPojo[]>(url);
    }

    getLedgerByAccountGroup(arg: any[],cid): Observable<any[]> {
        const url = StaticVariable.porturl + 'GetLedgerByAccountGroup/'+arg+'/'+cid;
        return this.httpclient.get<any[]>(url);
    }
    getLedgerByType(type,cid): Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerByType/'+type+'/'+cid;
        return this.httpclient.get<LedgerPojo[]>(url);
    }
    getUserLedgerAllByCId(cid): Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetUserLedgerAllByCId/'+cid;
        return this.httpclient.get<LedgerPojo[]>(url);
     }
    
    getLedgerCode(accid):  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateLCode/'+accid;
        return this.httpclient.get<any>(url);
    }
    getLedgerByLedgerId(ledgerId:number,accid):  Observable<any> {
        const url = StaticVariable.porturl + 'GetLedgerByLedgerId/'+ledgerId+'/'+accid;
        return this.httpclient.get<any>(url);
    }
    getLedgerByLedgerGroupId(ledgeGrprId:number,accid):  Observable<LedgerPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerByLedgerGroup/'+ledgeGrprId+'/'+accid;
        return this.httpclient.get<LedgerPojo[]>(url);
    }
    //ledger group service
    getLedgerGroupAll(): Observable<LedgerGroupPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerGroupNotPaidAll';
        return this.httpclient.get<LedgerGroupPojo[]>( url );
    }
    getAllLedgerGroup(): Observable<LedgerGroupPojo[]> {
        const url = StaticVariable.porturl + 'GetLedgerGroupAll';
        return this.httpclient.get<LedgerGroupPojo[]>( url );
    }
   
}