import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StaticVariable } from "src/app/globle.class";

import { Http } from '@angular/http';
import { ReceiptPojo } from 'src/app/pojo/receipt.pojo';
@Injectable( {
    providedIn: 'root'
} )

export class ReceiptEntryService {

    constructor( private httpclient: HttpClient,private http: Http ) { }

    getReceiptAll(cid): Observable<ReceiptPojo[]> {
        const url = StaticVariable.porturl + 'GetReceiptAll/'+cid;
        return this.httpclient.get<ReceiptPojo[]>( url );
    }
    
    addReceipt( arg: ReceiptPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'AddReceipt';
        return this.httpclient.post<any>( url, arg );
    }
    
    updateReceipt( arg: ReceiptPojo ): Observable<any> {
        const url = StaticVariable.porturl + 'UpdateReceipt';
        return this.http.put( url, arg );
    }
    
    deleteReceipt( arg: number, uid:number  ): Observable<any> {
        const url = StaticVariable.porturl + 'DeleteReceipt/' + arg +'/' + uid;
        return this.httpclient.delete( url );
    }
    
    getAutoGenerateReceiptNo():  Observable<any> {
        const url = StaticVariable.porturl + 'GetAutoGenerateReceiptNo/';
        return this.httpclient.get<any>(url);
    }
    getReceiptById(id):  Observable<any> {
        const url = StaticVariable.porturl + 'GetReceiptById/'+id;
        return this.httpclient.get<any>(url);
    }
    
}